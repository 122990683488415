import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "reactstrap";
import { useFaqData } from "./services/faqState";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import Apis from "../utils/api";
const FaqDetails = () => {
  const { getfaqData } = useFaqData();
  const [loading, setLoading] = useState(false);
  const api = new Apis();
  const { id } = useParams();
  const [faq] = useState(getfaqData(id));
  console.log(faq);
  const [bodyText, setBodyText] = useState(faq.description);
  const [header, setHeader] = useState(faq.title);

  const handleEditorChange = (content) => {
    setBodyText(content);
  };
  const FaqAdd = async () => {
    try {
      if (!header) {
        throw new Error("Please add title.");
      }
      if (!bodyText) {
        throw new Error("Please add description.");
      }
      const requestBody = {
        id: id,
        title: header,
        description: bodyText,
        parentId: "1",
        status: true,
        type: faq.type,
      };
      setLoading(true);
      const response = await api.updatefaq(requestBody);
      setLoading(false);
      toast.success("Faq Edited SuccessFully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding admin:", error);
      setLoading(false);
    }
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} />
    {loading ? (
      <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
    ) : (
    <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
      <span style={{ fontSize: 20 }}>
        <strong>Faq Details</strong>
      </span>
      <div style={{ flexDirection: "row" }} className="mt-2">
        <div className="mt-3">
          <span>Faq Header*</span>
          <div class="col-md-12 mt-1">
            <div>
              <input class="form-control" placeholder="Enter Mail Header" value={header} onChange={(e) => setHeader(e.target.value)} />
            </div>
          </div>
        </div>

        <div className="mt-3">
          <span>Faq Body*</span>
          <div className="col-md-12 mt-1">
            <Editor
              apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
              value={bodyText}
              init={{
                selector: "textarea#basic-example",
                height: 500,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
              }}
              onEditorChange={handleEditorChange}
            />
          </div>
        </div>
        <div style={{ flexDirection: "row", justifyContent: "flex-end", display: "flex" }} className="mt-3">
        <button onClick={FaqAdd} type="submit" className="btn btn-primary modalFooterButton">
                    Update
                  </button>
        </div>
      </div>
    </div>
    )}
    </>
  );
};

export default FaqDetails;
