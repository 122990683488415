import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { TailSpin } from "react-loader-spinner";

const LoaderModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered backdrop="static">
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <TailSpin
          height="80"
          width="80"
          color="#0514a7"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperClass=""
          visible={true}
        />
        <b style={{color:'white',marginTop:20}} >Please wait </b>
      </div>
    </Modal>
  );
};
<style></style>;

export default LoaderModal;
