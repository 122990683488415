import React, { useState } from "react";
import EmailList from "./EmailList";
import TemplateList from "./TemplateList";
const EmailDetails = () => {
  const [displayType, setDisplayType] = useState("OutBox"); // Initial display type

  const handleDisplayTypeChange = (newDisplayType) => {
    setDisplayType(newDisplayType);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div style={{ marginRight: 10 }}>
          <button
            onClick={() => handleDisplayTypeChange("OutBox")}
            style={{
              color: displayType === "OutBox" ? "#0514A7" : "black",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              transition: "color 0.3s ease-in-out",
            }}
          >
            OutBox
          </button>
        </div>
        <div style={{ marginRight: 10 }}>
          <button
            onClick={() => handleDisplayTypeChange("Templates")}
            style={{
              color: displayType === "Templates" ? "#0514A7" : "black",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              transition: "color 0.3s ease-in-out",
            }}
          >
            Templates
          </button>
        </div>
      </div>
      {displayType === "OutBox" && <EmailList />}
      {displayType === "Templates" && <TemplateList />}
    </div>
  );
};

export default EmailDetails;
