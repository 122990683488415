import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useCouponState } from "./services/couponState";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Table, Form, FormGroup, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { Editor } from "@tinymce/tinymce-react";
import { useServiceList } from "../views/services/serviceListState";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import LoaderModal from "../component/common/Loader";
const EditCoupon = () => {
  const api = new Apis();
  const [isUsed, setIsUsed] = useState(false);
  const toggleIsUsed = () => {
    setIsUsed(!isUsed);
  };
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);
  const handleScheduleToggle = () => {
    setIsScheduleChecked(!isScheduleChecked);
  };
  const { id } = useParams();
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const { getCouponData } = useCouponState();
  const [CouponData] = useState(getCouponData(id));
  const [loading, setLoading] = useState(false);
  const [discription, setDiscription] = useState(CouponData.description);
  const [couponCode, setCouponCode] = useState(CouponData.code);
  const [couponQuantity, setCouponQuantity] = useState(CouponData.quantity);
  const [shareQuantity, setShareQuantity] = useState(CouponData.quantity - CouponData.usedCount);
  const [usedCount, setUsedCount] = useState(CouponData.usedCount);
  const [FurrDiscount, setFurrDiscount] = useState(CouponData.discount);
  const [selectedType, setSelectedType] = useState(CouponData.publishedFor);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [petParentOptions, setPetParentOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment(CouponData.startDate).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(CouponData.endDate).format("YYYY-MM-DD"));
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [petparentDatas, setSelectedPetParentDatas] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(CouponData.discountType);
  const [showDiscount, setShowDiscount] = useState(false); // Add a state for showing/hiding discount
  const animatedComponents = makeAnimated();
  const [selectedCollab, setSelectedCollab] = useState();
  const [WorkspacelistId, setWorkspacelistId] = useState(null);
  const [newStartTime, setStartNewTime] = useState();
  const [newEndTime, setEndNewTime] = useState();
  const handleStartDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix();
    setStartDate(inputDate);
    setStartNewTime(unixTimestamp*1000);
  };
  const handleEndDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix();
    setEndDate(inputDate);
    setEndNewTime(unixTimestamp*1000);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");
  const [selectedID, setSelectedID] = useState([]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState([]);
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    try {
      const selectedWorkspace = workspaceDatas.find((workspace) => workspace.designationName === event.target.value);
      console.log("Selected Workspace:", selectedWorkspace);
      if (selectedWorkspace && selectedWorkspace.id !== undefined) {
        setSelectedID(selectedWorkspace.collaboratorId);
        setSelectedWorkspaceId(selectedWorkspace.id);
        console.log("Selected Workspace ID:", selectedWorkspace);
      } else {
        setSelectedID([]);
        setSelectedWorkspaceId([]);
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };
  const { getServiceListData } = useServiceList();
  // Share Option
  const [shareOption, setSelectedShare] = useState("Email");
  const handleCheckboxChange = (e) => {
    setShowDiscount(e.target.checked); // Set showDiscount based on checkbox state
  };
  const handleDiscountChange = (event) => {
    setSelectedDiscount(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleShare = (event) => {
    setSelectedShare(event.target.value);
  };
  // coupon info
  // const [selectedRows, setSelectedRows] = useState([]);
  // const columns = ["checkbox", ...Object.keys(data[0])];
  // const toggleRowSelection = (rowIndex) => {
  //   if (selectedRows.includes(rowIndex)) {
  //     setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
  //   } else {
  //     setSelectedRows([...selectedRows, rowIndex]);
  //   }
  // };
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  useEffect(() => {
    if (selectedType === "Veterinary") {
      setWorkspacelistId("d8d71038-c48c-11ed-abc1-02817ef0919c");
    } else if (selectedType === "Grooming") {
      setWorkspacelistId("5e4c6b60-c48e-11ed-abc1-02817ef0919c");
    }
  }, [selectedType]);
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const petParentData = await api.getPetParentList();
        setSelectedPetParentDatas(petParentData);
        console.log(petParentData);
        const designationNames = petParentData.map((item) => item.name);
        setPetParentOptions(designationNames);
        console.log("petParentData", petParentData);
      } catch (error) {
        console.error("Error fetching pets:", error);
      }
    };
    fetchWorkspace();
  }, []);
  const userid = localStorage.getItem("userId") || null;
  const editCoupon = async () => {
    try {
      if (!couponCode) {
        throw new Error("Please fill out the couponCode.");
      }
      if (FurrDiscount <= 0) {
        throw new Error("Discount value is invalid");
      }
      if (!discription) {
        throw new Error("Please fill out the Discription.");
      }
      if (!couponQuantity) {
        throw new Error("Please fill out the coupon quantity.");
      }
      if (!startDate) {
        throw new Error("Please fill out the startDate.");
      }
      if (!endDate) {
        throw new Error("Please select a endDate.");
      }
      if (selectedCollab === "") {
        console.log(selectedCollab);
        throw new Error("Please select a workspace.");
      }
      const requestBody = {
        id: id,
        code: couponCode,
        createdBy: userid,
        expiryDate: newEndTime || CouponData.expiryDate,
        couponName: couponCode,
        quantity: couponQuantity,
        discount: FurrDiscount,
        startDate: newStartTime || CouponData.startDate,
        endDate: newEndTime || CouponData.endDate,
        split: CouponData.split,
        publishedFor: selectedType,
        published: false,
        collabDiscount: FurrDiscount,
        discountType: selectedDiscount,
        description: discription,
        active:CouponData.active,
        type: selectedType,
        usedCount:CouponData.usedCount||0,
        refId: (() => {
          if (selectedType === "Open") {
            return "1";
          } else if (selectedType === "Events") {
            return selectedEventId;
          } else if (selectedType === "Webinar") {
            return selectedWebinarID;
          } else if (selectedType === "Course") {
            return seletedCourseId;
          } else if (selectedType === "Veterinary" || selectedType === "Grooming") {
            return selectedWorkspaceId;
          } else {
            return "1";
          }
        })(),
      };
      setLoading(true);
      const response = await api.updateCoupon(1, requestBody);
      setLoading(false);
      toast.success("Coupon updated success"); //todo check success not app
      console.log("API response:", response);
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Error adding user: " + error.message);
    }
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "appointments",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getStatsViewsLikesShares(id, "COUPON");
        setData(
          response.map((item) => ({
            ...item,
            usedAt: new Date(item.usedAt).toLocaleString(), // Convert usedAt to local time
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]); // Make sure to include dependencies if necessary

  const toggleRowSelection = (rowIndex) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowIndex)) {
        return prevSelectedRows.filter((row) => row !== rowIndex);
      } else {
        return [...prevSelectedRows, rowIndex];
      }
    });
  };

  const columns = ["Name", "Email", "Mobile", "Used At"];
  const [eventList, setEventList] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(selectedType === "Events" ? CouponData.refId || "" : "");
  const [selectedWebinarID, setSeletedWebinarId] = useState(selectedType === "Webinar" ? CouponData.refId || "" : "");
  const [seletedCourseId, setSelectedCourseId] = useState(selectedType === "Course" ? CouponData.refId || "" : "");
  console.log("Selected Course ID", seletedCourseId);
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceId = selectedType === "Veterinary" ? "d8d71038-c48c-11ed-abc1-02817ef0919c" : "5e4c6b60-c48e-11ed-abc1-02817ef0919c";
        console.log("Selected option:", selectedType);
        console.log("Fetching workspace with ID:", workspaceId);
  
        const workspaceData = await api.getWorkspace(workspaceId);
        console.log("Fetched workspace data:", workspaceData);
  
        // Filter out only active workspaceData
        const activeWorkspaceData = workspaceData.filter(workspace => workspace.active === true);
        console.log("Active workspace data:", activeWorkspaceData);
  
        setSelectedWorkspacDatas(activeWorkspaceData);
        console.log("Selected workspace data set:", activeWorkspaceData);
  
        // Create workspaceOptions array with the correct structure
        const combinedNames = activeWorkspaceData.map((workspace) => ({
          designationName: workspace.designationName,
          workplaceName: workspace.workplaceName,
          id: workspace.id // Include ID for matching with refId
        }));
        setWorkspaceOptions(combinedNames);
        console.log("Workspace options set:", combinedNames);
  
        // Preselect the option if refId matches with workspace id
        const preselectedWorkspace = combinedNames.find(workspace => workspace.id === CouponData.refId);
        if (preselectedWorkspace) {
          setSelectedWorkspaceName(preselectedWorkspace.designationName);
          setSelectedWorkspaceId(preselectedWorkspace.id);
          setSelectedID(preselectedWorkspace.collaboratorId);
        }
      } catch (error) {
        console.error("Error fetching workspace:", error);
      }
    };
    fetchWorkspace();
  }, [selectedType]);
  
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const events = await api.getEventList();
        setEventList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEventList();
  }, []);

  const handleEventChange = (event) => {
    setSelectedEventId(event.target.value);
  };
  const [webinarList, setWebinarList] = useState();
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const events = await api.getWebinar();
        setWebinarList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEventList();
  }, []);
  const handleWebinarChange = (event) => {
    setSeletedWebinarId(event.target.value);
  };
  const [courseList, setCourseList] = useState();
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const events = await api.getCourse();
        setCourseList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEventList();
  }, []);
  const handleCourseChange = (event) => {
    setSelectedCourseId(event.target.value);
  };
  return (
    //   <div className="form-group col-md-12 mt-2">
    //   <div className="row   ">
    //     <div className="form-group  col-md-6 ">
    //       <label>Unassigned Coupon</label>
    //       <input
    //         type="text"
    //         className="form-control"
    //         id="couponQuantity"
    //         value={shareQuantity}
    //         disabled={true}
    //       />
    //     </div>
    //     <div className="col-md-6 form-group">
    //       <label>Used Coupon</label>
    //       <input
    //         type="text"
    //         className="form-control"
    //         id="endDateTime"
    //         value={usedCount}
    //         disabled={true}
    //       />
    //     </div>
    //   </div>
    // </div>
    // <div className="form-group col-md-12 mt-2">
    //   <div className="row   ">
    //     <div className="form-group  col-md-6 ">
    //       <label>Unused Coupon</label>
    //       <input
    //         type="text"
    //         className="form-control"
    //         id="couponQuantity"
    //         value={0}
    //         disabled={true}
    //       />
    //     </div>
    //     </div>
    //     </div>
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          <span style={{ fontSize: 20, marginLeft: "3%" }}>
            <b>Coupon Details</b>
          </span>
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 form-group"
                style={{
                  marginLeft: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="d-flex ">
                  <div>
                    <b>Unassigned Coupon :</b>
                    <span className="mx-2">{shareQuantity}</span>
                  </div>
                  <div className="mx-5">
                    <b>Used Coupon :</b>
                    <span className="mx-2">{usedCount}</span>
                  </div>
                  <div>
                    <b>Unused Coupon :</b>
                    <span className="mx-2">0</span>
                  </div>
                </div>
                <Button color="primary" onClick={toggleModal}>
                  <FontAwesomeIcon color="white" fontSize={20} icon={faShare} />
                  <span style={{ marginLeft: "5px" }}>Share Coupon</span>
                </Button>
              </div>
              <div className="col-md-12 mt-3 d-flex">
                <div className="col-md-6" style={{ flexDirection: "column", display: "flex" }}>
                  <div className="form-group mt-1">
                    <label>Coupon Code*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="customName"
                      placeholder="Enter Coupon Code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <select className="form-control" id="dropdown" value={selectedType} onChange={handleTypeChange}>
                      <option value="Open">FurrCrew</option>
                      <option value="Veterinary">Veterinary</option>
                      <option value="Grooming">Grooming</option>
                      <option value="Events">Events</option>
                      <option value="Webinar">Webinar</option>
                      <option value="Course">Course</option>
                    </select>
                  </div>

                  {selectedType === "Veterinary" || selectedType === "Grooming" ? (
                    <div className="form-group mt-2">
                      <label htmlFor="inputPetsSpecies">Select Workspace</label>
                      <select id="inputPetsSpecies" className="form-control" onChange={handleWorkspaceChange} value={selectedWorkspaceName}>
                        {workspaceOptions.map((workspace) => (
                          <option key={workspace.designationName} value={workspace.designationName}>
                            {`${workspace.designationName} - ${workspace.workplaceName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : selectedType === "Events" ? (
                    <div className="form-group mt-2">
                      <label htmlFor="inputEventList">Select Event</label>
                      <select id="inputEventList" className="form-control" onChange={handleEventChange} value={selectedEventId}>
                        {eventList &&
                          eventList.map((event) => (
                            <option key={event.id} value={event.id}>
                              {event.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : selectedType === "Webinar" ? (
                    <div className="form-group mt-2">
                      <label htmlFor="inputEventList">Select Webinar</label>
                      <select id="inputEventList" className="form-control" onChange={handleWebinarChange} value={selectedWebinarID}>
                        {webinarList &&
                          webinarList.map((webinar) => (
                            <option key={webinar.id} value={webinar.id}>
                              {webinar.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : selectedType === "Course" ? (
                    <div className="form-group mt-2">
                      <label htmlFor="inputEventList">Select Course</label>
                      <select id="inputEventList" className="form-control" onChange={handleCourseChange} value={seletedCourseId}>
                        {courseList &&
                          courseList.map((webinar) => (
                            <option key={webinar.id} value={webinar.id}>
                              {webinar.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : null}
                  <div className="form-group mt-1 ">
                    <label>Total Discount*</label>
                    <div className="d-flex">
                      <input type="number" className="form-control" id="FurrDiscount" value={FurrDiscount} onChange={(e) => setFurrDiscount(e.target.value)} />
                      <select style={{ borderRadius: "6px", marginLeft: 8 }} id="dropdown" value={selectedDiscount} onChange={handleDiscountChange}>
                        <option value="%">%</option>
                        <option value="Rs">Rs</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ flexDirection: "column", display: "flex" }}>
                  <div className="form-group">
                    <label>Discription*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="discription"
                      placeholder="Enter Discription"
                      value={discription}
                      onChange={(e) => setDiscription(e.target.value)}
                      rows={5}
                    />
                  </div>
                  <div className="form-group mt-2  ">
                    <label>Coupon Quantity*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="couponQuantity"
                      placeholder="Enter coupon quantity"
                      value={couponQuantity}
                      onChange={(e) => setCouponQuantity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-12 mt-2">
                <div className="row d-flex" style={{ alignItems: "center" }}>
                  {selectedType === "Veterinary" || selectedType === "Grooming" ? (
                    <div className="col-md-6 form-group">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        onChange={handleCheckboxChange} // Handle checkbox change event
                      />
                      <label className="form-check-label" htmlFor="exampleCheck1">
                        There is an FC Split.
                      </label>
                    </div>
                  ) : null}
                  <div className="col-md-6 form-group">
                    {showDiscount && (selectedType === "Veterinary" || selectedType === "Grooming") ? (
                      <div className="mt-1">
                        <label>Discount by {selectedType}*</label>
                        <div className="d-flex">
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="FurrDiscount"
                              value={FurrDiscount}
                              onChange={(e) => setFurrDiscount(e.target.value)}
                            />
                            <div className="input-group-prepend">
                              <span className="input-group-text">{selectedDiscount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group col-md-12 mt-2">
                <div className="row  ">
                  <div className="col-md-6 form-group">
                    <label>Start Date*</label>
                    <input type="date" className="form-control" id="startDateTime" value={startDate} onChange={handleStartDateChange} />
                  </div>
                  <div className="col-md-6 form-group">
                    <div className="row  ">
                      <label>End Date*</label>
                      <input type="date" className="form-control" id="endDateTime" value={endDate} onChange={handleEndDateChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-2">
            <div className="row">
              <div className="col-md-6 form-group" style={{ marginLeft: 10 }}>
                <span style={{ fontSize: 20 }}>
                  <b>Coupon Usage</b>
                </span>
              </div>
              <div className="form-group">
                <Table hover className="form-group">
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <td style={{ cursor: "pointer" }} key={colIndex}>
                            {column === "Used At" ? item.usedAt : item[column.toLowerCase()]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <h5>Internal Note</h5>
              <div className="chat-container">
                <div className="chat-messages">
                  {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                      <div className="message-text">{message.text}</div>
                      <div className="message-time">{getCurrentLocalDateTime()}</div>
                    </div>
                  ))}
                  {internalNotes.map((note) => (
                    <div key={note.id} className={`message bot`}>
                      <div className="message-text">{note.note}</div>
                      <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                    </div>
                  ))}
                </div>
                <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                  <textarea
                    className="form-control"
                    rows={5}
                    type="text"
                    placeholder="Type your message..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                    <button className="form-control" style={{ width: 100 }} type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Modal isOpen={isModalOpen} toggle={toggleModal} className="modal-lg">
            <ModalHeader toggle={toggleModal}>Share Coupon</ModalHeader>
            <ModalBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <b>Share Via:</b>
                  <div className="form-check" style={{ display: "inline-block", marginLeft: 10 }}>
                    <input className="form-check-input" type="radio" value="Email" checked={shareOption === "Email"} onChange={handleShare} />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Email
                    </label>
                  </div>
                  <div className="form-check mx-2" style={{ display: "inline-block" }}>
                    <input className="form-check-input" type="radio" value="Sms" checked={shareOption === "Sms"} onChange={handleShare} />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Sms
                    </label>
                  </div>
                </div>
                <div>
                  <b style={{ marginRight: 10 }}>Unassigned coupon :</b>
                  <span>{shareQuantity}</span>
                </div>
              </div>
              <div className="mt-3">
                <b>Add Pet Parent*</b>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={[]}
                  isMulti={selectedType === "All" ? false : true}
                  options={[
                    ...petParentOptions.map((workspace) => ({
                      value: workspace,
                      label: workspace,
                    })),
                  ]}
                  onChange={(selectedValues) => {
                    let selectedCollab = "";
                    if (selectedValues.some((option) => option.value === "text-top")) {
                      if (selectedType === "All") {
                        selectedCollab = selectedType;
                      } else {
                        selectedCollab = selectedValues
                          .filter((option) => option.value !== "text-top")
                          .map((option) => option.label)
                          .join(", ");
                      }
                    } else {
                      selectedCollab = selectedValues.map((option) => option.label).join(", ");
                    }
                    const usersSelected = selectedValues.length;
                    setShareQuantity(CouponData.quantity - CouponData.usedCount - usersSelected);
                    console.log(selectedCollab);
                    setSelectedCollab(selectedCollab);
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 25,
                  }}
                >
                  <Button
                    style={{
                      background: "#007bff",
                      border: "none",
                    }}
                    onClick={() => toast.error("Functionality not yet added")}
                  >
                    Share Coupon
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Modal>
          <div className="mt-3">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 25,
              }}
            >
              <Button
                onClick={editCoupon}
                style={{
                  marginRight: 5,
                  background: "#007bff",
                  border: "none",
                }}
              >
                Save
              </Button>
              <Button
                style={{
                  marginRight: 5,
                  border: "none",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCoupon;
