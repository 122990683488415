// DocumentUploader.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function DocumentUploader({
  documentNumber,
  documentName,
  selectedDocument,
  handleUpload,
  handleDelete,
  onAadharTextChange,
  aadharText,
  onPancardTextChange,
  panText,
  onDoctorTextChange,
  doctorText,
  onQualifcationTextChange,
  qualificationText,
}) {
  return (
    <div
      className="pt-2"
      style={{
        marginRight: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <span className="documentNumber">{documentNumber}</span>
        <span className="documentName">{documentName}</span>
      </div>
      <span style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
        {selectedDocument ? (
          <>
            <img src={URL.createObjectURL(selectedDocument)} alt="Selected Document" style={{ maxWidth: 100, maxHeight: 100, marginRight: 10 }} />
            <div style={{ marginRight: 10 }}>
              {documentName === "Aadhar Card" && (
                <input
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Aadhar Number"
                  value={aadharText}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onAadharTextChange(newValue); // Invoke the callback function
                  }}
                />
              )}
              {documentName === "PAN Card" && (
                <input
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Pan Number"
                  value={panText}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onPancardTextChange(newValue); // Invoke the callback function
                  }}
                />
              )}
              {documentName === "Doctor’s Licence" && (
                <input
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Doctor Licence Number"
                  value={doctorText}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onDoctorTextChange(newValue); // Invoke the callback function
                  }}
                />
              )}
              {documentName === "Qualification Certificates" && (
                <input
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Document Number"
                  value={qualificationText}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onQualifcationTextChange(newValue); // Invoke the callback function
                  }}
                />
              )}
              {documentName === "NGO Certificate" && (
                <input
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter NGO Certificate Number"
                  value={panText}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onPancardTextChange(newValue); // Invoke the callback function
                  }}
                />
              )}
            </div>
            <button type="button" className="btn btn-light navbar-buttons" onClick={handleDelete}>
              <FontAwesomeIcon color="#007bff" fontSize={20} icon={faTrash} />
            </button>
          </>
        ) : (
          <>
            <input type="file" accept="image/*" onChange={handleUpload} style={{ display: "none" }} id={`${documentName}-upload-input`} />
            <label htmlFor={`${documentName}-upload-input`} className="btn btn-light navbar-buttons" style={{ marginRight: 10 }}>
              <span className="navbar-button-text">Upload</span>
            </label>
          </>
        )}
      </span>
    </div>
  );
}

export default DocumentUploader;
