import React, { useState } from "react";
import { CardGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import dataURItoBlob, { isAlphaNumeric } from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import {Form,FormGroup,Input,} from "reactstrap";
const BlogDetail = () => {
  const api = new Apis();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [bodyText, setBodyText] = useState("");
  const [loading, setLoading] = useState(false);
  const handleEditorChange = (content) => {
    setBodyText(content);
  };
  const [customTitle, setCustomTitle] = useState("");
  const [imageName,setImageName]=useState();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    console.log(file);  
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 1920 && img.height === 1080) {
            setSelectedImage(e.target.result);
          } else {
            toast.error("Image should be 1920 x 1080");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = () => {
    setSelectedImage(null);
  };
  const [thumbImage, setThumbImage] = useState(null);

  const handleThumbImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 2160 && img.height === 2160) {
            setThumbImage(e.target.result);
          } else {
            // Image doesn't meet the size criteria
            toast.error("Please upload an image with dimensions 2160px by 2160px.");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleThumbImageDelete = () => {
    setThumbImage(null);
  };
  const timeOptions = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);
  const BlogAdd = async () => {
    try {
      // Basic validation checks
      if (!name || !isAlphaNumeric(name)) {
        throw new Error("Name is a required field");
      }
      if (!selectedImage) {
        throw new Error("Image is a required field");
      }
      if (!title) {
        throw new Error("Please provide a title for the blog.");
      }
      if (!time) {
        throw new Error("Please provide the read time span for the blog.");
      }
      if (!bodyText) {
        throw new Error("Please provide the content of the blog.");
      }
      if (publishType === "WEB" || publishType === "BOTH") {
        if (!thumbImage) {
          throw new Error("Cover image is required for WEB or BOTH publishType.");
        }
      }
      const requestBody = {
        title: title,
        status: false,
        // type: "Vet",
        readTimeSpan: time + " Min",
        data: bodyText,
        category: type,
        tags: tags,
        author: name,
        deviceType: publishType,
        featured:featuredSwitchState,
        sponsored:switchState,
        test:test,
        slug:customTitle
      };
      setLoading(true);
      const response = await api.addBlog(requestBody);
      setLoading(false);
      toast.success("Blog added successfully!");
      console.log(response);
      if (response.id) {
        console.log(response.id);
        setLoading(true);
        await uploadImage(response.id);
        setLoading(false);
      } else {
        throw new Error("Response ID is undefined.");
        setLoading(false);
      }
      if (response.id) {
        console.log(response.id);
        setLoading(true);
        await uploadCoverImage(response.id);
        setLoading(false);
      } else {
        throw new Error("Response ID is undefined.");
        setLoading(false);
      }
      navigate(-1);
    } catch (error) {
      console.error("Error adding blog:", error);
      toast.error("Error adding blog: " + error.message);
      setLoading(false);
    }
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("files", dataURItoBlob(selectedImage), fileName);
      const response = await api.uploadBLogImage(userId, formData);
      console.log("Img", response);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbImage) {
        console.log("No new image selected for upload.");
        return; 
      }
      console.log(userId);
      const formData = new FormData()
      formData.append("file", dataURItoBlob(thumbImage), `image_$.png`);
      const response = await api.uploadBlogCoverImage(userId, formData);
      console.log("CoverImg", response);
      toast.success("Blog added successfully");
      // navigate(-1);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  console.log(time);
  const [tags, setTags] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // If the last character is a space, add a hashtag automatically
    if (inputValue.endsWith(" ")) {
      const newTag = inputValue.trim();
      if (newTag === "") {
        setError("Tag cannot be empty.");
        return;
      }
      if (newTag.includes(" ")) {
        setError("Tag cannot contain spaces.");
        return;
      }
      if (tags.includes(`#${newTag}`)) {
        setError("Tag with the same name already exists.");
        return;
      }
      setTags((prevTags) => prevTags + `#${newTag} `);
      setInputValue("");
      setError("");
    } else {
      setInputValue(inputValue);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newTag = inputValue.trim();
      if (newTag === "") {
        setError("Tag cannot be empty.");
        return;
      }
      if (newTag.includes(" ")) {
        setError("Tag cannot contain spaces.");
        return;
      }
      if (tags.includes(`#${newTag}`)) {
        setError("Tag with the same name already exists.");
        return;
      }
      setTags((prevTags) => prevTags + `#${newTag} `);
      setInputValue("");
      setError("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags.replace(tagToRemove, "").trim();
    setTags(updatedTags);
  };
  const [type, setType] = useState("blog");
  const handleType = (event) => {
    setType(event.target.value);
  };
  const [publishType, setPublishType] = useState("MOBILE");
  const handlePublishType = (event) => {
    setPublishType(event.target.value);
  };
  const [switchState, setSwitchState] = useState(false);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(false);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const [test, setTest] = useState(false);
  const toggleTest = () => {
    setTest(!test);
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div className="row mt-2">
          <div className="col-md-4">
            <CardGroup
              className="p-3"
              style={{
                border: "1px solid #beb7b7",
                borderRadius: 10,
                marginRight: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div className="col-md-12">
                  <div className="form-group p-0">
                    <label className="pt-10 lableText">
                      Name
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Name of the author"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mt-2 p-0">
                  <label className="pt-10 lableText">
                    Image
                    <span className="required">*</span>
                  </label>
                  <div className="imageUploadBox">
                    <div className="text-right">
                      <label htmlFor="uploadInput">
                        <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleImageUpload} />
                        <Button
                          // onClick={handleUploadClick}
                          color="secondary"
                          as="span"
                        >
                          Upload
                        </Button>
                      </label>
                      {selectedImage && (
                        <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleImageDelete}>
                          <span className="vetUploadButton">
                            <FontAwesomeIcon fontSize={20} icon={faTrash} />
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex flex-wrap">
                          <div className="col-md-4 d-flex align-items-center pt-3">
                            {selectedImage && <img src={selectedImage} alt="Uploaded" style={{ maxWidth: "100%" }} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {publishType !== "MOBILE" && (
                  <div className="form-group mt-2 p-0">
                    <label className="pt-10 lableText">
                      Thumb Image
                      <span className="required">*</span>
                    </label>
                    <div className="imageUploadBox">
                      <div className="text-right">
                        <label htmlFor="thumbUploadInput">
                          <input type="file" id="thumbUploadInput" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                          <Button
                            // onClick={handleUploadClick}
                            color="secondary"
                            as="span"
                          >
                            Upload
                          </Button>
                        </label>
                        {thumbImage && (
                          <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                            <span className="vetUploadButton">
                              <FontAwesomeIcon fontSize={20} icon={faTrash} />
                            </span>
                          </button>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex flex-wrap">
                            <div className="col-md-4 d-flex align-items-center pt-3">
                              {thumbImage && <img src={thumbImage} alt="Uploaded" style={{ maxWidth: "100%" }} />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardGroup>
            <CardGroup
              className="p-3"
              style={{
                border: "1px solid #beb7b7",
                borderRadius: 10,
                marginRight: 10,
                marginTop: 30,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div>
                  <label className="lableText">Tags</label>
                  <div className="tag-container d-flex flex-wrap form-group p-0 ">
                    {tags.split(" ").map((tag, index) => (
                      <div
                        key={index}
                        style={{ backgroundColor: "#0d6efd", color: "white", borderRadius: 5, padding: 5, marginRight: 5, marginBottom: 5 }}
                        className="tag"
                      >
                        {tag}
                        <FontAwesomeIcon style={{ marginLeft: 5 }} onClick={() => handleTagRemove(tag)} fontSize={20} icon={faXmark} />
                      </div>
                    ))}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={inputValue}
                    style={{ width: "100%", marginTop: 10 }}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder="Add tags and press Enter"
                  />
                  {error && (
                    <p style={{ color: "red", marginTop: 5 }} className="error-message">
                      {error}
                    </p>
                  )}
                </div>
              </div>
            </CardGroup>
          </div>
          <div className="col-md-8 p-3" style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
            <div
              style={{
                marginRight: 10,
              }}
            >
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4>Create</h4>
                  <div style={{ flexDirection: "row", marginLeft: 10 }} className="">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="blog" id="blog" value="blog" checked={type === "blog"} onChange={handleType} />
                      <label className="form-check-label" htmlFor="blog">
                        Blog
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="article"
                        id="article"
                        value="article"
                        checked={type === "article"}
                        onChange={handleType}
                      />
                      <label className="form-check-label" htmlFor="article">
                        Educational Blog
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Featured</label>
                  </span>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Sponsored</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Test</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={test} onChange={toggleTest} />
                  </FormGroup>
                </Form>
              </div>
              </div>
                <div style={{ flexDirection: "row", marginTop: 10 }} className="">
                  <div>
                    <label className="lableText">
                      <span style={{ color: "#6b7280", fontWeight: 600 }}>Publish For</span>
                      <span className="required">*</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="MOBILE"
                      id="MOBILE"
                      value="MOBILE"
                      checked={publishType === "MOBILE"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="MOBILE">
                      FurrCrewApp
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="WEB"
                      id="WEB"
                      value="WEB"
                      checked={publishType === "WEB"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="WEB">
                      WebSite
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="BOTH"
                      id="BOTH"
                      value="BOTH"
                      checked={publishType === "BOTH"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="BOTH">
                      Both
                    </label>
                  </div>
                </div>
                <div className="form-group p-0 mt-2">
                  <label className="lableText">
                    Title
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control form-control-lg mt-2"
                    placeholder="Enter main title here"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="form-group p-0 mt-2">
                  <label className="lableText">
                    Custom Title
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control form-control-lg mt-2"
                    placeholder="Enter custom title here"
                    value={customTitle}
                    onChange={(e) => setCustomTitle(e.target.value)}
                  />
                </div>
                <div className="form-group p-0 mt-2">
                  <label className="lableText">Read Time*</label>
                  <div
                    className="col-md-12"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <select name="secondaryBlogTitle" className="form-control form-control-lg mt-2" value={time} onChange={(e) => setTime(e.target.value)}>
                      <option value="">Select Time</option>
                      {timeOptions.map((option) => (
                        <option key={option} value={option}>
                          {option} min
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group p-0 mt-2">
                  <label className="lableText">
                    Body Text
                    <span className="required">*</span>
                  </label>
                  <Editor
                    apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                    value={bodyText}
                    init={{
                      selector: "textarea#basic-example",
                      height: 500,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                <div className="mt-5">
                  <button onClick={BlogAdd} type="submit" className="btn btn-primary modalFooterButton">
                    Create
                  </button>
                  <button onClick={() => navigate(-1)} type="button" className="btn btn-secondary modalFooterButton">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogDetail;
