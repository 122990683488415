import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUserAlt,faPaw,faUserFriends,faBuilding,faBell,faGift,faEnvelope,faExclamation,faCog,faExclamationCircle,faAlignLeft,faHeartCircleCheck,faVideo,faBriefcase,faDoorOpen, faEdit,faRectangleAd, faTicket, faBuildingColumns, faChalkboardUser, faBookJournalWhills, faBook, faQuestion,} from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/Furr.png";
function Sidebar({ isLoggedIn ,handleLogout}) {
  const [sidebarActive, setSidebarActive] = useState(false);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setSidebarActive((prevState) => !prevState);
  };
  const { id } = useParams();
  const location = useLocation();
  const isRouteActive = (path) => {
    return location.pathname.includes(path);
  };
  return (
    <>
      <div>
        <div className="wrapper">
          <nav id="sidebar" className={sidebarActive ? "active" : ""}>
            <div className="row text-center">
              <div className="col-md-12 sidebarHeaderImg mt-3">
                <div
                  style={{
                    flexDirection: sidebarActive ? "column-reverse" : "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Logo}
                    className="img-thumbnail"
                    alt="Cinque Terre"
                    onClick={toggleSidebar}
                    style={{
                      // marginBottom: sidebarActive ? "0" : "0px",
                      marginLeft: sidebarActive ? "0" : "10px",
                    }}
                  />
                  {!sidebarActive ? (
                    <span style={{ marginLeft: 20 }}> Admin Panel</span>
                  ) : null}
                </div>
              </div>
            </div>

            <ul className="list-unstyled components">
              <hr style={{ border: "1px solid #ffffff" }} />
              <li>
                <a>
                  <FontAwesomeIcon fontSize={20} icon={faUserAlt} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">
                      User Management {process.env.REACT_APP_API}
                    </span>
                  )}
                </a>
                <div></div>
                <ul className="list-unstyled" id="homeSubmenu">
                  <li>
                    <a
                      className={
                        isRouteActive("/PetParent") ||
                        isRouteActive(`/PetParent/PetParentDetails/`) ||
                        isRouteActive(
                          "/PetParent/PetParentDetails/AddPetDetails"
                        ) ||
                        isRouteActive("/PetParent/AddUser")
                          ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/PetParent")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faPaw} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Pet Parent</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/CollaboratorList") ||
                        isRouteActive("/CollaboratorList/EditCollaborator") ||
                        isRouteActive("/CollaboratorList/AddCollaborator")
                          ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/CollaboratorList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faUserFriends} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Collaborator</span>
                      )}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className={
                    isRouteActive("/WorkSpaceList") ||
                    isRouteActive("/CollaboratorList/WorkspaceDetails") ||
                    isRouteActive("/CollaboratorList/NewWorkspace")
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/WorkSpaceList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faBriefcase} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Workspace Management</span>
                  )}
                </a>
              </li>
              <li>
                <a
                  className={
                    isRouteActive("/AppointmentList") ||
                    isRouteActive("/AppointmentList/AddAppointment") ||
                    isRouteActive(`/AppointmentList/AppointmentDetails/${id}`)
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/AppointmentList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faBuilding} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">
                      Appointment Management
                    </span>
                  )}
                </a>
              </li>
              <li>
                <a
                  className={
                    isRouteActive("/RollsList") ||
                    isRouteActive("/RollsList/EditRoll")
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/RollsList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faVideo} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Rolls Management</span>
                  )}
                </a>
              </li>
              <li>
                <a
                  className={
                    isRouteActive("/AdoptionList") ||
                    isRouteActive("/AdoptionList/EditAdoption") ||
                    isRouteActive("/AdoptionList/CreateAdoption")
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/AdoptionList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faHeartCircleCheck} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Adoption Management</span>
                  )}
                </a>
              </li>
              <li>
                <a
                  className={
                    isRouteActive("/NotificationList") ||
                    isRouteActive("/NotificationList/CreateNotification") ||
                    isRouteActive("/NotificationList/EditNotification")
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/NotificationList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faBell} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Push Notifications</span>
                  )}
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a
                  className={
                    isRouteActive("/CouponList") ||
                    isRouteActive("/CouponList/EditCoupon")
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/CouponList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faGift} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Coupon Management</span>
                  )}
                </a>
              </li>
              {/* <li>
                <a
                  className={
                    isRouteActive("/EmailDetails") ||
                    isRouteActive("/EmailDetails/NewMail") ||
                    isRouteActive("/EmailDetails/NewTemplate")
                      ? "activeLink"
                      : "inactiveLink"
                  }
                  onClick={() => navigate("/EmailDetails")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faEnvelope} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Emails</span>
                  )}
                </a>
              </li> */}
              <li>
                <a>
                  <FontAwesomeIcon fontSize={20} icon={faExclamationCircle} />
                  {!sidebarActive && (
                    <span className="sidebarHeading menuItem-active-link">
                      Support
                    </span>
                  )}
                </a>
              </li>
              <li>
              <a
                  // className={
                  //   isRouteActive("/MiscellaneousList") ||
                  //   isRouteActive("/MyAccount") ||
                  //   isRouteActive("/MiscellaneousList/RollsPermissionList") ||
                  //   isRouteActive("/BlogList") ||
                  //   isRouteActive("/BlogList/BlogDetails") ||
                  //   isRouteActive("/AdminList")
                  //     ? "activeLink"
                  //     : "inactiveLink"
                  // }
                  // onClick={() => navigate("/BlogList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faBuildingColumns} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Education</span>
                  )}
                </a>
                <div></div>
                <ul className="list-unstyled" id="homeSubmenu">
                  <li>
                    <a
                      className={
                        isRouteActive("/WebinarList") ? "activeLink"
                        : "inactiveLink"
                      }
                      onClick={() => navigate("/WebinarList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faChalkboardUser} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Webinar</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/CoursesList") ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/CoursesList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faBook} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Course</span>
                      )}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
              <a
                  // className={
                  //   isRouteActive("/MiscellaneousList") ||
                  //   isRouteActive("/MyAccount") ||
                  //   isRouteActive("/MiscellaneousList/RollsPermissionList") ||
                  //   isRouteActive("/BlogList") ||
                  //   isRouteActive("/BlogList/BlogDetails") ||
                  //   isRouteActive("/AdminList")
                  //     ? "activeLink"
                  //     : "inactiveLink"
                  // }
                  // onClick={() => navigate("/BlogList")}
                >
                  <FontAwesomeIcon fontSize={20} icon={faCog} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Miscellaneous</span>
                  )}
                </a>
                <div></div>
                <ul className="list-unstyled" id="homeSubmenu">
                  <li>
                    <a
                      className={
                        isRouteActive("/BlogList") ? "activeLink"
                        : "inactiveLink"
                      }
                      onClick={() => navigate("/BlogList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faEdit} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Blog/Article</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/AdvertismentList") ? "activeLink"
                        : "inactiveLink"
                      }
                      onClick={() => navigate("/AdvertismentList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faRectangleAd} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Advertisment</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/EventList") ||  isRouteActive("/EventList/EventDetails")                      ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/EventList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faTicket} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Events</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/FaqList") ||  isRouteActive("/FaqList/FaqDetails")                      ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/FaqList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faQuestion} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">FAQs</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/AdminList") 
                          ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/AdminList")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faUserFriends} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">Manage Admin</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        isRouteActive("/MyAccount") 
                          ? "activeLink"
                          : "inactiveLink"
                      }
                      onClick={() => navigate("/MyAccount")}
                    >
                      <FontAwesomeIcon fontSize={20} icon={faUserFriends} />
                      {!sidebarActive && (
                        <span className="sidebarHeading">My Account</span>
                      )}
                    </a>
                  </li>
                </ul>
              </li>
              <hr />
              {isLoggedIn ? (
              <li style={{ cursor: "pointer" }}>
                <a onClick={handleLogout}>
                  <FontAwesomeIcon fontSize={20} icon={faDoorOpen} />
                  {!sidebarActive && (
                    <span className="sidebarHeading">Log out</span>
                  )}
                </a>
              </li>
            ) : null}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
