import React, { useState, useEffect } from "react";
import { CardGroup, Button } from "react-bootstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoaderModal from "../component/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "../component/css/WorkspaceDetails.css";
import WorkspaceTopBar from "../component/WorkspaceTopBar";
import { useWorkSpaceState } from "./services/workspaceState";
import { useParams } from "react-router-dom";
import moment from "moment";
import Apis from "../utils/api";
import { useServiceList } from "./services/serviceListState";
// import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import bankNames from "../assets/banknames.json";
import ConfirmDialog from "../component/common/ConfirmDialog";
import { getValue } from "firebase/remote-config";
import { getRemoteConfig } from "firebase/remote-config";
const WorkspaceDetails = () => {
  const userid = localStorage.getItem("userId") || null;
  console.log("USERSID", userid);
  const api = new Apis();
  const { id, collaboratorId, selectedService } = useParams();
  const { getServiceListDatabyId } = useServiceList();
  const [serviceName, setServiceName] = useState(getServiceListDatabyId(selectedService));
  const { getWorkspaceData } = useWorkSpaceState();
  const [workspaceData, setWorkspaceData] = useState(getWorkspaceData(id));
  const [adharCardDropdown, setAdharCardDropdown] = useState(false);
  const [panCardDropDown, setPancardDropDown] = useState(false);
  const [qualificationDropDown, setQualificationDropDown] = useState(false);
  const [ngoCertificateDropDown, setngoCertificateDropDown] = useState(false);
  const [doctorLicenceDropDown, setDoctorLicenceDropDown] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditBankModal, setIsEditBankModal] = useState(false);
  const [selectedDot, setSelectedDot] = useState(0);
  // const [editedEmail, setEditedEmail] = useState("");
  const [editedContact, setEditedContact] = useState("");
  const [editedCity, setEditedCity] = useState("");
  const [editedEducation, setEditedEducation] = useState("");
  const [editedAreaofexpertise, setEditedAreaofexpertise] = useState("");
  const [editedExperince, setEditedExperince] = useState("");
  const [editedLocation, setEditedLocation] = useState("");
  const [editedLink, setEditedLink] = useState("");
  const [editedRegistration, setEditedRegistration] = useState("");
  const [editedAchievment, setEditedAchievment] = useState("");
  const [editedAreaofFocus, setEditedAreaofFocus] = useState("");
  const [editedBankName, setEditedBankName] = useState("");
  const [editedAccountHolderName, setAccountHolderName] = useState("");
  const [editedAccountNumber, setEditedAccountNumber] = useState("");
  const [editedIfsc, setEditedIfsc] = useState("");
  const [editedGst, setEditedGSt] = useState("");
  const [editedPan, setEditedPan] = useState("");
  const [noteText, setNoteText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("Online");

  const [selectedVeterinaryCardTypes, setSelectedVeterinaryCardTypes] = useState([]);
  const [visitType, setVisitType] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [dataFilled, setDataFilled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);

  const [isGstEnabled, setIsGstEnabled] = useState(workspaceData.gstEnabled || false);
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  console.log(workspaceData);
  const [serviceId] = useState(workspaceData.serviceId);
  const [selectedServicees, setSelectedServicees] = useState("");
  const [servicesName, setServicesName] = useState(selectedServicees === "Veterinary" ? selectedType : "");
  useEffect(() => {
    if (serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c") {
      setSelectedServicees("Groomers");
    } else if (serviceId === "d8d71038-c48c-11ed-abc1-02817ef0919c") {
      setSelectedServicees("Veterinary");
    } else {
      setSelectedServicees("NGO");
    }
  }, [serviceId]);
  const [images, setImages] = useState(
    workspaceData.doctorImages.map((image) => ({
      value: image.value,
      key: image.key, // or use any other unique identifier
    }))
  );
  const [serviceDropdown, setServiceDropDown] = useState(false);
  const [bank, setBankDetails] = useState({});
  const [user, setUser] = useState([
    {
      Contact: workspaceData.phone || "null",
      city: workspaceData.city || "null",
      education: workspaceData.education || "null",
      expertise: workspaceData.expertise || "null",
      experience: workspaceData.experience || "null",
      latitude: workspaceData.latitude || "null",
      longitude: workspaceData.longitude || "null",
      bankName: bank.name || "",
      accountHolderName: bank.accountHolderName || "",
      AccountNumber: bank.accountNumber || "",
      IFSCCode: bank.ifsc || "",
      gst: bank.gst || "",
      PanNo: bank.pan || "",
      registrationNumber: workspaceData.registrationNumber || "",
      achievement: workspaceData.achievement || "",
      areaOfFocus: workspaceData.areaOfFocus || "",
      link: workspaceData.link || "",
    },
  ]);
  useEffect(() => {
    setUser((prevUser) => [
      {
        ...prevUser[0],
        bankName: bank.name || "",
        PanNo: bank.pan || "",
        IFSCCode: bank.ifsc || "",
        accountHolderName: bank.accountHolderName || "",
        AccountNumber: bank.accountNumber || "",
        gst: bank.gst || "",
      },
    ]);
  }, [bank]);
  const handleSaveEdits = () => {
    const updateduser = [...user];
    updateduser[selectedDot].Contact = editedContact;
    updateduser[selectedDot].city = editedCity;
    updateduser[selectedDot].education = educationArray;
    updateduser[selectedDot].expertise = expertiseArray;
    updateduser[selectedDot].experience = editedExperince;
    updateduser[selectedDot].link = editedLink;
    updateduser[selectedDot].registrationNumber = editedRegistration;
    updateduser[selectedDot].achievement = editedAchievment;
    updateduser[selectedDot].areaOfFocus = editedAreaofFocus;
    updateduser[selectedDot].latitude = editedLocation.split(",")[0].trim(); // Update longitude
    updateduser[selectedDot].longitude = editedLocation.split(",")[1].trim(); // Update latitude
    setIsEditModalOpen(false);
  };
  const handleBankEdits = async () => {
    const updateduser = [...user];
    updateduser[selectedDot].bankName = editedBankName;
    updateduser[selectedDot].accountHolderName = editedAccountHolderName;
    updateduser[selectedDot].AccountNumber = editedAccountNumber;
    updateduser[selectedDot].gst = editedGst;
    updateduser[selectedDot].IFSCCode = editedIfsc;
    updateduser[selectedDot].PanNo = editedPan;
    const requestBankBody = {
      name: editedBankName,
      ifsc: editedIfsc || (editedIfsc === "" ? bank.ifsc : undefined),
      accountNumber: editedAccountNumber || (editedAccountNumber === "" ? bank.accountNumber : undefined),
      pan: editedPan || (editedPan === "" ? bank.pan : undefined),
      gst: editedGst || (editedGst === "" ? bank.gst : undefined),
      workspaceId: id,
      createdBy: userid,
      accountHolderName: editedAccountHolderName || (editedAccountHolderName === "" ? bank.accountHolderName : undefined),
    };
    if (selectedService !== "5e4a1033-c48e-11ed-abc1-02817ef0919c") {
      if (!bank.name && !bank.pan && !bank.ifsc && !bank.accountHolderName && !bank.accountNumber) {
        const bankResponse = await api.addBankDetails(id, requestBankBody);
        console.log("API response (Bank - Add):", bankResponse);
        toast.success("Added Bank Details Successfully");
        setIsEditBankModal(false);
      } else {
        const bankResponse = await api.updateBank(id, requestBankBody);
        console.log("API response (Bank - Update):", bankResponse);
        toast.success("Edited Workspace Successfully");
        setIsEditBankModal(false);
      }
    } else {
      setIsEditBankModal(false);
      // Handle the case where the selected service ID is "5e4a1033-c48e-11ed-abc1-02817ef0919c"
      console.log("Skipping API call for selected service ID:", selectedService);
    }
  };
  const handleDotClick = (index) => {
    setSelectedDot(index);
  };
  const adharcarddropDown = () => {
    setAdharCardDropdown(!adharCardDropdown);
  };
  const serviceddropdown = () => {
    setServiceDropDown(!serviceDropdown);
  };
  const pancarddropDown = () => {
    setPancardDropDown(!panCardDropDown);
  };
  const doctorLicencedropdown = () => {
    setDoctorLicenceDropDown(!doctorLicenceDropDown);
  };
  const qualificationdropDown = () => {
    setQualificationDropDown(!qualificationDropDown);
  };
  const ngoCertificate = () => {
    setngoCertificateDropDown(!ngoCertificateDropDown);
  };
  const [uploading, setUploading] = useState(false);
  const [newlyAddedImages, setNewlyAddedImages] = useState([]);
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];
    if (updatedImages.length + files.length > 5) {
      toast.error("Workspace images cannot be more than 5.");
      return;
    }
    const newlyAdded = [...newlyAddedImages];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const extension = getFileExtension(file.name);
        const newImage = { key: Date.now(), value: e.target.result, extension: extension };
        updatedImages.push(newImage);
        newlyAdded.push(newImage);
        setImages(updatedImages);
        setNewlyAddedImages(newlyAdded);
        setUploading(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      const currentDatetime = new Date().toISOString().replace(/[-T:]/g, "").slice(0, -5);
      for (let i = 0; i < newlyAddedImages.length; i++) {
        const image = newlyAddedImages[i];
        const extension = image.extension || "png"; // Use 'png' as default if extension is not available
        const timestampedName = `image_${currentDatetime}_${i + 1}.${extension}`;
        formData.append("files", dataURItoBlob(image.value), timestampedName);
      }
      setLoading(true);
      const workspaceImage = await api.uploadWorkspaceImages(id, collaboratorId, formData);
      setLoading(false);
      setUploading(false);
      toast.success("Uploaded New Image Successfully");
    } catch (error) {
      console.error("Error uploading images:", error);
      setLoading(false);
      toast.error("Cannot Upload New Image");
    }
  };

  const handleDeleteImage = async (index, imageid) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    setLoading(true);
    console.log(imageid);
    try {
      const deleteImages = await api.deleteWorkspaceImage(id, {
        data: [imageid],
      });
      console.log("Workspace Image:", deleteImages);
      toast.success("Deleted Workspace Image Successfully");
    } catch (error) {
      console.error("Error deleting workspace image:", error);
      toast.error("Error deleting workspace image");
    } finally {
      setLoading(false);
    }
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [educationArray, setEducationArray] = useState(workspaceData.education ? [...workspaceData.education] : []);

  const addEducation = () => {
    setEducationArray((prevState) => [...prevState, ""]);
  };

  const [expertiseArray, setExpertiseArray] = useState(workspaceData.expertise ? [...workspaceData.expertise] : []);

  const addExpertise = () => {
    setExpertiseArray((prevState) => [...prevState, ""]);
  };

  const [uploadedImages, setUploadedImages] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeDocument, setActiveDocument] = useState(null);
  const [selectAllDays, setSelectAllDays] = useState(true);
  const [selectedDays, setSelectedDays] = useState(workspaceData.workplaceTime?.days || []);
  const openImageModal = (documentName) => {
    if (documentName === "Aadhar Card") {
      setActiveDocument("AADHAR");
    } else {
      setActiveDocument(documentName);
    }
    setModalOpen(true);
  };
  const closeModal = () => {
    setActiveDocument(null);
    setModalOpen(false);
  };
  console.log(workspaceData.services);
  const handleFileUpload = async (event, documentName) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUploadedImages((prevImages) => ({
        ...prevImages,
        [documentName]: imageUrl,
      }));
    }
    const formData = new FormData();
    formData.append("files", file);
    try {
      let uploadApi;
      if (documentName === "Aadhar Card") {
        uploadApi = api.uploadAadhar(id, collaboratorId, "1723614193", formData);
      } else if (documentName === "Pan Card") {
        uploadApi = api.uploadPan(id, collaboratorId, "Amcpp2908c", formData);
      }
      const response = await uploadApi;
      console.log(response);
    } catch (error) {
      console.error(`Error uploading ${documentName}:`, error);
    }
  };
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const toggleSelectAllDays = () => {
    if (selectAllDays) {
      setSelectedDays([]);
    } else {
      setSelectedDays(daysOfWeek);
    }
    setSelectAllDays(!selectAllDays);
  };
  const toggleDay = (day) => {
    let updatedSelectedDays;
    if (selectedDays.includes(day)) {
      updatedSelectedDays = selectedDays.filter((d) => d !== day);
    } else {
      updatedSelectedDays = [...selectedDays, day];
    }
    setSelectedDays(updatedSelectedDays);
    const allDaysSelected = daysOfWeek.every((d) => updatedSelectedDays.includes(d));
    setSelectAllDays(allDaysSelected);
  };
  useEffect(() => {
    if (id) {
    }
  }, [id, getWorkspaceData]);
  const originalName = workspaceData.workplaceName || "null";
  const originalAddress = workspaceData.address || "null";
  const originalAbout = workspaceData.about || "null";
  const originaldesignationName = workspaceData.designationName || "null";
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(originalName);
  const [address, setAddress] = useState(originalAddress);
  const [about, setAbout] = useState(originalAbout);
  const [designationNames, setDesignationNames] = useState(originaldesignationName);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setName(originalName);
    setAddress(originalAddress);
    setAbout(originalAbout);
    setDesignationNames(designationNames);
    setIsEditing(false);
  };
  const [dropdownStates, setDropdownStates] = useState(workspaceData.services ? workspaceData.services.map(() => false) : []);
  const toggleDropdown = (index) => {
    const updatedStates = [...dropdownStates];
    updatedStates[index] = !updatedStates[index];
    setDropdownStates(updatedStates);
  };
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startBreakTime, setStartBreakTime] = useState("");
  const [endBreakTime, setEndBreakTime] = useState("");
  const [latLng, setLatLng] = useState("0.0, 0.0");

  useEffect(() => {
    const formattedStartTime = moment(workspaceData.workplaceTime?.start_time, "hh:mm A")?.format("HH:mm") || "";
    const formattedEndTime = moment(workspaceData.workplaceTime?.end_time, "hh:mm A").format("HH:mm" || "");
    const formattedStartBreakTime = moment(workspaceData.workplaceTime?.lunch_break.start_time, "hh:mm A").format("HH:mm" || "");
    const formattedEndBreakTime = moment(workspaceData.workplaceTime?.lunch_break.end_time, "hh:mm A").format("HH:mm" || "");
    setStartBreakTime(formattedStartBreakTime);
    setEndBreakTime(formattedEndBreakTime);
    setStartTime(formattedStartTime);
    setEndTime(formattedEndTime);
  }, [workspaceData]);
  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };
  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };
  const handleBreakStartTimeChange = (e) => {
    setStartBreakTime(e.target.value);
  };
  const handleBreakEndTimeChange = (e) => {
    setEndBreakTime(e.target.value);
  };
  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const bankDetails = await api.getBankDetails(id, collaboratorId);
        setBankDetails(bankDetails);
        console.log("Bank", bankDetails);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchBankDetails();
  }, []);
  const [showServiceAdd, setShowServiceAdd] = useState(false);
  const handleServiceSelection = (type) => {
    if (selectedVeterinaryCardTypes.includes(type)) {
      setShowServiceAdd(true); // Open the modal
    } else {
      setSelectedServiceType(null);
      setSelectedType(type);
      setShowServiceAdd(true); // Open the modal
    }
  };
  const handleModify = (type) => {
    setSelectedServiceType(null);
  };

  const handleDelete = (identifier) => {
    setSelectedServiceType(null);
    if (selectedServicees === "Veterinary") {
      const updatedSelectedServices = selectedServices.filter((service) => service.type !== identifier);
      setSelectedServices(updatedSelectedServices);
      const updatedVeterinaryCardTypes = selectedVeterinaryCardTypes.filter((cardType) => cardType !== identifier);
      setSelectedVeterinaryCardTypes(updatedVeterinaryCardTypes);
    } else if (selectedServicees === "Groomers") {
      const updatedGroomerServices = groomerServices.filter((service) => service.name !== identifier);
      setGroomerServices(updatedGroomerServices);
    }
  };
  const handleDeletes = (serviceType) => {
    setWorkspaceData((prevWorkspaceData) => {
      const updatedServices = prevWorkspaceData.services.filter((service) => service.name !== serviceType);
      const updatedGroomerServices = selectedServicees === "Groomers" ? groomerServices.filter((service) => service.name !== serviceType) : groomerServices;

      return {
        ...prevWorkspaceData,
        services: updatedServices,
        groomerServices: updatedGroomerServices,
      };
    });
  };
  const [groomerServices, setGroomerServices] = useState([]);
  const handleSaves = () => {
    if (selectedVeterinaryCardTypes.includes(selectedType)) {
      setShowServiceAdd(false);
      return;
    }

    const newService = {
      name: servicesName || selectedType,
      visit: visitType,
      convenienceFee: platformFee,
      type: selectedType,
      description: serviceDescription,
      rate: serviceRate,
      ...(isGstEnabled && { gstPrice: gst }),
      isHomeVisit: visitType === "Home visit" ? true : false,
      inHandAmount: inHandAmount,
    };
    setWorkspaceData((prevWorkspaceData) => {
      let updatedServices = [];
      if (selectedServicees === "Veterinary") {
        updatedServices = [...prevWorkspaceData.services, newService];
        setSelectedServices(updatedServices);
        setSelectedVeterinaryCardTypes([...selectedVeterinaryCardTypes, selectedType]);
        setDataFilled(true);
        setShowServiceAdd(false);
        setServiceDescription("");
        setServiceRate("");
        setPlatformFee(0);
        setInHandAmount(0);
      } else if (selectedServicees === "Groomers") {
        setGroomerServices((prevGroomerServices) => [...prevGroomerServices, newService]);
        updatedServices = [...prevWorkspaceData.services, newService];
        setSelectedServices(updatedServices);
        setSelectedServicees("Groomers");
        setDataFilled(true);
        setShowServiceAdd(false);
        setServicesName("");
        setVisitType("");
        setServiceDescription("");
        setServiceRate("");
        setPlatformFee(0);
        setInHandAmount(0);
      }
      return {
        ...prevWorkspaceData,
        services: updatedServices,
      };
    });
  };
  // console.log("WorkspaceInfo", workspaceData);
  // console.log(selectedService)
  const handleCreate = async () => {
    if (!thumbselectedImage && !preloadedCoverImage) {
      return toast.error("Cover Image is Needed");
    }
    try {
      const updatedServices = selectedService === "d8d71038-c48c-11ed-abc1-02817ef0919c" ? selectedServices : workspaceData.services;
      const latitude = editedLocation ? editedLocation.split(",")[0].trim() : undefined;
      const longitude = editedLocation ? editedLocation.split(",")[1].trim() : undefined;
      const requestBody = {
        id: id,
        designationName: designationNames,
        about: about,
        workplaceName: name,
        collaboratorId: collaboratorId,
        serviceId: selectedService,
        address: address,
        town: workspaceData.city,
        services: selectedServices.length === 0 ? workspaceData.services : updatedServices,
        pincode: workspaceData.pincode,
        state: workspaceData.state,
        education: educationArray || [null],
        city: editedCity || (editedCity === "" ? workspaceData.city : undefined),
        expertise: expertiseArray || [null],
        workplaceTime: {
          days: selectedDays || workspaceData.workplaceTime.days,
          start_time: startTime || workspaceData.workplaceTime.start_time,
          end_time: endTime || workspaceData.workplaceTime.end_time,
          lunch_break: {
            start_time: startBreakTime || workspaceData.workplaceTime.lunch_break.start_time,
            end_time: endBreakTime || workspaceData.workplaceTime.lunch_break.end_time,
          },
        },
        createdBy: userid,
        createdAt: "",
        latitude: latitude || workspaceData.latitude,
        longitude: longitude || workspaceData.longitude,
        phone: editedContact || (editedContact === "" ? workspaceData.phone : undefined),
        experience: editedExperince || (editedExperince === "" ? workspaceData.experience : undefined),
        default: "false",
        active: isVerified === "Active",
        status: isVerified,
        liked: "false",
        vacation: isVacationMode,
        registraionNumber: editedRegistration,
        listOnly: listOnly,
        featured: featuredSwitchState,
        sponsored: switchState,
        gstEnabled: isGstEnabled,
      };

      const requesNgoBody = {
        id: id,
        designationName: designationNames,
        about: about,
        workplaceName: name,
        collaboratorId: 1,
        serviceId: "5e4a1033-c48e-11ed-abc1-02817ef0919c",
        address: address,
        town: workspaceData.city,
        services: selectedServices.length === 0 ? workspaceData.services : updatedServices,
        pincode: workspaceData.pincode,
        state: workspaceData.state,
        city: editedCity || (editedCity === "" ? workspaceData.city : undefined),
        workplaceTime: {
          days: selectedDays || workspaceData.workplaceTime.days,
          start_time: startTime || workspaceData.workplaceTime.start_time,
          end_time: endTime || workspaceData.workplaceTime.end_time,
          lunch_break: {
            start_time: startBreakTime || workspaceData.workplaceTime.lunch_break.start_time,
            end_time: endBreakTime || workspaceData.workplaceTime.lunch_break.end_time,
          },
        },
        createdBy: userid,
        createdAt: "",
        latitude: latitude,
        longitude: longitude,
        phone: editedContact || (editedContact === "" ? workspaceData.phone : undefined),
        gstEnabled: "false",
        default: "false",
        active: isVerified === "Active",
        status: isVerified,
        liked: "false",
        vacation: isVacationMode,
        registrationNumber: editedRegistration,
        achievement: editedAchievment,
        areaOfFocus: editedAreaofFocus,
        link: editedLink,
        featured: featuredSwitchState,
        sponsored: switchState,
      };

      setLoading(true);

      if (selectedService === "d8d71038-c48c-11ed-abc1-02817ef0919c" || selectedService === "5e4c6b60-c48e-11ed-abc1-02817ef0919c") {
        const response = await api.updateWorkspaceData(id, requestBody);
        console.log("API response:", response);
      } else {
        const resonseNgo = await api.updateNGO(id, requesNgoBody);
        console.log(resonseNgo);
      }
      // console.log(resonseNgo);
      if (thumbselectedImage) {
        uploadCoverImage(id);
      } else {
        setLoading(false);
      }
      toast.success("Workspace Edited Successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error editing user:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const [selectedServiceData, setSelectedServiceData] = useState(null);
  const handleSavev = () => {
    const rate = parseFloat(serviceRate);
    const updatedServiceData = {
      ...selectedServiceData,
      rate: rate,
      description: serviceDescription,
      convenienceFee: platformFee,
      inHandAmount: inHandAmount,
    };
    const updatedServices = workspaceData.services.map((service) => (service.name === updatedServiceData.name ? updatedServiceData : service));
    const updatedWorkspaceData = {
      ...workspaceData,
      services: updatedServices,
    };
    setWorkspaceData(updatedWorkspaceData);

    setShowModal(false);
  };
  const [serviceRate, setServiceRate] = useState("");
  const [platformFee, setPlatformFee] = useState(0);
  const [gst, setGst] = useState(0);
  const [serviceDescription, setServiceDescription] = useState("");
  const [inHandAmount, setInHandAmount] = useState(0);
  const remoteConfig = getRemoteConfig();
  const gstPer = getValue(remoteConfig, "gstPercentage");
  const platformPer = getValue(remoteConfig, "platformFeePercentage");
  console.log(gstPer);
  console.log(platformPer);
  useEffect(() => {
    if (serviceRate) {
      const rate = parseFloat(serviceRate);
      const platformFeePercentage = platformPer?._value;
      const gstPercentage = gstPer?._value;
      const calculatedPlatformFee = rate * platformFeePercentage;
      let calculatedInHandAmount = rate - calculatedPlatformFee;

      // Deduct GST if isGstEnabled is true
      if (isGstEnabled) {
        calculatedInHandAmount -= calculatedInHandAmount * gstPercentage;
      }
      const calculatedGst = ((rate - calculatedPlatformFee) / 100) * 18;
      setPlatformFee(calculatedPlatformFee);
      setGst(calculatedGst);
      setInHandAmount(calculatedInHandAmount);
    }
  }, [serviceRate, isGstEnabled]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Verified");
  const toggleDropdownl = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    toggleDropdownl();
  };
  const hasAadharCardImage = () => {
    const aadharCardDoc = workspaceDocument.find((doc) => doc.docName === "AADHAR");
    return aadharCardDoc && aadharCardDoc.docImage;
  };
  const dropdownOptions = ["Delete", "Verified"].filter((option) => option !== selectedOption);
  const [isAadharModalOpen, setAadharModalOpen] = useState(false);
  const openAadharModal = () => {
    setAadharModalOpen(true);
  };
  const [editedAadharImageUrl, setEditedAadharImageUrl] = useState();
  const handleEditAadhar = async () => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.style.display = "none";
      fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const reader = new FileReader();
            reader.onload = (e) => {
              const editedImageUrl = e.target.result;
              setEditedAadharImageUrl(editedImageUrl);
            };
            reader.readAsDataURL(file);
            const Aadhar = new FormData();
            Aadhar.append("files", file);
            setLoading(true);
            const aadharUpload = await api.uploadAadhar(id, collaboratorId, "123", Aadhar);
            setLoading(false);
            toast.success("Aadhar Card Updated Successfully");
            console.log(aadharUpload);
          } catch (error) {
            console.error("Error reading or uploading edited Aadhar image:", error);
          }
        }
      });
      fileInput.click();
    } catch (error) {
      console.error("Error handling Aadhar image edit:", error);
    }
  };
  const AadharModal = ({ isOpen, toggleModal, documentData, editedImageUrl }) => (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>View Aadhar Image</ModalHeader>
      <ModalBody>
        {editedImageUrl ? (
          <img src={editedImageUrl} alt="Edited Aadhar" style={{ width: "100%" }} />
        ) : (
          documentData &&
          documentData.map((doc) => {
            if (doc.docName === "AADHAR") {
              return <img key={doc.id} src={doc.docImage} alt={doc.docName} style={{ width: "100%" }} />;
            }
            return null;
          })
        )}
      </ModalBody>
    </Modal>
  );
  const hasPanCardImage = () => {
    const panCardDoc = workspaceDocument.find((doc) => doc.docName === "PAN");
    return panCardDoc && panCardDoc.docImage;
  };
  const [editedPanCardImageUrl, setEditedPanCardImageUrl] = useState(null);
  const handleEditPanCard = async () => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.style.display = "none";
      fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const editedImageUrl = e.target.result;
              setEditedPanCardImageUrl(editedImageUrl);
            };
            reader.readAsDataURL(file);
            const Pan = new FormData();
            Pan.append("files", file);
            setLoading(true);
            const panUpload = await api.uploadPan(id, collaboratorId, "76543", Pan);
            setLoading(false);
            toast.success("Pan Card Updated Successfully");
            console.log(panUpload);
          } catch (error) {
            console.error("Error reading or uploading PAN Card image:", error);
          }
        }
      });
      fileInput.click();
    } catch (error) {
      console.error("Error handling PAN Card image edit:", error);
    }
  };
  const openPanCardModal = () => {
    setPanCardModalOpen(true);
  };
  const [indexItem, setIndexItem] = useState("");
  const [imageId, setImageID] = useState("");
  const [isPanCardModalOpen, setPanCardModalOpen] = useState(false);
  const PanCardModal = ({ isOpen, toggleModal, documentData, editedImageUrl }) => (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>View PAN Card Image</ModalHeader>
      <ModalBody>
        {editedImageUrl ? (
          <img src={editedImageUrl} alt="Edited PAN Card" style={{ width: "100%" }} />
        ) : (
          documentData &&
          documentData.map((doc) => {
            if (doc.docName === "PAN") {
              return <img key={doc.id} src={doc.docImage} alt={doc.docName} style={{ width: "100%" }} />;
            }
            return null;
          })
        )}
      </ModalBody>
    </Modal>
  );
  const [isDoctorLicenseModalOpen, setDoctorLicenseModalOpen] = useState(false);
  const openDoctorLicenseModal = () => {
    setDoctorLicenseModalOpen(true);
  };
  const hasDoctorLicenseImage = () => {
    const doctorLicenseDoc = workspaceDocument.find((doc) => doc.docName === "DOCTOR_LICENSE");
    return doctorLicenseDoc && doctorLicenseDoc.docImage;
  };
  const [editedDoctorLicenseImageUrl, setEditedDoctorLicenseImageUrl] = useState(null);
  const handleEditDoctorLicense = async () => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.style.display = "none";
      fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const editedImageUrl = e.target.result;
              setEditedDoctorLicenseImageUrl(editedImageUrl);
              openDoctorLicenseModal();
            };
            reader.readAsDataURL(file);
            const DocLicense = new FormData();
            DocLicense.append("files", file);
            setLoading(true);
            const docUpload = await api.uploadDocLicense(id, collaboratorId, "12334", DocLicense);
            setLoading(false);
            toast.success("Doc License Updated Successfully");
            console.log(docUpload);
          } catch (error) {
            console.error("Error reading or uploading doctor license image:", error);
          }
        }
      });
      fileInput.click();
    } catch (error) {
      console.error("Error handling doctor license image edit:", error);
    }
  };
  const DoctorLicenseModal = ({ isOpen, toggleModal, documentData, editedImageUrl }) => (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{editedImageUrl ? "View Edited Doctor's License Image" : "View Doctor's License Image"}</ModalHeader>
      <ModalBody>
        {editedImageUrl ? (
          <img src={editedImageUrl} alt="Edited Doctor's License" style={{ width: "100%" }} />
        ) : (
          documentData &&
          documentData.map((doc) => {
            if (doc.docName === "DOCTOR_LICENSE") {
              return <img key={doc.id} src={doc.docImage} alt={doc.docName} style={{ width: "100%" }} />;
            }
            return null;
          })
        )}
      </ModalBody>
    </Modal>
  );
  const [isQualificationCertificateModalOpen, setQualificationCertificateModalOpen] = useState(false);
  const hasQualificationCertificateImage = () => {
    const qualificationCertificateDoc = workspaceDocument.find((doc) => doc.docName === "QUALIFICATION_CERTIFICATE");
    return qualificationCertificateDoc && qualificationCertificateDoc.docImage;
  };
  const openQualificationCertificateModal = () => {
    setQualificationCertificateModalOpen(true);
  };
  const handleEdit = async () => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.style.display = "none";
      fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const editedImageUrl = e.target.result;
              setEditedQualificationImageUrl(editedImageUrl);
            };
            reader.readAsDataURL(file);
            const qualification = new FormData();
            qualification.append("files", file);
            setLoading(true);
            const qualificationUpload = await api.uploadQualification(id, collaboratorId, "123123", qualification);
            setLoading(false);
            if (serviceName.name === "NGOs") {
              toast.success("NGO Certificate Updated Successfully");
            } else {
              toast.success("Qualification Certificate Updated Successfully");
            }
            console.log(qualificationUpload);
          } catch (error) {
            console.error("Error reading or uploading qualification certificate image:", error);
          }
        }
      });
      fileInput.click();
    } catch (error) {
      console.error("Error handling qualification certificate image edit:", error);
    }
  };
  const QualificationCertificateModal = ({ isOpen, toggleModal, documentData, editedImageUrl }) => (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        {serviceName.name === "NGOs"
          ? editedImageUrl
            ? "View Edited NGO Certificate Image"
            : "View NGO Certificate Image"
          : editedImageUrl
          ? "View Edited QUALIFICATION_CERTIFICATE Image"
          : "View QUALIFICATION_CERTIFICATE Image"}
      </ModalHeader>
      <ModalBody>
        {editedImageUrl ? (
          <img src={editedImageUrl} alt="Edited QUALIFICATION_CERTIFICATE" style={{ width: "100%" }} />
        ) : (
          documentData &&
          documentData.map((doc) => {
            if (doc.docName === "QUALIFICATION_CERTIFICATE") {
              return <img key={doc.id} src={doc.docImage} alt={doc.docName} style={{ width: "100%" }} />;
            }
            return null;
          })
        )}
      </ModalBody>
    </Modal>
  );
  const [editedQualificationImageUrl, setEditedQualificationImageUrl] = useState(null);
  const [workspaceDocument, setWorkspaceDocument] = useState([]);
  useEffect(() => {
    const fetchWorkspaceDocument = async () => {
      try {
        const documents = await api.getWorkSpaceDocument(collaboratorId);
        setWorkspaceDocument(documents);
      } catch (error) {
        console.error("Error fetching workspace documents:", error);
      }
    };
    fetchWorkspaceDocument();
  }, [collaboratorId]);
  console.log("docID", workspaceDocument);
  console.log("Groomer Service", groomerServices);
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "workspace",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const [thumbselectedImage, setThumbSelectedImage] = useState(null);
  const [preloadedCoverImage, setPreloadedCoverImage] = useState(null);
  const handleThumbImageUpload = (e) => {
    const file = e.target.files[0];
    const img = new Image();

    img.onload = function () {
      if (img.width === 1920 && img.height === 1080) {
        setThumbSelectedImage(file);
      } else {
        toast.error("Thumb image must be 1920 by 1080 pixels.");
      }
    };

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleThumbImageDelete = () => {
    setThumbSelectedImage(null);
  };
  useEffect(() => {
    if (workspaceData.coverImage && workspaceData.coverImage.length > 0) {
      const coverImage = new Image();
      coverImage.src = workspaceData.coverImage;
      coverImage.onload = () => setPreloadedCoverImage(coverImage);
    }
  }, [workspaceData.coverImage]);
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbselectedImage) {
        console.log("No new image selected for upload.");
        return;
      }
      const timestamp = Date.now();
      const fileData = thumbselectedImage.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      console.log(userId);
      const formData = new FormData();
      formData.append("file", thumbselectedImage, fileName);
      const response = await api.uploadCoverImg("WORKSPACE", userId, formData);
      console.log("CoverImg", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  const handleDeleteDocImages = async (docid) => {
    try {
      const deleteImages = await api.deleteDocumentImages(id, docid);
      console.log("Document Images:", deleteImages);
      toast.success("Deleted Document Image Successfully");
      const updatedDocumentData = workspaceDocument.filter((doc) => doc.id !== docid);
      setWorkspaceDocument(updatedDocumentData);
    } catch (error) {
      console.error("Error deleting Document image:", error);
      toast.error("Error deleting Document image");
    } finally {
      setLoading(false);
    }
  };
  const [isVacationMode, setIsVacationMode] = useState(workspaceData.vacation);
  const handleToggle = () => {
    setIsVacationMode(!isVacationMode);
  };
  const [isVerified, setisVerified] = useState(workspaceData.status);
  const handleToggles = () => {
    setisVerified((prevState) => (prevState === "Active" ? "Pending" : "Active"));
  };

  const [listOnly, setListOnly] = useState(workspaceData.listOnly);
  const toggleListOnly = () => {
    setListOnly(!listOnly);
  };
  const [switchState, setSwitchState] = useState(workspaceData.sponsored);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(workspaceData.featured);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const [testState, setTeststate] = useState(workspaceData.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateTestStatus("WORKSPACE", workspaceData.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          {/* <WorkspaceTopBar /> */}
          <div className="row mt-2 d-flex" style={{ justifyContent: "center" }}>
            <div className="col-md-10 p-3 " style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
              {/* <div
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span class="vetName">
                <strong> Service Type </strong>
              </span>
            </div>
            <div>
              <span class="navbar-button-text">Vet</span>
            </div>
          </div> */}
              <div
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {workspaceData.serviceId === "d8d71038-c48c-11ed-abc1-02817ef0919c" ? (
                    <span className="vetName">Veterinary</span>
                  ) : workspaceData.serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c" ? (
                    <span className="vetName">Grooming</span>
                  ) : (
                    <span className="vetName">NGO</span>
                  )}
                </div>

                {/* <div>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdownl}>
                    <DropdownToggle caret color="light" className="navbar-buttons">
                      <span style={{ marginRight: 20 }} className="navbar-button-text">
                        {selectedOption}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleOptionClick("Delete")}>Delete</DropdownItem>
                      <DropdownItem onClick={() => handleOptionClick("Verified")}>Verified</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div> */}
              </div>
              <div style={{ marginTop: 10, marginRight: 10 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span>Verified</span>
                  </div>
                  <div>
                    <Form>
                      <FormGroup switch>
                        <Input type="switch" role="switch" checked={isVerified === "Active"} onChange={handleToggles} />
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
              {serviceName.name === "NGOs" ? null : (
                <div>
                  <div style={{ marginTop: 10, marginRight: 10 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <span>Vacation Mode</span>
                      </div>
                      <div>
                        <Form>
                          <FormGroup switch>
                            <Input type="switch" id="vacationSwitch" checked={isVacationMode} onChange={handleToggle} />
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 10, marginRight: 10 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>List Only</span>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={listOnly} onChange={toggleListOnly} />
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ marginTop: 10, marginRight: 10 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Featured</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Sponsored</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Test</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                    </FormGroup>
                  </Form>
                </div>
                {serviceName.name === "Groomers" && (
                  <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                    <label>Enable GST</label>
                    <Form>
                      <FormGroup switch>
                        <Input id="gstSwitch" type="switch" role="switch" onChange={() => setIsGstEnabled(!isGstEnabled)} checked={isGstEnabled} />
                      </FormGroup>
                    </Form>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                {isEditing ? (
                  <>
                    <button type="button" className="btn btn-light navbar-buttons" onClick={handleSaveClick} style={{ marginRight: 10 }}>
                      <span className="navbar-button-text">Save</span>
                    </button>
                    <button type="button" className="btn btn-light navbar-buttons" onClick={handleCancelClick}>
                      <span className="navbar-button-text">Cancel</span>
                    </button>
                  </>
                ) : (
                  <button type="button" className="btn btn-light navbar-buttons" onClick={handleEditClick}>
                    <span className="navbar-button-text">Edit</span>
                  </button>
                )}
              </div>
              <div style={{ marginTop: 10, marginRight: 10 }}>
                <div
                  style={
                    {
                      // display: "flex",
                      // justifyContent: "space-between",
                      // alignItems: "center",
                    }
                  }
                >
                  <div>
                    {isEditing ? (
                      <input className="form-control" type="text" value={designationNames} onChange={(e) => setDesignationNames(e.target.value)} />
                    ) : (
                      <div style={{ justifyContent: "space-between", display: "flex" }}>
                        Designation Name
                        <span className="about">{designationNames}</span>
                      </div>
                    )}
                  </div>
                  <div className="pt-2">
                    {isEditing ? (
                      <input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    ) : (
                      <div style={{ justifyContent: "space-between", display: "flex" }}>
                        Workspace Name
                        <span className="name">{name}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-2">
                  {isEditing ? (
                    <input className="form-control" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                  ) : (
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      Address
                      <span className="address">{address}</span>
                    </div>
                  )}
                </div>
                <div className="pt-2">
                  {isEditing ? (
                    <input className="form-control" type="text" value={about} onChange={(e) => setAbout(e.target.value)} />
                  ) : (
                    <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                      About
                      <div className="col-md-10" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="about">{about}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={{ fontWeight: 600 }}>Details</span>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-light navbar-buttons"
                    onClick={() => {
                      setEditedContact(user[selectedDot].Contact);
                      setEditedCity(user[selectedDot].city);
                      setEditedEducation(user[selectedDot].education);
                      setEditedAreaofexpertise(user[selectedDot].expertise);
                      setEditedExperince(user[selectedDot].experience);
                      setEditedLink(user[selectedDot].link);
                      setEditedRegistration(user[selectedDot].registrationNumber);
                      setEditedAchievment(user[selectedDot].achievement);
                      setEditedAreaofFocus(user[selectedDot].areaOfFocus);
                      setEditedLocation(`${user[selectedDot].latitude}, ${user[selectedDot].longitude}`); // Corrected line
                      setIsEditModalOpen(true);
                    }}
                  >
                    <span class="navbar-button-text">Edit</span>
                  </button>
                </div>
              </div>
              {serviceName.name !== "NGOs" && (
                <div
                  className="pt-2"
                  style={{
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span>Email</span>
                  </div>
                  <div>{workspaceData.email && <span>{workspaceData.email}</span>}</div>
                </div>
              )}

              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span>Contact</span>
                </div>
                <div>
                  <span>{user[selectedDot].Contact}</span>
                </div>
              </div>
              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span>City</span>
                </div>
                <div>
                  <span>{user[selectedDot].city}</span>
                </div>
              </div>
              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span>Location</span>
                </div>
                <div>
                  <span>{`${user[selectedDot].latitude}, ${user[selectedDot].longitude}`}</span>
                </div>
              </div>
              {serviceName.name === "Groomers" ? (
                <div
                  className="pt-2"
                  style={{
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span>Experience</span>
                  </div>
                  <div>
                    <span>{user[selectedDot].experience}</span>
                  </div>
                </div>
              ) : (
                <div>
                  {serviceName.name === "NGOs" ? (
                    <div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Link</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.link || null}</span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Registration Number</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.registrationNumber || null}</span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Achievment</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.achievement || null}</span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Area of Focus</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.areaOfFocus || null}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Education</span>
                        </div>
                        <div>
                          <span>
                            {Array.isArray(user[selectedDot].education) && user[selectedDot].education.length > 0
                              ? user[selectedDot].education.join(",")
                              : user[selectedDot].education}
                          </span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Area of Expertise</span>
                        </div>
                        <div>
                          <span>
                            {Array.isArray(user[selectedDot].expertise) && user[selectedDot].expertise.length > 0
                              ? user[selectedDot].expertise.join(",")
                              : user[selectedDot].expertise}
                          </span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Experience</span>
                        </div>
                        <div>
                          <span>{user[selectedDot].experience}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-3">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <span style={{ fontWeight: 600 }}>Workplace Timing</span>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="selectAll" checked={selectAllDays} onChange={toggleSelectAllDays} />
                    <label className="form-check-label" htmlFor="selectAll">
                      All Days
                    </label>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {daysOfWeek.map((day) => (
                    <div className="form-check form-check-inline" key={day}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={day}
                        checked={selectedDays && selectedDays.includes(day)}
                        onChange={() => toggleDay(day)}
                      />
                      <label className="form-check-label" htmlFor={day}>
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div className="col-md-3">
                    <small className="text-muted">Select Start time</small>
                    <input type="time" className="form-control" value={startTime} onChange={handleStartTimeChange} placeholder="Select start time" />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: 20 }}>
                    <small className="text-muted">Select End time</small>
                    <input type="time" className="form-control" value={endTime} onChange={handleEndTimeChange} placeholder="Select end time" />
                  </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div className="col-md-3">
                    <small className="text-muted">Select Start Break time</small>
                    <input type="time" className="form-control" value={startBreakTime} placeholder="Select start time" onChange={handleBreakStartTimeChange} />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: 20 }}>
                    <small className="text-muted">Select End Break time</small>
                    <input type="time" className="form-control" value={endBreakTime} placeholder="Select end time" onChange={handleBreakEndTimeChange} />
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <span style={{ fontWeight: 600 }}>Documents and Certificates</span>
              </div>
              <div>
                {/* Render Aadhar Card section */}
                <div
                  className="pt-2"
                  style={{
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span className="documentNumber">1</span>
                    <span className="documentName">Aadhar Card</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button type="button" className="btn btn-light navbar-buttons mx-2" onClick={openAadharModal}>
                      <span className="navbar-button-text">View</span>
                    </button>
                    <Dropdown isOpen={adharCardDropdown} toggle={adharcarddropDown}>
                      <DropdownToggle caret style={{ background: "transparent", border: "1px solid #d4d6da" }}>
                        <FontAwesomeIcon color="grey" fontSize={20} icon={faEllipsisVertical} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => handleEditAadhar()}>Edit</DropdownItem>
                        {workspaceDocument &&
                          workspaceDocument.map(
                            (doc) =>
                              doc.docName === "AADHAR" && (
                                <DropdownItem key={doc.id} onClick={() => handleDeleteDocImages(doc.id)} style={{ color: "red" }}>
                                  Delete
                                </DropdownItem>
                              )
                          )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div
                  className="pt-2"
                  style={{
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span className="documentNumber">2</span>
                    <span className="documentName">PAN Card</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button type="button" className="btn btn-light navbar-buttons mx-2" onClick={openPanCardModal}>
                      <span className="navbar-button-text">View</span>
                    </button>
                    <Dropdown isOpen={panCardDropDown} toggle={pancarddropDown}>
                      <DropdownToggle caret style={{ background: "transparent", border: "1px solid #d4d6da" }}>
                        <FontAwesomeIcon color="grey" fontSize={20} icon={faEllipsisVertical} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => handleEditPanCard()}>Edit</DropdownItem>
                        {workspaceDocument &&
                          workspaceDocument.map(
                            (doc) =>
                              doc.docName === "PAN" && (
                                <DropdownItem key={doc.id} onClick={() => handleDeleteDocImages(doc.id)} style={{ color: "red" }}>
                                  Delete
                                </DropdownItem>
                              )
                          )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <AadharModal
                  isOpen={isAadharModalOpen}
                  toggleModal={() => setAadharModalOpen(false)}
                  documentData={workspaceDocument}
                  editedImageUrl={editedAadharImageUrl}
                />
                <PanCardModal
                  isOpen={isPanCardModalOpen}
                  toggleModal={() => setPanCardModalOpen(false)}
                  documentData={workspaceDocument}
                  editedImageUrl={editedPanCardImageUrl}
                />
              </div>
              {serviceName.name === "NGOs" ? (
                <div
                  className="pt-2"
                  style={{
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span class="documentNumber">3</span>
                    <span class="documentName">NGO Certificate</span>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <button type="button" class="btn btn-light navbar-buttons mx-2" onClick={openQualificationCertificateModal}>
                        <span class="navbar-button-text">View</span>
                      </button>
                    </span>
                    <div>
                      <Dropdown isOpen={ngoCertificateDropDown} toggle={ngoCertificate}>
                        <DropdownToggle
                          caret
                          style={{
                            background: "transparent",
                            border: "1px solid #d4d6da",
                          }}
                        >
                          <FontAwesomeIcon color="grey" fontSize={20} icon={faEllipsisVertical} />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit("QUALIFICATION_CERTIFICATE")}>Edit</DropdownItem>
                          {workspaceDocument &&
                            workspaceDocument.map(
                              (doc) =>
                                doc.docName === "QUALIFICATION_CERTIFICATE" && (
                                  <DropdownItem key={doc.id} onClick={() => handleDeleteDocImages(doc.id)} style={{ color: "red" }}>
                                    Delete
                                  </DropdownItem>
                                )
                            )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <QualificationCertificateModal
                    isOpen={isQualificationCertificateModalOpen}
                    toggleModal={() => setQualificationCertificateModalOpen(false)}
                    documentData={workspaceDocument}
                    editedImageUrl={editedQualificationImageUrl}
                  />
                </div>
              ) : (
                <div>
                  {serviceName.name === "Groomers" ? null : (
                    <div
                      className="pt-2"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <span className="documentNumber">3</span>
                        <span className="documentName">Doctor’s Licence</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button type="button" className="btn btn-light navbar-buttons mx-2" onClick={openDoctorLicenseModal}>
                          <span className="navbar-button-text">View</span>
                        </button>
                        <Dropdown isOpen={doctorLicenceDropDown} toggle={doctorLicencedropdown}>
                          <DropdownToggle caret style={{ background: "transparent", border: "1px solid #d4d6da" }}>
                            <FontAwesomeIcon color="grey" fontSize={20} icon={faEllipsisVertical} />
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem onClick={() => handleEditDoctorLicense()}>Edit</DropdownItem>
                            {workspaceDocument &&
                              workspaceDocument.map(
                                (doc) =>
                                  doc.docName === "DOCTOR_LICENSE" && (
                                    <DropdownItem key={doc.id} onClick={() => handleDeleteDocImages(doc.id)} style={{ color: "red" }}>
                                      Delete
                                    </DropdownItem>
                                  )
                              )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {serviceName.name === "Groomers" ? <span className="documentNumber">3</span> : <span className="documentNumber">4</span>}
                      <span className="documentName">Qualification Certificate</span>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <button type="button" className="btn btn-light navbar-buttons mx-2" onClick={openQualificationCertificateModal}>
                          <span className="navbar-button-text">View</span>
                        </button>
                      </div>
                      <Dropdown isOpen={qualificationDropDown} toggle={qualificationdropDown}>
                        <DropdownToggle
                          caret
                          style={{
                            background: "transparent",
                            border: "1px solid #d4d6da",
                          }}
                        >
                          <FontAwesomeIcon color="grey" fontSize={20} icon={faEllipsisVertical} />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit("QUALIFICATION_CERTIFICATE")}>Edit</DropdownItem>
                          {workspaceDocument &&
                            workspaceDocument.map(
                              (doc) =>
                                doc.docName === "QUALIFICATION_CERTIFICATE" && (
                                  <DropdownItem key={doc.id} onClick={() => handleDeleteDocImages(doc.id)} style={{ color: "red" }}>
                                    Delete
                                  </DropdownItem>
                                )
                            )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <DoctorLicenseModal
                    isOpen={isDoctorLicenseModalOpen}
                    toggleModal={() => setDoctorLicenseModalOpen(false)}
                    documentData={workspaceDocument}
                    editedImageUrl={editedDoctorLicenseImageUrl}
                  />
                  {/* Render QUALIFICATION_CERTIFICATE Modal */}
                  <QualificationCertificateModal
                    isOpen={isQualificationCertificateModalOpen}
                    toggleModal={() => setQualificationCertificateModalOpen(false)}
                    documentData={workspaceDocument}
                    editedImageUrl={editedQualificationImageUrl}
                  />
                </div>
              )}
              <Modal isOpen={showServiceAdd} toggle={() => setShowServiceAdd(false)}>
                <ModalHeader toggle={() => setShowServiceAdd(false)}>{selectedType}</ModalHeader>
                <ModalBody>
                  {selectedServicees === "Groomers" ? (
                    <div>
                      <div className="  mt-2">
                        <label for="inputPetBreed">Service Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Service Name"
                          value={servicesName}
                          onChange={(e) => setServicesName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="  mt-2">
                        <label for="inputPetBreed">Visit Type</label>
                        <div>
                          <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="center">
                              Center
                            </label>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="visitType"
                              id="center"
                              value="Center"
                              onChange={(e) => setVisitType(e.target.value)}
                            />
                          </div>
                          <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="homevisit">
                              Home Visit
                            </label>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="visitType"
                              id="homevisit"
                              value="Home visit"
                              onChange={(e) => setVisitType(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="  mt-2">
                    <label for="inputPetBreed">Service Discription</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Service Description"
                      value={serviceDescription}
                      onChange={(e) => setServiceDescription(e.target.value)}
                      required
                    />
                  </div>
                  <div className="  mt-2">
                    <label for="inputPetBreed">Service Rate*</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Service Rate"
                      value={serviceRate}
                      onChange={(e) => setServiceRate(e.target.value)}
                      required
                    />
                  </div>
                  {/* {selectedServicees === "Groomers" && (
                    <div className=" mt-2">
                      <label>Enable GST</label>
                      <Form>
                        <FormGroup switch>
                          <Input id="gstSwitch" type="switch" role="switch" onChange={() => setIsGstEnabled(!isGstEnabled)} checked={isGstEnabled} />
                        </FormGroup>
                      </Form>
                    </div>
                  )} */}
                  <div className="mt-2">
                    <span>
                      <strong>Details</strong>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        Platform Fee (10%):
                      </span>
                      {isGstEnabled && selectedServicees === "Groomers" ? <span style={{ flexDirection: "row" }}>GST (18%):</span> : null}
                    </div>
                    <div>
                      <span
                        style={{
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        ₹{platformFee.toFixed(2)}
                      </span>
                      {isGstEnabled && selectedServicees === "Groomers" ? <span style={{ flexDirection: "row" }}>₹{gst.toFixed(2)}</span> : null}
                    </div>
                  </div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>In-Hand Amount**:</span>
                    <span>₹{inHandAmount.toFixed(2)}</span>
                  </div>
                  <div className="mt-2">
                    <span>*Amount Will be Charged to customer</span>
                    <span>**Amount Will be transferred to Collaborator</span>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => setShowServiceAdd(false)}>
                    Close
                  </Button>
                  <Button color="primary" onClick={handleSaves}>
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {workspaceData.services && workspaceData.services.length > 0 ? (
                <>
                  <div className="pt-3">
                    <span style={{ fontWeight: 600 }}>Services Offered</span>
                  </div>
                  {selectedServicees === "Veterinary" && (
                    <>
                      {!workspaceData.services.some((service) => service.name === "Online" || service.type === "Online") && (
                        <Button
                          onClick={() => handleServiceSelection("Online")}
                          disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Online")}
                        >
                          Online
                        </Button>
                      )}
                      {!workspaceData.services.some((service) => service.name === "Offline" || service.type === "Offline") && (
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={() => handleServiceSelection("Offline")}
                          disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Offline")}
                        >
                          Offline
                        </Button>
                      )}
                      {!workspaceData.services.some((service) => service.name === "Home Visit" || service.type === "Home Visit") && (
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={() => handleServiceSelection("Home Visit")}
                          disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Home Visit")}
                        >
                          Home Visit
                        </Button>
                      )}
                    </>
                  )}
                  {selectedServicees === "Groomers" && (
                    <div style={{ flexDirection: "row" }}>
                      <Button onClick={() => handleServiceSelection("Add Service")}>Add Service</Button>
                    </div>
                  )}
                  {workspaceData.services.map((service, index) => (
                    <CardGroup
                      key={index}
                      className="p-3"
                      style={{
                        marginTop: 10,
                        border: "1px solid #beb7b7",
                        borderRadius: 10,
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div style={{ flexDirection: "column" }}>
                          {service.name || service.type}
                          {workspaceData.serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c" && (
                            <span>({service.isHomeVisit === true ? "Home Visit" : "Center"})</span>
                          )}
                          <div>{service.description}</div>
                        </div>
                        <div>Service Cost:</div>
                        <div>Platform Fee (10%):</div>
                        <div>{isGstEnabled && service.gstPrice ? <div>GST(18%):</div> : ""}</div>
                        <div>In-Hand Amount:</div>
                      </div>
                      <div>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Dropdown isOpen={dropdownStates[index]} toggle={() => toggleDropdown(index)}>
                            <DropdownToggle style={{ background: "none", border: "none" }}>
                              <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                onClick={() => {
                                  setSelectedServiceData(service);
                                  setServiceRate(service.rate.toString()); // Populate the service rate field as a string
                                  setServiceDescription(service.description); // Populate other fields as needed
                                  setPlatformFee(service.convenienceFee);
                                  setShowModal(true);
                                }}
                              >
                                Modify
                              </DropdownItem>
                              <DropdownItem onClick={() => handleDeletes(service.name)}>Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div>
                          <br></br>
                        </div>
                        <div>₹{service.rate}</div>
                        <div>₹{service.convenienceFee}</div>
                        <div>{isGstEnabled && service.gstPrice ? <div>₹{parseFloat(service.gstPrice).toFixed(0)}</div> : ""}</div>
                        <div>₹{(parseFloat(service.rate) - parseFloat(service.convenienceFee)).toFixed(0)}</div>
                      </div>
                    </CardGroup>
                  ))}
                </>
              ) : (
                <div>
                  {selectedServicees === "NGO" ? null : (
                    <div className="mt-2">
                      <span>Service Offered*</span>
                      {selectedServicees === "Groomers" ? (
                        <div style={{ flexDirection: "row" }}>
                          <Button onClick={() => handleServiceSelection("Add Service")}>Add Service</Button>
                        </div>
                      ) : (
                        <div style={{ flexDirection: "row" }}>
                          <Button
                            onClick={() => handleServiceSelection("Online")}
                            disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Online")}
                          >
                            Online
                          </Button>
                          <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => handleServiceSelection("Offline")}
                            disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Offline")}
                          >
                            Offline
                          </Button>
                          <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => handleServiceSelection("Home Visit")}
                            disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Home Visit")}
                          >
                            Home Visit
                          </Button>
                        </div>
                      )}

                      {dataFilled && selectedServicees === "Groomers" && (
                        <div>
                          {groomerServices.map((service, index) => (
                            <CardGroup
                              key={index}
                              className="p-3"
                              style={{
                                marginTop: 10,
                                border: "1px solid #beb7b7",
                                borderRadius: 10,
                                marginRight: 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div style={{ flexDirection: "column" }}>
                                  <div>
                                    {service.name} ({service.isHomeVisit === true ? "Home Visit" : "Center"})
                                  </div>
                                  <div>{service.description}</div>
                                </div>
                                <div>Service Cost</div>
                                <div> Platform Fee (10%):</div>
                                <div>{isGstEnabled && service.gstPrice ? <div>GST(18%):</div> : ""}</div>
                                <div>In-Hand Amount</div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Dropdown isOpen={selectedServiceType === service.name} toggle={() => toggleDropdown(service.name)}>
                                    <DropdownToggle tag="span" data-toggle="dropdown">
                                      <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem onClick={() => handleModify(service.name)}>Modify</DropdownItem>
                                      <DropdownItem onClick={() => handleDeletes(service.name)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                                <div>
                                  <br></br>
                                </div>
                                <div>₹{service.rate}</div>
                                <div>₹{service.convenienceFee.toFixed(2)}</div>
                                <div>{isGstEnabled && service.gstPrice ? <div>₹{parseFloat(service.gstPrice).toFixed(0)}</div> : ""}</div>

                                {/* <div>₹{service && service.gstPrice ? : ''}</div> */}
                                <div>₹{(parseFloat(service.rate) - parseFloat(service.convenienceFee)).toFixed(0)}</div>
                              </div>
                            </CardGroup>
                          ))}
                        </div>
                      )}
                      {dataFilled &&
                        selectedServicees !== "Groomers" &&
                        selectedServices.map((service, index) => (
                          <CardGroup
                            key={index}
                            className="p-3"
                            style={{
                              marginTop: 10,
                              border: "1px solid #beb7b7",
                              borderRadius: 10,
                              marginRight: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div style={{ flexDirection: "column" }}>
                                <div>{service.type}</div>
                                <div>{service.description}</div>
                              </div>
                              <div>Service Cost</div>
                              <div>Convenience Fee</div>
                              <div>In-Hand Amount</div>
                            </div>
                            <div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Dropdown isOpen={selectedServiceType === service.type} toggle={() => toggleDropdown(service.type)}>
                                  <DropdownToggle tag="span" data-toggle="dropdown">
                                    <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem onClick={() => handleModify(service.type)}>Modify</DropdownItem>
                                    <DropdownItem onClick={() => handleDeletes(service.type)}>Delete</DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                              <div>₹{service.rate}</div>
                              <div>₹{platformFee.toFixed(2)}</div>
                              <div>₹{(parseFloat(service.rate) - parseFloat(service.convenienceFee)).toFixed(2)}</div>
                            </div>
                          </CardGroup>
                        ))}
                    </div>
                  )}
                </div>
              )}
              <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>{selectedServiceData ? "Modify Service" : ""}</ModalHeader>
                <ModalBody>
                  {selectedServiceData && (
                    <>
                      {/* Populate and edit service data */}
                      <div className=" mt-2">
                        <label htmlFor="inputServiceName">Service Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Service Name"
                          value={selectedServiceData.name}
                          onChange={(e) =>
                            setSelectedServiceData({
                              ...selectedServiceData,
                              name: e.target.value,
                            })
                          }
                          required
                        />
                        <div className=" mt-2">
                          <label htmlFor="inputServiceDescription">Service Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Service Description"
                            value={serviceDescription}
                            onChange={(e) => setServiceDescription(e.target.value)}
                            required
                          />
                        </div>
                        <div className="  mt-2">
                          <label for="inputPetBreed">Service Rate*</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Service Rate"
                            value={serviceRate}
                            onChange={(e) => setServiceRate(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mt-2">
                          <span>
                            <strong>Details</strong>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span style={{ flexDirection: "row", display: "flex" }}>Platform Fee (10%):</span>
                            {isGstEnabled && selectedServicees === "Groomers" ? <span style={{ flexDirection: "row" }}>GST (18%):</span> : null}
                          </div>
                          <div>
                            <span style={{ flexDirection: "row", display: "flex" }}>₹{platformFee.toFixed(2)}</span>
                            {isGstEnabled && selectedServicees === "Groomers" ? <span style={{ flexDirection: "row" }}>₹{gst.toFixed(2)}</span> : null}
                          </div>
                        </div>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>In-Hand Amount**:</span>
                          <span>₹{inHandAmount.toFixed(2)}</span>
                        </div>
                        <div className="mt-2">
                          <span>*Amount Will be Charged to customer</span>
                          <span>**Amount Will be transferred to Collaborator</span>
                        </div>
                      </div>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => setShowModal(false)}>
                    Close
                  </Button>
                  <Button color="primary" onClick={handleSavev}>
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
              {serviceName.name === "NGOs" ? null : (
                <div>
                  <div
                    className="pt-3"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: 600 }}>Bank Details</span>
                    </div>
                    <div>
                      <button
                        type="button"
                        class="btn btn-light navbar-buttons"
                        onClick={() => {
                          setEditedBankName(user[selectedDot].bankName);
                          setAccountHolderName(user[selectedDot].accountHolderName);
                          setEditedAccountNumber(user[selectedDot].AccountNumber);
                          setEditedGSt(user[selectedDot].gst);
                          setEditedIfsc(user[selectedDot].IFSCCode);
                          setEditedPan(user[selectedDot].PanNo);
                          setIsEditBankModal(true);
                        }}
                      >
                        <span class="navbar-button-text">Edit</span>
                      </button>
                    </div>
                  </div>

                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Bank Name</span>
                    </div>
                    <div>
                      <span>{user[selectedDot].bankName}</span>
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Account Holder Name</span>
                    </div>
                    <div>
                      <span>{user[selectedDot].accountHolderName}</span>
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Account Number</span>
                    </div>
                    <div>
                      <span>{user[selectedDot].AccountNumber}</span>
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>IFSC Code</span>
                    </div>
                    <div>
                      <span>{user[selectedDot].IFSCCode}</span>
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Gst No.</span>
                    </div>
                    <div>
                      <span>{user[selectedDot].gst}</span>
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Pan No.</span>
                    </div>
                    <div>
                      <span>{user[selectedDot].PanNo}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <strong>Media</strong>
                  </div>
                  <div>
                    {uploading ? (
                      <Button onClick={handleSave}>Save</Button>
                    ) : (
                      <Button>
                        <label
                          htmlFor="imageUpload"
                          style={{
                            marginRight: 5,
                            border: "none",
                            padding: "5px 10px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Upload
                          <input type="file" id="imageUpload" multiple onChange={handleImageUpload} style={{ display: "none" }} />
                        </label>
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {images.map((image, index) => (
                    <div key={image.key} className="image-item" style={{ position: "relative", marginRight: 10 }}>
                      <img src={image.value} className="img-fluid" style={{ width: 100, height: "auto" }} alt={`Image ${index}`} />
                      <Button
                        style={{ background: "transparent", border: "none" }}
                        onClick={() => {
                          setIsConfirmOpen(true);
                          setIndexItem(index);
                          setImageID(image.key);
                        }}
                        className="delete-button"
                      >
                        <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" mt-2">
                <label className="pt-10 lableText">
                  Cover Image
                  <span className="required">*</span>
                </label>
                <div className="coverUpload">
                  <div className="text-right">
                    <label htmlFor="coverUpload" className="btn btn-light navbar-buttons">
                      <span className="vetUploadButton">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                      </span>
                      <span className="navbar-button-text pl-2">Upload</span>
                      <input id="coverUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                    </label>
                    {thumbselectedImage && (
                      <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                        <span className="vetUploadButton">
                          <FontAwesomeIcon fontSize={20} icon={faTrash} />
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex flex-wrap">
                        <div className="col-md-4 d-flex align-items-center pt-3">
                          {thumbselectedImage ? (
                            <img src={URL.createObjectURL(thumbselectedImage)} alt="Uploaded" style={{ width: 200 }} />
                          ) : (
                            preloadedCoverImage && <img src={preloadedCoverImage.src} alt="Prepopulated" style={{ width: 200 }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} contentLabel="Edit Modal">
            <ModalHeader style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Edit User</div>
            </ModalHeader>
            <ModalBody className="px-5">
              <div>
                <label>Contact:</label>
                <input className="form-control" type="text" value={editedContact} onChange={(e) => setEditedContact(e.target.value)} />
              </div>
              <div>
                <label>City:</label>
                <input className="form-control" type="text" value={editedCity} onChange={(e) => setEditedCity(e.target.value)} />
              </div>
              <div>
                <label>Location:</label>
                <input className="form-control" type="text" value={editedLocation} onChange={(e) => setEditedLocation(e.target.value)} />
              </div>
              {serviceName.name === "Groomers" ? (
                <div>
                  <label>Experience:</label>
                  <input className="form-control" type="text" value={editedExperince} onChange={(e) => setEditedExperince(e.target.value)} />
                </div>
              ) : (
                <div>
                  {serviceName.name === "NGOs" ? (
                    <div>
                      <label>Link:</label>
                      <input className="form-control" type="text" value={editedLink} onChange={(e) => setEditedLink(e.target.value)} />
                      <label>Registration Number:</label>
                      <input className="form-control" type="text" value={editedRegistration} onChange={(e) => setEditedRegistration(e.target.value)} />
                      <label>Achievment:</label>
                      <input className="form-control" type="text" value={editedAchievment} onChange={(e) => setEditedAchievment(e.target.value)} />
                      <label>Area of Focus:</label>
                      <input className="form-control" type="text" value={editedAreaofFocus} onChange={(e) => setEditedAreaofFocus(e.target.value)} />
                    </div>
                  ) : (
                    <div>
                      <div className="">
                        <label>Education:</label>
                        {educationArray.map((education, index) => (
                          <div key={index}>
                            <input
                              className="form-control mt-2"
                              type="text"
                              value={education}
                              onChange={(e) => {
                                const updateEducationArray = [...educationArray];
                                updateEducationArray[index] = e.target.value;
                                setEducationArray(updateEducationArray);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <FontAwesomeIcon onClick={addEducation} color="#0D6EFD" fontSize={20} icon={faPlus} />
                      </div>
                      <div className="">
                        <label>Area of Expertise:</label>
                        {expertiseArray.map((expertise, index) => (
                          <div key={index}>
                            <input
                              className="form-control mt-2"
                              type="text"
                              value={expertise}
                              onChange={(e) => {
                                const updatedExpertiseArray = [...expertiseArray];
                                updatedExpertiseArray[index] = e.target.value;
                                setExpertiseArray(updatedExpertiseArray);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <FontAwesomeIcon onClick={addExpertise} color="#0D6EFD" fontSize={20} icon={faPlus} />
                      </div>
                      <div>
                        <label>Experience:</label>
                        <input className="form-control" type="text" value={editedExperince} onChange={(e) => setEditedExperince(e.target.value)} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleSaveEdits} style={{ marginRight: 10 }} color="primary">
                Save
              </Button>
              <Button onClick={() => setIsEditModalOpen(false)} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={isEditBankModal} onRequestClose={() => setIsEditBankModal(false)} contentLabel="Edit Modal">
            <ModalHeader style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Edit User</div>
            </ModalHeader>
            <ModalBody className="px-5">
              <div>
                <label>Bank Name:</label>
                <select className="form-control" style={{ borderRadius: 6 }} value={editedBankName} onChange={(e) => setEditedBankName(e.target.value)}>
                  <option value="" disabled>
                    Select a Bank
                  </option>
                  {bankNames.map((bank, index) => (
                    <option key={index} value={bank.name}>
                      {bank.name}
                    </option>
                  ))}
                </select>
                {/* <input className="form-control" type="text"  /> */}
              </div>
              <div>
                <label>Account Holder Name:</label>
                <input className="form-control" type="text" value={editedAccountHolderName} onChange={(e) => setAccountHolderName(e.target.value)} />
              </div>
              <div>
                <label>Account Number</label>
                <input className="form-control" type="text" value={editedAccountNumber} onChange={(e) => setEditedAccountNumber(e.target.value)} />
              </div>
              <div>
                <label>IFSC Code</label>
                <input className="form-control" type="text" value={editedIfsc} onChange={(e) => setEditedIfsc(e.target.value)} />
              </div>
              <div>
                <label>Gst</label>
                <input className="form-control" type="text" value={editedGst} onChange={(e) => setEditedGSt(e.target.value)} />
              </div>
              <div>
                <label>Pan</label>
                <input className="form-control" type="text" value={editedPan} onChange={(e) => setEditedPan(e.target.value)} />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleBankEdits} style={{ marginRight: 10 }} color="primary">
                Save
              </Button>
              <Button onClick={() => setIsEditBankModal(false)} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={isModalOpen} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>View Image</ModalHeader>
            <ModalBody>
              {workspaceDocument &&
                workspaceDocument.map((doc) => {
                  if (doc.docName === "AADHAR") {
                    return <img key={doc.id} src={doc.docImage} alt={doc.docName} style={{ width: "100%" }} />;
                  }
                  return null;
                })}
            </ModalBody>
          </Modal>
          <Modal isOpen={isModalOpen} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>View Image</ModalHeader>
            <ModalBody>
              {workspaceDocument &&
                workspaceDocument.map((doc) => {
                  if (doc.docName === "PAN") {
                    return <img key={doc.id} src={doc.docImage} alt={doc.docName} style={{ width: "100%" }} />;
                  }
                  return null;
                })}
            </ModalBody>
          </Modal>
          <div style={{ marginTop: 20, padding: "0 80px" }}>
            <h5>Internal Note</h5>
            <div className="chat-container">
              <div className="chat-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <div className="message-text">{message.text}</div>
                    <div className="message-time">{getCurrentLocalDateTime()}</div>
                  </div>
                ))}
                {internalNotes.map((note) => (
                  <div key={note.id} className={`message bot`}>
                    <div className="message-text">{note.note}</div>
                    <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                  </div>
                ))}
              </div>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                <textarea
                  className="form-control"
                  rows={5}
                  type="text"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                  <button className="form-control" style={{ width: 100 }} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "8%",
              marginTop: 10,
            }}
          >
            <Button onClick={handleCreate} style={{ marginRight: 10 }} color="primary">
              Save
            </Button>
            <Button color="secondary">Cancel</Button>
          </div>
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleDeleteImage(indexItem, imageId)}
            message="Are you sure you want to Delete Image?"
          />
        </div>
      )}
    </>
  );
};

export default WorkspaceDetails;
