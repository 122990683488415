import React, { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFileExport,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
const AddPet = ({id}) => {
  const navigate=useNavigate();
  return (
    <div style={styles.topBar}>
      <div style={styles.leftButtons}>
      <div style={styles.rightButtons}>
        <button style={styles.iconButton}>
          <Button
            style={styles.addButton}
            color="#ffffff"
            onClick={() => navigate(`/PetParent/PetParentDetails/AddPetDetails/${id}`)}
          >
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
            <span style={{ marginLeft: "5px" }}>Add Pet</span>
          </Button>
        </button>
       </div>
      </div>
    </div>
  );
};

const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  dotsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  dot: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#6B7280",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 5px",
  },
  moreDogsMessage: {
    marginLeft: "10px",
    color: "red",
  },
};

export default AddPet;
