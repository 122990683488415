import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, ToastBody } from "reactstrap";
import Apis from "../utils/api";
import { toast } from "react-toastify";
import { isValidEmail } from "../utils/helpers";

const AdminTopBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const api = new Apis();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSave = async () => {
    try {
      if (!email || !isValidEmail(email)) {
        throw new Error("Email is not valid");
      }
      if (!role) {
        throw new Error("Role is not valid");
      }
      const response = await api.addAdmin({
        email,
        role,
      });
      toast.success("SuccessFully added");
      console.log("API response:", response);
      // You can add further actions here upon successful API response
      toggleModal(); // Close the modal after saving
    } catch (error) {
      toast.error("Error" + error.message);
      console.error("Error adding admin:", error);
    }
  };
  return (
    <div style={styles.topBar}>
      <div style={styles.leftButtons}>
        {/* Add the "Find Something" input */}
        <div style={styles.findInput}>
          <InputGroup>
            <Button>
              <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
            </Button>
            <Input placeholder="Find Something" />
          </InputGroup>
        </div>

        <button style={styles.iconButton}>
          <Button style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faFileExport} />
            <span style={{ marginLeft: "5px" }}>Export</span>
          </Button>
        </button>
      </div>

      <div style={styles.rightButtons}>
        <button style={styles.iconButton}>
          <Button style={styles.addButton} color="#ffffff" onClick={toggleModal}>
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
            <span style={{ marginLeft: "5px" }}>New Admin</span>
          </Button>
        </button>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered className="modal-lg">
        <ModalHeader toggle={toggleModal}>Add Admin</ModalHeader>
        <ModalBody>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Name">Name*</label>
                  <input type="text" class="form-control" id="name" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Name" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Name">Phone No.*</label>
                  <input type="text" class="form-control" id="mobile" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Mobile" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="accountNumber">Email*</label>
                  <input type="text" class="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Role">Role*</label>
                  <select class="form-control" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="Super Admin">Super Admin</option>
                    <option value="Staff">Staff</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Gender">Gender*</label>
                  <select class="form-control" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="Super Admin">Male</option>
                    <option value="Staff">Female</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end", // Use space-between to separate left and right buttons
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center", // Align the items vertically
  },
  findInput: {
    marginRight: "10px", // Adjust spacing between input and export button
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default AdminTopBar;
