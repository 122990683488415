import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faPlus, faFileExport, faSearch, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Apis from "../utils/api";
import { useServiceList } from "../views/services/serviceListState";
import { useNavigate } from "react-router-dom";
import LoaderModal from "./common/Loader";
import dataURItoBlob from "../utils/helpers";
import {Form,FormGroup} from "reactstrap";
const RollsTopBar = ({ setSearchQuery, onClick, refreshRollsData }) => {
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const api = new Apis();
  const navigate = useNavigate();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { getServiceListData } = useServiceList();
  const [WorkspacelistId, setWorkspacelistId] = useState(null);
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        if (video.duration <= 60) {
          setSelectedVideo(file);
        } else {
          toast.error("Video duration should be no longer than 1 minute.");
        }
      };
      video.src = URL.createObjectURL(file);
    }
  };
  const handleCancelVideo = () => {
    setSelectedVideo(null);
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };
  const initialText = "Write a Roll Caption";
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(initialText);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleInputChange = (event) => {
    setEditedText(event.target.value);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedText(initialText);
  };
  const initialRollText = "Write a Roll Tags";
  const [isRollEditing, setIsRollEditing] = useState(false);
  const [editedRollText, setEditedRollText] = useState(initialRollText);
  const handleRollEditClick = () => {
    setIsRollEditing(true);
  };
  const handleRollInputChange = (event) => {
    setEditedRollText(event.target.value);
  };
  const handleRollSaveClick = () => {
    setIsRollEditing(false);
  };
  const handleRollCancelClick = () => {
    setIsRollEditing(false);
    setEditedRollText(initialRollText);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);
  const handleScheduleToggle = () => {
    setIsScheduleChecked(!isScheduleChecked);
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [selectedID, setSelectedID] = useState([]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState([]);
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    try {
      const selectedWorkspace = workspaceDatas.find((workspace) => workspace.designationName === event.target.value);
      console.log("Selected Workspace:", selectedWorkspace);
      if (selectedWorkspace && selectedWorkspace.id !== undefined) {
        setSelectedID(selectedWorkspace.collaboratorId);
        setSelectedWorkspaceId(selectedWorkspace.id);
        console.log("Selected Workspace ID:", selectedWorkspace);
      } else {
        setSelectedID([]);
        setSelectedWorkspaceId([]);
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };
  const [test, setTest] = useState(false);
  const toggleTest = () => {
    setTest(!test);
  };
  const rollUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedVideo);
    formData.append("description", editedText);
    formData.append("tag", editedRollText);
    let collaboratorIdParameter = "";
    let workspaceIdParameter = "";
    if (selectedOption === "Veterinary" || selectedOption === "Grooming") {
      collaboratorIdParameter = selectedID;
      workspaceIdParameter = selectedWorkspaceId;
      console.log(selectedID);
    } else if (selectedOption === "FurrCrew") {
      collaboratorIdParameter = "1";
      workspaceIdParameter="1"
    } else if (selectedOption === "FurrFiesta") {
      collaboratorIdParameter = "2";
      workspaceIdParameter="2"
    }
    try {
      // Basic validation checks
      if(!selectedOption){
        throw new Error("Please select a User Type to upload.");
      }
      if (!selectedVideo) {
        throw new Error("Please select a video to upload.");
      }
      if (!editedText) {
        throw new Error("Please provide a description.");
      }
      if (!editedRollText) {
        throw new Error("Please provide a tag for the video.");
      }
      if (!thumbImage) {
        throw new Error("Cover Image is required.");
      }
      setLoading(true);
      const response = await api.uploadRolls(collaboratorIdParameter, selectedOption, isChecked, workspaceIdParameter,featuredSwitchState,switchState,test,formData);
      setLoading(false);
      uploadCoverImage(response.id);
      console.log("ResponseID", response);
      setSelectedVideo(null);
      toast.success("Video uploaded successfully!");
      setIsModalOpen(false);
      setEditedText(initialText);
      setSelectedOption(null);
      setEditedRollText(initialRollText);
      setThumbImage(null);
      refreshRollsData();
      window.location.reload();
    } catch (error) {
      console.error("Error uploading video:", error);
      if (error.response && error.response.status === 400) {
        toast.error("Please Select Workspace");
      } else {
        toast.error("Error uploading video: " + error.message);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    const serviceData = getServiceListData(selectedOption);
    console.log("serviceData", serviceData);
    setWorkspacelistId(serviceData);
  }, [selectedOption, getServiceListData]);
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceId = selectedOption === "Veterinary" ? "d8d71038-c48c-11ed-abc1-02817ef0919c" : "5e4c6b60-c48e-11ed-abc1-02817ef0919c";
        const workspaceData = await api.getWorkspace(workspaceId);
        console.log("WorkspaceListID", workspaceId);
        const activeWorkspaceData = workspaceData.filter((data) => data.active === true);
        setSelectedWorkspacDatas(activeWorkspaceData);
        console.log(activeWorkspaceData);
        setWorkspaceOptions(activeWorkspaceData);
        console.log("WorkspaceData", activeWorkspaceData);
      } catch (error) {
        console.error("Error fetching workspace:", error);
      }
    };

    fetchWorkspace();
  }, [selectedOption]);

  console.log("Is Checked", isChecked);
  const [thumbImage, setThumbImage] = useState(null);
  const [imageName, setImageName] = useState();
  const handleCoverImageUpload = (event) => {
    console.log("handleCoverImageUpload called");
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          console.log("Image width:", img.width);
          console.log("Image height:", img.height);
          if (img.width === 1080 && img.height === 1350) {
            setThumbImage(e.target.result);
          } else {
            toast.error("Cover image should be 1080 by 1350 pixels");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCoverClick = () => {
    document.getElementById("thumbUploadInput").click();
  };
  const handleThumbImageDelete = () => {
    setThumbImage(null);
  };
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbImage) {
        console.log("No new image selected for upload.");
        return;
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(thumbImage), fileName);
      const response = await api.uploadCoverImg("ROLL", userId, formData);
      console.log("CoverImg", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const [switchState, setSwitchState] = useState(false);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(false);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  return (
    <>
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={styles.topBar}>
          <ToastContainer position="top-right" autoClose={5000} />
          <div style={styles.leftButtons}>
            <div style={styles.findInput}>
              <InputGroup>
                <Button>
                  <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
                </Button>
                <Input
                  placeholder="Find Something"
                  onChange={handleSearchInputChange} // Call the handler on input change
                />
              </InputGroup>
            </div>
            <button style={styles.iconButton}>
              <Button onClick={onClick} style={styles.exportButton} color="#ffffff">
                <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faFileExport} />
                <span style={{ marginLeft: "5px" }}>Export</span>
              </Button>
            </button>
          </div>
          <div style={styles.rightButtons}>
            <button style={styles.iconButton}>
              <Button style={styles.addButton} color="#ffffff" onClick={toggleModal}>
                <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
                <span style={{ marginLeft: "5px" }}>New Roll</span>
              </Button>
            </button>
          </div>
          {/* Modal component */}
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Upload New Roll</ModalHeader>
            <ModalBody>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {selectedVideo ? (
                  <div
                    style={{
                      maxWidth: "100%",
                      maxHeight: "60vh",
                      overflow: "auto",
                    }}
                  >
                    <video controls style={{ width: "100%" }}>
                      <source src={URL.createObjectURL(selectedVideo)} />
                    </video>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#EEEEEE",
                      padding: "20%",
                      borderRadius: 5,
                      height: 200,
                    }}
                  >
                    <Button color="secondary" onClick={handleUploadClick}>
                      Upload
                    </Button>
                  </div>
                )}
              </div>
              {selectedVideo && (
                <Button color="danger" onClick={handleCancelVideo}>
                  Cancel
                </Button>
              )}
              <div style={{ flexDirection: "row" }} className="mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="Evening"
                  id="Evening"
                  value="option3"
                  checked={isChecked} // Bind the state to the checkbox's checked attribute
                  onChange={handleCheckboxChange} // Handle the checkbox change event
                />
                <span className="mx-2">
                  This roll is <span style={{ color: "red" }}>18+</span>
                </span>
              </div>
              <hr />
              <div className="mt-2">
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <span>
                    <b>ROLL CAPTION</b>
                  </span>
                  <span>
                    <FontAwesomeIcon color="blue" fontSize={20} icon={faEdit} onClick={handleEditClick} />
                  </span>
                </div>
                <div>
                  {isEditing ? (
                    <div>
                      <textarea
                        type="text"
                        value={editedText}
                        onChange={handleInputChange}
                        rows={4}
                        style={{
                          width: "80%",
                          border: "1.25px solid #6b7280",
                          borderRadius: 5,
                          backgroundColor: "#f3f4f6",
                          minHeight: 30,
                          resize: "vertical",
                          fontSize: 15,
                          padding: 8,
                        }}
                      />
                      <div className="d-flex mt-2">
                        <Button color="primary" className="mr-2" onClick={handleSaveClick}>
                          Save
                        </Button>
                        <Button style={{ marginLeft: 5 }} color="secondary" onClick={handleCancelClick}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <span style={{ fontSize: 15 }}>{editedText}</span>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <span>
                    <b>ROLL TAGS</b>
                  </span>
                  <span>
                    <FontAwesomeIcon color="blue" fontSize={20} icon={faEdit} onClick={handleRollEditClick} />
                  </span>
                </div>
                <div>
                  {isRollEditing ? (
                    <div>
                      <textarea
                        type="text"
                        value={editedRollText}
                        onChange={handleRollInputChange}
                        rows={4}
                        style={{
                          width: "80%",
                          border: "1.25px solid #6b7280",
                          borderRadius: 5,
                          backgroundColor: "#f3f4f6",
                          minHeight: 30,
                          resize: "vertical",
                          fontSize: 15,
                          padding: 8,
                        }}
                      />
                      <div className="d-flex mt-2">
                        <Button color="primary" className="mr-2" onClick={handleRollSaveClick}>
                          Save
                        </Button>
                        <Button style={{ marginLeft: 5 }} color="secondary" onClick={handleRollCancelClick}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <span style={{ fontSize: 15 }}>{editedRollText}</span>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <span>
                  <b>USER TYPE</b>
                </span>
                <div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" value="FurrCrew" checked={selectedOption === "FurrCrew"} onChange={handleOptionChange} />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      FurrCrew
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="FurrFiesta"
                      checked={selectedOption === "FurrFiesta"}
                      onChange={handleOptionChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      FurrFiesta
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Veterinary"
                      checked={selectedOption === "Veterinary"}
                      onChange={handleOptionChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio3">
                      Veterinary
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" value="Grooming" checked={selectedOption === "Grooming"} onChange={handleOptionChange} />
                    <label className="form-check-label" htmlFor="inlineRadio4">
                      Groomer
                    </label>
                  </div>
                </div>
                {selectedOption === "Veterinary" || selectedOption === "Grooming" ? (
                  <div className="form-group mt-2">
                    <label htmlFor="inputPetsSpecies">Select Workspace</label>
                    <select id="inputPetsSpecies" className="form-control" onChange={handleWorkspaceChange} value={selectedWorkspaceName}>
                      <option value="" disabled hidden>
                        Select a Workspace from List
                      </option>
                      {workspaceOptions.map((workspace) => (
                        <option key={workspace.designationName} value={workspace.designationName}>
                          {`${workspace.designationName} - ${workspace.workplaceName}`}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Featured</label>
                  </span>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Sponsored</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Test</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={test} onChange={toggleTest} />
                  </FormGroup>
                </Form>
              </div>
              </div>
                <div className="form-group mt-2 p-0">
                  <label className="pt-10 lableText">
                    Cover Image
                    <span className="required">*</span>
                  </label>
                  <div className="imageUploadBox">
                    <div className="text-right">
                      <label htmlFor="thumbUploadInput">
                        <input type="file" id="thumbUploadInput" style={{ display: "none" }} onChange={handleCoverImageUpload} />
                        <Button
                          // onClick={handleCoverClick}
                          color="secondary"
                          as="span"
                          onClick={handleCoverClick}
                        >
                          Upload
                        </Button>
                      </label>
                      {thumbImage && (
                        <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                          <span className="vetUploadButton">
                            <FontAwesomeIcon fontSize={20} icon={faTrash} />
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex flex-wrap">
                          <div className="col-md-4 d-flex align-items-center pt-3">
                            {thumbImage && <img src={thumbImage} alt="Uploaded" style={{ maxWidth: "50%" }} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={rollUpload}>
                Save
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};
const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default RollsTopBar;
