import React, { useEffect, useState } from "react";
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Label, Input, Pagination, PaginationItem, PaginationLink,Form,FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CollaboratorTopBar from "../../component/CollaboratorTopBar";
import Apis from "../../utils/api";
import CollabSelect from "../../component/CollabSelect";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../component/common/ConfirmDialog";
import LoaderModal from "../../component/common/Loader";
const CollaboratorList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [collaboratos, setCollaboratorList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [currentPage, setCurrentPage] = useState(1);
  const api = new Apis();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAllCollaborators = async () => {
      try {
        const result = await api.getAllCollaborators(selectedType);
        setCollaboratorList(result);
        setCurrentPage(1);
      } catch (error) {
        console.log("Error fetching Collaborators ", error);
      }
    };
    fetchAllCollaborators();
  }, [selectedType]);
  const [selectedRows, setSelectedRows] = useState([]);
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const totalPages = Math.ceil(collaboratos.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const filteredCollaboratos = collaboratos.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const mobileLower = (item.mobile || "").toLowerCase();
    const collaboratorNameLower = (item.collaboratorName || "").toLowerCase();
    const emailLower = (item.email || "").toLowerCase();
    const idLower = (item.id || "").toLowerCase();
    return (
      mobileLower.includes(searchQueryLower) ||
      collaboratorNameLower.includes(searchQueryLower) ||
      emailLower.includes(searchQueryLower) ||
      idLower.includes(searchQueryLower)
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCollaboratos.slice(indexOfFirstItem, indexOfLastItem);
  const columns = ["checkbox", ...Object.keys(filteredCollaboratos[0] || {})];
  const customTableHeadings = ["checkbox", "ID", "Name", "Mobile", "City", "Email", "Status", "Actions"];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // Update the state to indicate that the item has been copied
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    // Clear the "Copied" message after a few seconds
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000); // 2000 milliseconds = 2 seconds
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => filteredCollaboratos[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleEdit = (item) => {
    navigate(`/CollaboratorList/EditCollaborator/${item.id}`);
  };

  // const handleDelete = async (item) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await api.deleteCollaborator(item.id);
  //     setIsLoading(false);
  //     toast.success("Collab deleted successfully!");
  //     navigate("/CollaboratorList");
  //     console.log(response);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Error deleting the Collab:", error);
  //     toast.error(`Error deleting the Collab: ${error.response.data.message}`);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleDelete = async (item, rowIndex) => {
    try {
      setIsLoading(true);
      const response = await api.deleteCollaborator(item.id);
      setIsLoading(false);
      const originalIndex = indexOfFirstItem + rowIndex;
      setCollaboratorList((prevWebinars) => {
        const updatedAppointments = [...prevWebinars];
        updatedAppointments.splice(originalIndex, 1);
        return updatedAppointments;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("CollaboratorList deleted successfully!");
      navigate("/CollaboratorList");
      console.log(response);
    } catch (error) {
      console.error("Error deleting the CollaboratorList:", error);
      toast.error(`Error deleting the Collab: ${error.response.data.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex, setRowIndex] = useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); 
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {isLoading ? (
        <LoaderModal isOpen={isLoading} toggle={() => setIsLoading(false)} />
      ) : (
        <>
          <CollabSelect onSelectType={setSelectedType} />
          <CollaboratorTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} />
          <Table hover style={{ tableLayout: "auto" }}>
            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <tr>
                {customTableHeadings.map((heading, index) => (
                  <th key={index} style={{ whiteSpace: "nowrap" }}>
                    {heading === "checkbox" ? (
                      <input
                        type="checkbox"
                        checked={selectedRows.length === currentItems.length}
                        onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                      />
                    ) : (
                      heading
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {customTableHeadings.map((heading, colIndex) => (
                    <td key={colIndex}>
                      {heading === "Actions" ? (
                        <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                            {/* Three vertical dots icon */}
                            <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : heading === "ID" ? (
                        <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                          {item.id.slice(0, 8)}
                          {copiedItems[rowIndex] && <span style={{ color: "green" }}>Copied!</span>}
                        </span>
                      ) : heading === "checkbox" ? (
                        <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                      ) : heading === "Name" ? (
                        <a href style={{ cursor: "pointer" }} onClick={() => navigate(`/CollaboratorList/EditCollaborator/${item.id}`)}>
                        { item.collaboratorName}
                      </a>
                      ) : heading === "Mobile" ? (
                        item.mobile
                      ) : heading === "City" ? (
                        item.city
                      ) : heading === "Email" ? (
                        item.email
                      ) : heading === "Status" ? (
                        item.status
                      )  :(
                        item[heading]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleDelete(itemId, selectedrowIndex)}
            message="Are you sure you want to remove the listing?"
          />
        </>
      )}
    </>
  );
};

export default CollaboratorList;
