import { useContext } from 'react';
import { AppContext } from './states';

export function useWebinarState() {
  const { state, dispatch } = useContext(AppContext);
  const currentWebinarData = state.webinarState;
  const setwebinarData  = data => {
    sessionStorage.setItem('webinarData', JSON.stringify(data));
    dispatch({ type: 'webinarState', payload: data });
  };
  const getwebinarData  = id => {
    const storedData = sessionStorage.getItem('webinarData');
    const webinarData = storedData ? JSON.parse(storedData) : currentWebinarData;
    if (id) {
      return webinarData && webinarData.length > 0
        ? webinarData.find(i => i.id === id) || null
        : null;
    } else {
      return webinarData;
    }
  };
  return {
    webinarData: currentWebinarData,
    setwebinarData ,
    getwebinarData ,
  };
}
