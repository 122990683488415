import React, { useState } from "react";
import { Routes, Route, useNavigate,Navigate } from "react-router-dom";
import CustomScroll from "./component/ScrollBehaviour";
import NavBar from "./component/Sidebar";
import TopBar from "./component/TopBar";
import CollaboratorList from "./views/list/CollaboratorList";
import PetParent from "./views/list/PetParent";
import AppointmentList from "./views/list/AppointmentList";
import Login from "./views/Login";
import PetParentDetails from "./views/PetParentDetails";
import WorkspaceDetails from "./views/WorkspaceDetails";
import NewWorkspace from "./views/NewWorkspace";
import AdoptionList from "./views/list/AdoptionList";
import CreateAdoption from "./views/CreateAdoption";
import CouponList from "./views/list/CouponList";
import NotificationList from "./views/list/NotificationList";
import CreateNotification from "./views/CreateNotification";
import MiscellaneousList from "./views/list/MiscellaneousList";
import AdminList from "./views/list/AdminList";
import BlogList from "./views/list/BlogList";
import BlogDetail from "./views/BlogDetail";
import EmailDetails from "./views/list/EmaiDetailsList";
import NewMail from "./views/NewMail";
import NewTemplate from "./views/NewTemplate";
import AppointmentDetails from "./views/AppointmnetDetails";
import RollsList from "./views/list/RollsList";
import WorkSpaceList from "./views/list/WorkSpaceList";
import EditCollaborator from "./views/EditCollaborator";
import AddUser from "./views/AddUser";
import AddPetDetails from "./views/AddPetDetails";
import AddCollaborator from "./views/AddCollaborator";
import AddAppointment from "./views/AddAppointment";
import EditRoll from "./views/EditRoll";
import RollsPermissionList from "./views/RollsPermissionList";
import EditAdoption from "./views/EditAdoption";
import EditNotification from "./views/EditNotification";
import EditCoupon from "./views/EditCoupon";
import AddBankAndImages from "./views/AddBankAndImages";
import EditBlogDetails from "./views/EditBlogDetails";
import MyAccount from "./views/MyAccount";
import EventDetails from "./views/EventDetails";
import EventList from "./views/list/EventList";
import EditEventDetails from "./views/EditEventDetails";
import EventAddress from "./views/EventAddress";
import WebinarList from "./views/list/WebinarList";
import CoursesList from "./views/list/CoursesList";
import AddWebinar from "./views/AddWebinar";
import EditWebinar from "./views/EditWebinar";
import Addcourse from "./views/AddCourse";
import EditCourse from "./views/EditCourse";
import AddedCourses from "./views/AddedCourses";
import "react-toastify/dist/ReactToastify.css";
import AdvertismentList from "./views/list/AdvertismentList";
import CreateAdvertisment from "./views/CreateAdvertisment";
import EditAdvertisment from "./views/EditAdvertisment";
import FaqList from "./views/list/FaqList";
import AddFaq from "./views/AddFaq";
import VersionWorspace from "./views/VersionWorkspace";
import FaqDetails from "./views/FaqDetails";
const App = () => {
  const navigate = useNavigate();
  const initialIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedIn);
  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    navigate("/PetParent");
  };
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false); 
    navigate("/login"); 
  };
  return (
    <div>
      {isLoggedIn ? (
        <div style={{ display: "flex" }}>
          <div style={{ overflowX: "hidden", height: "100vh" }}>
           <CustomScroll> <NavBar isLoggedIn={isLoggedIn} handleLogout={handleLogout} /></CustomScroll> 
          </div>
          <div style={{ flex: 1, backgroundColor: "#ffffff" ,overflowX: "auto",height:'100vh'}}>
            {/* <TopBar /> */}
            <div style={{ padding: 20,  }}>
              <Routes>     
              <Route path="/" element={<Navigate to="/PetParent" />} />
                <Route path="/PetParent" element={<PetParent />} />
                <Route path="/AppointmentList" element={<AppointmentList />} />
                <Route path="/AdoptionList" element={<AdoptionList />} />
                <Route path="/CollaboratorList" element={<CollaboratorList />}/>
                <Route path="/CouponList" element={<CouponList />} />
                <Route path="/NotificationList" element={<NotificationList />}/>
                <Route path="/MiscellaneousList" element={<MiscellaneousList />}/>
                <Route path="/AdminList" element={<AdminList />} />
                <Route path="/BlogList" element={<BlogList />} />
                <Route path="/MyAccount" element={<MyAccount />} />
                <Route path="/EmailDetails" element={<EmailDetails />} />
                <Route path="/RollsList" element={<RollsList />} />
                <Route path="/WorkSpaceList" element={<WorkSpaceList />} />
                <Route path="/EventList" element={<EventList />} />
                <Route path="/WebinarList" element={<WebinarList />} />
                <Route path="/CoursesList" element={<CoursesList />} />
                <Route path="/AdvertismentList" element={<AdvertismentList />} />
                <Route path="/FaqList" element={<FaqList />} />

                <Route path="/CoursesList/Addcourse" element={<Addcourse />} />
                <Route path="/CoursesList/EditCourse/:id" element={<EditCourse />} />
                <Route path="/WebinarList/AddWebinar" element={<AddWebinar />} />
                <Route path="/WebinarList/EditWebinar/:id" element={<EditWebinar />} />
                <Route path="/EventList/EditEventDetails/:id" element={<EditEventDetails />} />
                <Route path="/EventList/EventDetails" element={<EventDetails />} />
                <Route path="/MiscellaneousList/RollsPermissionList" element={<RollsPermissionList />}/>
                <Route path="/PetParent/AddUser" element={<AddUser />} />
                <Route path="/CollaboratorList/EditCollaborator/:id" element={<EditCollaborator />}/>
                <Route path="/RollsList/EditRoll/:id/:createdBy" element={<EditRoll />} />
                <Route path="/AppointmentList/AppointmentDetails/:id" element={<AppointmentDetails />}/>
                <Route path="/NotificationList/CreateNotification" element={<CreateNotification />}/>
                <Route path="/NotificationList/EditNotification" element={<EditNotification />}/>
                <Route path="/AdoptionList/CreateAdoption" element={<CreateAdoption />}/>
                <Route path="/AdoptionList/EditAdoption/:id" element={<EditAdoption />}/>
                <Route path="/PetParent/PetParentDetails/:id" element={<PetParentDetails />} />
                <Route path="/AdvertismentList/CreateAdvertisment" element={<CreateAdvertisment />} />
                <Route path="/AdvertismentList/EditAdvertisment/:id" element={<EditAdvertisment />} />
                <Route path="/FaqList/AddFaq" element={<AddFaq />} />
                <Route path="/FaqList/FaqDetails/:id" element={<FaqDetails />} />

                <Route path="/CoursesList/Addcourse/AddedCourses/:id/:selectedEpisodes" element={<AddedCourses />} />
                <Route path="/EventList/EventDetails/EventAddress" element={<EventAddress />} />
                <Route path="/WorkSpaceList/WorkspaceDetails/:id/:collaboratorId/:selectedService" element={<WorkspaceDetails />}/>
                <Route path="/WorkSpaceList/VersionWorspace/:id/:collaboratorId/:selectedService" element={<VersionWorspace />}/>
                <Route path="/WorkSpaceList/NewWorkspace" element={<NewWorkspace />}/>
                <Route path="/WorkSpaceList/NewWorkspace/AddBankAndImages/:id/:collabId/:selectedService" element={<AddBankAndImages />}/>
                <Route path="/EmailDetails/NewMail" element={<NewMail />} />
                <Route path="/EmailDetails/NewTemplate" element={<NewTemplate />}/>
                <Route path="/BlogList/BlogDetails" element={<BlogDetail />} />
                <Route path="/BlogList/EditBlogDetails/:id" element={<EditBlogDetails />} />
                <Route path="/CollaboratorList/AddCollaborator" element={<AddCollaborator />}/>
                <Route path="/AppointmentList/AddAppointment" element={<AddAppointment />}/>
                <Route path="/CouponList/EditCoupon/:id" element={<EditCoupon />} />
                <Route path="/PetParent/PetParentDetails/AddPetDetails/:id" element={<AddPetDetails />}/>
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
