import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WorkspaceStateProvider } from './views/services/states';
import { initializeApp } from "firebase/app"; 
import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import config from './utils/config';
const firebaseConfig = {
  apiKey:process.env.React_APP_FIREBASE_APIKEY,
  authDomain: "myapp-db4db.firebaseapp.com",
  projectId: "myapp-db4db",
  storageBucket: "myapp-db4db.appspot.com",
  messagingSenderId:process.env.React_APP_FIREBASE_SENDERID,
  appId:process.env.React_APP_FIREBASE_APPID,
  measurementId:process.env.React_APP_FIREBASE_MEASUREMENTID
};
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
let rcDefaults = config.variables;
remoteConfig.defaultConfig = rcDefaults;
fetchAndActivate(remoteConfig)
  .then(() => {
    console.log("Remote config activated");
    const val = getValue(remoteConfig, "welcome_message");
    console.log(val);
  })
  .catch((error) => {
    console.error("Error fetching remote config:", error);
  });
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <WorkspaceStateProvider>
        <App />
      </WorkspaceStateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
