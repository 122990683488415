import React, { useState, useEffect } from "react";
import { Button,Input,FormGroup,Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "../utils/api";
import LoaderModal from "../component/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { isAlphaNumeric, isValidEmail, isValidMobile } from "../utils/helpers";
import "react-toastify/dist/ReactToastify.css";
const EditCollaborator = () => {
  const api = new Apis();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [collab, setCollab] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };
  const handleCancelImage = () => {
    setSelectedImage(null);
  };

  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => {
    if (e.target.value.length <= 10) {
      setPhone(e.target.value);
    }
  };
  const handleCityChange = (e) => setCity(e.target.value);
  useEffect(() => {
    const fetchCollab = async () => {
      try {
        const detail = await api.getCollabDetails(id);
        setSelectedImage(detail.imageUrl);
        setName(detail.collaboratorName);
        setEmail(detail.email);
        setPhone(detail.mobile);
        setCity(detail.city);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchCollab();
  }, [id]);
  const editcollab = async () => {
    try {
      if (!isAlphaNumeric(name)) {
        throw new Error("Please fill out the collaborator name.");
      }
      if (!isValidMobile(phone)) {
        throw new Error("Please fill out a valid phone number.");
      }
      if (!isValidEmail(email)) {
        throw new Error("Please fill out a valid email address.");
      }
      if (!city) {
        throw new Error("Please fill out the city name.");
      }
      const requestBody = {
        id: id,
        email: email,
        countryCode: "+91",
        mobile: phone,
        collaboratorName: name,
        isVerified: true,
        isEmailVerified: true,
        city: city,
        status: "Created",
      };
      setLoading(true);
      const response = await api.EditCollab(id, requestBody);
      setLoading(false);
      toast.success("Collab Updated successfully!");
      console.log("API response:", response);
      setLoading(true);
      await uploadParentImage();
      setLoading(false);
    } catch (error) {
      console.error("Error editing collab:", error);
      toast.error(error.message);
      setLoading(false);
    }
  };
  const uploadParentImage = async () => {
    setLoading(true);
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      let imageBlob = selectedImage;
      if (!(selectedImage instanceof Blob)) {
        // If selectedImage is not a Blob, assume it's a data URL and convert it to a Blob
        const response = await fetch(selectedImage);
        const blob = await response.blob();
        imageBlob = new File([blob], "filename.jpg"); // Replace "filename.jpg" with the desired file name
      }
      const formData = new FormData();
      formData.append("file", imageBlob);
      const response = await api.updateCollabImg(id, formData);
      console.log("Img", response);
      toast.success("Image Added SuccessFully.");
      setLoading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
    }
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes()
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "collaborator",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };  
  const [testState, setTeststate] = useState(collab.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState); 
      const status = newSwitchState ? "Active" : "InActive";
      const requestBody = {
        status: status,
        active: newSwitchState,
        userId: id,
      };
      setLoading(true);
      const response = await api.addTestUser(requestBody);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response)
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            <span style={{ fontSize: 20 }}>
              <strong>Edit Collaborator</strong>
            </span>
            <div style={{ display: "flex" }}>
              <div className=" col-md-6">
                <label for="inputName"> Name* </label>
                <input type="text" className="form-control" placeholder="Enter name" required value={name} onChange={handleNameChange} />
              </div>
              <div style={{ marginLeft: 20 }} className=" col-md-6">
                <label for="inputEmail"> Email* </label>
                <input onChange={handleEmailChange} type="text" className="form-control" placeholder="Enter Email" required value={email} />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className=" col-md-6">
                <label for="inputName"> Phone* </label>
                <input onChange={handlePhoneChange} value={phone} type="text" className="form-control" placeholder="Enter Mobile no." required />
              </div>
              <div style={{ marginLeft: 20 }} className=" col-md-6">
                <label for="inputName"> City* </label>
                <input onChange={handleCityChange} type="text" className="form-control" placeholder="Enter City" required value={city} />
              </div>
            </div>
            
            <div style={{display:"flex",justifyContent:'space-between'}} className="  mt-2 col-md-6">
          <label for="inputName"> Test</label>
          <Form>
                              <FormGroup switch>
                                <Input type="switch" role="switch" checked={testState}  onChange={handleTestChange} />
                              </FormGroup>
                            </Form>
        </div>
            <label className="mt-2" for="inputName">
              Uploaded Image*
            </label>
            <div className="mt-2 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: 5,
                  height: 200,
                  width: "50%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {selectedImage ? (
                  <>
                    <img
                      src={selectedImage}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <div style={{ position: "absolute", top: 10, right: 10 }}>
                      <Button style={{ backgroundColor: "red", border: "none" }} onClick={handleCancelImage}>
                        <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                      </Button>
                    </div>
                  </>
                ) : (
                  <label htmlFor="uploadInput">
                    <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleFileChange} />
                    <Button onClick={handleUploadClick} color="secondary" as="span">
                      Upload
                    </Button>
                  </label>
                )}
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
                  <h5 >Internal Note</h5>
                    <div className="chat-container">
                      <div className="chat-messages">
                      {messages.map((message, index) => (
                          <div key={index} className={`message ${message.sender}`}>
                            <div className="message-text">{message.text}</div>
                            <div className="message-time">{getCurrentLocalDateTime()}</div>
                          </div>
                        ))}
                        {internalNotes.map((note) => (
                          <div key={note.id} className={`message bot`}>
                            <div className="message-text">{note.note}</div>
                            <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                          </div>
                        ))}
                      </div>
                      <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                        <textarea  className="form-control" rows={5} type="text" placeholder="Type your message..." value={inputText} onChange={(e) => setInputText(e.target.value)} />
                        <div style={{ marginTop: 10,display:'flex',justifyContent:'flex-end' }}>
                  <button className="form-control" style={{width:100}} type="submit">
                    Send
                  </button>
                </div>
                      </form>
                    </div>
                  </div>
            <div
              className="mt-2"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button style={{ marginRight: 10 }} color="primary" onClick={editcollab}>
                Save
              </Button>
              <Button color="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditCollaborator;
