import React, { useState, useEffect } from "react";
import { Table, Form, FormGroup, Input,Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Label, Pagination, PaginationItem, PaginationLink,Button } from "reactstrap";
import BlogTopBar from "../../component/BlogTopBar";
import Apis from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useBlogData } from "../services/blogState";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../../component/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialog from "../../component/common/ConfirmDialog";
const BlogList = () => {
  const api = new Apis();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blog, setBlogList] = useState([]);
  const { setblogData, getblogData } = useBlogData();
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedType, setSelectedType] = useState("blog");
  const totalPages = Math.ceil(blog.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state,setState]=useState(true);
  const filteredBlog = blog.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const blogLower = (item.data || "").toLowerCase();
    const blogTitle = (item.title || "").toLowerCase();
    return (
      (!state || item.status === true) &&
      (blogLower.includes(searchQueryLower) ||
      blogTitle.includes(searchQueryLower))
    );
  });
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredBlog.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const otppetParent = await api.getBlogsList(selectedType);
        setBlogList(otppetParent);
        setblogData(otppetParent);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching coupon:", error);
      }
    };
    fetchBlogs();
  }, [selectedType]);
  const handleToggleChange = async (event) => {
    const itemId = event.target.value;
    const updatedBlog = [...blog];
    const itemToUpdate = updatedBlog.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        itemToUpdate.status = !itemToUpdate.status;
        await BlogAdd(itemToUpdate);
        setBlogList(updatedBlog);
      } catch (error) {
        console.error("Error toggling blog status:", error);
        itemToUpdate.status = !itemToUpdate.status;
        setBlogList(updatedBlog);
      }
    }
  };
  const BlogAdd = async (itemToUpdate) => {
    try {
      const requestBody = {
        id: itemToUpdate.id,
        title: itemToUpdate.title,
        status: itemToUpdate.status,
        type:itemToUpdate.type,
        readTimeSpan: itemToUpdate.readTimeSpan,
        data: itemToUpdate.data,
        category:itemToUpdate.category,
        deviceType:itemToUpdate.deviceType,
        author: itemToUpdate.author,
        featured:itemToUpdate.featured,
        sponsored:itemToUpdate.sponsored,
        test:itemToUpdate.test,
        slug:itemToUpdate.slug
      };
      setLoading(true);
      const response = await api.updateBlog(requestBody);
      setLoading(false);
      toast.success("Blog Status Changed!");
      console.log(response);
    } catch (error) {
      setLoading(false);
      toast.error("Blog Status Changed!");
      console.error("Error adding admin:", error);
    }
  };
  const customTableHeadings = [
    "checkbox",
    "ID",
    "Title",
    "Read Time",
    "Detail",
    "Active",
    "Actions"
  ];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const truncateHTML = (html, maxLength) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let textContent = tempDiv.textContent || tempDiv.innerText || "";
    textContent = textContent.trim();
    if (textContent.length > maxLength) {
      textContent = textContent.substring(0, maxLength) + "...";
    }
    return textContent;
  };
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map(
      (rowIndex) =>blog[rowIndex]
    );
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [
      headers.join(","),
      ...selectedData.map((rowData) =>
        headers.map((header) => rowData[header]).join(",")
      ),
    ].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleEdit = (item) => {
    navigate(`/BlogList/EditBlogDetails/${item.id}`);
  };
  const handleDelete = async (item, rowIndex) => {
    try {
      setLoading(true);
      const response = await api.deleteBlogs(item.id);
      setLoading(false);
      // Calculate the index in the original blog array
      const originalIndex = indexOfFirstItem + rowIndex;
      // Remove the deleted item from the state
      setBlogList((prevBlogs) => {
        const updatedBlogs = [...prevBlogs];
        updatedBlogs.splice(originalIndex, 1);
        return updatedBlogs;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("Blog deleted successfully!");
      navigate('/BlogList');
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Blog:", error);
    } finally {
      setLoading(false);
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));
  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const handleblogarticlechange = (service) => {
    setSelectedType(service);
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex,setRowIndex]=useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} />
    {loading ? (
      <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
    ) : (  
    <>
    <div style={{ display: "flex" }}>
      <b
        style={{
          marginRight: 5,
          cursor: "pointer",
          color: selectedType === "blog" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleblogarticlechange("blog")}
      >
        Blog
      </b>
      <b
        style={{
          marginRight: 5,
          cursor: "pointer",
          color: selectedType === "article" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleblogarticlechange("article")}
      >
         Educational Blog
      </b>
      {/* <b
        style={{
          marginRight: 5,
          cursor: "pointer",
          color: selectedType === "NGOs" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("NGOs")}
      >
        onAadharTextChange
      </b> */}
    </div>
      <BlogTopBar setSearchQuery={setSearchQuery}  onClick={exportSelectedRowsToCSV}/>
      <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
      <Table hover>
      <thead style={{position:'sticky',top:0,zIndex:1}}>
          <tr>
            {customTableHeadings.map((heading, index) => (
              <th key={index} style={{ whiteSpace: "nowrap" }}>
                {heading === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === currentItems.length
                          ? []
                          : currentItems.map((_, index) => index)
                      )
                    }
                  />
                ) : (
                  heading
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {customTableHeadings.map((heading, colIndex) => (
                <td key={colIndex}>
                  {heading === "Actions" ? (
                        <Dropdown style={{textAlign:'center'}} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                          <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) :heading === "ID" ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(item.id, rowIndex)}
                      title={item.id}
                    >
                      {item.id.slice(0, 8)}
                      {copiedItems[rowIndex] && (
                        <span style={{ color: "green" }}>Copied!</span>
                      )}
                    </span>
                  ) : heading === "checkbox" ? (
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(rowIndex)}
                      onChange={() => toggleRowSelection(rowIndex)}
                    />
                  ): heading === "Title" ? (              
                      <a href style={{ cursor: "pointer" }} onClick={() => navigate(`/BlogList/EditBlogDetails/${item.id}`)}>
                      {item.title}
                    </a>
                  ) : heading === "Read Time" ? (
                    item.readTimeSpan
                  ) : heading === "Detail" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncateHTML(item.data, 50),
                      }}
                    />
                  ) : heading === "Active" ? (
                    <Form>
                      <FormGroup switch>
                        <Input
                          type="switch"
                          role="switch"
                          checked={item.status}
                          onChange={handleToggleChange}
                          value={item.id}
                        />
                      </FormGroup>
                    </Form>
                  )  : (
                    item[heading]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        toggle={() => setIsConfirmOpen(!isConfirmOpen)}
        onConfirm={() => handleDelete(itemId,selectedrowIndex)}
        message="Are you sure you want to remove the listing?"
      />
    </>
    )}
    </>
  );
};

export default BlogList;
