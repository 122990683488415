export const unauthorizedHandler = (error) => {
  if (error) {
    if (error.message === "Network Error") {
      // window.alertify('error', 'Server is down for maintenance!');
    }

    if (error.status === 500) {
      console.log(error.message);
    }

    if (error.status === 400) {
      console.log(error.message);
    }

    if (error.status === 404 && error.data) {
      setTimeout(() => {
        console.log("Platform APIs " + error.statusText);
      });
    }

    if (error.status === 403) {
      window.location = "/";
      console.log(error.message);
      localStorage.setItem("unauthorized", true);
    }
  }
};

export default function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const isValidEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
};

// Mobile number validation helper function
export const isValidMobile = (mobile) => {
  const mobileRegex = /^[0-9]{10}$/; // Assuming a 10-digit format
  return mobileRegex.test(mobile);
};
export const formatUnixTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toISOString().slice(0, 16);
};
export const isValidPinCode = (pincode) => {
  const pincodeRegex = /^\d{6}$/;
  return pincodeRegex.test(pincode);
};
export const isAlphaOnly = (alpha) => {
  const alphabetRegex = /^[A-Za-z]+$/;
  return alphabetRegex.test(alpha);
};
export const isAlphaNumeric = (alphanum) => {
  return /^[a-zA-Z0-9 ]+$/.test(alphanum);
};
export const isNum = (num) => {
  return /^[0-9]+$/.test(num);
};
