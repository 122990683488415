import React, { useState, useEffect } from "react";
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import rollImage from "../assets/rolls.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRollsState } from "./services/rollsState";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import { toast, ToastContainer } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import { Form, FormGroup, Input } from "reactstrap";
const data = [
  {
    "Roll ID": "#6B7280",
    Likes: "10000",
    Views: "1000",
    "User Type": "Groomer",
    "Workspace Name": "Kataria vet Clinic",
    Reports: "10000",
    "18+ Video": "Evana",
  },
];
const data2 = [
  {
    "Reported By": "Abhay Pradhan",
    Reason: "I just dont Like it",
    Phone: "8007692186",
    Email: "abhaypradhan98@gmail.com",
  },
];
const EditRoll = () => {
  const api = new Apis();
  const { id, createdBy } = useParams();
  const { getRollsListData } = useRollsState();
  const [rollData, setRollData] = useState(getRollsListData(id));
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");
  const [isChecked, setIsChecked] = useState(rollData.adult);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
    setHasChanges(true);
  };
  // const toggleRowSelection = (rowIndex) => {
  //   if (selectedRows.includes(rowIndex)) {
  //     setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
  //   } else {
  //     setSelectedRows([...selectedRows, rowIndex]);
  //   }
  // };
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const initialText = rollData.description;
  const [isEditing, setIsEditing] = useState(false);
  const [originalText, setOriginalText] = useState(initialText);
  const [editedText, setEditedText] = useState(initialText);
  const [loading, setLoading] = useState(false);
  const handleEditClick = () => {
    setEditedText(originalText);
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setEditedText(event.target.value);
    setHasChanges(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedText(originalText);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setOriginalText(editedText);
  };
  const initialTags = rollData.tag;
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [originalTags, setOriginalTags] = useState(initialTags);
  const [editedTags, setEditedTags] = useState(initialTags);
  const [hasChanges, setHasChanges] = useState(false);
  const handleEditTagsClick = () => {
    setEditedTags(originalTags);
    setIsEditingTags(true);
    setHasChanges(true);
  };

  const handleTagsInputChange = (event) => {
    const inputValue = event.target.value;

    setEditedTags(inputValue);
  };

  const handleCancelTagsClick = () => {
    setIsEditingTags(false);
    setEditedTags(originalTags);
  };

  const handleSaveTagsClick = () => {
    // Save the tags as they are
    setIsEditingTags(false);
    setOriginalTags(editedTags);
  };
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoUrl(file);
      setIsVideoUploaded(true);
      setHasChanges(true);
    }
  };
  const [isToggled, setIsToggled] = useState(rollData.status === "ACTIVE");
  const [isBlock, setIsBlock] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const handleBlock = () => {
    setIsBlock(!isBlock);
  };
  const deleteVideo = () => {
    setVideoUrl("");
    setIsVideoUploaded(false);
  };

  const rollUpload = async () => {
    if (!thumbselectedImage && !preloadedCoverImage) {
      return toast.error("Cover Image is a required field");
    }
    try {
      setLoading(true);
      const initialTagsToConvert = initialTags || "";
      const convertedInitialTags = initialTagsToConvert.replace(/#/g, "%23"); 
      const editedTagsToConvert = editedTags || ""; 
      const convertedEditedTags = editedTagsToConvert.replace(/#/g, "%23");
      const tagsToSave = convertedEditedTags !== convertedInitialTags ? convertedEditedTags : convertedInitialTags;   
      let workspaceIdToSend = selectedWorkspaceId;
      if (selectedOption === "FurrCrew") {
        workspaceIdToSend = 1;
      } else if (selectedOption === "FurrFiesta") {
        workspaceIdToSend = 2;
      }
      else if (selectedWorkspaceId === null) {
        workspaceIdToSend = rollData.workspaceId;
      }
      const response = await api.updateRollDetails(
        id,
        editedText,
        rollData.status,
        tagsToSave,
        isChecked,
        selectedOption,
        selectedIdToSend,
        workspaceIdToSend,
        featuredSwitchState,
        switchState
      );
      setLoading(false);
      toast.success("Edited Roll Successfully");
      console.log(response);
      if (id) {
        console.log(response.id);
        await updaterollUpload(id);
        setLoading(false);
      } else {
        setLoading(false);
      }
      if (thumbselectedImage) {
        uploadCoverImage(id);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error editing roll");
      console.error("Error editing roll:", error);
    }
  };

  const updaterollUpload = async (id) => {
    if (!thumbselectedImage && !preloadedCoverImage) {
      throw new Error("Cover Image is a required field");
    }
    if (!videoUrl) {
      console.error("No Video Selected");
      return;
    }
    console.error("No Video Selected");
    const formData = new FormData();
    formData.append("file", videoUrl);
    try {
      setLoading(true);
      const response = await api.updateRollVideo(id, formData);
      setLoading(false);
      toast.success("Video Uploaded SuccessFully");
      console.log(response);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "rolls",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };

  const [thumbselectedImage, setThumbSelectedImage] = useState(null);
  const [preloadedCoverImage, setPreloadedCoverImage] = useState(null);
  const handleThumbImageUpload = (e) => {
    const file = e.target.files[0];
    const img = new Image();

    img.onload = function () {
      if (img.width === 1080 && img.height === 1350) {
        setThumbSelectedImage(file);
        setHasChanges(true);
      } else {
        toast.error("Thumbnail image should be 1080 by 1350 pixels");
      }
    };

    if (file) {
      img.src = URL.createObjectURL(file);
    }
  };

  const handleThumbImageDelete = () => {
    setThumbSelectedImage(null);
  };
  useEffect(() => {
    if (rollData.coverImage && rollData.coverImage.length > 0) {
      const coverImage = new Image();
      coverImage.src = rollData.coverImage;
      coverImage.onload = () => setPreloadedCoverImage(coverImage);
    }
  }, [rollData.coverImage]);
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbselectedImage) {
        console.log("No new image selected for upload.");
        return;
      }
      const timestamp = Date.now();
      const fileData = thumbselectedImage.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      console.log(userId);
      const formData = new FormData();
      formData.append("file", thumbselectedImage, fileName);
      const response = await api.uploadCoverImg("ROLL", userId, formData);
      console.log("CoverImg", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  let convertedType = rollData.type;
  if (rollData.type === rollData.type.toUpperCase()) {
    convertedType = rollData.type.charAt(0).toUpperCase() + rollData.type.slice(1).toLowerCase();
  }
  const [selectedOption, setSelectedOption] = useState(convertedType);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setHasChanges(true);
  };
  const [selectedID, setSelectedID] = useState([]);
  let selectedIdToSend;

  if (selectedOption === "FurrCrew") {
    selectedIdToSend = 1;
  } else if (selectedOption === "FurrFiesta") {
    selectedIdToSend = 2;
  } else {
    selectedIdToSend = selectedID;
  }
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceId = selectedOption === "Veterinary" ? "d8d71038-c48c-11ed-abc1-02817ef0919c" : "5e4c6b60-c48e-11ed-abc1-02817ef0919c";
        console.log("Selected option:", selectedOption);
        console.log("Fetching workspace with ID:", workspaceId);

        const workspaceData = await api.getWorkspace(workspaceId);
        console.log("Fetched workspace data:", workspaceData);
        const activeWorkspaceData = workspaceData.filter((data) => data.collaboratorId === rollData.createdBy);
        setSelectedWorkspacDatas(activeWorkspaceData);
        setWorkspaceOptions(activeWorkspaceData);
        console.log("Workspace options set:", activeWorkspaceData);
        const prepopulatedWorkspace = activeWorkspaceData.find((workspace) => workspace.id === rollData.workspaceId);
        if (prepopulatedWorkspace) {
          setSelectedWorkspaceName(prepopulatedWorkspace.designationName);
          setSelectedID(prepopulatedWorkspace.collaboratorId);
        }
      } catch (error) {
        console.error("Error fetching workspace:", error);
      }
    };

    fetchWorkspace();
  }, [selectedOption]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(rollData.workspaceId||"");
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    setHasChanges(true);
    console.log(workspaceDatas);
    try {
      const selectedWorkspace = workspaceDatas.find((workspace) => workspace.designationName === event.target.value);
      console.log("Selected Workspace:", selectedWorkspace);
      if (selectedWorkspace && selectedWorkspace.id !== undefined) {
        setSelectedID(selectedWorkspace.collaboratorId);
        setSelectedWorkspaceId(selectedWorkspace.id);
        console.log("Selected Workspace ID:", selectedWorkspace);
      } else {
        setSelectedID([]);
        setSelectedWorkspaceId([]);
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getStatsViewsLikesShares(id, "ROLL");
        setData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const openModal = (type) => {
    const filteredData = filterData(type);
    setModalData(filteredData);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((row) => row !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const filterData = (type) => {
    switch (type) {
      case "viewed":
        return data.filter((item) => item.viewed);
      case "liked":
        return data.filter((item) => item.liked);
      case "shared":
        return data.filter((item) => item.shared);
      default:
        return [];
    }
  };
  const [switchState, setSwitchState] = useState(rollData.sponsored);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(rollData.featured);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
      setHasChanges(true);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
      setHasChanges(true);
    } catch (error) {
      console.error("error");
    }
  };
  const [testState, setTeststate] = useState(rollData.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateTestStatus("ROLL", rollData.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="row col-md-8">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <b style={{ fontSize: 20 }}>Edit Roll</b>
              <Button color={isToggled ? "success" : "danger"}>{isToggled ? "Active" : "Inactive"}</Button>
            </div>
            <div className="col-md-12 mt-2">
              <div className="row">
                <div className="col-md-4">
                  {isVideoUploaded ? (
                    <div>
                      <video controls src={URL.createObjectURL(videoUrl)} width="100%" height="auto">
                        Your browser does not support the video tag.
                      </video>
                      <Button onClick={deleteVideo}>Remove Video</Button>
                    </div>
                  ) : (
                    <div>
                      <video controls src={rollData.location} width="100%" height="auto">
                        Your browser does not support the video tag.
                      </video>
                      <Button>
                        <label htmlFor="video-upload" style={{ cursor: "pointer" }}>
                          Upload New Video *
                        </label>
                        <input id="video-upload" type="file" accept="video/*" onChange={handleVideoUpload} style={{ display: "none" }} />
                      </Button>
                    </div>
                  )}
                </div>

                <div className="col-md-8 d-flex align-items-center ">
                  <div className="col-md-6">
                    <div style={{ cursor: "pointer" }} onClick={() => openModal("viewed")}>
                      <span>Views</span>
                      <br />
                      <span>
                        <strong>{rollData.viewCount}</strong>
                      </span>
                    </div>
                    <div className="mt-3">
                      <div style={{ cursor: "pointer" }} onClick={() => openModal("shared")}>
                        <span>SHARE</span>
                        <br />
                        <span>
                          <strong>{rollData.sharedCount}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ cursor: "pointer" }} onClick={() => openModal("liked")}>
                      <span>Likes</span>
                      <br />
                      <span>
                        <strong>{rollData.likedCount}</strong>
                      </span>
                    </div>
                    <div className="mt-3" style={{ cursor: "pointer" }} onClick={openModal}>
                      <span>REPORTS</span>
                      <br />
                      <span>
                        <strong>1</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-3" />
            <div style={{ flexDirection: "row" }} className="mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="Evening"
                id="Evening"
                value="option3"
                checked={isChecked} // Bind the state to the checkbox's checked attribute
                onChange={handleCheckboxChange} // Handle the checkbox change event
              />
              <span className="mx-2">
                This roll is <span style={{ color: "red" }}>18+</span>
              </span>
            </div>
            <div>
              <div className="d-flex mt-2" style={{ justifyContent: "space-between" }}>
                <span>
                  <b>ROLL CAPTION</b>
                </span>
                <span>
                  <FontAwesomeIcon color="blue" fontSize={20} icon={faEdit} onClick={handleEditClick} />
                </span>
              </div>
              <div>
                {isEditing ? (
                  <div>
                    <textarea
                      type="text"
                      value={editedText}
                      onChange={handleInputChange}
                      rows={4}
                      style={{
                        width: "80%",
                        border: "1.25px solid #6b7280",
                        borderRadius: 5,
                        backgroundColor: "#f3f4f6",
                        minHeight: 30,
                        resize: "vertical",
                        fontSize: 15,
                        padding: 8,
                      }}
                    />
                    <div style={{ marginTop: 10 }}>
                      <Button color="primary" onClick={handleSaveClick}>
                        Save
                      </Button>
                      <Button style={{ marginLeft: 10 }} color="secondary" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <span style={{ fontSize: 15 }}>{editedText}</span>
                )}
              </div>
            </div>
            <div>
              <div className="d-flex mt-2" style={{ justifyContent: "space-between" }}>
                <span>
                  <b>ROLL TAGS</b>
                </span>
                <span>
                  <FontAwesomeIcon color="blue" fontSize={20} icon={faEdit} onClick={handleEditTagsClick} />
                </span>
              </div>
              <div>
                {isEditingTags ? (
                  <div>
                    <textarea
                      value={editedTags || ""}
                      onChange={handleTagsInputChange}
                      rows={3}
                      style={{
                        width: "80%",
                        border: "1.25px solid #6b7280",
                        borderRadius: 5,
                        backgroundColor: "#f3f4f6",
                        minHeight: 40,
                        resize: "vertical",
                        fontSize: 15,
                        padding: 8,
                      }}
                    />
                    <div style={{ marginTop: 10 }}>
                      <Button color="primary" onClick={handleSaveTagsClick}>
                        Save
                      </Button>
                      <Button color="secondary" onClick={handleCancelTagsClick}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <span style={{ fontSize: 15 }}>
                    {editedTags &&
                      editedTags
                        .split(" ")
                        .map((tag) => (tag.startsWith("%23") ? `#${tag.slice(3)}` : tag))
                        .join(" ")}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-2">
              <span>
                <b>Uploaded By</b>
              </span>

              <br></br>
              <span>{rollData.uploaderName}</span>
              <div style={{ display: "flex" }}>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" value="FurrCrew" checked={selectedOption === "FurrCrew"} onChange={handleOptionChange} />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    FurrCrew
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" value="FurrFiesta" checked={selectedOption === "FurrFiesta"} onChange={handleOptionChange} />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    FurrFiesta
                  </label>
                </div>
                {selectedOption === "Veterinary" || selectedOption === "Grooming" ? (
                  <div style={{ display: "flex" }}>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Veterinary"
                        checked={selectedOption === "Veterinary"}
                        onChange={handleOptionChange}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio3">
                        Veterinary
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" value="Grooming" checked={selectedOption === "Grooming"} onChange={handleOptionChange} />
                      <label className="form-check-label" htmlFor="inlineRadio4">
                        Groomer
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
              {selectedOption === "Veterinary" || selectedOption === "Grooming" ? (
                <div className=" mt-2">
                  <label htmlFor="inputPetsSpecies">Selected Workspace</label> Designation - Workspace Name
                  <select id="inputPetsSpecies" className="form-control" onChange={handleWorkspaceChange} value={selectedWorkspaceName}>
                    {workspaceOptions.map((workspace) => (
                      <option key={workspace.designationName} value={workspace.designationName}>
                        {`${workspace.designationName} - ${workspace.workplaceName}`}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Featured</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                  </FormGroup>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>Sponsored</label>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                  </FormGroup>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>Test</label>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                  </FormGroup>
                </Form>
              </div>
            </div>
            <div className="mt-2">
              <label className="pt-10 lableText">
                Cover Image
                <span className="required">*</span>
              </label>
              <div className="imageUploadBox">
                <div className="text-right">
                  <label htmlFor="imageUpload" className="btn btn-light navbar-buttons">
                    <span className="vetUploadButton">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </span>
                    <span className="navbar-button-text pl-2">Upload</span>
                    <input id="imageUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                  </label>
                  {thumbselectedImage && (
                    <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                      <span className="vetUploadButton">
                        <FontAwesomeIcon fontSize={20} icon={faTrash} />
                      </span>
                    </button>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap">
                      <div className="col-md-4 d-flex align-items-center pt-3">
                        {thumbselectedImage ? (
                          <img src={URL.createObjectURL(thumbselectedImage)} alt="Uploaded" style={{ width: 200 }} />
                        ) : (
                          preloadedCoverImage && <img src={preloadedCoverImage.src} alt="Prepopulated" style={{ width: 200 }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <hr className="my-3" />
            </div>
            <div style={{ marginTop: 20 }}>
              <h5>Internal Note</h5>
              <div className="chat-container">
                <div className="chat-messages">
                  {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                      <div className="message-text">{message.text}</div>
                      <div className="message-time">{getCurrentLocalDateTime()}</div>
                    </div>
                  ))}
                  {internalNotes.map((note) => (
                    <div key={note.id} className={`message bot`}>
                      <div className="message-text">{note.note}</div>
                      <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                    </div>
                  ))}
                </div>
                <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                  <textarea
                    className="form-control"
                    rows={5}
                    type="text"
                    placeholder="Type your message..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                    <button className="form-control" style={{ width: 100 }} type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="mt-5">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button
                    onClick={rollUpload}
                    style={{
                      marginRight: 5,
                      background: "#007bff",
                      border: "none",
                    }}
                    disabled={!hasChanges}
                  >
                    Save
                  </Button>
                  <Link to="/rollsList">
                    <Button
                      style={{
                        marginRight: 5,
                        border: "none",
                      }}
                    >
                      Cancel
                    </Button>
                  </Link>
                </div>
                <Button color={isBlock ? "success" : "danger"} onClick={handleBlock}>
                  {isBlock ? "Unblock" : "Block"}
                </Button>
              </div>
            </div>
          </div>
          <Modal isOpen={isModalOpen} toggle={closeModal} size="lg">
            <ModalHeader>Stats</ModalHeader>
            <ModalBody>
              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default EditRoll;
