import React, { useState, useEffect } from "react";
import { Table, Form, FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Button,Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";
import CoursesTopBar from "../../component/CoursesTopBar";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api";
import { useCourseState } from "../services/courseState";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../../component/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialog from "../../component/common/ConfirmDialog";
const CoursesList = () => {
  const api = new Apis();
  const { setcourseData } = useCourseState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [course, setEventList] = useState([
    // { id: 1, name: 'course 1',type:'Offline',price:"200",date:"12.10.23" },
    // { id: 2, name: 'course 2',type:'Offline',price:"200",date:"12.10.23"  },
    // { id: 3, name: 'course 3',type:'Offline',price:"200",date:"12.10.23"  },
    // Add more events to the list
  ]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(course.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  useEffect(() => {
    const fetchWebinarList = async () => {
      try {
        const webinarList = await api.getCourse();
        setcourseData(webinarList);
        setEventList(webinarList);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching Course:", error);
      }
    };
    fetchWebinarList();
  }, []);
  const handleToggleChange = async (event) => {
    const itemId = event.target.value;
    const updatedCourse = [...course];
    const itemToUpdate = updatedCourse.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        // Toggle the 'published' flag and update the status accordingly
        itemToUpdate.published = !itemToUpdate.published;
        itemToUpdate.status = itemToUpdate.published ? 'Active' : 'Inactive';
        await webinarEdit(itemToUpdate);
        setEventList(updatedCourse);
      } catch (error) {
        console.error("Error toggling webinar status:", error);
        // Revert changes on error
        itemToUpdate.published = !itemToUpdate.published;
        itemToUpdate.status = itemToUpdate.published ? 'Active' : 'Inactive';
        setEventList(updatedCourse);
      }
    }
  };
  const webinarEdit = async (itemToUpdate) => {
    try {
      const requestBody = {
        id: itemToUpdate.id,
        title: itemToUpdate.title,
        description: itemToUpdate.description,
        eventOn: itemToUpdate.eventOn,
        status: itemToUpdate.status,
        published: itemToUpdate.published,
        mode: itemToUpdate.mode,
        price: itemToUpdate.price,
        author: itemToUpdate.author,
        eduCategory: "Course",
        paid: itemToUpdate.paid,
        publishDate:itemToUpdate.publishDate,
      };
      setLoading(true);
      const response = await api.updateWebinar(requestBody);
      setLoading(false);
      toast.success("Course Status Changed!");
      console.log(response);
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.success("Course Status Cant be Changed!");
      setLoading(false);
    }
  };
  //   useEffect(() => {
  //     const fetchEventList = async () => {
  //       try {
  //         const eventList = await api.getEventList();
  //         setEventData(eventList);
  //         setEventList(eventList);
  //         setCurrentPage(1);
  //       } catch (error) {
  //         console.error("Error fetching course:", error);
  //       }
  //     };
  //     fetchEventList();
  //   }, []);

  const customTableHeadings = [
    "checkbox",
    // "ID",
    "ID",
    "Course Name",
    "Course Type",
    "Course Price",
    "Publish Date",
    "Live Date",
    "Status",
    "Active",
    "Actions",
  ];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 1000);
  };
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const [state,setState]=useState(true);
  const filteredCourse = course.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const titleLower = (item.title || "").toLowerCase();
    const modeLower = (item.mode || "").toLowerCase();
    return (
      (!state || item.status === "Active") && 
      (titleLower.includes(searchQueryLower) ||
      modeLower.includes(searchQueryLower))
    );
  });
  
  const currentItems = filteredCourse.slice(indexOfFirstItem, indexOfLastItem);
  const navigate = useNavigate();
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => course[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const handleEdit = (item) => {
    navigate(`/CoursesList/EditCourse/${item.id}`);
  };

  const handleDelete = async (item, rowIndex) => {
    try {
      setLoading(true);
      const response = await api.deleteWebinar(item.id);
      setLoading(false);
      // Calculate the index in the original course array
      const originalIndex = indexOfFirstItem + rowIndex;
      // Remove the deleted item from the state
      setEventList((prevCourses) => {
        const updatedCourses = [...prevCourses];
        updatedCourses.splice(originalIndex, 1);
        return updatedCourses;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("Course deleted successfully!");
      navigate("/CoursesList");
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Course:", error);
    } finally {
      setLoading(false);
    }
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex,setRowIndex]=useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); 
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          <CoursesTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} />
          <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
          <Table hover>
            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <tr>
                {customTableHeadings.map((heading, index) => (
                  <th key={index} style={{ whiteSpace: "nowrap" }}>
                    {heading === "checkbox" ? (
                      <input
                        type="checkbox"
                        checked={selectedRows.length === currentItems.length}
                        onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                      />
                    ) : (
                      heading
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {customTableHeadings.map((heading, colIndex) => (
                    <td key={colIndex}>
                      {heading === "Actions" ? (
                        <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                          <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : heading === "ID" ? (
                        <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                          {item.id.slice(0, 8)}
                          {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                        </span>
                      ) : heading === "Course Name" ? (
                        <a onClick={() => navigate(`/CoursesList/EditCourse/${item.id}`)} style={{ cursor: "pointer" }}>
                          {item.title}
                        </a>
                      ) : heading === "checkbox" ? (
                        <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                      ) : heading === "Status" ? (
                        item.status
                      ) : heading === "Course Type" ? (
                        item.mode
                      ) : heading === "Status" ? (
                        item.status
                      ) : heading === "Course Price" ? (
                        item.price
                        )
                        : heading === "Publish Date" ? (
                         <span>
                         {moment
                           .utc(item.publishDate)
                           .local()
                           .format("DD-MM-YYYY hh:mm A")}
                       </span>
                    
                     ) : heading === "Live Date" ? (
                       //   moment(item.scheduledDate).local().format('YYYY-MM-DD HH:mm A')
                       <span>
                         {moment
                           .utc(item.eventOn)
                           .local()
                           .format("DD-MM-YYYY hh:mm A")}
                       </span>
                     )  : heading === "Active" ? (
                        <Form>
                          <FormGroup switch>
                            <Input type="switch" role="switch" checked={item.status==="Active"} value={item.id} onChange={handleToggleChange} />
                          </FormGroup>
                        </Form>
                      )  :(
                        item[heading]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
          <ConfirmDialog
        isOpen={isConfirmOpen}
        toggle={() => setIsConfirmOpen(!isConfirmOpen)}
        onConfirm={() => handleDelete(itemId,selectedrowIndex)}
        message="Are you sure you want to remove the listing?"
      />
        </>
      )}
    </>
  );
};

export default CoursesList;
