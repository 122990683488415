import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import dataURItoBlob from "../utils/helpers";
import LoaderModal from "../component/common/Loader";
const AddPetDetails = () => {
  const navigate = useNavigate();
  const api = new Apis();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [petName, setPetName] = useState();
  const [gender, setGender] = useState("Male");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancelImage = () => {
    setSelectedImage(null);
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const handleYearIncrement = () => {
    setYears(years + 1);
  };
  const handleYearDecrement = () => {
    if (years > 0) {
      setYears(years - 1);
    }
  };
  const handleMonthIncrement = () => {
    if (months < 11) {
      setMonths(months + 1);
    } else {
      setMonths(0);
      setYears(years + 1);
    }
  };
  const handleMonthDecrement = () => {
    if (months > 0) {
      setMonths(months - 1);
    } else {
      if (years > 0) {
        setMonths(11);
        setYears(years - 1);
      }
    }
  };

  const [breeds, setBreeds] = useState([]); // State for breeds
  const [selectedBreed, setSelectedBreed] = useState("");
  const handleBreedChange = (event) => {
    setSelectedBreed(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("exotic"); // Default value
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error("Token not available");
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}admin/v1/super/admin/pet/category?type=${selectedType}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        setBreeds(data || []);
      } catch (error) {
        console.error("Error fetching pet category:", error);
      }
    };
    fetchCategory();
  }, [selectedType]);
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const addPet = async () => {
    try {
      // Basic validation checks
      if (!petName) {
        throw new Error("Please fill out the pet name.");
      }
      if (!selectedType) {
        throw new Error("Please select a pet type.");
      }
      if (!selectedBreed) {
        throw new Error("Please select a pet breed.");
      }
      if (!gender) {
        throw new Error("Please select a pet gender.");
      }
      if (!years && !months) {
        throw new Error("Please fill out the pet's age.");
      }
      if (!id) {
        throw new Error("Please provide a user ID.");
      }
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      const requestBody = {
        petName: petName,
        type: selectedType,
        breed: selectedBreed,
        gender: gender,
        year: years,
        month: months,
        userId: id,
      };
      setIsLoading(true);
      const response = await api.addPet(id, requestBody);
      setIsLoading(false);
      toast.success("Added Pet Successfully.");
      if (response.id) {
        console.log(response.id);
        await uploadImage(response.id);
        toast.success("User image added successfully!");
        setIsLoading(false);
        navigate(-1);
      } else {
        throw new Error("Response ID is undefined.");
      }
    } catch (error) {
      console.error("Error adding pet:", error);
      toast.error("Error adding pet: " + error.message);
    }
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      const formData = new FormData();
      const currentTimestamp = new Date().getTime();

      // Format the timestamp to use in the file name (you can adjust the format as needed)
      const formattedTimestamp = new Date(currentTimestamp).toISOString().replace(/[-:.]/g, "");

      // Append the formatted timestamp to the file name
      const fileName = `image_${formattedTimestamp}.png`;
      formData.append("file", dataURItoBlob(selectedImage),fileName);
      const response = await api.uploadPetImage(id, userId, formData);
      console.log("Img", response);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  return (
    <>
       <ToastContainer position="top-right" autoClose={5000} />
      {isLoading ? (
        <LoaderModal isOpen={isLoading} toggle={() => setIsLoading(false)} />
      ) : (
        <>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            <span style={{ fontSize: 20 }}>
              <strong>Add Pet</strong>
            </span>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="form-group  mt-2">
                    <label for="inputPetName">Pet Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter pet name"
                      required
                      value={petName}
                      onChange={(e) => setPetName(e.target.value)}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="inputPetsSpecies">Pet Species*</label>
                      <select id="inputPetsSpecies" className="form-control" value={selectedType} onChange={handleTypeChange}>
                        <option value="dog">Dog</option>
                        <option value="cat">Cat</option>
                        <option value="bird">Bird</option>
                        <option value="exotic">Exotic</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="inputPetBreed">Pet Breed*</label>
                      <select id="inputPetBreed" className="form-control" value={selectedBreed} onChange={handleBreedChange}>
                        <option value="" disabled selected>
                          Select a breed
                        </option>
                        {breeds.map((breed, index) => (
                          <option key={index} value={breed}>
                            {breed}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group col-md-6  mt-2">
                      <label for="inputPetAge">Pet Age*</label>
                      <div>
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={handleYearDecrement}
                        >
                          <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                        </Button>
                        {years} years
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={handleYearIncrement}
                        >
                          <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={handleMonthDecrement}
                        >
                          <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                        </Button>
                        {months} months
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={handleMonthIncrement}
                        >
                          <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                        </Button>
                      </div>
                    </div>
                    <div className="form-group col-md-6  mt-2">
                      <label htmlFor="inputPetsSpecies">Gender*</label>
                      <select id="inputPetsSpecies" className="form-control" onChange={(e) => setGender(e.target.value)} value={gender}>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                  <label className="form-group  mt-2" for="inputName">
                    <label for="inputPetGender"> Upload Pet Image*</label>
                  </label>
                  <div className="mt-2 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderRadius: 5,
                        height: 200,
                        width: "50%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      {selectedImage ? (
                        <>
                          <img
                            src={selectedImage}
                            alt="Uploaded"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                          <div style={{ position: "absolute", top: 10, right: 10 }}>
                            <Button style={{ backgroundColor: "red", border: "none" }} onClick={handleCancelImage}>
                              <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <label htmlFor="uploadInput">
                          <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleFileChange} />
                          <Button onClick={handleUploadClick} color="secondary" as="span">
                            Upload
                          </Button>
                        </label>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    <Button style={{ marginRight: 10 }} color="primary" onClick={addPet}>
                      Add
                    </Button>
                    <Button color="secondary" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddPetDetails;
