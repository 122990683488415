import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button,Form,FormGroup,Input, } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Apis from "../utils/api";
import dataURItoBlob from "../utils/helpers";
import LoaderModal from "../component/common/Loader";
const Addcourse = () => {
  const api = new Apis();
  const [loading, setLoading] = useState(false);
  const [webtype, setWebtype] = useState("Free");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAdvetisment, setSelectedAdvetisment] = useState(null);
  const [selectedEpisodes, setSelectedEpisodes] = useState(1);
  const [showAdvertisement, setShowAdvertisement] = useState(false);
  const [webinarDateTime, setDateTime] = useState("");
  const [liveDateTime, setLiveDateTime] = useState("");
  const [min, setMin] = useState();
  const [type, setType] = useState("Online");
  const [price, setPrice] = useState("");
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const handlePriceChange = (event) => {
    setPrice(Number(event.target.value));
  };
  const handleType = (event) => {
    setType(event.target.value);
  };
  const handleAdvertismentimgchange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedAdvetisment(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handlecancleAdvetisment = () => {
    setSelectedAdvetisment(null);
  };
  const handleMinChange = (event) => {
    setMin(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleadvertismentimage = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleAdvertismentimgchange);
    fileInput.click();
  };
  const handleCheckboxChange = (e) => {
    setShowAdvertisement(e.target.checked);
  };
  const handleEpisodeChange = (event) => {
    setSelectedEpisodes(event.target.value);
  };
  const [selectedVideo, setSelectedVideo] = useState(null);
  const handleListingTypeChange = (event) => {
    setWebtype(event.target.value);
  };
  const [imageName,setImageName]=useState();
  const handleImgChange = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancelImage = () => {
    setSelectedImage(null);
  };
  const handleImgupload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleImgChange);
    fileInput.click();
  };
  const handleCancelVideo = () => {
    setSelectedVideo(null);
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
    }
  };
  const handleAuthorChange = (event) => {
    setSelectedAuthor(event.target.value);
  };
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000;
    setDateTime(unixTimestamp);
  };
  const handleLiveDate = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix()*1000;
    setLiveDateTime(unixTimestamp);
  };
  const navigate = useNavigate();
  const handleHeaderChange = (event) => {
    setHeader(event.target.value);
  };
  const [switchState, setSwitchState] = useState(false);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(false);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const [test, setTest] = useState(false);
  const toggleTest = () => {
    setTest(!test);
  };
  const addWebinar = async () => {
    try {
      // Basic validation checks
      if (!selectedVideo) {
        throw new Error("No video selected for upload.");
      }
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      if (!header) {
        throw new Error("Title is a required field.");
      }
      if (!description) {
        throw new Error("Description is a required field.");
      }
      // if (!webinarDateTime) {
      //   throw new Error("Webinar Date and Time is a required field.");
      // }
      if (!liveDateTime) {
        throw new Error("Live Date is a required field");
      }
      if (!type) {
        throw new Error("Type is a required field.");
      }
      if (!selectedAuthor) {
        throw new Error("Selected Author is a required field.");
      }
      if (!min || min < 0) {
        throw new Error("Duration is invalid");
      }
      const requestBody = {
        title: header,
        description: description,
        eventOn: liveDateTime,
        status: "Inactive",
        published: false,
        mode: type,
        price: price,
        author: selectedAuthor,
        eduCategory: "Course",
        sponsored: switchState,
        featured:featuredSwitchState,
        test:test
        // publishDate:webinarDateTime,
      };
      if (webtype === "Paid") {
        requestBody.paid = true;
      } else {
        // Set isPaid to false if webtype is not "Paid"
        requestBody.paid = false;
      }
      setLoading(true);
      const response = await api.createWebinar(requestBody);
      setLoading(false);
      toast.success("Course added successfully!");
      console.log(response);
      if (response.id) {
        console.log(response.id);
        await uploadImage(response.id);
        toast.success("Course image added successfully!");
      } else {
        throw new Error("Response ID is undefined.");
      }
      if (response.id) {
        console.log(response.id);
        await rollUpload(response.id);
        toast.success("Video added successfully!");
      } else {
        throw new Error("Response ID is undefined.");
      }
      navigate(`/CoursesList/Addcourse/AddedCourses/${response.id}/${selectedEpisodes}`);
      setLoading(false);
    } catch (error) {
      console.error("Error adding Course:", error);
      toast.error("Error adding Course: " + error.message);
      setLoading(false);
    }
  };
  const uploadImage = async (userId) => {
    try {
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(selectedImage),fileName);
      const response = await api.uploadWebinarPoster(userId, formData);
      console.log("Img", response);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const rollUpload = async (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("files", selectedVideo);
    try {
      // Basic validation checks
      const response = await api.uploadWebinar(id, header, description, min, 0, formData);
      console.log(response);
      setSelectedVideo(null);
      toast.success("Video uploaded successfully!");
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("Error uploading video: " + error.message);
    }
  };
  return (
    <>
       <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <span style={{ fontSize: 20 }}>
            <strong>Add Courses</strong>
          </span>
          {/* ------Upload option start--- */}
          <div className="pt-2 row">
            <div className="col-md-7">
              <div className=" mt-3">
                <label>Upload Course Trailer*</label>
                <div style={{ display: "flex" }}>
                  {selectedVideo ? (
                    <div
                      style={{
                        maxWidth: "100%",
                        // maxHeight: "60vh",
                        overflow: "auto",
                      }}
                    >
                      <video controls style={{ width: "100%" }}>
                        <source src={URL.createObjectURL(selectedVideo)} />
                      </video>
                    </div>
                  ) : (
                    <div style={{}}>
                      <Button color="secondary" onClick={handleUploadClick}>
                        Upload
                      </Button>
                    </div>
                  )}
                </div>
                {selectedVideo && (
                  <Button color="danger" onClick={handleCancelVideo}>
                    Remove
                  </Button>
                )}
              </div>
              <div className=" mt-2">
                <label>Title*</label>
                <input type="text" name="header" className="form-control" value={header} onChange={handleHeaderChange} />
              </div>
              <div className=" mt-2">
                <label>Description*</label>
                <textarea value={description} className="form-control" rows={10} onChange={handleDescriptionChange}></textarea>
              </div>
            </div>
            <div className="col-md-5">
              <div className=" mt-3">
                <label>Upload Course Poster*</label>
                <div>
                  {selectedImage ? (
                    <>
                      <img
                        src={selectedImage}
                        alt="Uploaded"
                        style={{
                          maxWidth: "60%",
                          maxHeight: "100%",
                        }}
                      />
                      <div style={{ marginTop: 10 }}>
                        <Button style={{ backgroundColor: "red", border: "none" }} onClick={handleCancelImage}>
                          <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <label htmlFor="uploadInput">
                      <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleImgChange} />
                      <Button onClick={handleImgupload} color="secondary" as="span">
                        Upload
                      </Button>
                    </label>
                  )}
                </div>
              </div>
              <div className=" mt-2">
                <label>Presenter*</label>
                {/* <select id="inputPetParent" className="form-control" value={selectedAuthor} onChange={handleAuthorChange}>
                  <option value="Abhay">Abhay</option>
                  <option value="Rajesh">Rajesh</option>
                </select> */}
                    <input type="text" name="header" className="form-control" value={selectedAuthor} onChange={handleAuthorChange} />
              </div>
              {/* <div className=" mt-2">
                <label>Published Date and Time*</label>
                <input type="datetime-local" className="form-control form-control mt-2" id="startDateTime" onChange={handleDateChange} />
              </div> */}
              <div className=" mt-2">
                <label>Live Date*</label>
                <input type="datetime-local" className="form-control form-control mt-2" id="startDateTime" onChange={handleLiveDate} />
              </div>
              <div className=" mt-2 col-md-12">
                <label>Duration*</label>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input type="number" name="header" className="form-control" value={min} onChange={handleMinChange} />
                    <span style={{ marginLeft: 10 }}>Min</span>
                  </div>
                </div>
              </div>
              <div style={{ flexDirection: "row" }} className="mt-2">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="Online" id="Online" value="Online" checked={type === "Online"} onChange={handleType} />
                  <label className="form-check-label" htmlFor="Online">
                    Online
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Offline"
                    id="Offline"
                    value="Offline"
                    checked={type === "Offline"}
                    onChange={handleType}
                  />
                  <label className="form-check-label" htmlFor="Offline">
                    Offline
                  </label>
                </div>
              </div>
              <div style={{ flexDirection: "row" }} className="mt-2">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Free"
                    id="Free"
                    value="Free"
                    checked={webtype === "Free"}
                    onChange={handleListingTypeChange}
                  />
                  <label className="form-check-label" htmlFor="Free">
                    Free
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Paid"
                    id="Paid"
                    value="Paid"
                    checked={webtype === "Paid"}
                    onChange={handleListingTypeChange}
                  />
                  <label className="form-check-label" htmlFor="Paid">
                    Paid
                  </label>
                </div>
              </div>
              {webtype === "Paid" && (
                <div style={{ flexDirection: "row" }}>
                  <div className="mt-2 col-md-6">
                    <label>Price</label>
                    <input id="myInput" type="number" name="myCountry" className="form-control" value={price} onChange={handlePriceChange} />
                  </div>
                </div>
              )}
              {/* <div className="mt-2 d-flex">
                <span>Advertisement</span>
                <input type="checkbox" checked={showAdvertisement} onChange={handleCheckboxChange} style={{ marginLeft: 10 }} />
              </div>
              {showAdvertisement && (
                <div className="mt-2">
                  <label>Advertisement Link</label>
                  <input type="text" name="myCountry" className="form-control" />
                </div>
              )}
              {showAdvertisement && (
                <div className="mt-2">
                  <label>Advertisement Image</label>
                  <div>
                    {selectedAdvetisment ? (
                      <>
                        <img
                          src={selectedAdvetisment}
                          alt="Uploaded"
                          style={{
                            maxWidth: "60%",
                            maxHeight: "100%",
                          }}
                        />
                        <div style={{ marginTop: 10 }}>
                          <Button style={{ backgroundColor: "red", border: "none" }} onClick={handlecancleAdvetisment}>
                            <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                          </Button>
                        </div>
                      </>
                    ) : (
                      <label htmlFor="uploadInput">
                        <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleAdvertismentimgchange} />
                        <Button onClick={handleadvertismentimage} color="secondary" as="span">
                          Upload
                        </Button>
                      </label>
                    )}
                  </div>
                </div>
              )} */}
              <div style={{display:'flex'}}>
                <div className=" mt-2">
            <label>Featured</label>
            <Form>
              <FormGroup switch>
                <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch}/>
              </FormGroup>
            </Form>
          </div>
          <div className="mt-2 mx-2">
            <label>Sponsored</label>
            <Form>
              <FormGroup switch>
                <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
              </FormGroup>
            </Form>
          </div>
          <div className="mt-2 mx-3">
            <label>Test</label>
            <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={test} onChange={toggleTest} />
                  </FormGroup>
                </Form>
          </div>
          </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <span>Number of Episodes</span>
            <div style={{ marginLeft: 20 }}>
              <select id="inputPetParent" className="form-control" onChange={handleEpisodeChange}>
                <option value="1" selected={selectedEpisodes === 1}>
                  1
                </option>
                <option value="2" selected={selectedEpisodes === 2}>
                  2
                </option>
                <option value="3" selected={selectedEpisodes === 3}>
                  3
                </option>
                <option value="4" selected={selectedEpisodes === 4}>
                  4
                </option>
                <option value="5" selected={selectedEpisodes === 5}>
                  5
                </option>
                <option value="6" selected={selectedEpisodes === 6}>
                  6
                </option>
                <option value="7" selected={selectedEpisodes === 7}>
                  7
                </option>
                <option value="8" selected={selectedEpisodes === 8}>
                  8
                </option>
                <option value="9" selected={selectedEpisodes === 9}>
                  9
                </option>
                <option value="10" selected={selectedEpisodes === 10}>
                  10
                </option>
                <option value="11" selected={selectedEpisodes === 11}>
                  11
                </option>
                <option value="12" selected={selectedEpisodes === 12}>
                  12
                </option>
              </select>
            </div>
          </div>
          <div className="pt-2 row" style={{ justifyContent: "flex-end", display: "flex" }}>
            <div className="col-md-6" style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
              <Button onClick={addWebinar} color="primary">
                Next
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Addcourse;
