import { React } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import Modal from "react-modal";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function LeafletMap({ coordinates, setCoordinates, toggleModal, modalIsOpen }) {
  // const map = this.LeafletMap.useMap()
  //     map.locate().on("locationfound", function (e) {
  //       setCoordinates(e.latlng)
  //       map.flyTo(e.latlng, 13);
  //     });
  const LocationSetter = () => {
    useMapEvents({
      click(e) {
        setCoordinates(e.latlng);
      },
    });
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen} onRequestClose={toggleModal} style={customStyles} contentLabel="Coordinates Modal">
        <MapContainer center={{ lat: 22.59, lng: 79.56 }} zoom={6} style={{ height: "400px", width: "800px" }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={coordinates} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} />
          <LocationSetter />
        </MapContainer>
      </Modal>
    </div>
  );
}

export default LeafletMap;
