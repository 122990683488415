import React, { useState,useEffect } from "react";
import { Table, Button, Accordion, AccordionBody, AccordionHeader, AccordionItem, Input, FormGroup, Form ,Modal,ModalHeader,ModalBody} from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import Apis from "../utils/api";
import { useCourseState } from "./services/courseState";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { isNum, isAlphaNumeric } from "../utils/helpers";
import LoaderModal from "../component/common/Loader";
import dataURItoBlob from "../utils/helpers";
const data3 = [
  {
    Name: "Abhay",
    Phone: "8007692186",
    "View Date": "22-10-2023",
    "View Count": 2,
    Status: "Completed",
    Paid: "250",
    Downloaded: "",
  },
];
const EditCourse = () => {
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const api = new Apis();
  const navigate = useNavigate();
  const { id, createdBy } = useParams();
  const { getcourseData } = useCourseState();
  const [dataWeb] = useState(getcourseData(id));
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = ["checkbox", ...Object.keys(data3[0])];
  const data2 = [
    {
      // Id: "#6B7280",
      Likes: dataWeb.likedCount||0,
      Views: dataWeb.viewedCount,
      "Share Count": dataWeb.sharedCount,
    },
  ];
  const columns2 = ["checkbox", ...Object.keys(data2[0])];
  const [selectedVideo, setSelectedVideo] = useState(null);
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const handleCancelVideo = () => {
    setSelectedVideo(null);
  };
  const handleUploadClick = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.addEventListener("change", (event) => handleFileChange(event, index));
    fileInput.click();
  };
 
  const [subscriberList, setsubsciberList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  useEffect(() => {
    const fetchSubscriber = async () => {
      try {
        const subscriber = await api.getPurchasedList(id);
        setsubsciberList(subscriber);
        setCurrentPage(1);
        console.log(subscriber)
      } catch (error) {
        console.error("Error fetching RollSList:", error);
      }
    };
    fetchSubscriber();
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subscriberList.slice(indexOfFirstItem, indexOfLastItem);
  const customTableHeadings = ["checkbox", "ID","Name","Mobile", "Email","Purchased Date","Payment Status"];
  const data4 = [
    {
      // Id: "#6B7280",
      Likes: dataWeb.likedCount,
      Views: dataWeb.viewedCount,
      "Share Count": dataWeb.sharedCount,
    },
  ];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
    }
  };
  

  const [loading, setLoading] = useState(false);
  const [webinarDateTime, setDateTime] = useState(moment(dataWeb.eventOn).format("YYYY-MM-DDTHH:mm"));
  const [publishedDate, setPublishedDateTime] = useState(moment(dataWeb.publishDate).format("YYYY-MM-DDTHH:mm"));
  const [newTimeUnix, setNewTimeUnix] = useState();
  const [newPublishedTimeUnix, setNewPublishedTimeUnix] = useState();
  const [type, setType] = useState(dataWeb.mode);
  const [header, setHeader] = useState(dataWeb.title || null);
  const [description, setDescription] = useState(dataWeb.description || null);
  const [price, setPrice] = useState(dataWeb.price || 0);
  const [selectedAuthor, setSelectedAuthor] = useState(dataWeb.author || null);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [noteText, setNoteText] = useState("");
  // Check if dataWeb and dataWeb.files are defined before filtering
  const initialEpisodes = dataWeb && dataWeb.files ? dataWeb.files.filter((episode) => episode.episodeNo !== 0) : [];

  const [episodes, setEpisodes] = useState(initialEpisodes.length > 0 ? initialEpisodes : null);

  const [min, setMin] = useState();
  const [webtype, setWebtype] = useState(dataWeb.paid ? "Paid" : "Free");
  const addEpisode = () => {
    // Find the highest episode number and add 1 to it for the new episode
    const maxEpisodeNo = Math.max(...episodes.map((episode) => episode.episodeNo));
    const newEpisodeNo = maxEpisodeNo + 1;
    // Create a new episode object with default values
    const newEpisode = {
      episodeNo: newEpisodeNo,
      title: "",
      description: "",
      filePath: "",
      min: "",
    };
    // Add the new episode to the state
    setEpisodes([...episodes, newEpisode]);
  };
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoUrl(file);
      setIsVideoUploaded(true);
    }
  };
  const deleteVideo = () => {
    setVideoUrl("");
    setIsVideoUploaded(false);
  };
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName,setImageName]=useState();
  const handleImgChange = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImgupload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleImgChange);
    fileInput.click();
  };
  const handleAuthorChange = (e) => {
    setSelectedAuthor(e.target.value);
  };
  const handleHeaderChange = (e) => {
    setHeader(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleType = (event) => {
    setType(event.target.value);
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleMinChange = (event) => {
    setMin(event.target.value);
  };
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000;
    setDateTime(inputDate);
    setNewTimeUnix(unixTimestamp);
  };
  const handlePublished = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000;
    setPublishedDateTime(inputDate);
    setNewPublishedTimeUnix(unixTimestamp);
  };
  const episodeUpload = async (index) => {
    const episode = episodes[index];

    try {
      setLoading(true);

      if (episode.id) {
        // Existing episode, perform update logic
        const response = await api.updateCourse(episode.id, episode.title, episode.description, episode.videoDuration, episode.episodeNo);
        toast.success("Episode updated successfully!");
      } else {
        // New episode, perform create logic
        const formData = new FormData();
        formData.append("files", selectedVideo);
        const response = await api.uploadWebinar(id, episode.title, episode.description, episode.videoDuration, episode.episodeNo, formData);
        // Handle the response for create as needed
        console.log(response);
        toast.success("New episode created successfully!");
        navigate(-1);
      }

      setLoading(false);
      setSelectedVideo(null);
    } catch (error) {
      console.error("Error uploading episode:", error);
      toast.error("Error uploading episode: " + error.message);
      setLoading(false);
    }
  };
  const episodeUpdate = async (index, ep) => {
    console.log(ep.videoDuration);
    setLoading(true);
    try {
      const response = await api.updateCourse(
        id,
        episodes[index].id,
        episodes[index].title,
        episodes[index].description,
        episodes[index].videoDuration,
        episodes[index].episodeNo
      );
      toast.success("Course Edited successfully!");
      console.log(response);
      setLoading(false);
      if (id) {
        console.log(id);
        await courseUpdateUpload(id,episodes[index].id);
        setLoading(false);
        navigate(-1)
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error Editing the Course:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const EditCourse = async () => {
    try {
      // Basic validation checks
      if (!selectedAuthor || !isAlphaNumeric(selectedAuthor)) {
        throw new Error("Author is a required field");
      }
      if (!header) {
        throw new Error("Header is a required field");
      }
      if (!description) {
        throw new Error("Description is a required field");
      }
      // removed validation for video and image because added while creating Course
      // todo uploaded video is not appearing @abhay take a look
      const requestBody = {
        id: id,
        title: header,
        description: description,
        eventOn: newTimeUnix || dataWeb.eventOn,
        status: dataWeb.status,
        published: dataWeb.published,
        mode: type,
        price: webtype === "Free" ? 0 : price,
        author: selectedAuthor,
        eduCategory: "Course",
        publishDate:dataWeb.publishDate,
      };
      if (webtype === "Paid") {
        requestBody.paid = true;
      } else {
        requestBody.paid = false;
      }
      setLoading(true);
      const response = await api.updateWebinar(requestBody);
      setLoading(false);
      toast.success("Course Edited successfully!");
      console.log(response);
      if (response.id) {
        console.log(response.id);
        await rollUpload(response.id);
        setLoading(false);
      } else {
        setLoading(false);
      }
      if (response.id) {
        console.log(response.id);
        await uploadImage(response.id);
        setLoading(false);
      } else {
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error("Error adding Course:", error);
    }
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(selectedImage),fileName);
      const response = await api.uploadWebinarPoster(userId, formData);
      console.log("Img", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const episodeZero = dataWeb.files && dataWeb.files.find((file) => file.episodeNo === 0);
  const [switchState, setSwitchState] = useState(dataWeb.sponsored);

  const handleSwitchChange = async (event) => {
    try {
      const newSwitchState = !switchState; // Calculate the new state first
      setSwitchState(newSwitchState); // Update the state
      setLoading(true);
      await api.webinarFeaturedStatus(id, "sponsored", newSwitchState); // Use the updated state
      setLoading(false);
      toast.success("Status Success");
    } catch (error) {
      // Handle API call errors
      console.error("Error updating status:", error);
    }
  };
  const [featuredSwitchState, setfeaturedSwitchState] = useState(dataWeb.featured);
  const handleFeaturedSwitch = async (event) => {
    try {
      const newSwitchState = !featuredSwitchState; // Calculate the new state first
      setfeaturedSwitchState(newSwitchState); // Update the state
      setLoading(true);
      await api.webinarFeaturedStatus(id, "featured", newSwitchState); // Use the updated state
      setLoading(false);
      toast.success("Status Success");
    } catch (error) {
      // Handle API call errors
      console.error("Error updating status:", error);
    }
  };

  const rollUpload = async (id) => {
    if (!videoUrl) {
      console.error("No Video Selected");
      // Optionally throw an error or handle the case where videoUrl is not present.
      return;
    }
    const formData = new FormData();
    formData.append("file", videoUrl);
    try {
      // Basic validation checks
      setLoading(true);
      const response = await api.updateWebinarVideo(id, episodeZero.id, formData);
      setLoading(false);
      toast.success("Video Uploaded SuccessFully");
      console.log(response);
      setSelectedVideo(null);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };
  const courseUpdateUpload = async (id,episode) => {
    if (!selectedVideo) {
      console.error("No Video Selected");
      // Optionally throw an error or handle the case where videoUrl is not present.
      toast.error("Error")
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedVideo);
    try {
      // Basic validation checks
      setLoading(true);
      const response = await api.updateWebinarVideo(id, episode, formData);
      setLoading(false);
      toast.success("Video Uploaded SuccessFully");
      console.log(response);
      setSelectedVideo(null);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };
  const handleListingTypeChange = (event) => {
    setWebtype(event.target.value);
  };
  const handleUpdateVideo = (index, newVideo) => {
    const updatedEpisodes = [...episodes];
    updatedEpisodes[index].filePath = newVideo; // Update only the video file path for the specified episode index
    setEpisodes(updatedEpisodes);
  };
  const [viewsModal, setViewsModal] = useState(false);

  const [videoCount, setVideoCount] = useState();
  const [statstype, statssetType] = useState("Likes"); // Set default type

  const toggleViewsModal = () => setViewsModal(!viewsModal);

  const fetchLikeViewShare = async () => {
    try {
      const fetchLikeViewShare = await api.getVideoStats(id, statstype);
      setVideoCount(fetchLikeViewShare);
    } catch (error) {
      console.error("Error fetching video stats:", error);
    }
  };

  useEffect(() => {
    fetchLikeViewShare();
  }, [id, statstype]); // Fetch data when id or type changes
  const currentItemsStats = videoCount && videoCount.slice(indexOfFirstItem, indexOfLastItem);
  const customstatsTableHeadings = ["checkbox", "ID", "Name", "Mobile", "City", "Email"];
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes()
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "appointments",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };  
  const utcTimestamp = dataWeb.createAt;
  const localDate = new Date(utcTimestamp);
  const localDateString = localDate.toLocaleString();
  const formattedLocalDateString = localDateString.replace(",", "");
  let localModifiedAt = "";
  if (dataWeb.modifiedAt === 0) {
    localModifiedAt = "0";
  } else {
    const modifiedAt = new Date(dataWeb.modifiedAt);
    localModifiedAt = modifiedAt.toLocaleString();
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getStatsViewsLikesShares(id, "EDU");
        setData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const openModal = (type) => {
    const filteredData = filterData(type);
    setModalData(filteredData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const filterData = (type) => {
    switch (type) {
      case "viewed":
        return data.filter((item) => item.viewed);
      case "liked":
        return data.filter((item) => item.liked);
      case "shared":
        return data.filter((item) => item.shared);
      default:
        return [];
    }
  };
  const [testState, setTeststate] = useState(dataWeb.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateTestStatus("WEBINAR_COURSE", dataWeb.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          <div
            className="form-group"
            style={{
              display: "flex",
              paddingLeft: "9%",
              paddingRight: "9%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              <b>Edit Course Details</b>
            </span>
            {dataWeb.status !== null || "" ? (
              <div style={{ padding: 10, background: "green", borderRadius: 10 }}>
                <span style={{ color: "white" }}>{dataWeb.status}</span>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {/* <span style={{ fontSize: 20 ,marginLeft:"9%"}}>
        <strong>Edit Course Details</strong>
        </span> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="row col-md-10">
              <div className="col-md-12 mt-2">
                <div className="row">
                  <div className="col-md-4">
                    {isVideoUploaded ? (
                      <div>
                        <video controls src={URL.createObjectURL(videoUrl)} width="100%" height="auto">
                          Your browser does not support the video tag.
                        </video>
                        <Button onClick={deleteVideo}>Delete Video</Button>
                      </div>
                    ) : (
                      <div>
                        {episodeZero ? (
                          <video controls src={episodeZero.filePath} width="100%" height="auto">
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <p>Episode 0 not found</p>
                        )}
                        <Button>
                          <label htmlFor="video-upload" style={{ cursor: "pointer" }}>
                            Upload New Video*
                          </label>
                          <input id="video-upload" type="file" accept="video/*" onChange={handleVideoUpload} style={{ display: "none" }} />
                        </Button>
                      </div>
                    )}
                    <div style={{ marginTop: 10 }}>
                      {selectedImage ? (
                        <div>
                          <img
                            src={selectedImage}
                            alt="Uploaded"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                          <Button style={{ marginTop: 10 }} onClick={handleImgupload}>
                            Change
                          </Button>
                        </div>
                      ) : dataWeb.poster && dataWeb.poster.length > 0 ? (
                        <div>
                          <img src={dataWeb.poster} alt="Prepopulated" style={{ width: 200 }} />
                          <div style={{ marginTop: 10 }}>
                            <Button onClick={handleImgupload}>Change</Button>
                          </div>
                        </div>
                      ) : (
                        <label htmlFor="uploadInput">
                          <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleImgChange} />
                          <Button onClick={handleImgupload} color="secondary" as="span">
                            Upload*
                          </Button>
                        </label>
                      )}
                    </div>
                    <div className="mt-2">
                      <label>By Author*</label>
                      {/* <select id="inputPetParent" className="form-control" value={selectedAuthor} onChange={handleAuthorChange}>
                        <option value="Abhay">Abhay</option>
                        <option value="Rajesh">Rajesh</option>
                      </select> */}
                      <input id="myInput" type="text" name="myCountry" value={selectedAuthor} onChange={handleAuthorChange} className="form-control" />
                    </div>
                    <div style={{display:"flex",gap:20}}>
                    <div className=" mt-2">
                      <label>Featured*</label>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeaturedSwitch} />
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="mt-2">
                      <label>Sponsored*</label>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="mt-2">
                  <label>Test</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                    </FormGroup>
                  </Form>
                </div>
                </div>
                  </div>
                  <div className="col-md-8  ">
                    <div className=" ">
                      <label>Header*</label>
                      <input id="myInput" type="text" name="myCountry" value={header} onChange={handleHeaderChange} className="form-control" />
                    </div>
                    <div className=" mt-2">
                      <label>Description*</label>
                      <textarea value={description} className="form-control" rows={12} onChange={handleDescriptionChange}></textarea>
                    </div>
                    <div style={{ flexDirection: "row" }} className="mt-2">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Online"
                          id="Online"
                          value="Online"
                          checked={type === "Online"}
                          onChange={handleType}
                        />
                        <label className="form-check-label" htmlFor="Online">
                          Online
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Offline"
                          id="Offline"
                          value="Offline"
                          checked={type === "Offline"}
                          onChange={handleType}
                        />
                        <label className="form-check-label" htmlFor="Offline">
                          Offline
                        </label>
                      </div>
                    </div>
                    <div style={{ flexDirection: "row" }} className="mt-2">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Free"
                          id="Free"
                          value="Free"
                          checked={webtype === "Free"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Free">
                          Free
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Paid"
                          id="Paid"
                          value="Paid"
                          checked={webtype === "Paid"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Paid">
                          Paid
                        </label>
                      </div>
                    </div>
                    {webtype === "Paid" && (
                      <div className="mt-2">
                        <label>Price*</label>
                        <input id="myInput" type="number" name="price" className="form-control" value={price} onChange={handlePriceChange} />
                      </div>
                    )}

                    <div className=" mt-2">
                      <label>Published Date and Time*</label>
                      <input
                        type="datetime-local"
                        className="form-control form-control mt-2"
                        id="startDateTime"
                        value={publishedDate}
                        disabled={true}
                        onChange={handlePublished}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Live Date and Time*</label>
                      <input
                        type="datetime-local"
                        className="form-control form-control mt-2"
                        id="startDateTime"
                        value={webinarDateTime}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Created At*</label>
                      <input type="text" className="form-control form-control mt-2" id="startDateTime" disabled={true} value={formattedLocalDateString} />
                    </div>
                    <div className="mt-2">
                      <label>Modified At*</label>
                      <input type="da" className="form-control form-control mt-2" id="startDateTime" disabled={true} value={localModifiedAt} />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={EditCourse}
                  style={{
                    marginRight: 5,
                    background: "#007bff",
                    border: "none",
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{
                    marginRight: 5,
                    border: "none",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <hr className="my-3" />
                {dataWeb.paid === true && (
                <div
                  style={{
                    borderRadius: 5,
                    borderColor: "#E5E7EB",
                    padding: 10,
                    border: "1px solid #E5E7EB",
                    overflow: "auto",
                    marginTop: 20,
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <strong>Subscribed by</strong>
                  </span>
                  <Table hover style={{ tableLayout: "auto" }}>
                    <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      <tr>
                        {customTableHeadings.map((heading, index) => (
                          <th key={index} style={{ whiteSpace: "nowrap" }}>
                            {heading === "checkbox" ? (
                              <input
                                type="checkbox"
                                checked={selectedRows.length === currentItems.length}
                                onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                              />
                            ) : (
                              heading
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                          {customTableHeadings.map((heading, colIndex) => (
                            <td key={colIndex}>
                              { heading === "ID" ? (
                                <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                                {item.userId.slice(0, 5)}
                                {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                              </span>
                              ) : heading === "Mobile" ? (
                                item.mobile.slice(0, 10)
                              ) : heading === "Email" ? (
                                item.email       
                              ): heading === "Purchased Date" ? (
                                moment(item.paymentDate).format('YYYY-MM-DD HH:mm:ss')
                              ) : heading === "Name" ? (
                                item.name      
                              ) : heading === "Payment Status" ? (
                                item.status
                              ): (
                                item[heading]
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                )}
                 <div
                  style={{
                    borderRadius: 5,
                    borderColor: "#E5E7EB",
                    padding: 10,
                    border: "1px solid #E5E7EB",
                    overflow: "auto",
                    marginTop: 20,
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <strong>Video Stats</strong>
                  </span>
                  <Table hover>
                    <thead>
                      <tr>
                        {columns2.map((column, index) => (
                          <th key={index}>
                            {column === "checkbox" ? (
                              <input
                                type="checkbox"
                                checked={selectedRows.length === data4.length}
                                onChange={() => setSelectedRows(selectedRows.length === data4.length ? [] : data4.map((_, index) => index))}
                              />
                            ) : (
                              column
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data4.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>
                            <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                          </td>
                          {columns2.slice(1).map((column, colIndex) => (
                            <td key={colIndex}>
                              {column === "Likes" ? (
                                <Link onClick={() => openModal("liked")}>{item[column]}</Link>
                              ) : column === "Views" ? (
                                <Link onClick={() => openModal("viewed")}>{item[column]}</Link>
                              ) : column === "Share Count" ? (
                                <Link onClick={() => openModal("shared")}>{item[column]}</Link>
                              ) : (
                                item[column]
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Modal isOpen={isModalOpen} toggle={closeModal} size="lg">
                  <ModalHeader>Stats</ModalHeader>
                  <ModalBody>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                <div className="col-md-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{
                      border: "none",
                      marginTop: 10,
                      backgroundColor: "#0514a7",
                    }}
                    onClick={addEpisode}
                  >
                    Add Episode
                  </Button>
                </div>
              </div>
              <Accordion flush open={open} toggle={toggle}>
              {(episodes ?? []).map((episode, index) => (
                  <AccordionItem key={index}>
                    <AccordionHeader targetId={index.toString()}>Episode {episode.episodeNo}</AccordionHeader>
                    <div style={{ marginBottom: 20 }}>
                      <AccordionBody accordionId={index.toString()}>
                        <div className="pt-2 row">
                          <div className="col-md-7">
                            <div className="mt-2">
                              <label>Title*</label>
                              <input
                                id="myInput"
                                type="text"
                                name="myCountry"
                                className="form-control"
                                value={episodes[index].title}
                                onChange={(e) => {
                                  const updatedEpisodes = [...episodes];
                                  updatedEpisodes[index].title = e.target.value;
                                  setEpisodes(updatedEpisodes);
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label>Description*</label>
                              <textarea
                                id="myInput"
                                type="text"
                                name="myCountry"
                                className="form-control"
                                rows={10}
                                value={episodes[index].description}
                                onChange={(e) => {
                                  const updatedEpisodes = [...episodes];
                                  updatedEpisodes[index].description = e.target.value;
                                  setEpisodes(updatedEpisodes);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className=" mt-2 col-md-12">
                              <label>Duration*</label>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    value={episodes[index].videoDuration}
                                    type="number"
                                    name="header"
                                    className="form-control"
                                    onChange={(e) => {
                                      const updatedEpisodes = [...episodes];
                                      updatedEpisodes[index].videoDuration = e.target.value;
                                      setEpisodes(updatedEpisodes);
                                    }}
                                  />
                                  <span style={{ marginLeft: 10 }}>Min</span>
                                </div>
                              </div>
                            </div>
                           <div className="mt-3">
                                <label>Upload Course</label>
                                <div style={{ display: "flex" }}>
                                  {selectedVideo ? (
                                    <div style={{ maxWidth: "100%", overflow: "auto" }}>
                                      <video controls style={{ width: "100%" }}>
                                        <source src={URL.createObjectURL(selectedVideo)} />
                                      </video>
                                      <Button color="danger" onClick={() => handleCancelVideo(index)}>
                                        Remove
                                      </Button>
                                    </div>
                                  )   : episode.filePath ? (
                                    <div style={{ maxWidth: "100%", overflow: "auto" }}>
                                      <video controls style={{ width: "100%" }}>
                                        <source src={episode.filePath} />
                                      </video>
                                      <Button color="secondary" onClick={() => handleUpdateVideo(index, null)}>
                                        Remove Video
                                      </Button>
                                    </div>
                                
                                  ) : (
                                    <div>
                                      <Button color="secondary" onClick={() => handleUploadClick(index)}>
                                        Upload*
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            
                          </div>
                        </div>
                        <div className="mt-5   " style={{ display: "flex", justifyContent: "flex-end" }}>
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <div>
                              <Button
                                style={{
                                  marginRight: 5,
                                  background: "#007bff",
                                  border: "none",
                                }}
                                onClick={() => (episode.id ? episodeUpdate(index, episode) : episodeUpload(index))}
                              >
                                {episode.id ? "Update" : "Create"}
                              </Button>

                              <Button
                                style={{
                                  border: "none",
                                }}
                                onClick={() => navigate(-1)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>

              <div style={{ marginTop: 20 }}>
            <h5>Internal Note</h5>
            <div className="chat-container">
              <div className="chat-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <div className="message-text">{message.text}</div>
                    <div className="message-time">{getCurrentLocalDateTime()}</div>
                  </div>
                ))}
                {internalNotes.map((note) => (
                  <div key={note.id} className={`message bot`}>
                    <div className="message-text">{note.note}</div>
                    <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                  </div>
                ))}
              </div>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                <textarea
                  className="form-control"
                  rows={5}
                  type="text"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                 <div style={{ marginTop: 10,display:'flex',justifyContent:'flex-end' }}>
                  <button className="form-control" style={{width:100}} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCourse;
