import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import moment from "moment";
import { type } from "os";
import dataURItoBlob from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
const CreateAdvertisement = () => {
  const api = new Apis();
  const [loading, setLoading] = useState(false);
  const [isInternalLink, setIsInternalLink] = useState(true);
  const [advertisementFor, setAdvertisementFor] = useState("ROLL");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isVideoAllowed, setIsVideoAllowed] = useState(false);
  const [selectedPetParentId, setSelectedPetParentId] = useState(null);
  const [isListDetails, setIsListDetails] = useState(true);
  const [subtype, setSubType] = useState();
  const [internalLinks, setInternalLink] = useState();
  const [externalLinks, setExternalLink] = useState();
  const [title, setTitle] = useState();
  const [discription, setDescription] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleStartDate = (e) => {
    const inputDate = e.target.value;
    const dateMoment = moment(inputDate);
    const milliseconds = dateMoment.valueOf();
    setStartDate(milliseconds);
  };

  const handleEndDate = (e) => {
    const inputDate = e.target.value;
    const dateMoment = moment(inputDate);
    const milliseconds = dateMoment.valueOf();
    setEndDate(milliseconds);
  };
  const handleRadioChange = (e) => {
    setIsInternalLink(e.target.value === "option1");
  };
  const handleListRadio = (e) => {
    setIsListDetails(e.target.value === "List");
    const setSubtype = e.target.value;
    setSubType(setSubtype);
  };
  const handleAdvertisementChange = (e) => {
    const selectedOption = e.target.value;
    setAdvertisementFor(selectedOption);
    setIsVideoAllowed(selectedOption === "ROLL");
  };
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const [fileInputKey, setFileInputKey] = useState(0);

  // const handleImageChange = (e) => {
  //   const files = e.target.files;
  //   const selectedFiles = [];
  //   for (let i = 0; i < files.length; i++) {
  //     selectedFiles.push(URL.createObjectURL(files[i]));
  //   }
  //   setSelectedImages(selectedFiles);
  // };
  const handleImageChange = (event) => {
    const files = event.target.files;
    const updatedImages = [...selectedImages];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedImages.push(e.target.result);
        setSelectedImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleRadioChangee = (e) => {
    const value = e.target.value;
    setIsVideoAllowed(value === "Video");
    setSelectedImages([]);
    setFileInputKey((prevKey) => prevKey + 1);
  };
  const [Advertisment, setWebinarList] = useState([]);
  useEffect(() => {
    const fetchWebinarList = async () => {
      try {
        const webinarList = await api.getWebinar();
        // setEventData(webinarList);
        setWebinarList(webinarList);
      } catch (error) {
        console.error("Error fetching Advertisment:", error);
      }
    };
    fetchWebinarList();
  }, []);
  const [COURSE, setCouseList] = useState([]);
  useEffect(() => {
    const fetchCourseList = async () => {
      try {
        const courseList = await api.getCourse();
        setCouseList(courseList);
      } catch (error) {
        console.error("Error fetching Course:", error);
      }
    };
    fetchCourseList();
  }, []);
  const addAdvertisment = async () => {
    if (!title || !discription || !startDate || !endDate) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (advertisementFor === "WEBINAR" && isListDetails && !selectedPetParentId) {
      toast.error("Please select an Advertisment.");
      return;
    }

    if (advertisementFor === "COURSE" && isListDetails && !selectedPetParentId) {
      toast.error("Please select a Course.");
      return;
    }

    if (isInternalLink && !internalLinks) {
      toast.error("Please enter the Internal Link.");
      return;
    }

    if (!isInternalLink && !externalLinks) {
      toast.error("Please enter the External Link.");
      return;
    }

    if (selectedImages.length === 0) {
      toast.error("Please upload a workspace image.");
      return;
    }
    try {
      const requestBody = {
        title: title,
        description: discription,
        status: "Active",
        startDate: startDate,
        endDate: endDate,
        advertiserName: "aac",
        rawData: "axcfde",
        filePath: "bhn",
        type: advertisementFor,
        externalLink: externalLinks,
        internalLink: internalLinks,
        viewedCount: 2,
        subType: subtype === "Details" ? "Details" : "List",
        refId: subtype === "Details" ? selectedPetParentId : "",
      };
      setLoading(true);
      const response = await api.createAdvertisment(requestBody);
      setLoading(false);
      console.log(response.id);
      if (advertisementFor === "ROLL" && isVideoAllowed) {
        // Handle video upload
        // Create a FormData for video upload
        const videoFormData = new FormData();
        for (let i = 0; i < selectedImages.length; i++) {
          videoFormData.append("file", dataURItoBlob(selectedImages[i]),`image_${i + 1}.png`);
        }
        setLoading(true);
        const videoResponse = await api.uploadAdvertisment(response.id, "portrait", videoFormData);
        setLoading(false);
        console.log(videoResponse);
      } else {
        // Handle image upload
        const imageFormData = new FormData();
        for (let i = 0; i < selectedImages.length; i++) {
          imageFormData.append("file", dataURItoBlob(selectedImages[i]),`image_${i + 1}.png`);
        }
        setLoading(true);
        const imageResponse = await api.uploadAdvertisment(response.id, "", imageFormData);
        setLoading(false);
        console.log(imageResponse);
      }
    } catch (error) {
      console.error("Error adding Advertisment:", error);
    }
  };
  return (
    <>
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <ToastContainer position="top-right" autoClose={5000} />
          <div className="row">
            <div className="col-md-12">
              <div>
                <form className="modalForm">
                  <span className="form-group" style={{ fontSize: 20 }}>
                    <strong>Add Advertisement</strong>
                  </span>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Advertisement For</label>
                    <select id="inputPetsSpecies" className="form-control" onChange={handleAdvertisementChange} value={advertisementFor}>
                      <option value="ROLL">ROLL</option>
                      <option value="EVENT">EVENT</option>
                      <option value="BLOG">BLOG</option>
                      <option value="ARTICLE">ARTICLE</option>
                      <option value="WEBINAR">WEBINAR</option>
                      <option value="COURSE">COURSE</option>
                      <option value="VET">VET</option>
                      <option value="GROOMER">GROOMER</option>
                      <option value="NGO">NGO</option>
                      <option value="ADOPTION">ADOPTION</option>
                    </select>
                  </div>
                  {advertisementFor === "WEBINAR" && (
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Advertisement In</label>
                      <br />
                      <input className="form-check-input" type="radio" value="List" onChange={handleListRadio} checked={isListDetails} />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                        List
                      </label>
                      <input
                        style={{ marginLeft: 10 }}
                        className="form-check-input"
                        type="radio"
                        value="Details"
                        onChange={handleListRadio}
                        checked={!isListDetails}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                        Details
                      </label>
                      <div className="mt-2">
                        {isListDetails ? null : (
                          <select id="inputPetParent" className="form-control" onChange={(e) => setSelectedPetParentId(e.target.value)}>
                            <option value="" selected>
                              Select a Advertisment
                            </option>
                            {Advertisment &&
                              Advertisment.map((parent, index) => (
                                <option key={index} value={parent.id}>
                                  {parent.title}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                  {advertisementFor === "COURSE" && (
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Advertisement In</label>
                      <br />
                      <input className="form-check-input" type="radio" value="option1" onChange={handleListRadio} checked={isListDetails} />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                        List
                      </label>
                      <input
                        style={{ marginLeft: 10 }}
                        className="form-check-input"
                        type="radio"
                        value="option2"
                        onChange={handleListRadio}
                        checked={!isListDetails}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                        Details
                      </label>
                      <div className="mt-2">
                        {isListDetails ? null : (
                          <select id="inputPetParent" className="form-control" onChange={(e) => setSelectedPetParentId(e.target.value)}>
                            <option value="" selected>
                              Select a Course
                            </option>
                            {COURSE &&
                              COURSE.map((parent, index) => (
                                <option key={index} value={parent.id}>
                                  {parent.title}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group mt-2"></div>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Title</label>
                    <input onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Description</label>
                    <textarea onChange={(e) => setDescription(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6 mt-2">
                      <label>Start Date</label>
                      <input onChange={handleStartDate} type="date" className="form-control mt-2" id="startDateTime" />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label>End Date</label>
                      <input onChange={handleEndDate} type="date" className="form-control mt-2" id="endDateTime" />
                    </div>
                  </div>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Advertisement Link</label>
                    <br />
                    <input className="form-check-input" type="radio" value="option1" onChange={handleRadioChange} checked={isInternalLink} />
                    <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                      Internal Link
                    </label>
                    <input
                      style={{ marginLeft: 10 }}
                      className="form-check-input"
                      type="radio"
                      value="option2"
                      onChange={handleRadioChange}
                      checked={!isInternalLink}
                    />
                    <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                      External Link
                    </label>
                  </div>
                  <div className="form-group mt-2">
                    {isInternalLink ? (
                      <input type="text" className="form-control" onChange={(e) => setInternalLink(e.target.value)} placeholder="Enter Internal Link" />
                    ) : (
                      <input type="text" className="form-control" onChange={(e) => setExternalLink(e.target.value)} placeholder="Enter External Link" />
                    )}
                  </div>
                  {advertisementFor === "ROLL" ? (
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Upload</label>
                      <input
                        style={{ marginLeft: 5 }}
                        className="form-check-input"
                        type="radio"
                        value="Image"
                        checked={!isVideoAllowed}
                        onChange={handleRadioChangee}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                        Image
                      </label>
                      <input
                        style={{ marginLeft: 10 }}
                        className="form-check-input"
                        type="radio"
                        value="Video"
                        checked={isVideoAllowed}
                        onChange={handleRadioChangee}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                        Video
                      </label>
                      <input key={fileInputKey} type="file" accept={isVideoAllowed ? "video/*" : "image/*"} multiple onChange={handleImageChange} />
                      <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                        {selectedImages.map((image, index) => (
                          <div key={index}>
                            {isVideoAllowed ? (
                              <video controls width="200" height="150">
                                <source src={image} type="video/mp4" />
                              </video>
                            ) : (
                              <div>
                                <img
                                  src={image}
                                  alt={`Selected ${isVideoAllowed ? "Video" : "Image"} ${index + 1}`}
                                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                                />
                                <Button
                                  style={{ background: "transparent", border: "none" }}
                                  onClick={() => handleDeleteImage(index)}
                                  className="delete-button"
                                >
                                  <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="form-group mt-2">
                      <label className="radioBtnText" style={{ marginRight: 10 }}>
                        Upload Image
                      </label>
                      <input key={fileInputKey} type="file" accept={"image/*"} multiple onChange={handleImageChange} />
                      <div style={{ display: "flex", marginTop: 10 }}>
                        {selectedImages.map((image, index) => (
                          <div key={index}>
                            <div>
                              <img
                                src={image}
                                alt={`Selected ${isVideoAllowed ? "Video" : "Image"} ${index + 1}`}
                                style={{ maxWidth: "100px", maxHeight: "100px" }}
                              />
                              <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(index)} className="delete-button">
                                <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div style={{ marginTop: 20 }}>
                    <label style={{ fontStyle: "italic" }}>Internal Note</label>
                    <Editor
                      apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                      value={noteText}
                      init={{
                        selector: "textarea#basic-example",
                        height: 500,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | blocks | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                      }}
                      onEditorChange={handleNoteChange}
                    />
                  </div>
                </form>
                <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={addAdvertisment} style={{ marginRight: 10 }} color="primary">
                    Add
                  </Button>
                  <Button color="secondary">Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateAdvertisement;
