import React, { useState, useEffect } from "react";
import { CardGroup } from "react-bootstrap";
import "../component/css/WorkspaceDetails.css";
import { Button, FormGroup, Form, Input } from "reactstrap";
import WorkspaceTopBar from "../component/WorkspaceTopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEllipsisVertical, faPenToSquare, faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import LoaderModal from "../component/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import moment from "moment/moment";
import Apis from "../utils/api";
import DocumentUploader from "../component/common/DocumentNumber";
import { useServiceList } from "./services/serviceListState";
import { useNavigate } from "react-router-dom";
import dataURItoBlob, { isAlphaNumeric, isValidEmail, isValidMobile, isValidPinCode } from "../utils/helpers";
const NewWorkspace = () => {
  const navigate = useNavigate();
  const api = new Apis();
  const { getServiceListData } = useServiceList();
  const [selectedServiceToModify, setSelectedServiceToModify] = useState(null);
  const [selectAllDays, setSelectAllDays] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedVeterinaryCardTypes, setSelectedVeterinaryCardTypes] = useState([]);
  const [selectedService, setSelectedService] = useState("VETERINARY");
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState("Online");
  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceName, setServiceName] = useState(selectedService === "VETERINARY" ? selectedType : "");
  const [loading, setLoading] = useState(false);
  const [serviceRate, setServiceRate] = useState("");
  const [visitType, setVisitType] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [dataFilled, setDataFilled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [platformFee, setPlatformFee] = useState(0);
  const [gst, setGst] = useState(0);
  const [inHandAmount, setInHandAmount] = useState(0);
  const [images, setImages] = useState([]);
  const [about, setAbout] = useState("");
  const [experience, setExperience] = useState("");
  const [educations, setEducations] = useState([""]); // Array to store education values
  const [expertises, setExpertises] = useState([""]); // Array to store expertise values
  const [workplaceName, setWorkplaceName] = useState("");
  const [address, setAddress] = useState("");
  const [cityDistrict, setCityDistrict] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [workspaceNumber, setWorkspacenumber] = useState("");
  const [workplaceAddress, setWorkplaceAddress] = useState("");
  const [collaborator, setCollaboratorList] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState({});
  const [name, setName] = useState(selectedCollaborator ? selectedCollaborator.collaboratorName || "" : "");
  const [email, setEmail] = useState(selectedCollaborator ? selectedCollaborator.email || "" : "");
  const [contact, setContact] = useState(selectedCollaborator ? selectedCollaborator.mobile || "" : "");
  const [city, setCity] = useState(selectedCollaborator ? selectedCollaborator.city || "" : "");
  useEffect(() => {
    if (selectedCollaborator) {
      setName(selectedCollaborator.collaboratorName || "");
      setEmail(selectedCollaborator.email || "");
      setContact(selectedCollaborator.mobile || "");
      setCity(selectedCollaborator.city || "");
    }
  }, [selectedCollaborator]);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedImages.push(e.target.result);
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  
  // useEffect(() => {
  //   if (serviceRate) {
  //     const rate = parseFloat(serviceRate);
  //     const platformFeePercentage = 0.1;
  //     const gstPercentage = 0.18;
  //     const calculatedPlatformFee = rate * platformFeePercentage;
  //     let calculatedInHandAmount = rate - calculatedPlatformFee;
      
  //     // Deduct GST if isGstEnabled is true
  //     if (isGstEnabled) {
  //       calculatedInHandAmount -= calculatedInHandAmount * gstPercentage;
  //     }
      
  //     const calculatedGst = calculatedInHandAmount * gstPercentage;
  //     setPlatformFee(calculatedPlatformFee);
  //     setGst(calculatedGst);
  //     setInHandAmount(calculatedInHandAmount);
  //   }
  // }, [serviceRate, isGstEnabled]);
  const handleServiceSelection = (type) => {
    if (selectedVeterinaryCardTypes.includes(type)) {
      return;
    }
    setSelectedServiceType(null);
    setSelectedType(type);
    setShowModal(true);
  };
  const toggleDropdown = (type) => {
    setSelectedServiceType(selectedServiceType === type ? null : type);
  };
  const [platformFeeDetails, setPlatformFeeDetails] = useState(0);
  const [gstDetails, setGstDetails] = useState(0);
  const [inHandAmountDetails, setInHandAmountDetails] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const handleModify = (type) => {
    const serviceToModify =
      selectedService === "GROOMING" ? groomerServices.find((service) => service.name === type) : selectedServices.find((service) => service.type === type);
    setSelectedServiceToModify(serviceToModify);
    setPlatformFeeDetails(serviceToModify.convenienceFee);
    setGstDetails(serviceToModify.gst);
    setInHandAmountDetails(serviceToModify.inHandAmount);
    setSelectedServiceToModify(serviceToModify);
    setShowModal(true);
    setModifyModal(true);
  };
  const handleDelete = (identifier) => {
    setSelectedServiceType(null);
    if (selectedService === "VETERINARY") {
      const updatedSelectedServices = selectedServices.filter((service) => service.type !== identifier);
      setSelectedServices(updatedSelectedServices);
      const updatedVeterinaryCardTypes = selectedVeterinaryCardTypes.filter((cardType) => cardType !== identifier);
      setSelectedVeterinaryCardTypes(updatedVeterinaryCardTypes);
    } else if (selectedService === "GROOMING") {
      const updatedGroomerServices = groomerServices.filter((service) => service.name !== identifier);
      setGroomerServices(updatedGroomerServices);
    }
  };
  const [groomerServices, setGroomerServices] = useState([]);
  const handleSave = () => {
    try {
      if (!serviceRate) {
        throw new Error("Service rate is a required field*");
      }
      const updatedService = {
        name: selectedService === "VETERINARY" ? selectedType : serviceName,
        visit: visitType,
        convenienceFee: platformFee,
        type: selectedType,
        description: serviceDescription,
        rate: serviceRate,
        ...(isGstEnabled && { gstPrice: parseFloat(gst.toFixed(2)) }),
        isHomeVisit: visitType === "Home visit" ? true : false,
        inHandAmount: inHandAmount,
      };
      if (selectedServiceToModify) {
        // Update existing service data
        const updatedServices =
          selectedService === "GROOMING"
            ? groomerServices.map((service) => (service.name === selectedServiceToModify.name ? updatedService : service))
            : selectedServices.map((service) => (service.type === selectedServiceToModify.type ? updatedService : service));
        setSelectedService(selectedService === "GROOMING" ? "GROOMING" : "VETERINARY");
        setSelectedServiceToModify(null);
        setSelectedServices(selectedService === "GROOMING" ? [] : updatedServices);
        setGroomerServices(selectedService === "GROOMING" ? updatedServices : []);
      } else {
        // Add new service data
        if (selectedService === "VETERINARY") {
          setSelectedServices([...selectedServices, updatedService]);
          setSelectedVeterinaryCardTypes([...selectedVeterinaryCardTypes, selectedType]);
          setGroomerServices([]);
          setSelectedService("VETERINARY");
          setServiceDescription("");
          setServiceRate("");
          setPlatformFee(0);
          setInHandAmount(0);
        } else if (selectedService === "GROOMING") {
          setGroomerServices([...groomerServices, updatedService]);
          setSelectedServices([]);
          setSelectedVeterinaryCardTypes([]);
          setSelectedService("GROOMING");
          setServiceName("");
          setVisitType("");
          setServiceDescription("");
          setServiceRate("");
          setPlatformFee(0);
          setInHandAmount(0);
        }
      }
      setDataFilled(true);
      setShowModal(false);
      setModifyModal(false);
    } catch (error) {
      toast.error("" + error);
    }
  };
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const toggleSelectAllDays = () => {
    if (selectAllDays) {
      setSelectedDays([]);
    } else {
      setSelectedDays(daysOfWeek);
    }
    setSelectAllDays(!selectAllDays);
  };
  const toggleDay = (day) => {
    let updatedSelectedDays;

    if (selectedDays.includes(day)) {
      updatedSelectedDays = selectedDays.filter((d) => d !== day);
    } else {
      updatedSelectedDays = [...selectedDays, day];
    }
    setSelectedDays(updatedSelectedDays);
    const allDaysSelected = daysOfWeek.every((d) => updatedSelectedDays.includes(d));
    setSelectAllDays(allDaysSelected);
  };
  const getDocumentNumber = () => {
    if (selectedService === "GROOMING") {
      return 3;
    } else if (selectedService === "VETERINARY") {
      return 4;
    } else {
      return null;
    }
  };
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    const updatedServiceList = getServiceListData(selectedService);
    setServiceList(updatedServiceList);
    console.log(updatedServiceList);
    console.log("service List", serviceList);
  }, [selectedService]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isGstEnabled, setIsGstEnabled] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleSaveDetails = () => {
    try {
      if (!isAlphaNumeric(name)) {
        throw new Error("Name is invalid");
      }
      if (!isValidEmail(email)) {
        throw new Error("Email is invalid");
      }
      if (!isValidMobile(contact)) {
        throw new Error("Mobile number is not valid.");
      }
      if (!city) {
        throw new Error("City is a required field.");
      }
      setSelectedCollaborator((prevCollaborator) => ({
        ...prevCollaborator,
        collaboratorName: name,
        email,
        mobile: contact,
        city,
      }));
      toggleModal();
    } catch (error) {
      toast.error("" + error);
      console.error(error);
    }
  };
  const [expertiseRows, setExpertiseRows] = useState([{ education: "", areaOfExpertise: "" }]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [breakStartTime, setBreakStartTime] = useState("");
  const [breakEndTime, setBreakEndTime] = useState("");
  // Event handlers to update state when input values change
  //Ngo details
  const [ngoName, setNgoName] = useState();
  const [aboutNgo, setaboutNgo] = useState();
  const [registerNumber, setRegisterNumber] = useState();
  const [achievments, setAchievements] = useState();
  const [areaOfFocus, setAreaofFocus] = useState();
  const [ngoCity, setNgoCity] = useState();
  const [ngoState, setNgoState] = useState();
  const [ngoPincode, setNgoPincode] = useState();
  const [ngoAddress, setNgoAddress] = useState();
  const [ngoPhone, setNgoPhone] = useState();
  const [ngoLink, setNgoLink] = useState();
  console.log(ngoPhone);
  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };
  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };
  const handleBreakStartTimeChange = (event) => {
    setBreakStartTime(event.target.value);
  };
  const handleBreakEndTimeChange = (event) => {
    setBreakEndTime(event.target.value);
  };
  useEffect(() => {
    const fetchAllCollaborators = async () => {
      try {
        const result = await api.getAllCollaborators("All");
        setCollaboratorList(result);
        console.log(result);
      } catch (error) {
        console.log("Error fetching Collaborators ", error);
      }
    };
    fetchAllCollaborators();
  }, [selectedType]);
  const handleCollaboratorSelect = (service) => {
    setSelectedType(service);
  };
  console.log("selectCollabId", serviceList.id);
  const [latLng, setLatLng] = useState("0.0, 0.0");
  const userid = localStorage.getItem("userId") || null;
  const addWorkspaces = async () => {
    try {
      if (selectedService === "VETERINARY" && (!serviceList || !serviceList.id)) {
        throw new Error("Service list is required.");
      }
      if (!selectedCollaborator || !selectedCollaborator.id || !selectedCollaborator.mobile || !selectedCollaborator.city) {
        throw new Error("Collaborator basic fields are required.");
      }
      if (!isValidMobile(selectedCollaborator.mobile)) {
        throw new Error("Mobile number is not valid.");
      }
      if (!name || !isAlphaNumeric(name)) {
        throw new Error("Name with designation is a required field.");
      }
      if (!about) {
        throw new Error("About is a required field.");
      }
      if (selectedService === "VETERINARY" && !educations) {
        throw new Error("Education is a required field.");
      }
      if (selectedService === "VETERINARY" && !expertises) {
        throw new Error("Area of expertise is a required field.");
      }
      if (selectedService === "VETERINARY" && !experience) {
        throw new Error("Experience is a required field.");
      }
      if (!workplaceName) {
        throw new Error("Workplace name is a required field.");
      }
      if (!state) {
        throw new Error("State is a required field.");
      }
      if (!cityDistrict) {
        throw new Error("City is a required field.");
      }
      if (!isValidPinCode(pincode)) {
        throw new Error("Pincode is invalid.");
      }
      if (!address) {
        throw new Error("Address is a required field.");
      }
      if (!workspaceNumber) {
        throw new Error("Workspace number is a required field.");
      }
      if (selectedService === "VETERINARY" ? !serviceList || serviceList.length === 0 : !groomerServices || groomerServices.length === 0) {
        throw new Error("Services offered are required.");
      }
      if (!selectedDays) {
        throw new Error("Selected days is a required field.");
      }
      if (!startTime || !endTime) {
        throw new Error("Start time and end time are required fields.");
      }
      if (!breakStartTime || !breakEndTime) {
        throw new Error("Break Start and end time are required fields.");
      }
      if (!thumbImage) {
        throw new Error("Cover Image is a required.");
      }
      if (selectedService === "VETERINARY") {
        const requestBody = {
          designationName: name,
          about: about,
          workplaceName: workplaceName,
          collaboratorId: selectedCollaborator.id,
          serviceId: serviceList.id,
          address: address,
          town: city,
          city: city,
          pincode: pincode,
          state: state,
          education: educations,
          expertise: expertises,
          workplaceTime: {
            days: selectedDays,
            start_time: moment(startTime, "HH:mm").format("hh:mm A"),
            end_time: moment(endTime, "HH:mm").format("hh:mm A"),
            lunch_break: {
              start_time: breakStartTime ? moment(breakStartTime, "HH:mm").format("hh:mm A") : null,
              end_time: breakEndTime ? moment(breakEndTime, "HH:mm").format("hh:mm A") : null,
            },
          },
          createdBy: userid,
          createdAt: "03-Sep-2023 09:46:16 UTC",
          services: selectedServices,
          latitude: latLng.split(",")[0].trim(),
          longitude: latLng.split(",")[1].trim(),
          phone: selectedCollaborator.mobile,
          gstEnabled: " false",
          experience: experience,
          default: "false",
          active: "false",
          liked: "false",
          vacation: "false",
          status: "Pending",
          listOnly: listOnly,
          test:test
        };
        setLoading(true);
        const response = await api.addWorkspace(requestBody);
        console.log("API response:", response);
        uploadCoverImage(response.id);
        setLoading(false);
        navigate(`/WorkSpaceList/NewWorkspace/AddBankAndImages/${response.id}/${selectedCollaborator.id}/${selectedService}`);
      } else if (selectedService === "GROOMING") {
        const requestBody = {
          designationName: name,
          about: about,
          workplaceName: workplaceName,
          collaboratorId: selectedCollaborator.id,
          serviceId: serviceList.id,
          address: address,
          town: city,
          city: city,
          pincode: pincode,
          state: state,
          workplaceTime: {
            days: selectedDays,
            start_time: moment(startTime, "HH:mm").format("hh:mm A"),
            end_time: moment(endTime, "HH:mm").format("hh:mm A"),
            lunch_break: {
              start_time: breakStartTime ? moment(breakStartTime, "HH:mm").format("hh:mm A") : null,
              end_time: breakEndTime ? moment(breakEndTime, "HH:mm").format("hh:mm A") : null,
            },
          },
          createdBy: userid,
          createdAt: "03-Sep-2023 09:46:16 UTC",
          services: groomerServices,
          latitude: latLng.split(",")[0].trim(),
          longitude: latLng.split(",")[1].trim(),
          phone: selectedCollaborator.mobile,
          gstEnabled: " false",
          default: "false",
          active: "false",
          liked: "false",
          vacation: "false",
          status: "Pending",
          listOnly: listOnly,
          featured:featuredSwitchState,
          sponsored:switchState,
          gstEnabled:isGstEnabled,
          test:test
        };
        setLoading(true);
        const response = await api.addWorkspace(requestBody);
        console.log("API response:", response);
        uploadCoverImage(response.id);
        setLoading(false);
        navigate(`/WorkSpaceList/NewWorkspace/AddBankAndImages/${response.id}/${selectedCollaborator.id}/${selectedService}`);
      }
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.error("Error adding Workspace: " + error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (serviceRate) {
      const rate = parseFloat(serviceRate);
      const platformFeePercentage = 0.1;
      const gstPercentage = 0.18;
      const calculatedPlatformFee = rate * platformFeePercentage;
      let calculatedInHandAmount = rate - calculatedPlatformFee;
      
      // Deduct GST if isGstEnabled is true
      if (isGstEnabled) {
        calculatedInHandAmount -= calculatedInHandAmount * gstPercentage;
      }
      const calculatedGst = ((rate - calculatedPlatformFee) / 100) * 18;
      setPlatformFee(calculatedPlatformFee);
      setGst(calculatedGst);
      setInHandAmount(calculatedInHandAmount);
    }
  }, [serviceRate, isGstEnabled]);
  const addNgo = async () => {
    if (!ngoName) {
      toast.error("Please enter the NGO name.");
      setLoading(false);
      return;
    }
    if (!aboutNgo) {
      toast.error("Please provide information about the NGO.");
      setLoading(false);
      return;
    }
    if (!ngoAddress) {
      toast.error("Please enter the NGO address.");
      setLoading(false);
      return;
    }
    if (!registerNumber) {
      toast.error("Please enter the Register Number.");
      setLoading(false);
      return;
    }
    if (!achievments) {
      toast.error("Please enter the Achievments.");
      setLoading(false);
      return;
    }
    if (!areaOfFocus) {
      toast.error("Please enter the Area of Focus.");
      setLoading(false);
      return;
    }
    if (!ngoCity) {
      toast.error("Please enter the NGO City.");
      setLoading(false);
      return;
    }
    if (!ngoState) {
      toast.error("Please enter the NGO State.");
      setLoading(false);
      return;
    }
    if (!ngoPincode) {
      toast.error("Please enter the NGO Pincode.");
      setLoading(false);
      return;
    }
    if (!selectedDays) {
      toast.error("Please Select the days.");
      setLoading(false);
      return;
    }
    if (!startTime) {
      toast.error("Please Select startTime.");
      setLoading(false);
      return;
    }
    if (!endTime) {
      toast.error("Please Select endTime.");
      setLoading(false);
      return;
    }
    if (!breakStartTime) {
      toast.error("Please Select breakStartTime.");
      setLoading(false);
      return;
    }
    if (!breakEndTime) {
      toast.error("Please Select breakEndTime.");
      setLoading(false);
      return;
    }
    if (!thumbImage) {
      toast.error("Cover Image is Required.");
      setLoading(false);
      return;
    }
    try {
      const requestBody = {
        designationName: ngoName,
        about: aboutNgo,
        workplaceName: ngoName,
        collaboratorId: 1,
        serviceId: serviceList.id,
        address: ngoAddress,
        town: ngoCity,
        city: ngoCity,
        pincode: ngoPincode,
        state: ngoState,
        phone: ngoPhone,
        workplaceTime: {
          days: selectedDays,
          start_time: moment(startTime, "HH:mm").format("hh:mm A"),
          end_time: moment(endTime, "HH:mm").format("hh:mm A"),
          lunch_break: {
            start_time: breakStartTime ? moment(breakStartTime, "HH:mm").format("hh:mm A") : null,
            end_time: breakEndTime ? moment(breakEndTime, "HH:mm").format("hh:mm A") : null,
          },
        },
        createdBy: userid,
        registrationNumber: registerNumber,
        achievement: achievments,
        areaOfFocus: areaOfFocus,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
        status: "Pending",
        link: ngoLink,
        featured:featuredSwitchState,
        sponsored:switchState,
        test:test
      };
      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        // Assuming dataURItoBlob function is correctly implemented
        formData.append("files", dataURItoBlob(images[i]), `image_${i + 1}.png`);
      }
      setLoading(true);
      const Aadhar = new FormData();
      const Pan = new FormData();
      const ngoCertificate = new FormData();
      Aadhar.append("files", selectedAdhar);
      Pan.append("files", selectedPan);
      ngoCertificate.append("files", selectedNGoCertificate);
      const response = await api.addNgoWorkspace(requestBody);
      const workspaceImage = await api.uploadWorkspaceImages(response.workspaceId, 1, formData);
      const aadharUpload = await api.uploadAadhar(response.id, 1, aadharText, Aadhar);
      const qulaificationUpload = await api.uploadQualification(response.id, 1, ngoNumber, ngoCertificate);
      const panupload = await api.uploadPan(response.id, 1, panText, Pan);
      toast.success("NGO Added SuccessFully");
      uploadCoverImage(response.workspaceId);
      setLoading(false);
      console.log("Adhar Uplaod:", aadharUpload);
      console.log("pan Upload:", panupload);
      console.log("workspaceImage", workspaceImage);
      console.log("API response:", response.workspaceId);
      navigate(-1);
    } catch (error) {
      console.error("Error adding Workspace:", error);
      toast.error("Error adding Workspace: " + error.message);
      setLoading(false);
    }
  };
  const experienceIsValid = /^\d{1,2}$/.test(experience) && parseInt(experience) <= 50;
  const handleExperienceChange = (value) => {
    if (/^\d{0,2}$/.test(value) && parseInt(value) <= 50) {
      setExperience(value);
    } else if (/^\d{3,}$/.test(value)) {
      setExperience(value.slice(-2));
    }
  };
  const handleEducationChange = (e, index) => {
    const newEducations = [...educations];
    newEducations[index] = e.target.value;
    setEducations(newEducations);
  };

  const addEducation = () => {
    setEducations([...educations, ""]);
  };

  const handleExpertiseChange = (e, index) => {
    const newExpertises = [...expertises];
    newExpertises[index] = e.target.value;
    setExpertises(newExpertises);
  };

  const addExpertise = () => {
    setExpertises([...expertises, ""]);
  };
  const [selectedAdhar, setSelectedAdhar] = useState(null);
  const [selectedPan, setSelectedPan] = useState(null);
  const [selectedNGoCertificate, setSelectedNGoCertificate] = useState(null);
  const handleAadharUpload = (event) => {
    setSelectedAdhar(event.target.files[0]);
  };
  const deleteAadhar = () => {
    setSelectedAdhar(null);
  };
  const handlePan = (event) => {
    setSelectedPan(event.target.files[0]);
  };
  const deletePan = () => {
    setSelectedPan(null);
  };
  const [aadharText, setaadharText] = useState("");
  const handleaadharText = (value) => {
    setaadharText(value);
  };
  const [panText, setpanText] = useState("");
  const handlepanText = (value) => {
    setpanText(value);
  };
  const [ngoNumber, setngoText] = useState("");
  const handleNgoNumber = (value) => {
    setngoText(value);
  };
  const handleCertifcate = (event) => {
    setSelectedNGoCertificate(event.target.files[0]);
  };
  const deleteNgoCertificate = () => {
    setSelectedNGoCertificate(null);
  };
  const [thumbImage, setThumbImage] = useState(null);
  const [imageName, setImageName] = useState();
  const handleCoverImageUpload = (event) => {
    console.log("handleCoverImageUpload called");
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          console.log("Image width:", img.width);
          console.log("Image height:", img.height);
          if (img.width === 1920 && img.height === 1080) {
            setThumbImage(e.target.result);
          } else {
            toast.error("Please upload an image with dimensions 1920px by 1080px.");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCoverClick = () => {
    document.getElementById("thumbUploadInput").click();
  };
  const handleThumbImageDelete = () => {
    setThumbImage(null);
  };
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbImage) {
        console.log("No new image selected for upload.");
        return; // No need to upload if there's no new image
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(thumbImage), fileName);
      // error in uploading image @abhay
      const response = await api.uploadCoverImg("WORKSPACE", userId, formData);
      console.log("CoverImg", response);
      // navigate(-1);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const [listOnly, setListOnly] = useState(false);
  const toggleListOnly = () => {
    setListOnly(!listOnly);
  };
  const [test, setTest] = useState(false);
  const toggleTest = () => {
    setTest(!test);
  };
  const [switchState, setSwitchState] = useState(false);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(false);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          {/* <WorkspaceTopBar />

          <Modal isOpen={modifyModal} toggle={() => setModifyModal(false)}>
            <ModalHeader toggle={() => setModifyModal(false)}>{selectedType}</ModalHeader>
            <ModalBody>
              <div className="mt-2">
                <label htmlFor="inputServiceName">Service Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Service Name"
                  value={selectedServiceToModify ? selectedServiceToModify.name : ""}
                  onChange={(e) => setServiceName(e.target.value)}
                  required
                />
              </div>
              {selectedService === "GROOMING" && (
                <div className="mt-2">
                  <label htmlFor="inputVisitType">Visit Type</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label" htmlFor="center">
                        Center
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="visitType"
                        id="center"
                        value="Center"
                        checked={visitType === "Center"}
                        onChange={() => setVisitType("Center")}
                      />
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label" htmlFor="homevisit">
                        Home Visit
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="visitType"
                        id="homevisit"
                        value="Home visit"
                        checked={visitType === "Home visit"}
                        onChange={() => setVisitType("Home visit")}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2">
                <label htmlFor="inputServiceDescription">Service Description</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Service Description"
                  value={selectedServiceToModify ? selectedServiceToModify.description : ""}
                  onChange={(e) => setServiceDescription(e.target.value)}
                  required
                />
              </div>
              <div className="mt-2">
                <label htmlFor="inputServiceRate">Service Rate*</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Service Rate"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  required
                />
              </div>
              
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setModifyModal(false)}>
                Close
              </Button>
              <Button color="primary" onClick={handleSave}>
                Save
              </Button>
            </ModalFooter>
          </Modal> */}
          <div className="row mt-2" style={{ display: "flex", justifyContent: "center" }}>
            <div className="col-md-10 p-3" style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
              <div>
                <span>
                  <b>Select Service Type</b>
                </span>
              </div>
              <div style={{ flexDirection: "row" }}>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="service"
                    id="VETERINARY"
                    value="VETERINARY"
                    onChange={() => setSelectedService("VETERINARY")}
                    onClick={() => handleCollaboratorSelect("All")}
                    // checked={selectedService === "VETERINARY"}
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="VETERINARY">
                    Veterinary
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="service"
                    id="GROOMING"
                    value="GROOMING"
                    onChange={() => setSelectedService("GROOMING")}
                    onClick={() => handleCollaboratorSelect("All")}
                  />
                  <label className="form-check-label" htmlFor="GROOMING">
                    Groomers
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="service" id="NGO" value="NGO" onChange={() => setSelectedService("NGO")} />
                  <label className="form-check-label" htmlFor="NGO">
                    NGO and Donation
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>List Only</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={listOnly} onChange={toggleListOnly} />
                  </FormGroup>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Test</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={test} onChange={toggleTest} />
                  </FormGroup>
                </Form>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Featured</label>
                  </span>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Sponsored</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                    </FormGroup>
                  </Form>
                </div>
                {selectedService === "GROOMING" && (
                <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Enable GST</label>
                  <Form>
                    <FormGroup switch>
                      <Input id="gstSwitch" type="switch" role="switch" onChange={() => setIsGstEnabled(!isGstEnabled)} checked={isGstEnabled} />
                    </FormGroup>
                  </Form>
                </div>
              )}
              </div>
              {selectedService !== "NGO" && (
                <div className="mt-2">
                  <span>
                    <b>Select Collaborator*</b>
                  </span>
                  <div>
                    <select
                      className="form-control"
                      value={selectedCollaborator.collaboratorName || ""}
                      onChange={(e) => {
                        const selectedName = e.target.value;
                        const selectedCollaboratorObject = collaborator.find((collab) => collab.collaboratorName === selectedName);
                        setSelectedCollaborator(selectedCollaboratorObject || {});
                      }}
                    >
                      <option value="" disabled>
                        Select a Collaborator
                      </option>
                      {collaborator.map((collaborator) => (
                        <option key={collaborator.id} value={collaborator.collaboratorName}>
                          {collaborator.collaboratorName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {selectedService !== "NGO" && (
                <div className="pt-2">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Basic Details</span>
                    <Button style={{ backgroundColor: "transparent", border: "none" }} onClick={toggleModal}>
                      <FontAwesomeIcon color="blue" fontSize={18} icon={faPenToSquare} />
                    </Button>
                  </div>
                </div>
              )}
              {selectedService !== "NGO" && (
                <div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Name With Designation*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Designation"
                        value={selectedCollaborator ? selectedCollaborator.collaboratorName : ""}
                        disabled={true}
                      />
                    </div>
                    <div>
                      <span>Email ID*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={selectedCollaborator ? selectedCollaborator.email : ""}
                        disabled={true}
                      />
                    </div>
                    <div>
                      <span>Contact Number*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Number"
                        value={selectedCollaborator ? selectedCollaborator.mobile : ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>City*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter City"
                        value={selectedCollaborator ? selectedCollaborator.city : ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <span>About*</span>
                    <input
                      style={{ borderRadius: 6 }}
                      type="email"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Description"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    {selectedService !== "GROOMING" && selectedService !== "NGO" && (
                      <div className="col-md-12 d-flex" style={{ flexWrap: "wrap" }}>
                        <div className="col-md-5">
                          <span>Education*</span>
                          {educations.map((education, index) => (
                            <div key={index}>
                              <input
                                style={{ borderRadius: 6 }}
                                className="form-control mt-2"
                                aria-describedby="emailHelp"
                                placeholder="Enter Education"
                                value={education}
                                onChange={(e) => handleEducationChange(e, index)}
                              />
                              {index === educations.length - 1 && (
                                <FontAwesomeIcon className="mt-2" onClick={() => addEducation()} color="#0D6EFD" fontSize={20} icon={faPlus} />
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="col-md-6" style={{ marginLeft: 10 }}>
                          <span>Area of Expertise*</span>
                          {expertises.map((expertise, index) => (
                            <div key={index}>
                              <input
                                style={{ borderRadius: 6 }}
                                className="form-control mt-2"
                                placeholder="Enter Area of Expertise"
                                value={expertise}
                                onChange={(e) => handleExpertiseChange(e, index)}
                              />
                              {index === expertises.length - 1 && (
                                <FontAwesomeIcon className="mt-2" onClick={() => addExpertise()} color="#0D6EFD" fontSize={20} icon={faPlus} />
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="col-md-5">
                          <span>Experience*</span>
                          <input
                            style={{ borderRadius: 6 }}
                            className={`form-control ${experienceIsValid ? "" : "is-invalid"}`}
                            aria-describedby="emailHelp"
                            placeholder="Enter Years of experience"
                            value={experience}
                            onChange={(e) => handleExperienceChange(e.target.value)}
                          />
                          {experienceIsValid ? null : <div className="invalid-feedback">Please enter a valid 2-digit number (not exceeding 50).</div>}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="pt-2">
                    <div>
                      <span style={{ fontWeight: 600 }}>Workplace Details</span>
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span>Workplace Name*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Workplace Name"
                        value={workplaceName}
                        onChange={(e) => setWorkplaceName(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>State*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>City / District*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter City / District"
                        value={cityDistrict}
                        onChange={(e) => setCityDistrict(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>Pincode*</span>
                      <input
                        style={{ borderRadius: 6 }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Pincode"
                        type="number"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="pt-2"
                    style={{
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-md-4">
                      <span>Address*</span>
                      <input
                        style={{ borderRadius: 6, width: "93%" }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <span>Workspace Number*</span>
                      <input
                        style={{ borderRadius: 6, width: "93%" }}
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Number"
                        value={workspaceNumber}
                        type="number"
                        onChange={(e) => setWorkspacenumber(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <span>Location*</span>
                      <input
                        type="text"
                        style={{ borderRadius: 6, width: "93%" }}
                        className="form-control"
                        placeholder="Enter Latitude, Longitude"
                        value={latLng}
                        onChange={(e) => setLatLng(e.target.value)}
                      />
                    </div>
                    {/* <div>
                  <span>Workplace Address*</span>
                  <input
                    style={{ borderRadius: 6 }}
                    className="form-control"
                    placeholder="Enter Workplace Address"
                    value={workplaceAddress}
                    onChange={(e) => setWorkplaceAddress(e.target.value)}
                  />
                </div> */}
                  </div>
                  {selectedService === "NGO" ? null : (
                    <div className="mt-2">
                      <span>Service Offered*</span>
                      {selectedService === "GROOMING" ? (
                        <div style={{ flexDirection: "row" }}>
                          <Button onClick={() => handleServiceSelection("Add Service")}>Add Service</Button>
                        </div>
                      ) : (
                        <div style={{ flexDirection: "row" }}>
                          <Button
                            onClick={() => handleServiceSelection("Online")}
                            disabled={selectedService === "VETERINARY" && selectedVeterinaryCardTypes.includes("Online")}
                          >
                            Online
                          </Button>
                          <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => handleServiceSelection("Offline")}
                            disabled={selectedService === "VETERINARY" && selectedVeterinaryCardTypes.includes("Offline")}
                          >
                            Offline
                          </Button>
                          <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => handleServiceSelection("Home Visit")}
                            disabled={selectedService === "VETERINARY" && selectedVeterinaryCardTypes.includes("Home Visit")}
                          >
                            Home Visit
                          </Button>
                        </div>
                      )}
                      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                        <ModalHeader toggle={() => setShowModal(false)}>{selectedType}</ModalHeader>
                        <ModalBody>
                          {selectedService === "GROOMING" ? (
                            <div>
                              <div className="  mt-2">
                                <label for="inputPetBreed">Service Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Service Name"
                                  value={serviceName}
                                  onChange={(e) => setServiceName(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="  mt-2">
                                <label for="inputPetBreed">Visit Type</label>
                                <div>
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label" htmlFor="center">
                                      Center
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="visitType"
                                      id="center"
                                      value="Center"
                                      onChange={(e) => setVisitType(e.target.value)}
                                    />
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label" htmlFor="homevisit">
                                      Home Visit
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="visitType"
                                      id="homevisit"
                                      value="Home visit"
                                      onChange={(e) => setVisitType(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="  mt-2">
                            <label for="inputPetBreed">Service Discription</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Service Description"
                              value={serviceDescription}
                              onChange={(e) => setServiceDescription(e.target.value)}
                              required
                            />
                          </div>
                          <div className="  mt-2">
                            <label for="inputPetBreed">Service Rate*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Service Rate"
                              value={serviceRate}
                              onChange={(e) => setServiceRate(e.target.value)}
                              required
                            />
                          </div>
                          {/* {selectedService === "GROOMING" && (
                            <div className=" mt-2">
                              <label>Enable GST</label>
                              <Form>
                                <FormGroup switch>
                                  <Input id="gstSwitch" type="switch" role="switch" onChange={() => setIsGstEnabled(!isGstEnabled)} checked={isGstEnabled} />
                                </FormGroup>
                              </Form>
                            </div>
                          )} */}
                          <div className="mt-2">
                            <span>
                              <strong>Details</strong>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <span
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                Platform Fee (10%):
                              </span>
                              {isGstEnabled && selectedService === "GROOMING" ? <span style={{ flexDirection: "row" }}>GST (18%):</span> : null}
                            </div>
                            <div>
                              <span
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                ₹{platformFee.toFixed(2)}
                              </span>
                              {isGstEnabled && selectedService === "GROOMING" ? <span style={{ flexDirection: "row" }}>₹{gst.toFixed(2)}</span> : null}
                            </div>
                          </div>
                          <hr />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>In-Hand Amount**:</span>
                            <span>₹{inHandAmount.toFixed(2)}</span>
                          </div>
                          <div className="mt-2">
                            <span>*Amount Will be Charged to customer</span>
                            <span>**Amount Will be transferred to Collaborator</span>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={() => setShowModal(false)}>
                            Close
                          </Button>
                          <Button color="primary" onClick={handleSave}>
                            Save
                          </Button>
                        </ModalFooter>
                      </Modal>
                      {dataFilled && selectedService === "GROOMING" && (
                        <div>
                          {groomerServices.map((service, index) => (
                            <CardGroup
                              key={index}
                              className="p-3"
                              style={{
                                marginTop: 10,
                                border: "1px solid #beb7b7",
                                borderRadius: 10,
                                marginRight: 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div style={{ flexDirection: "column" }}>
                                  <div>
                                    {service.name} ({service.visit})
                                  </div>
                                  <div>{service.description}</div>
                                </div>
                                <div>Service Cost</div>
                                <div> Platform Fee (10%):</div>
                            
                                <div>{service && service.gstPrice ?<div>GST(18%):</div> : ''}</div>
                                <div>In-Hand Amount</div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Dropdown isOpen={selectedServiceType === service.name} toggle={() => toggleDropdown(service.name)}>
                                    <DropdownToggle tag="span" data-toggle="dropdown">
                                      <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem onClick={() => handleModify(service.name)}>Modify</DropdownItem>
                                      <DropdownItem onClick={() => handleDelete(service.name)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                                <div>
                                  <br></br>
                                </div>
                                <div>₹{service.rate}</div>
                                <div>₹{service.convenienceFee.toFixed(2)}</div>
                                <div>{service && service.gstPrice ? <div>₹{parseFloat(service.gstPrice).toFixed(2)}</div> : ""}</div>
                                <div>₹{service.inHandAmount}</div>
                              </div>
                            </CardGroup>
                          ))}
                        </div>
                      )}
                      {dataFilled &&
                        selectedService !== "GROOMING" &&
                        selectedServices.map((service, index) => (
                          <CardGroup
                            key={index}
                            className="p-3"
                            style={{
                              marginTop: 10,
                              border: "1px solid #beb7b7",
                              borderRadius: 10,
                              marginRight: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div style={{ flexDirection: "column" }}>
                                <div>{service.type}</div>
                                <div>{service.description}</div>
                              </div>
                              <div>Service Cost</div>
                              <div>Convenience Fee</div>
                              <div>In-Hand Amount</div>
                            </div>
                            <div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Dropdown isOpen={selectedServiceType === service.type} toggle={() => toggleDropdown(service.type)}>
                                  <DropdownToggle tag="span" data-toggle="dropdown">
                                    <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem onClick={() => handleModify(service.type)}>Modify</DropdownItem>
                                    <DropdownItem onClick={() => handleDelete(service.type)}>Delete</DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                              <div>₹{service.rate}</div>
                              <div>₹{service.convenienceFee.toFixed(2)}</div>
                              <div>₹{service.inHandAmount}</div>
                            </div>
                          </CardGroup>
                        ))}
                    </div>
                  )}
                </div>
              )}
              {selectedService === "NGO" && (
                <div>
                  <div>
                    <span style={{ fontWeight: 600 }}>NGO Details</span>
                    <div
                      className="pt-2"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-6">
                        <span>NGO Name*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter NGO Name"
                          value={ngoName}
                          onChange={(e) => setNgoName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6" style={{ marginLeft: 20 }}>
                        <span>About*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter About NGO"
                          value={aboutNgo}
                          onChange={(e) => setaboutNgo(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="pt-2"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-4">
                        <span>Register Number*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Register Number"
                          value={registerNumber}
                          onChange={(e) => setRegisterNumber(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4 mx-2">
                        <span>Achievements*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Achievements"
                          value={achievments}
                          onChange={(e) => setAchievements(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Area of Focus*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Area of Focus"
                          value={areaOfFocus}
                          onChange={(e) => setAreaofFocus(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="pt-2"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-4">
                        <span>City*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter City"
                          value={ngoCity}
                          onChange={(e) => setNgoCity(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4 mx-2">
                        <span>State*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter State"
                          value={ngoState}
                          onChange={(e) => setNgoState(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <span>Pincode*</span>
                        <input
                          type="number"
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Pincode"
                          value={ngoPincode}
                          onChange={(e) => setNgoPincode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="pt-2"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-6">
                        <span>Address*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Address"
                          value={ngoAddress}
                          onChange={(e) => setNgoAddress(e.target.value)}
                        />
                      </div>
                      <div style={{ marginLeft: 15 }} className="col-md-6">
                        <span>Location*</span>
                        <input
                          type="text"
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          placeholder="Enter Latitude, Longitude"
                          value={latLng}
                          onChange={(e) => setLatLng(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="pt-2"
                      style={{
                        marginRight: 10,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="col-md-6">
                        <span>Phone*</span>
                        <input
                          style={{ borderRadius: 6 }}
                          type="tel"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Phone"
                          value={ngoPhone}
                          onChange={(e) => {
                            const input = e.target.value;
                            if (/^\d{0,10}$/.test(input)) {
                              setNgoPhone(input);
                            }
                          }}
                        />
                      </div>
                      <div style={{ marginLeft: 15 }} className="col-md-6">
                        <span>Link</span>
                        <input
                          type="text"
                          style={{ borderRadius: 6 }}
                          className="form-control"
                          placeholder="Enter Link"
                          value={ngoLink}
                          onChange={(e) => setNgoLink(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  {selectedService !== "NGO" ? (
                    <div>
                      <span>Workplace Timings*</span>
                    </div>
                  ) : (
                    <div>
                      <span>NGO's Timing*</span>
                    </div>
                  )}
                </div>
                <div style={{ flexDirection: "row" }}>
                  {daysOfWeek.map((day) => (
                    <div className="form-check form-check-inline" key={day}>
                      <input className="form-check-input" type="checkbox" id={day} checked={selectedDays.includes(day)} onChange={() => toggleDay(day)} />
                      <label className="form-check-label" htmlFor={day}>
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div className="col-md-3">
                    <small className="text-muted">Select Start time</small>
                    <input type="time" className="form-control" value={startTime} onChange={handleStartTimeChange} placeholder="Select start time" />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: 20 }}>
                    <small className="text-muted">Select End time</small>
                    <input type="time" className="form-control" value={endTime} onChange={handleEndTimeChange} placeholder="Select end time" />
                  </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div className="col-md-3">
                    {/* //todo add valiadation for end date to be greater than start */}
                    <small className="text-muted">Select Start Break time</small>
                    <input
                      type="time"
                      className="form-control"
                      value={breakStartTime}
                      onChange={handleBreakStartTimeChange}
                      placeholder="Select start break time"
                    />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: 20 }}>
                    <small className="text-muted">Select End Break time</small>
                    <input type="time" className="form-control" value={breakEndTime} onChange={handleBreakEndTimeChange} placeholder="Select end break time" />
                  </div>
                </div>
                <div className="form-group mt-2 p-0">
                  <label className="pt-10 lableText">
                    Cover Image
                    <span className="required">*</span>
                  </label>
                  <div className="imageUploadBox">
                    <div className="text-right">
                      <label htmlFor="thumbUploadInput">
                        <input type="file" id="thumbUploadInput" style={{ display: "none" }} onChange={handleCoverImageUpload} />
                        <Button
                          // onClick={handleCoverClick}
                          color="secondary"
                          as="span"
                          onClick={handleCoverClick}
                        >
                          Upload
                        </Button>
                      </label>
                      {thumbImage && (
                        <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                          <span className="vetUploadButton">
                            <FontAwesomeIcon fontSize={20} icon={faTrash} />
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex flex-wrap">
                          <div className="col-md-4 d-flex align-items-center pt-3">
                            {thumbImage && <img src={thumbImage} alt="Uploaded" style={{ maxWidth: "50%" }} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              {selectedService === "NGO" && (
                <div>
                  <DocumentUploader
                    documentNumber={1}
                    documentName="Aadhar Card"
                    selectedDocument={selectedAdhar}
                    handleUpload={handleAadharUpload}
                    handleDelete={deleteAadhar}
                    onAadharTextChange={handleaadharText}
                    aadharText={aadharText}
                  />
                  <DocumentUploader
                    documentNumber={2}
                    documentName="PAN Card"
                    selectedDocument={selectedPan}
                    handleUpload={handlePan}
                    handleDelete={deletePan}
                    onPancardTextChange={handlepanText}
                    panText={panText}
                  />
                  <DocumentUploader
                    documentNumber={3}
                    documentName="NGO Certificate"
                    selectedDocument={selectedNGoCertificate}
                    handleUpload={handleCertifcate}
                    handleDelete={deleteNgoCertificate}
                    onPancardTextChange={handleNgoNumber}
                    panText={ngoNumber}
                  />
                </div>
              )}
            </div>
          </div>
          {selectedService === "NGO" && (
            <div className="mt-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <strong>NGO Images</strong>
                </div>
                <div>
                  <Button>
                    <label
                      htmlFor="imageUpload"
                      style={{
                        marginRight: 5,
                        border: "none",
                        padding: "5px 10px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      Upload
                      <input type="file" id="imageUpload" multiple onChange={handleImageUpload} style={{ display: "none" }} />
                    </label>
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {images.map((image, index) => (
                  <div key={index} className="image-item" style={{ position: "relative", marginRight: 10 }}>
                    <img src={image} className="img-fluid" style={{ width: 100, height: "auto" }} alt={`${index}`} />
                    <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(index)} className="delete-button">
                      <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedService !== "NGO" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  marginRight: 5,
                  background: "#007bff",
                  border: "none",
                }}
                onClick={addWorkspaces}
              >
                Create
              </Button>
              <Button
                style={{
                  marginRight: 5,
                  border: "none",
                }}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  marginRight: 5,
                  background: "#007bff",
                  border: "none",
                }}
                onClick={addNgo}
              >
                Create
              </Button>
              <Button
                style={{
                  marginRight: 5,
                  border: "none",
                }}
              >
                Cancel
              </Button>
            </div>
          )}
          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Edit Basic Information</ModalHeader>
            <ModalBody>
              <div>
                <span>Name With Designation*</span>
                <input style={{ borderRadius: 6 }} className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              {/* <div>
                <span>Email*</span>
                <input style={{ borderRadius: 6 }} className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <span>Contact number*</span>
                <input style={{ borderRadius: 6 }} className="form-control" value={contact} onChange={(e) => setContact(e.target.value)} />
              </div>
              <div>
                <span>City*</span>
                <input style={{ borderRadius: 6 }} className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />
              </div> */}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleSaveDetails}>
                Save
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};

export default NewWorkspace;
