import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Form,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api";
import WorkspaceTopBar from "../../component/WorkspaceTopBar";
import WorkspaceCollab from "../../component/WorkspaceCollab";
import { useWorkSpaceState } from "../services/workspaceState";
import { useServiceList } from "../services/serviceListState";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faEllipsisVertical, faList, faLock, faPen, faTrash, faUnlock, faXmark } from "@fortawesome/free-solid-svg-icons";
import LoaderModal from "../../component/common/Loader";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialog from "../../component/common/ConfirmDialog";
import { getValue } from "firebase/remote-config";
import { getRemoteConfig } from "firebase/remote-config";
const WorkspaceList = () => {
  const remoteConfig = getRemoteConfig();
  const val = getValue(remoteConfig, "welcome_message");
  console.log(val);
  const api = new Apis();
  const [collaboratorData, setCollaboratorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedType] = useState("VETERINARY");
  const [selectedService, setSelectedService] = useState(null);
  const [state, setState] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { setServiceListData } = useServiceList();
  const { setWorkspaceData } = useWorkSpaceState();

  const handleCollaboratorTypeSelect = async (selectedType) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not available");
        return;
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}admin/v1/super/admin/services/05eea7e4-f324-4bb0-b056-b9f7e944da40`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      const selectedServiceData = data.find((item) => item.type === selectedType);
      setServiceListData(data);
      if (selectedServiceData) {
        setSelectedService(selectedServiceData.id);
        console.log("Selected Service ID:", selectedServiceData.id);
        const otpData = await api.getWorkspace(selectedServiceData.id);
        console.log(otpData);
        setCollaboratorData(otpData);
        setWorkspaceData(otpData);
        setCurrentPage(1);
      } else {
        setSelectedService(null);
        console.log("Selected Service not found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleCollaboratorTypeSelect(selectedType);
  }, [selectedType]);
  const columns = ["checkbox", ...Object.keys(collaboratorData[0] || {})];
  const filterCollab = collaboratorData.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const designationNameLower = (item.designationName || "").toLowerCase();
    const workplaceNameLower = (item.workplaceName || "").toLowerCase();
    const phoneLower = (item.phone || "").toLowerCase();
    const idLower = (item.id || "").toLowerCase();
    return (
      (!state || item.status === "Active") &&
      (designationNameLower.includes(searchQueryLower) ||
        workplaceNameLower.includes(searchQueryLower) ||
        phoneLower.includes(searchQueryLower) ||
        idLower.includes(searchQueryLower))
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filterCollab.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const totalPages = Math.ceil(filterCollab.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const customTableHeadings = [
    "checkbox",
    "ID",
    "Designation Name",
    "Mobile",
    "City",
    "Workplace Name",
    "Featured",
    "Sponsored",
    // "Verified",
    "Status",
    "Lock",
    "Test",
    "Actions",
  ];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => currentItems[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleToggleChange = async (item) => {
    const updatedWorkspace = [...collaboratorData];
    const itemToUpdate = updatedWorkspace.find((workspaceItem) => workspaceItem.id === item.id);

    if (!itemToUpdate) {
      console.error("Item not found in collaboratorData");
      return;
    }

    try {
      if (itemToUpdate.serviceId === "5e4a1033-c48e-11ed-abc1-02817ef0919c"||itemToUpdate.serviceId === "d8d71038-c48c-11ed-abc1-02817ef0919c") {
        itemToUpdate.status = item.status === "Active" ? "Pending" : "Active";
        itemToUpdate.active = !item.active;
        await workspaceEdit(itemToUpdate);
        setCollaboratorData(updatedWorkspace);
      } else if (itemToUpdate.listOnly === true) {
        itemToUpdate.listOnly = false;
        itemToUpdate.status = "Pending";
        itemToUpdate.active = false;
        await workspaceEdit(itemToUpdate);
        setCollaboratorData(updatedWorkspace);
      } else if (Array.isArray(itemToUpdate.services) && itemToUpdate.services.length > 0) {
        if (itemToUpdate.status.toLowerCase() === "active" || itemToUpdate.status.toLowerCase() === "pending") {
          itemToUpdate.status = item.status === "Active" ? "Pending" : "Active";
          itemToUpdate.active = !item.active;
          await workspaceEdit(itemToUpdate);
          setCollaboratorData(updatedWorkspace);
        } else {
          toast.error("Cannot change status to 'Pending' when the service is null.");
        }
      } else {
        toast.error("Workspace Services are null.");
      }
    } catch (error) {
      console.error("Error toggling blog status:", error);
      itemToUpdate.status = item.status === "Active" ? "Pending" : "Active";
      itemToUpdate.active = !item.active;
      setCollaboratorData(updatedWorkspace);
    }
  };
  const handleTestChange = async (event) => {
    const itemId = event.target.value;
    const updatedWorkspace = [...collaboratorData];
    const itemToUpdate = updatedWorkspace.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        // Toggle the 'published' flag and update the status accordingly
        itemToUpdate.test = !itemToUpdate.test;
        setIsLoading(true);
        const response = await api.updateTestStatus("WORKSPACE",itemToUpdate.id,itemToUpdate.test);
        setIsLoading(false);
        console.log(response);
        toast.success("Workspace Test Status Changed!");
        setCollaboratorData(updatedWorkspace);
      } catch (error) {
        console.error("Error toggling Blog status:", error);
        // Revert changes on error
        itemToUpdate.test = !itemToUpdate.test;
        setCollaboratorData(updatedWorkspace);
      }
    }
  };
  const workspaceEdit = async (itemToUpdate) => {
    try {
      const requestBody = {
        id: itemToUpdate.id,
        designationName: itemToUpdate.designationName,
        about: itemToUpdate.about,
        workplaceName: itemToUpdate.workplaceName,
        collaboratorId: itemToUpdate.collaboratorId,
        serviceId: itemToUpdate.serviceId,
        address: itemToUpdate.address,
        town: itemToUpdate.town,
        city: itemToUpdate.city,
        pincode: itemToUpdate.pincode,
        state: itemToUpdate.state,
        education: itemToUpdate.education,
        expertise: itemToUpdate.expertise,
        workplaceTime: {
          days: itemToUpdate.workplaceTime.days,
          start_time: itemToUpdate.workplaceTime.start_time,
          end_time: itemToUpdate.workplaceTime.end_time,
          lunch_break: {
            start_time: itemToUpdate.workplaceTime.lunch_break.start_time,
            end_time: itemToUpdate.workplaceTime.lunch_break.end_time,
          },
        },
        createdBy: itemToUpdate.createdBy,
        createdAt: itemToUpdate.createdAt,
        services: itemToUpdate.services,
        latitude: itemToUpdate.latitude,
        longitude: itemToUpdate.longitude,
        phone: itemToUpdate.phone,
        gstEnabled: itemToUpdate.gstEnabled,
        experience: itemToUpdate.experience,
        default: itemToUpdate.default,
        active: itemToUpdate.active,
        liked: itemToUpdate.liked,
        vacation: itemToUpdate.vacation,
        status: itemToUpdate.status,
        listOnly: itemToUpdate.listOnly,
        featured: itemToUpdate.featured,
        sponsored: itemToUpdate.sponsored,
      };
      setIsLoading(true);
      const response = await api.updateWorkspaceData(itemToUpdate.id, requestBody);
      setIsLoading(false);
      toast.success("WorkSpace Status Changed!");
      console.log(response);
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.success("WorkSpace Status Cant be Changed!");
      setIsLoading(false);
    }
  };
  const handleEdit = (item) => {
    navigate(`/WorkSpaceList/WorkspaceDetails/${item.id}/${item.collaboratorId}/${selectedService}`);
  };

  const handleDelete = async (item, rowIndex) => {
    setIsLoading(true);
    try {
      const response = await api.deleteWorkspace(item.id);
      const originalIndex = indexOfFirstItem + rowIndex;
      setCollaboratorData((prevWorkspace) => {
        const updatedWorkspace = [...prevWorkspace];
        updatedWorkspace.splice(originalIndex, 1);
        return updatedWorkspace;
      });
      setSelectedRows([]);
      toast.success("Workspace deleted successfully!");
      navigate("/WorkSpaceList");
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Workspace:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex, setRowIndex] = useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  const handleListOnlyChange = async (itemId, rowIndex) => {
    const itemIdValue = itemId.id;
    const updatedWorkspace = [...collaboratorData];
    const itemToUpdate = updatedWorkspace.find((item) => item.id === itemIdValue);
    console.log("itemToUpdate:", itemToUpdate);
    if (itemToUpdate) {
      try {
        itemToUpdate.status = "Active";
        itemToUpdate.active = true;
        itemToUpdate.listOnly = true;
        await workspaceEdit(itemToUpdate);
        setCollaboratorData(updatedWorkspace);
      } catch (error) {
        console.error("Error toggling listOnly status:", error);
        itemToUpdate.status = "Pending";
        itemToUpdate.active = false;
        itemToUpdate.listOnly = false;
        setCollaboratorData(updatedWorkspace);
      }
    } else {
      console.error("ItemToUpdate not found. Cannot toggle listOnly status.");
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {isLoading ? (
        <LoaderModal isOpen={isLoading} toggle={() => setIsLoading(false)} />
      ) : (
        <>
          <WorkspaceCollab selectedType={selectedType} onSelectType={handleCollaboratorTypeSelect} />
          <WorkspaceTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} />
          <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
          <Table hover style={{ tableLayout: "auto" }}>
            <colgroup>
              {customTableHeadings.map((heading, index) => (
                <col key={index} style={{ width: heading === "checkbox" ? "40px" : "auto" }} />
              ))}
            </colgroup>
            <thead>
              <tr>
                {customTableHeadings.map((heading, index) => (
                  <th key={index} style={{ whiteSpace: "nowrap" }}>
                    {heading === "checkbox" ? (
                      <input
                        type="checkbox"
                        checked={selectedRows.length === currentItems.length}
                        onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                      />
                    ) : (
                      heading
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {customTableHeadings.map((heading, colIndex) => (
                    <td key={colIndex}>
                      {heading === "Actions" ? (
                        <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                            {/* Three vertical dots icon */}
                            <Button style={{ background: "none", border: "none" }}>
                              <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                            {item.status === "Active" ? (
                              <DropdownItem style={{ display: "flex", alignItems: "center" }} onClick={() => handleToggleChange(item)}>
                                <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faXmark} />{" "}
                                <span style={{ marginLeft: 10 }}>Diactivate</span>
                              </DropdownItem>
                            ) : (
                              <DropdownItem style={{ display: "flex", alignItems: "center" }} onClick={() => handleToggleChange(item)}>
                                <FontAwesomeIcon style={{ marginLeft: 10 }} color="green" fontSize={18} icon={faCheckDouble} />{" "}
                                <span style={{ marginLeft: 10 }}>Activate</span>
                              </DropdownItem>
                            )}
                            <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>

                            {(item.status === "Pending" || !item.listOnly) && selectedService !== "5e4a1033-c48e-11ed-abc1-02817ef0919c" && (
                              <DropdownItem
                                style={{ display: "flex", alignItems: "center" }}
                                onClick={() => {
                                  setIsConfirmOpen(true);
                                  setItemId(item);
                                  setRowIndex(rowIndex);
                                }}
                              >
                                <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faList} />
                                <span style={{ marginLeft: 10 }}>List Only</span>
                              </DropdownItem>
                            )}
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmDelete(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : heading === "ID" ? (
                        <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                          {item.id.slice(0, 5)}
                          {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                        </span>
                      ) : heading === "checkbox" ? (
                        <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                      ) : heading === "Designation Name" ? (
                        <a
                          href
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/WorkSpaceList/WorkspaceDetails/${item.id}/${item.collaboratorId}/${selectedService}`)}
                        >
                          {item.designationName}
                        </a>
                      ) : heading === "Mobile" ? (
                        item.phone
                      ) : heading === "City" ? (
                        item.city
                      ) : heading === "Workplace Name" ? (
                        item.workplaceName
                      ) : heading === "Featured" ? (
                        item.featured ? (
                          "True"
                        ) : (
                          "False"
                        )
                      ) : heading === "Sponsored" ? (
                        item.featured ? (
                          "True"
                        ) : (
                          "False"
                        )
                      ) : // : heading === "Verified" ? (
                      //   <Form>
                      //     <FormGroup switch>
                      //       <Input type="switch" role="switch" checked={item.status === "Active"} value={item.id} onChange={handleToggleChange} />
                      //     </FormGroup>
                      //   </Form>
                      // )
                      heading === "Status" ? (
                        item.status
                      ) : heading === "Lock" ? (
                        item.locked === true ? (
                          <a
                            href="#"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/WorkSpaceList/VersionWorspace/${item.id}/${item.collaboratorId}/${selectedService}`)}
                          >
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faLock} />
                          </a>
                        ) : (
                          <FontAwesomeIcon color="gray" fontSize={18} icon={faUnlock} />
                        )
                      ) :heading === "Test" ? (
                        <Form>
                          <FormGroup switch>
                            <Input type="switch" role="switch" checked={item.test === true} value={item.id} onChange={handleTestChange} />
                          </FormGroup>
                        </Form>
                      ) : (
                        item[heading]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30, 40, 50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
          <ConfirmDialog
            isOpen={isConfirmDelete}
            toggle={() => setIsConfirmDelete(!isConfirmDelete)}
            onConfirm={() => handleDelete(itemId, selectedrowIndex)}
            message="Are you sure you want to remove the listing?"
          />
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleListOnlyChange(itemId)}
            message="Are you sure you want to Make it Active and List Only"
          />
        </>
      )}
    </>
  );
};

export default WorkspaceList;
