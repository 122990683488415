import { useContext } from 'react';
import { AppContext } from './states';

export function useAdvertismentState() {
  const { state, dispatch } = useContext(AppContext);
  const currentAdvertismentData = state.advertismentState;
  const setadvertismentData = data => {
    sessionStorage.setItem('advertismentData', JSON.stringify(data));
    dispatch({ type: 'advertismentState', payload: data });
  };
  const getadvertismentData  = id => {
    const storedData = sessionStorage.getItem('advertismentData');
    const advertismentData = storedData ? JSON.parse(storedData) : currentAdvertismentData;

    return advertismentData && advertismentData.length > 0
      ? advertismentData.find(i => i.id === id) || null
      : null;
  };
  return {
    advertismentData: currentAdvertismentData,
    setadvertismentData,
    getadvertismentData ,
  };
}