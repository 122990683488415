import React, { useState, useEffect } from "react";
import DocumentUploader from "../component/common/DocumentNumber";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { useParams } from "react-router-dom";
import bankDetails from "../assets/banknames.json";
import dataURItoBlob from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import LoaderModal from "../component/common/Loader";
const AddBankAndImages = () => {
  const navigate = useNavigate();
  const { id, collabId,selectedService } = useParams();
  const api = new Apis();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAdhar, setSelectedAdhar] = useState(null);
  const [selectedPan, setSelectedPan] = useState(null);
  const [selectedDoctorLicense, setSelectedDoctorLicense] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const handleAadharUpload = (event) => {
    setSelectedAdhar(event.target.files[0]);
  };

  const deleteAadhar = () => {
    setSelectedAdhar(null);
  };
  const handlePan = (event) => {
    setSelectedPan(event.target.files[0]);
  };
  const deletePan = () => {
    setSelectedPan(null);
  };
  const handleDocLic = (event) => {
    setSelectedDoctorLicense(event.target.files[0]);
  };
  const deleteDocLic = () => {
    setSelectedDoctorLicense(null);
  };
  const handleQualification = (event) => {
    setSelectedQualification(event.target.files[0]);
  };
  const deleteQualification = () => {
    setSelectedQualification(null);
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];
    // Check if the total number of images does not exceed 5
    if (updatedImages.length + files.length > 5) {
      toast.error("Workspace images cannot be more than 5.");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedImages.push(e.target.result);
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  const [aadharText, setaadharText] = useState("");
  const handleaadharText = (value) => {
    setaadharText(value);
  };
  const [panText, setpanText] = useState("");
  const handlepanText = (value) => {
    setpanText(value);
  };
  const [doctorText, setdoctorText] = useState("");
  const handleDoctorText = (value) => {
    setdoctorText(value);
  };
  const [qualificationText, setqualificationText] = useState("");
  const handlequalificationText = (value) => {
    setqualificationText(value);
  };
  const [selectedBank, setSelectedBank] = useState("");
  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };
  const userid=localStorage.getItem('userId') || null;
  const handleSaveEdits = async () => {
    if (!selectedBank) {
      toast.error("Please select a bank.");
      setLoading(false);
    }
    if (!ifscCode) {
      toast.error("Please enter IFSC code.");
      setLoading(false);
    }
    if (!accountNumber) {
      toast.error("Please enter an account number.");
      setLoading(false);
    }
    if (!panNumber) {
      toast.error("Please enter a PAN number.");
      setLoading(false);
    }
    if (!accountHolderName) {
      toast.error("Please enter the account holder's name.");
      setLoading(false);
    }
    if (images.length === 0) {
      toast.error("Please upload a workspace image.");
      setLoading(false);
    }
    if (!selectedAdhar) {
      toast.error("Please upload aadhar");
      setLoading(false);
    }
    if (!selectedPan) {
      toast.error("Please upload Pan");
      setLoading(false);
    }
    try {
      const requestBody = {
        name: selectedBank,
        ifsc: ifscCode,
        accountNumber: accountNumber,
        pan: panNumber,
        gst: gstNumber,
        workspaceId: id,
        createdBy: userid,
        accountHolderName: accountHolderName,
      };
      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        // Assuming dataURItoBlob function is correctly implemented
        formData.append("files", dataURItoBlob(images[i]), `image_${i + 1}.png`);
      }
      const Aadhar = new FormData();
      const Pan = new FormData();
      const DocLice = new FormData();
      const qualification = new FormData();
      Aadhar.append("files", selectedAdhar);
      Pan.append("files", selectedPan);
      DocLice.append("files", selectedDoctorLicense);
      qualification.append("files", selectedQualification);
      console.log("Aadhar: " + selectedAdhar);
      console.log("Pan: " + selectedPan);
      console.log("DocLisence: " + selectedDoctorLicense);
      console.log("Qualification: " + selectedQualification);
      setLoading(true);
      const aadharUpload = await api.uploadAadhar(id, collabId, aadharText, Aadhar);
      toast.success("Workspace added successfully!");
      const panupload = await api.uploadPan(id, collabId, panText, Pan);
      if (qualification.has("files")) {
        const qulaificationUpload = await api.uploadQualification(id, collabId, qualificationText, qualification);
        console.log("qulaificationUpload upload", qulaificationUpload);
      }
      console.log("Aadhar upload", aadharUpload);
      console.log("Pan upload", panupload);
      if (selectedService !== "Grooming") {
        const docUpload = await api.uploadDocLicense(id, collabId, doctorText, DocLice);
        console.log(docUpload);
      }
      // console.log("qulaificationUpload upload", qulaificationUpload);
      const workspaceImage = await api.uploadWorkspaceImages(id, collabId, formData);
      console.log(workspaceImage);
      const response = await api.addBankDetails(id, requestBody);
      setLoading(false);
      navigate(-2);
      console.log("API response:", response);
    } catch (error) {
      console.error("Error editing Workspace:", error);
      toast.error("Error adding Workspace: " + error.response.data.message);
      setLoading(false);
    }
  };
  return (
    <>
     <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          <div>
            <div className="pt-3">
              <span style={{ fontWeight: 600, fontSize: 20 }}>Documents and Certificates</span>
            </div>
            <DocumentUploader
              documentNumber={1}
              documentName="Aadhar Card"
              selectedDocument={selectedAdhar}
              handleUpload={handleAadharUpload}
              handleDelete={deleteAadhar}
              onAadharTextChange={handleaadharText}
              aadharText={aadharText}
            />
            <DocumentUploader
              documentNumber={2}
              documentName="PAN Card"
              selectedDocument={selectedPan}
              handleUpload={handlePan}
              handleDelete={deletePan}
              onPancardTextChange={handlepanText}
              panText={panText}
            />
            {selectedService !== "Grooming" && selectedService !== "NGO" && (
            <DocumentUploader
              documentNumber={3}
              documentName="Doctor’s Licence"
              selectedDocument={selectedDoctorLicense}
              handleUpload={handleDocLic}
              handleDelete={deleteDocLic}
              onDoctorTextChange={handleDoctorText}
              doctorText={doctorText}
            />
            )}
            <DocumentUploader
              documentNumber={selectedService !== "Grooming" ? 4 : 3}
              documentName="Qualification Certificates"
              selectedDocument={selectedQualification}
              handleUpload={handleQualification}
              handleDelete={deleteQualification}
              onQualifcationTextChange={handlequalificationText}
              qualificationText={qualificationText}
            />
          </div>
          <div
            className="mt-3"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span style={{ fontWeight: 600, fontSize: 20 }}>Bank Details</span>
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span>Bank Name**</span>
              <div>
                <select className="form-control" style={{ width: "226px", borderRadius: 6 }} onChange={handleBankChange} value={selectedBank}>
                  <option value="" disabled>
                    Select a Bank
                  </option>
                  {bankDetails.map((bank, index) => (
                    <option key={index} value={bank.name}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <span>Account Holder Name*</span>
              <input
                style={{ borderRadius: 6 }}
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter Account Holder Name"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </div>
            <div>
              <span>Account Number*</span>
              <input
                style={{ borderRadius: 6 }}
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter Account Number"
                type="number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span>IFSC Code*</span>
              <input
                style={{ borderRadius: 6 }}
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter IFSC"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              />
            </div>
            <div>
              <span>PAN Number*</span>
              <input
                style={{ borderRadius: 6 }}
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter Pan Number"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
              />
            </div>
            <div>
              <span>GST Number (Optional)</span>
              <input
                style={{ borderRadius: 6 }}
                className="form-control"
                placeholder="Enter Area of Expertise"
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <strong>Workspace Images</strong>
              </div>
              <div>
                <Button>
                  <label
                    htmlFor="imageUpload"
                    style={{
                      marginRight: 5,
                      border: "none",
                      padding: "5px 10px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Upload
                    <input type="file" id="imageUpload" multiple onChange={handleImageUpload} style={{ display: "none" }} />
                  </label>
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {images.map((image, index) => (
                <div key={index} className="image-item" style={{ position: "relative", marginRight: 10 }}>
                  <img src={image} className="img-fluid" style={{ width: 100, height: "auto" }} alt={` ${index}`} />
                  <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(index)} className="delete-button">
                    <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "8%",
            }}
          >
            <Button
              style={{
                marginRight: 5,
                background: "#007bff",
                border: "none",
              }}
              //   onClick={addWorkspaces}
              onClick={handleSaveEdits}
            >
              Create
            </Button>
            <Button
              style={{
                marginRight: 5,
                border: "none",
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AddBankAndImages;
