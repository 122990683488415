import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {Form,FormGroup,Input,} from "reactstrap";
import { isAlphaNumeric, isValidMobile, isValidPinCode, isNum } from "../utils/helpers";
import dataURItoBlob from "../utils/helpers";
import LoaderModal from "../component/common/Loader";
import "react-toastify/dist/ReactToastify.css";
const CreateAdoption = () => {
  const api = new Apis();
  const [listingType, setListingType] = useState("INDIVIDUAL");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [link, setLink] = useState("");
  const [petName, setPetName] = useState("");
  const [species, setSpecies] = useState("");
  const [breeds, setBreeds] = useState([]);
  const [selectedBreed, setSelectedBreed] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [size, setSize] = useState("");
  const [coatLength, setCoatLength] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [petStory, setPetStory] = useState("");
  const [images, setImages] = useState([]);
  const handleListingTypeChange = (event) => {
    setListingType(event.target.value);
  };
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleUploadListing = async () => {
    try {
      // Basic validation checks
      if (!phoneNumber || !isValidMobile(phoneNumber)) {
        throw new Error("Please fill out valid phone number.");
      }
      if (!city) {
        throw new Error("Please fill out the city.");
      }
      if (!state) {
        throw new Error("Please fill out the state.");
      }
      if (!address) {
        throw new Error("Please fill out the address.");
      }
      if (!petName) {
        throw new Error("Please fill out the pet name.");
      }
      if (!selectedType) {
        throw new Error("Please select the pet species.");
      }
      if (!selectedBreed) {
        throw new Error("Please fill out the pet breed.");
      }
      if (!sex) {
        throw new Error("Please select the pet sex.");
      }
      // if (!years || !isNum(years)) {
      //   throw new Error("Please fill out the pet years.");
      // }
      // if (!months || !isNum(months)) {
      //   throw new Error("Please fill out the pet months.");
      // }
      if (!size) {
        throw new Error("Please fill out the pet size.");
      }
      if (!coatLength) {
        throw new Error("Please fill out the pet coat length.");
      }
      if (!color) {
        throw new Error("Please fill out the pet color.");
      }
      if (!weight || !isNum(weight)) {
        throw new Error("Please fill out the pet weight.");
      }
      if (!petStory) {
        throw new Error("Please fill out the pet story.");
      }
      if (!thumbImage) {
        throw new Error("Cover image is Required.");
      }
      const requestBody = {
        refId: (() => {
          switch (listingType) {
            case "INDIVIDUAL":
              return selectedPetParent;
            case "NGOs":
              return selectedNgo;
            case "3rd Parties":
              return userName||"FurrCrew";
            default:
              return userName||"FurrCrew";
          }
        })(),
        petName: petName,
        address: address,
        city: city,
        state: state,
        pincode: pincode || null,
        type: selectedType,
        breed: selectedBreed,
        sex: sex,
        month: months,
        year: years ,
        color: color,
        weight: weight,
        size: size,
        coatLength: coatLength,
        story: petStory,
        status: "CREATED",
        mobile: phoneNumber,
        link: link || null,
        phone: phoneNumber,
        listedBy: listingType,
        featured:featuredSwitchState,
        sponsored:switchState,
        test:test
      };
      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        formData.append("files", dataURItoBlob(images[i]), `image_${i + 1}.png`);
      }
      setLoading(true);
      const response = await api.addAdoption( requestBody);
      setLoading(false);
      toast.success("Added Adoption Successfully.");
      if (response.id) {
        console.log(response.id);
        setLoading(true);
        await api.uploadAdoptionImage(response.id, formData);
        setLoading(false);
        toast.success("Adoption image added successfully!");
        navigate(-1);
      } else {
        throw new Error("Response ID is undefined.");
      }
      uploadCoverImage(response.id);
      console.log("API response:", response);
    } catch (error) {
      console.error("Error adding pet:", error);
      toast.error("Error adding pet: " + error.message);
      setLoading(false);
    }
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];
    if (updatedImages.length + files.length > 5) {
      toast.error("You can upload a maximum of 5 images.");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedImages.push(e.target.result);
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  const [selectedType, setSelectedType] = useState("");
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          // Handle case when token is not available
          console.error("Token not available");
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}admin/v1/super/admin/pet/category?type=${selectedType}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        setBreeds(data || []);
      } catch (error) {
        console.error("Error fetching pet category:", error);
      }
    };
    fetchCategory();
  }, [selectedType]);  
  const [petParent, setPetParentList] = useState([]);
  const [selectedPetParent, setSelectedPetParent] = useState();
  const [selectedNgo, setSelectedNgo] = useState();
  useEffect(() => {
    const fetchpetParent = async () => {
      try {
        const fetchpetParent = await api.getPetParentList();
        setPetParentList(fetchpetParent);
        console.log(fetchpetParent);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchpetParent();
  }, []);
  const [ngoList, setNgoList] = useState([]);
  useEffect(() => {
    const listNgo = async () => {
      try {
        const listNgo = await api.getWorkspace("5e4a1033-c48e-11ed-abc1-02817ef0919c");
        setNgoList(listNgo);
        console.log(listNgo);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    listNgo();
  }, []);
  const handleBreedChange = (event) => {
    setSelectedBreed(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleSexType = (event) => {
    setSex(event.target.value);
  };
  const handleSize = (event) => {
    setSize(event.target.value);
  };
  const handleCoatLength = (event) => {
    setCoatLength(event.target.value);
  };
  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const handleYearIncrement = () => {
    setYears(years + 1);
  };
  const handleYearDecrement = () => {
    if (years > 0) {
      setYears(years - 1);
    }
  };
  const handleMonthIncrement = () => {
    if (months < 11) {
      setMonths(months + 1);
    } else {
      setMonths(0);
      setYears(years + 1);
    }
  };
  const handleMonthDecrement = () => {
    if (months > 0) {
      setMonths(months - 1);
    } else {
      if (years > 0) {
        setMonths(11);
        setYears(years - 1);
      }
    }
  };
  const [thumbImage, setThumbImage] = useState(null);
  const [imageName,setImageName]=useState();
  const handleCoverImageUpload = (e) => {
    const file = e.target.files[0];
    setImageName(file);
    const img = new Image();
    img.onload = function () {
      if (img.width === 1920 && img.height === 1080) {
        setThumbImage(file);
      } else {
        toast.error("Thumb image must be 1920 by 1080 pixels.");
      }
    };
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
};
  const handleCoverClick = () => {
    document.getElementById("thumbUploadInput").click();
  };
  const handleThumbImageDelete = () => {
    setThumbImage(null);
  };
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbImage) {
        console.log("No new image selected for upload.");
        return;
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file",thumbImage,fileName);
      const response = await api.uploadCoverImg("ADOPTION", userId, formData);
      console.log("CoverImg", response);
      // navigate(-1);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const [switchState, setSwitchState] = useState(false);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(false);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const [test, setTest] = useState(false);
  const toggleTest = () => {
    setTest(!test);
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <span style={{ fontSize: 20 }}>
            <strong>Create new Listing</strong>
          </span>
          <div style={{ flexDirection: "row" }} className="mt-2">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="listingType"
                id="INDIVIDUAL"
                value="INDIVIDUAL"
                checked={listingType === "INDIVIDUAL"}
                onChange={handleListingTypeChange}
              />
              <label className="form-check-label" htmlFor="INDIVIDUAL">
                INDIVIDUAL
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="listingType"
                id="NGOs"
                value="NGOs"
                checked={listingType === "NGOs"}
                onChange={handleListingTypeChange}
              />
              <label className="form-check-label" htmlFor="NGOs">
                NGOs
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="listingType"
                id="3rd Parties"
                value="3rd Parties"
                checked={listingType === "3rd Parties"}
                onChange={handleListingTypeChange}
              />
              <label className="form-check-label" htmlFor="3rd Parties">
                3rd Parties
              </label>
            </div>
          </div>
          <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Featured</label>
                  </span>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Sponsored</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                    </FormGroup>
                  </Form>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Test</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={test} onChange={toggleTest} />
                  </FormGroup>
                </Form>
              </div>
          <div className="pt-2">
            <div>
              <span style={{ fontWeight: 600 }}>General user information*</span>
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <span>Phone Number*</span>
              <input
                type="number"
                style={{ borderRadius: 6 }}
                className="form-control"
                placeholder="Enter Phone Number*"
                value={phoneNumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setPhoneNumber(e.target.value);
                  }
                }}
              />
            </div>
            {listingType === "INDIVIDUAL" && (
              <div style={{ marginLeft: 20 }}>
                <span>Pet Parent*</span>
                <select style={{ borderRadius: 6 }} className="form-control" value={selectedPetParent} onChange={(e) => setSelectedPetParent(e.target.value)}>
                  <option selected value="" disabled>
                    Select Pet Parent
                  </option>
                  {petParent.map((petParent) => (
                    <option key={petParent.id} value={petParent.id}>
                      {petParent.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {listingType === "NGOs" && (
              <div style={{ marginLeft: 20 }}>
                <span>NGO*</span>
                <select style={{ borderRadius: 6 }} className="form-control" value={selectedNgo} onChange={(e) => setSelectedNgo(e.target.value)}>
                  {/* map through ngoList to populate options */}
                  <option selected value="" disabled>
                    Select NGO
                  </option>
                  {ngoList.map((ngo) => (
                    <option key={ngo.id} value={ngo.id}>
                      {ngo.workplaceName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {listingType === "3rd Parties" && (
              <div style={{ marginLeft: 20 }}>
                <span>User Name</span>
                <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter User Name" onChange={(e) => setUserName(e.target.value)} />
              </div>
            )}
            <div style={{ marginLeft: 20 }}>
              <span>Link</span>
              <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter Link" value={link} onChange={(e) => setLink(e.target.value)} />
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <span>City*</span>
              <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter City*" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div style={{ marginLeft: 20 }}>
              <span>State*</span>
              <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter State" value={state} onChange={(e) => setState(e.target.value)} />
            </div>
            <div style={{ marginLeft: 20 }}>
              <span>Pincode</span>
              <input
                type="number"
                style={{ borderRadius: 6 }}
                className="form-control"
                placeholder="Enter Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <span>Address*</span>
              <input
                style={{ borderRadius: 6, width: "475px" }}
                className="form-control"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="pt-2">
            <div>
              <span style={{ fontWeight: 600 }}>Listing Information*</span>
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <span>Name*</span>
              <input
                style={{ borderRadius: 6 }}
                className="form-control"
                placeholder="Eg. Oscar*"
                value={petName}
                onChange={(e) => setPetName(e.target.value)}
              />
            </div>
            <div className="col-md-2" style={{ marginLeft: 20 }}>
              <span htmlFor="inputPetsSpecies">Pet Species*</span>
              <select id="inputPetsSpecies" className="form-control" value={selectedType} onChange={handleTypeChange}>
                <option value="" disabled selected>
                  Select a Species
                </option>
                <option selected value="dog">
                  Dog
                </option>
                <option value="cat">Cat</option>
                <option value="bird">Bird</option>
                <option value="exotic">Exotic</option>
              </select>
            </div>
            <div className="col-md-3" style={{ marginLeft: 20 }}>
              <label htmlFor="inputPetBreed">Pet Breed*</label>
              <select id="inputPetBreed" className="form-control" value={selectedBreed} onChange={handleBreedChange}>
                <option value="" disabled selected>
                  Select a breed
                </option>
                {breeds.map((breed, index) => (
                  <option key={index} value={breed}>
                    {breed}
                  </option>
                ))}
              </select>
            </div>
            {/* <div style={{ marginLeft: 20 }}>
          <span>Species*</span>
          <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. Dog" value={species} onChange={(e) => setSpecies(e.target.value)} />
        </div>
        <div style={{ marginLeft: 20 }}>
          <span>Breed*</span>
          <input
            style={{ borderRadius: 6 }}
            className="form-control"
            placeholder="Eg. Pug"
            value={breed}
            onChange={(e) => setBreed(e.target.value)}
          />
        </div> */}
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="col-md-2">
              <span>Gender*</span>
              {/* <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. Male*" value={sex} onChange={(e) => setSex(e.target.value)} /> */}
              <select id="inputSex" className="form-control " value={sex} onChange={handleSexType}>
                <option value="" disabled selected>
                  Select a Gender
                </option>
                <option selected value="Male">
                  Male
                </option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div style={{ marginLeft: 20, textAlign: "center" }}>
              <span>Age*</span>
              <div>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={handleYearDecrement}
                >
                  <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                </Button>
                {years} years
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={handleYearIncrement}
                >
                  <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                </Button>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={handleMonthDecrement}
                >
                  <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                </Button>
                {months} months
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={handleMonthIncrement}
                >
                  <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                </Button>
              </div>
            </div>
            <div className="col-md-3" style={{ marginLeft: 20 }}>
              <span>Size*</span>
              {/* <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. Small" value={size} onChange={(e) => setSize(e.target.value)} /> */}
              <select id="inputSex" className="form-control " value={size} onChange={handleSize}>
                <option value="" disabled selected>
                  Select a size
                </option>
                <option selected value="Small">
                  Small
                </option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
              </select>
            </div>
          </div>
          <div
            className="pt-2"
            style={{
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="col-md-3">
              <span>Coat Length*</span>
              <select id="inputSex" className="form-control " value={coatLength} onChange={handleCoatLength}>
                <option value="" disabled selected>
                  Select a Coat Length
                </option>
                <option selected value="Short">
                  Short
                </option>
                <option value="Medium">Medium</option>
                <option value="Long">Long</option>
              </select>
              {/* <input
            style={{ borderRadius: 6 }}
            className="form-control"
            placeholder="Eg. Short"
            value={coatLength}
            onChange={(e) => setCoatLength(e.target.value)}
          /> */}
            </div>
            <div className="col-md-3" style={{ marginLeft: 20 }}>
              <span>Color*</span>
              {/* <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. White" value={color} onChange={(e) => setColor(e.target.value)} /> */}
              <select id="inputSex" className="form-control " value={color} onChange={handleColorChange}>
                <option value="" disabled selected>
                  Select a Colour
                </option>
                <option value="Black">Black</option>
                <option value="White">White</option>
                <option value="Golden">Golden</option>
                <option value="Brown">Brown</option>
                <option value="Black & White">Black & White</option>
                <option value="Brown & White"> Brown & White</option>
              </select>
            </div>
            <div className="col-md-3" style={{ marginLeft: 20 }}>
              <span>Weight*</span>
              <input style={{ borderRadius: 6 }} type="number" className="form-control" placeholder="Eg. 13" value={weight} onChange={(e) => setWeight(e.target.value)} />
            </div>
          </div>
          <div className="mt-2">
            <span>Pet’s Story*</span>
            <input
              style={{ borderRadius: 6, height: 100 }}
              className="form-control"
              placeholder="Write Something..."
              value={petStory}
              onChange={(e) => setPetStory(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <strong>Upload Images</strong>
              </div>
              <div>
                <Button>
                  <label
                    htmlFor="imageUpload"
                    style={{
                      marginRight: 5,
                      border: "none",
                      padding: "5px 10px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Upload
                    <input type="file" id="imageUpload" multiple onChange={handleImageUpload} style={{ display: "none" }} />
                  </label>
                </Button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {images.map((image, index) => (
                <div key={index} className="image-item" style={{ position: "relative", marginRight: 10 }}>
                  <img src={image} className="img-fluid" style={{ width: 100, height: "auto" }} alt={`${index}`} />
                  <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(index)} className="delete-button">
                    <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-2 p-0">
            <label className="pt-10 lableText">
              Cover Image
              <span className="required">*</span>
            </label>
            <div className="imageUploadBox">
              <div className="text-right">
                <label htmlFor="thumbUploadInput">
                  <input type="file" id="thumbUploadInput" style={{ display: "none" }} onChange={handleCoverImageUpload} />
                  <Button
                    // onClick={handleCoverClick}
                    color="secondary"
                    as="span"
                    onClick={handleCoverClick}
                  >
                    Upload
                  </Button>
                </label>
                {thumbImage && (
                  <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                    <span className="vetUploadButton">
                      <FontAwesomeIcon fontSize={20} icon={faTrash} />
                    </span>
                  </button>
                )}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex flex-wrap">
                    <div className="col-md-4 d-flex align-items-center pt-3">
                      {thumbImage &&  <img src={URL.createObjectURL(thumbImage)} alt="Uploaded" style={{ width: 200 }} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div>
              <Button
                style={{
                  marginRight: 5,
                  background: "#007bff",
                  border: "none",
                }}
                onClick={handleUploadListing}
              >
                Upload Listing
              </Button>
              <Button
                style={{
                  marginRight: 5,
                  border: "none",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateAdoption;
