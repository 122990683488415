import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileExport, faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label } from "reactstrap";
import { useServiceList } from "../views/services/serviceListState";
import Apis from "../utils/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import { useNavigate } from "react-router-dom";
const CouponTopBar = ({ setSearchQuery, onClick, filterValue, setFilterValue }) => {
  const api = new Apis();
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const { getServiceListData } = useServiceList();
  const [WorkspacelistId, setWorkspacelistId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleFilter = () => {
    setFilterOpen(!isFilterOpen);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);
  const handleScheduleToggle = () => {
    setIsScheduleChecked(!isScheduleChecked);
  };
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    toggleFilter();
  };
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [discription, setDiscription] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponQuantity, setCouponQuantity] = useState("");
  const [FurrDiscount, setFurrDiscount] = useState("");
  const [discount, setDiscount] = useState("");
  // const [selectedType, setSelectedOption] = useState("Furrcrew");
  const [selectedFilter, setSeletedFilter] = useState("Furrcrew");
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState("%");
  const [selectedType, setSelectedType] = useState("Open");
  const [showDiscount, setShowDiscount] = useState(false); // Add a state for showing/hiding discount
  const animatedComponents = makeAnimated();
  const [selectedCollab, setSelectedCollab] = useState();
  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;
    const unixTimestamp = moment(selectedDate).unix();
    setStartDate(unixTimestamp * 1000);
  };
  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;
    const unixTimestamp = moment(selectedDate).unix();
    setEndDate(unixTimestamp * 1000);
  };
  const handleCheckboxChange = (e) => {
    setShowDiscount(e.target.checked); // Set showDiscount based on checkbox state
  };
  const handleDiscountChange = (event) => {
    setSelectedDiscount(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  // useEffect(() => {
  //   const serviceData = getServiceListData(selectedType);
  //   setWorkspacelistId(serviceData);
  // }, [selectedType, getServiceListData]);
  useEffect(() => {
    if (selectedType === "Veterinary") {
      setWorkspacelistId("d8d71038-c48c-11ed-abc1-02817ef0919c");
    } else if (selectedType === "Grooming") {
      setWorkspacelistId("5e4c6b60-c48e-11ed-abc1-02817ef0919c");
    }
  }, [selectedType]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState([]);
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    try {
      const selectedWorkspace = workspaceDatas.find((workspace) => workspace.designationName === event.target.value);
      console.log("Selected Workspace:", selectedWorkspace);
      if (selectedWorkspace && selectedWorkspace.id !== undefined) {
        setSelectedID(selectedWorkspace.collaboratorId);
        setSelectedWorkspaceId(selectedWorkspace.id);
        console.log("Selected Workspace ID:", selectedWorkspace);
      } else {
        setSelectedID([]);
        setSelectedWorkspaceId([]);
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };
  console.log("SelectedId", selectedWorkspaceId);
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceId = selectedType === "Veterinary" ? "d8d71038-c48c-11ed-abc1-02817ef0919c" : "5e4c6b60-c48e-11ed-abc1-02817ef0919c";
        console.log("Selected option:", selectedType);
        console.log("Fetching workspace with ID:", workspaceId);
        const workspaceData = await api.getWorkspace(workspaceId);
        console.log("Fetched workspace data:", workspaceData);
        // Filter out only active workspaceData
        const activeWorkspaceData = workspaceData.filter((workspace) => workspace.active === true);
        console.log("Active workspace data:", activeWorkspaceData);
        setSelectedWorkspacDatas(activeWorkspaceData);
        console.log("Selected workspace data set:", activeWorkspaceData);
        // Create workspaceOptions array with the correct structure
        const combinedNames = activeWorkspaceData.map((workspace) => ({
          designationName: workspace.designationName,
          workplaceName: workspace.workplaceName,
        }));
        setWorkspaceOptions(combinedNames);
        console.log("Workspace options set:", combinedNames);
      } catch (error) {
        console.error("Error fetching workspace:", error);
      }
    };
    fetchWorkspace();
  }, [selectedType]);
  const [endDate, setEndDate] = useState("");
  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const handleFilter = (event) => {
    setSeletedFilter(event.target.value);
    toggleFilter();
  };
  const [selectedID, setSelectedID] = useState([]);
  const userid = localStorage.getItem("userId") || null;
  const createCoupon = async () => {
    try {
      if (!discription) {
        throw new Error("Please enter the description.");
      }
      if (!couponCode) {
        throw new Error("Please fill out the couponCode.");
      }
      if (!startDate) {
        throw new Error("Please fill out the startDate.");
      }
      if (!endDate) {
        throw new Error("Please select a endDate.");
      }
      if (!selectedType) {
        throw new Error("Please select a selectedType.");
      }
      if (!couponQuantity) {
        throw new Error("Please enter the coupon quantity.");
      }
      if (!FurrDiscount) {
        throw new Error("Please enter total discount.");
      }
      const requestBody = {
        code: couponCode,
        createdBy: userid,
        expiryDate: endDate,
        couponName: couponCode,
        quantity: couponQuantity,
        discount: FurrDiscount,
        startDate: startDate,
        endDate: endDate,
        split: false,
        publishedFor: selectedType,
        published: false,
        collabDiscount: discount,
        discountType: selectedDiscount,
        description: discription,
        type: selectedType,
        refId: (() => {
          if (selectedType === "Open") {
            return "1";
          } else if (selectedType === "Events") {
            return selectedEventId;
          } else if (selectedType === "Webinar") {
            return selectedWebinarID;
          } else if (selectedType === "Course") {
            return seletedCourseId;
          } else if (selectedType === "Veterinary" || selectedType === "Grooming") {
            return selectedWorkspaceId;
          } else {
            return "1";
          }
        })(),
      };
      setLoading(true);
      const response = await api.createCupon(1, requestBody);
      console.log("API response:", response);
      setIsModalOpen(false);
      toast.success("Coupon added successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error adding user:", error);
      setLoading(false);
      toast.error("Error adding user: " + error.message);
    }
  };
  useEffect(() => {
    const selectedWorkspace = workspaceDatas.find((workspace) => workspace.designationName === selectedCollab);
    console.log("SelectedId", selectedCollab);
    if (selectedWorkspace) {
      setSelectedID(selectedWorkspace.id);
      console.log("SelectedId", selectedID);
    } else {
      setSelectedID([]);
    }
  }, [selectedCollab, workspaceDatas]);
  console.log("selected Collab", selectedID);
  const [eventList, setEventList] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedWebinarID, setSeletedWebinarId] = useState("");
  const [seletedCourseId, setSelectedCourseId] = useState("");
  console.log("Selected Course ID", seletedCourseId);
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const events = await api.getEventList();
        setEventList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEventList();
  }, []);
  const handleEventChange = (event) => {
    setSelectedEventId(event.target.value);
  };
  const [webinarList, setWebinarList] = useState();
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const events = await api.getWebinar();
        setWebinarList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEventList();
  }, []);
  const handleWebinarChange = (event) => {
    setSeletedWebinarId(event.target.value);
  };
  const [courseList, setCourseList] = useState();
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const events = await api.getCourse();
        setCourseList(events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEventList();
  }, []);
  const handleCourseChange = (event) => {
    setSelectedCourseId(event.target.value);
  };
  return (
    <>
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          <ToastContainer position="top-right" autoClose={5000} />
          <div style={styles.topBar}>
            <div>
              <button style={styles.iconButton}>
                <Button onClick={toggleFilter} style={styles.exportButton} color="#ffffff">
                  <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faFilter} />
                  <span style={{ marginLeft: "5px" }}>Filter</span>
                </Button>
              </button>
            </div>
            <div style={styles.leftButtons}>
              <div style={styles.findInput}>
                <InputGroup>
                  <Button>
                    <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
                  </Button>
                  <Input placeholder="Find Something" onChange={handleSearchInputChange} />
                </InputGroup>
              </div>
              <button style={styles.iconButton}>
                <Button onClick={onClick} style={styles.exportButton} color="#ffffff">
                  <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faFileExport} />
                  <span style={{ marginLeft: "5px" }}>Export</span>
                </Button>
              </button>
              <button style={styles.iconButton}>
                <Button style={styles.addButton} color="#ffffff" onClick={toggleModal}>
                  <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
                  <span style={{ marginLeft: "5px" }}>Create Coupon</span>
                </Button>
              </button>
            </div>
            {/* Modal component */}
            <Modal isOpen={isFilterOpen} toggle={toggleFilter} className="modal-md">
              <ModalHeader toggle={toggleFilter}>Filter Coupon</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <b>Published For</b>
                  <Input type="select" name="filterSelect" id="filterSelect" value={filterValue} onChange={handleFilterChange}>
                    <option value="">All</option>
                    <option value="FurrCrew">FURRCREW</option>
                    <option value="Veterinary">Veterninary</option>
                    <option value="Grooming">Grooming</option>
                    {/* Add more filter options as needed */}
                  </Input>
                  {/* Add more options as needed */}
                </FormGroup>
              </ModalBody>
            </Modal>
            <Modal isOpen={isModalOpen} toggle={toggleModal} className="modal-lg">
              <ModalHeader toggle={toggleModal}>Create Coupon</ModalHeader>
              <ModalBody>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <span>
                        <b>General Information</b>
                      </span>
                    </div>
                    <div className="col-md-12 mt-3 d-flex">
                      <div className="col-md-6" style={{ flexDirection: "column", display: "flex" }}>
                        <div className="form-group mt-1">
                          <label>Coupon Code*</label>
                          <input
                            type="text"
                            className="form-control"
                            id="customName"
                            placeholder="Enter Coupon Code"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <select className="form-control" id="dropdown" value={selectedType} onChange={handleTypeChange}>
                            <option value="Open">FurrCrew</option>
                            <option value="Veterinary">Veterinary</option>
                            <option value="Grooming">Grooming</option>
                            <option value="Events">Events</option>
                            <option value="Webinar">Webinar</option>
                            <option value="Course">Course</option>
                          </select>
                        </div>

                        {selectedType === "Veterinary" || selectedType === "Grooming" ? (
                          <div className="form-group mt-2">
                            <label htmlFor="inputPetsSpecies">Select Workspace</label>
                            <select id="inputPetsSpecies" className="form-control" onChange={handleWorkspaceChange} value={selectedWorkspaceName}>
                              {workspaceOptions.map((workspace) => (
                                <option key={workspace.designationName} value={workspace.designationName}>
                                  {`${workspace.designationName} - ${workspace.workplaceName}`}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : selectedType === "Events" ? (
                          <div className="form-group mt-2">
                            <label htmlFor="inputEventList">Select Event</label>
                            <select id="inputEventList" className="form-control" onChange={handleEventChange} value={selectedEventId}>
                              {eventList &&
                                eventList.map((event) => (
                                  <option key={event.id} value={event.id}>
                                    {event.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        ) : selectedType === "Webinar" ? (
                          <div className="form-group mt-2">
                            <label htmlFor="inputEventList">Select Webinar</label>
                            <select id="inputEventList" className="form-control" onChange={handleWebinarChange} value={selectedWebinarID}>
                              {webinarList &&
                                webinarList.map((webinar) => (
                                  <option key={webinar.id} value={webinar.id}>
                                    {webinar.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        ) : selectedType === "Course" ? (
                          <div className="form-group mt-2">
                            <label htmlFor="inputEventList">Select Course</label>
                            <select id="inputEventList" className="form-control" onChange={handleCourseChange} value={seletedCourseId}>
                              {courseList &&
                                courseList.map((webinar) => (
                                  <option key={webinar.id} value={webinar.id}>
                                    {webinar.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        ) : null}
                        <div className="form-group mt-1 ">
                          <label>Total Discount*</label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control"
                              id="FurrDiscount"
                              value={FurrDiscount}
                              onChange={(e) => setFurrDiscount(e.target.value)}
                            />
                            <select style={{ borderRadius: "6px", marginLeft: 8 }} id="dropdown" value={selectedDiscount} onChange={handleDiscountChange}>
                              <option value="%">%</option>
                              <option value="Rs">Rs</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6" style={{ flexDirection: "column", display: "flex" }}>
                        <div className="form-group">
                          <label>Description*</label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="description"
                            placeholder="Enter Description"
                            value={discription}
                            onChange={(e) => setDiscription(e.target.value)}
                            rows={5}
                          />
                        </div>
                        <div className="form-group mt-2  ">
                          <label>Coupon Quantity*</label>
                          <input
                            type="text"
                            className="form-control"
                            id="couponQuantity"
                            placeholder="Enter coupon quantity"
                            value={couponQuantity}
                            onChange={(e) => setCouponQuantity(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                      <div className="row d-flex" style={{ alignItems: "center" }}>
                        {selectedType === "Veterinary" || selectedType === "Grooming" ? (
                          <div className="col-md-6 form-group">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              onChange={handleCheckboxChange} // Handle checkbox change event
                            />
                            <label className="form-check-label" htmlFor="exampleCheck1">
                              There is an FC Split.
                            </label>
                          </div>
                        ) : null}
                        <div className="col-md-6 form-group">
                          {showDiscount && (selectedType === "Veterinary" || selectedType === "Grooming") ? (
                            <div className="mt-1">
                              <label>Discount by {selectedType}</label>
                              <div className="d-flex">
                                <div className="input-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="FurrDiscount"
                                    value={FurrDiscount}
                                    onChange={(e) => setFurrDiscount(e.target.value)}
                                  />
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">{selectedDiscount}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                      <div className="row  ">
                        <div className="col-md-6 form-group">
                          <label>Start Date*</label>
                          <input type="date" className="form-control" id="startDateTime" onChange={handleStartDateChange} />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>End Date*</label>
                          <input type="date" className="form-control" id="endDateTime" onChange={handleEndDateChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={createCoupon}>
                  Publish Coupon
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

// Add your styles here (styles object)

const styles = {
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default CouponTopBar;
