import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmDialog = ({ isOpen, toggle, onConfirm, message }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => { onConfirm(); toggle(); }}>Confirm</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDialog;

// Usage

// const [isConfirmOpen, setIsConfirmOpen] = useState(false);

// const handleDelete = () => {
//   // Handle the delete action here
//   console.log('Deleting...');
// };

// return (
//   <div>
//     <Button color="danger" onClick={() => setIsConfirmOpen(true)}>Delete Item</Button>
//     <ConfirmDialog
//       isOpen={isConfirmOpen}
//       toggle={() => setIsConfirmOpen(!isConfirmOpen)}
//       onConfirm={handleDelete}
//       message="Are you sure you want to delete this item?"
//     />
//   </div>
// );
