import { useContext } from 'react';
import { AppContext } from './states';

export function useEventState() {
  const { state, dispatch } = useContext(AppContext);
  const currentEventData = state.eventState;

  const setEventData = data => {
    sessionStorage.setItem('eventData', JSON.stringify(data));
    dispatch({ type: 'eventState', payload: data });
  };
  const getEventData = id => {
    const storedData = sessionStorage.getItem('eventData');
    const eventData = storedData ? JSON.parse(storedData) : currentEventData;
    if (eventData && eventData.length > 0) {
      return eventData.find(i => i.id === id) || null;
    } else {
      return null;
    }
  };

  return {
    eventData: currentEventData,
    setEventData,
    getEventData,
  };
}
