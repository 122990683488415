import { useContext } from 'react';
import { AppContext } from './states';

export function useCourseState() {
  const { state, dispatch } = useContext(AppContext);
  const currentCourseData = state.courseState;
  const setcourseData = data => {
    sessionStorage.setItem('courseData', JSON.stringify(data));
    dispatch({ type: 'courseState', payload: data });
  };
  const getcourseData = id => {
    const storedData = sessionStorage.getItem('courseData');
    const courseData = storedData ? JSON.parse(storedData) : currentCourseData;
    if (id) {
      return courseData && courseData.length > 0
        ? courseData.find(i => i.id === id) || null
        : null;
    } else {
      return courseData;
    }
  };
  return {
    courseData: currentCourseData,
    setcourseData,
    getcourseData,
  };
}
