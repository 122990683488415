import React, { useState, useEffect } from "react";
import { Table, Form, FormGroup, Input, Modal, ModalBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import RollsTopBar from "../../component/RollsTopBar";
import Apis from "../../utils/api";
import moment from "moment";
import { useRollsState } from "../services/rollsState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faEllipsisVertical,faTrash,faPen } from "@fortawesome/free-solid-svg-icons";
import LoaderModal from "../../component/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import ConfirmDialog from "../../component/common/ConfirmDialog";
const RollsList = () => {
  const api = new Apis();
  const { setRollsListData } = useRollsState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rollsList, setRolls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const [clickedRow, setClickedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (rowIndex) => {
    setClickedRow(rowIndex);
    setIsModalOpen(true);
  };

  const closeVideoModal = () => {
    setClickedRow(null);
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchRolls = async () => {
      try {
        const rolls = await api.getRollsLst();
        setRollsListData(rolls);
        rolls.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
        setRolls(rolls);

        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching RollSList:", error);
      }
    };
    fetchRolls();
  }, []);
  const [state,setState]=useState(true);
  const filteredRolls = rollsList.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const uploaderNameLower = (item.uploaderName || "").toLowerCase();
    const captionNameLower = (item.description || "").toLowerCase();
    const tagNameLower = (item.tag || "").toLowerCase();
    const idLower = (item.id || "").toLowerCase();
    return (
      (!state || item.status === "ACTIVE") && 
      (uploaderNameLower.includes(searchQueryLower) ||
      idLower.includes(searchQueryLower)||captionNameLower.includes(searchQueryLower)||tagNameLower.includes(searchQueryLower))
    );
  });  
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRolls.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(rollsList.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const customTableHeadings = ["checkbox", "ID", "Uploaded By", "Caption","Created", "Views", "Likes", "Shares", "Active", "Rolls","Thumbnail","Actions"];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => filteredRolls[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleEdit = (item) => {
    navigate(`/RollsList/EditRoll/${item.id}/${item.createdBy}`);
  };
  const refreshRollsData = async () => {
    try {
      const rolls = await api.getRollsLst();
      setRollsListData(rolls);
      rolls.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
      setRolls(rolls);

      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching RollSList:", error);
    }
  };
  const handleDelete = async (item, rowIndex) => {
    try {
      setIsLoading(true);
      const response = await api.deleteRolls(item.id);
      toast.success("Rolls deleted successfully!");
      setIsLoading(false);
      const originalIndex = indexOfFirstItem + rowIndex;
      console.log('Deleting index:', originalIndex);  
      setRolls((prevRolls) => {
        const updatedRolls = [...prevRolls];
        updatedRolls.splice(originalIndex, 1);
        return updatedRolls;
      });
      setSelectedRows([]);
      window.location.reload();
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Rolls:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const handleToggleChange = async (event) => {
    const { value: itemId, checked } = event.target;
    try {
      // Call your API with the new status
      const newStatus = checked ? 'ACTIVE' : 'Inactive';
      setIsLoading(true)
      await api.rollStatus(itemId, newStatus);
      toast.success("Roll Status Changed Succesfully")
      setIsLoading(false)
      // Update the local state if the API call is successful
      setRolls((prevRolls) =>
        prevRolls.map((roll) =>
          roll.id === itemId ? { ...roll, status: newStatus } : roll
        )
      );
      window.location.reload();
    } catch (error) {
      // Handle API call errors
      console.error("Error updating status:", error);
    }
  };
  const [selectedrowIndex,setRowIndex]=useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); 
  };
  return (
    <>
     <ToastContainer position="top-right" autoClose={5000} />
    {isLoading ? (
      <LoaderModal isOpen={isLoading} toggle={() => setIsLoading(false)} />
    ) : (
    <>
      <RollsTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV}  refreshRollsData={refreshRollsData} />
      <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
      <Table hover style={{ tableLayout: "auto" }}>
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <tr>
            {customTableHeadings.map((heading, index) => (
              <th key={index} style={{ whiteSpace: "nowrap" }}>
                {heading === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                  />
                ) : (
                  heading
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {customTableHeadings.map((heading, colIndex) => (
                <td key={colIndex}>
                  {heading === "Actions" ? (
                    <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                      <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                      <Button style={{ background: "none", border: "none" }}>
                        <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu right>
                      <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                        <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : heading === "ID" ? (
                    <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                      {item.id.slice(0, 5)}
                      {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                    </span>
                  ) : heading === "checkbox" ? (
                    <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                  ) : heading === "Views" ? (
                    item.viewCount
                  ) : heading === "Likes" ? (
                    item.likedCount
                  ) : heading === "Uploaded By" ? (
                    <a style={{ cursor: "pointer" }} onClick={() => navigate(`/RollsList/EditRoll/${item.id}/${item.createdBy}`)}>
                      {item.uploaderName}
                    </a>
                  ) : heading === "Shares" ? (
                    item.sharedCount
                    ) : heading === "Created" ? (
                      new Date(item.createdAt).toLocaleString()
                  ) : heading === "Rolls" ? (
                    // Show a button to open the video in a modal
                    // <button onClick={() => handleRowClick(rowIndex)}>
                    //  i
                    // </button>
                    <Button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => handleRowClick(rowIndex)}>
                      <FontAwesomeIcon color="grey" fontSize={20} icon={faCircleInfo} />
                    </Button>
                  ) : heading === "Thumbnail" ? (
                    <img src={item.coverImage} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                  ) : heading === "Caption" ? (
                    item.description
                  ): heading === "Active" ? (
                    <Form>
                      <FormGroup switch>
                        <Input
                          type="switch"
                          role="switch"
                          checked={item.status==="ACTIVE"}
                          value={item.id}
                          onChange={handleToggleChange}
                        />
                      </FormGroup>
                    </Form>
                  ) : (
                    item[heading]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <Modal isOpen={isModalOpen} toggle={closeVideoModal}>
          <ModalBody>
            <video src={clickedRow !== null ? currentItems[clickedRow].location : ""} controls autoPlay style={{ width: "100%", height: "60%" }} />
          </ModalBody>
        </Modal>
      </Table>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        toggle={() => setIsConfirmOpen(!isConfirmOpen)}
        onConfirm={() => handleDelete(itemId,selectedrowIndex)}
        message="Are you sure you want to remove the listing?"
      />
    </>
    )}
    </>
  );
};

export default RollsList;
