import { useContext } from 'react';
import { AppContext } from './states';

export function usePetparentState() {
  const { state, dispatch } = useContext(AppContext);
  const currentPetparentData = state.petparentState;
  const setPetparentData = data => {
    sessionStorage.setItem('petparentData', JSON.stringify(data));
    dispatch({ type: 'petparentState', payload: data });
  };
  const getPetparentData = id => {
    const storedData = sessionStorage.getItem('petparentData');
    const petparentData = storedData ? JSON.parse(storedData) : currentPetparentData;
    if (id) {
      return petparentData && petparentData.length > 0
        ? petparentData.find(i => i.id === id) || null
        : null;
    } else {
      return petparentData;
    }
  };
  const getPetParentDatabyId = id => {
    const storedData = sessionStorage.getItem('petparentData');
    const petparentData = storedData ? JSON.parse(storedData) : currentPetparentData;
    return petparentData && petparentData.length > 0
      ? petparentData.find(i => i.id === id) || null
      : null;
  };
  return {
    PetParentData: currentPetparentData,
    setPetparentData,
    getPetparentData,
    getPetParentDatabyId,
  };
}
