import { useContext } from 'react';
import { AppContext } from './states';

export function useAppointment() {
  const { state, dispatch } = useContext(AppContext);
  const currentAppointmentData = state.appointmentState;
  const setappointmentData  = data => {
    sessionStorage.setItem('appointmentData', JSON.stringify(data));
    dispatch({ type: 'appointmentState', payload: data });
  };
  const getappointmentData  = id => {
    const storedData = sessionStorage.getItem('appointmentData');
    const appointmentData = storedData ? JSON.parse(storedData) : currentAppointmentData;
    if (id) {
      return appointmentData && appointmentData.length > 0
        ? appointmentData.find(i => i.id === id) || null
        : null;
    } else {
      return appointmentData;
    }
  };
  return {
    appointmentData: currentAppointmentData,
    setappointmentData ,
    getappointmentData ,
  };
}
