import React, { useRef, useEffect } from 'react';
import '../component/css/CustomScroll.css'; 

const CustomScroll = ({ children }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    const handleWheel = (e) => {
      const deltaY = e.deltaY;
      contentElement.scrollTop += deltaY;
    };
    contentElement.addEventListener('wheel', handleWheel);
    return () => {
      contentElement.removeEventListener('wheel', handleWheel);
    };
  }, []);
  return (
    <div className="custom-scroll" ref={contentRef}>
      {children}
    </div>
  );
};

export default CustomScroll;
