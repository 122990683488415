import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CardGroup } from "react-bootstrap";
import SelectableCard from "../component/common/SelectedCard";
import { usePetparentState } from "./services/petparentState";
import Apis from "../utils/api";
import { useServiceList } from "./services/serviceListState";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import LoaderModal from "../component/common/Loader";
import { Badge } from "react-bootstrap";
const AddAppointment = () => {
  const { getServiceListData } = useServiceList();
  const api = new Apis();
  const { getPetparentData } = usePetparentState();
  const [petParent, setPetParentList] = useState();
  const [selectedCardDataArray, setSelectedCardDataArray] = useState([]);
  useEffect(() => {
    const fetchPetParents = async () => {
      try {
        const fetchedPetParentList = await api.getPetParentList();
        fetchedPetParentList.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
        setPetParentList(fetchedPetParentList);
      } catch (error) {
        console.error("Error fetching petParents:", error);
      }
    };

    fetchPetParents();
  }, []);
  const [selectedService, setSelectedService] = useState("Veterinary");
  const [WorkspacelistId, setWorkspacelistId] = useState(null);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [workspaceServices, setWorkspaceServices] = useState([]);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState("Online");
  const [petListing, setPetListing] = useState([]);
  const [numberofPets, setNumberofPets] = useState([]);
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");
  const [selectedName, setSelectedName] = useState();
  const [startDateTime, setStartDateTime] = useState("");
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix();
    setStartDateTime(unixTimestamp);
  };
  const [endDateTime, setEndDateTime] = useState("");
  const handleEndDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix();
    setEndDateTime(unixTimestamp);
  };
  const [selectedPetParentId, setSelectedPetParentId] = useState(null);
  const [selectedPetParent, setSelectedPetParent] = useState(null);

  useEffect(() => {
    const fetchPetsForSelectedParent = async () => {
      if (selectedPetParentId) {
        try {
          const pets = await api.getPets(selectedPetParentId);
          const selectedPetParent = petParent.find((parent) => parent.id === selectedPetParentId);
          setSelectedPetParent(selectedPetParent);
          setNumberofPets(pets);
          console.log(pets);
        } catch (error) {
          console.error("Error fetching pets:", error);
        }
      } else {
        setNumberofPets([]);
        setSelectedPetParent(null); // Clear the selected pet parent
      }
    };

    fetchPetsForSelectedParent();
  }, [selectedPetParentId, petParent]);
  console.log("PetParent", petParent);
  const [serviceIdd, setServiceId] = useState();
  console.log("Grooming", serviceIdd);
  useEffect(() => {
    // Check if selectedService is "Veterinary" or "Groomers" and set values accordingly
    if (selectedService === "Veterinary") {
      setServiceId("d8d71038-c48c-11ed-abc1-02817ef0919c");
      setWorkspacelistId("d8d71038-c48c-11ed-abc1-02817ef0919c");
    } else if (selectedService === "Groomers") {
      setServiceId("5e4c6b60-c48e-11ed-abc1-02817ef0919c");
      setWorkspacelistId("5e4c6b60-c48e-11ed-abc1-02817ef0919c");
    }
  }, [selectedService]);
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceData = await api.getWorkspace(WorkspacelistId);
        const activeWorkspaceData = workspaceData.filter((item) => item.active === true);
        setSelectedWorkspacDatas(activeWorkspaceData);
        const combinedNames = activeWorkspaceData.map((item) => `${item.designationName} - ${item.workplaceName}`);
        const designationServices = activeWorkspaceData.map((item) => item.services);
        setWorkspaceOptions(combinedNames);
        setWorkspaceServices(designationServices);
        console.log("WorkspaceData", activeWorkspaceData);
        if (combinedNames.length > 0) {
          setSelectedWorkspaceName(combinedNames[0]);
          const firstWorkspace = activeWorkspaceData[0];
          setSelectedWorkspaceServices(firstWorkspace.services);
          setSelectedWorkplaceNames(firstWorkspace.workplaceName);
          setSelectedWorkplaceId(firstWorkspace.id);
          setSelectedWorkspaceTime(firstWorkspace.workplaceTime);
          setSeletedCollabId(firstWorkspace.collaboratorId);
        }
      } catch (error) {
        console.error("Error fetching pets:", error);
      }
    };
    fetchWorkspace();
  }, [WorkspacelistId]);
  const [selectedPetNames, setPetNames] = useState(null);
  useEffect(() => {
    setPetListing(numberofPets.map((pet) => pet.petName));
    setPetNames(numberofPets);
  }, [numberofPets]);
  // useEffect(() => {
  //   if (petListing.length > 0 && selectedPetNames) {
  //     const selectedPet = numberofPets.find(
  //       (pet) => pet.petName === selectedPetNames.name
  //     );
  //   }
  // }, [petListing, selectedPetNames, numberofPets]);
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [selectedWorkspaceServices, setSelectedWorkspaceServices] = useState([]);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [selectedWorkplaceNames, setSelectedWorkplaceNames] = useState("");
  const [selectedWorkspaceId, setSelectedWorkplaceId] = useState("");
  const [selectedCollabId, setSeletedCollabId] = useState("");
  const [selectedWorkspaceTime, setSelectedWorkspaceTime] = useState("");
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    try {
      // Check if workspaceDatas is populated
      if (workspaceDatas.length > 0) {
        const selectedWorkspace = workspaceDatas.find((workspace) => `${workspace.designationName} - ${workspace.workplaceName}` === event.target.value);
        if (selectedWorkspace) {
          setSelectedWorkspaceServices(selectedWorkspace.services);
          setSelectedWorkplaceNames(selectedWorkspace.workplaceName);
          setSelectedWorkplaceId(selectedWorkspace.id);
          setSelectedWorkspaceTime(selectedWorkspace.workplaceTime);
          setSeletedCollabId(selectedWorkspace.collaboratorId);
        } else {
          setSelectedWorkspaceServices([]);
          setSelectedWorkplaceNames("");
        }
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };
  console.log(workspaceDatas);
  // const [selectedCardData, setSelectedCardData] = useState([]);
  // const handleCardSelect = (cardData, index) => {
  //   console.log("cardData", cardData);
  //   const cardIndex = selectedCardDataArray.findIndex((card) => card.name === cardData.name);
  //   if (cardIndex > -1) {
  //     // remove
  //   } else {
  //     // is groomer
  //     // if yes then add
  //     // if no
  //     // selectedCardData []
  //     // selectedCardData.push(cardData)
  //   }
  //   setSelectedCardIndex((prevIndex) => {
  //     if (prevIndex === index) {
  //       setSelectedCardData(null);
  //       return null;
  //     } else {
  //       setSelectedCardData(cardData);
  //       const cardIndex = selectedCardDataArray.findIndex((card) => card.name === cardData.name);
  //       if (cardIndex !== -1) {
  //         const updatedSelectedCardDataArray = [...selectedCardDataArray];
  //         updatedSelectedCardDataArray.splice(cardIndex, 1);
  //         setSelectedCardDataArray(updatedSelectedCardDataArray);
  //       } else {
  //         setSelectedCardDataArray([...selectedCardDataArray, cardData]);
  //       }
  //       return index;
  //     }
  //   });
  // };
  const navigate = useNavigate();
  // console.log(selectedPetParentId) this is petParentid
  //  console.log(WorkspacelistId.id) this is service id
  // console.log("Workspaceid",selectedWorkspaceId); this is WorkspaceId
  // console.log("CollabID",selectedCollabId) this is collab id
  // console.log(selectedPetId); this is pet id
  const [loading, setLoading] = useState(false);
  // const bookedServices = selectedCardData
  //   ? [
  //       {
  //         convenienceFee: parseFloat(selectedCardData.convenienceFee),
  //         description: selectedCardData.description,
  //         gstPrice: parseFloat(selectedCardData.gstPrice),
  //         name: selectedCardData.name,
  //         rate: parseFloat(selectedCardData.rate),
  //         totalCost: parseFloat(selectedCardData.rate) + parseFloat(selectedCardData.convenienceFee),
  //         isHomeVisit: selectedCardData.isHomeVisit,
  //         visit: selectedCardData.visit,
  //       },
  //     ]
  //   : [];
  // booking [] <-- push
  // console.log(bookedServices);
  const addAppointment = async () => {
    try {
      const requestBody = {
        amount: 1180,
        bookedBy: selectedPetParentId,
        bookedServices: selectedCardServices,
        currency: "INR",
        endTime: endTimeMilli,
        petId: selectedPetId,
        serviceId: serviceIdd,
        startTime: startTimeMilli,
        subType: "Hair Cut, Nail Cut",
        type: "OFFLINE",
        workspaceId: selectedWorkspaceId,
        status: "Created",
        address:
          selectedCardServices.some((service) => service.name === "Home Visit") || selectedCardServices.some((service) => service.isHomeVisit === true)
            ? addressSelected.id || null
            : null,
      };
      setLoading(true);
      const response = await api.addAppointment(selectedWorkspaceId, selectedCollabId, requestBody);
      setLoading(false);
      toast.success("Appointment Added Successfully");
      navigate(-1);
      console.log("API response:", response);
    } catch (error) {
      if (error.message === "Request failed with status code 500") {
        toast.error("Error: Phone number or email is duplicate");
      } else {
        toast.error("Error: " + error.message);
      }
      console.error("Error adding collaborator:", error);
    }
  };
  console.log("selectedWorkpsaceTime", selectedWorkspaceTime);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  useEffect(() => {
    const generateTimeSlots = () => {
      if (selectedWorkspaceTime && selectedWorkspaceTime.start_time && selectedWorkspaceTime.end_time && selectedWorkspaceTime.lunch_break) {
        const startTimeMoment = moment(selectedWorkspaceTime.start_time, "hh:mm a");
        const endTimeMoment = moment(selectedWorkspaceTime.end_time, "hh:mm a");
        const lunchBreakStartMoment = moment(selectedWorkspaceTime.lunch_break.start_time, "hh:mm a");
        const lunchBreakEndMoment = moment(selectedWorkspaceTime.lunch_break.end_time, "hh:mm a");
        const timeSlots = [];
        let currentSlot = startTimeMoment.clone();
        const currentDate = moment().format("YYYY-MM-DD");
        while (currentSlot.isBefore(endTimeMoment)) {
          if (
            currentSlot.isBefore(lunchBreakStartMoment) ||
            (currentSlot.isSameOrAfter(lunchBreakEndMoment) && moment(`${currentDate} ${currentSlot.format("HH:mm")}`, "YYYY-MM-DD HH:mm").isAfter(moment()))
          ) {
            timeSlots.push({
              label: currentSlot.format("hh:mm A"),
              value: currentSlot.format("HH:mm"),
            });
          }
          currentSlot.add(30, "minutes");
        }
        setSlots(timeSlots);
      }
    };
    generateTimeSlots();
  }, [selectedWorkspaceTime]);
  const [selectedDaySlot, setSelectedDaySlot] = useState(null);
  const [startTimeMilli, setStartTimeMilli] = useState();
  const [endTimeMilli, setEndTimeMilli] = useState(null);
  useEffect(() => {
    if (selectedDaySlot) {
      const combinedDateTime = moment(`${selectedDaySlot.date} ${selectedDaySlot.time}`, "YYYY-MM-DD HH:mm");
      const endTime = combinedDateTime.clone().add(30, "minutes");
      const combinedMillis = combinedDateTime.valueOf();
      const endMillis = endTime.valueOf();
      console.log("Combined and converted to milliseconds:", combinedMillis);
      console.log("End time and converted to milliseconds:", endMillis);
      setStartTimeMilli(combinedMillis);
      setEndTimeMilli(endMillis);
    }
  }, [selectedDaySlot]);
  const nextDays = Array.from({ length: 5 }, (_, index) => moment().add(index, "days").format("YYYY-MM-DD"));
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [petParentAddress, setPetParentAddress] = useState([]);
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const address = await api.getParentAddress(selectedPetParentId);
        setPetParentAddress(address);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchAddress();
  }, [selectedPetParentId]);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [type, setType] = useState();
  const [flatNo, setFlatNo] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [latLng, setLatLng] = useState("0.0, 0.0");
  const addAddress = async () => {
    try {
      const requestBody = {
        userId: selectedPetParentId,
        type: type,
        flatNo: flatNo,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
        address: address,
        pincode: pincode,
        city: city,
        state: "MH",
        default: false,
      };
      setLoading(true);
      const response = await api.addAppointmentAddress(selectedPetParentId, requestBody);
      setLoading(false);
      console.log("Id Response", response);
      const newAddressId = response.id;
      const newAddress = {
        id: newAddressId,
        type,
        flatNo,
        address,
        pincode,
        city,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
      };
      toast.success("Address added successfully!");
      console.log(response);
      setPetParentAddress([...petParentAddress, newAddress]);
      toggleModal();
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address: " + error.message);
      setLoading(false);
      toggleModal();
    }
  };
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const selectAddress = (index) => {
    setSelectedAddress(index);
    console.log("Selected Address:", petParentAddress[index]);
    setAddressSelected(petParentAddress[index]);
  };
  console.log("Address", addressSelected);
  console.log("Pet Parent Address", petParentAddress);
  const [selectedCardServices, setSelectedCardServices] = useState([]);
  const handleServiceSelection = (service) => {
    if (selectedService === "Veterinary") {
      setSelectedCardServices([service]);
    } else {
      if (service.isHomeVisit) {
        setSelectedCardServices((prevSelectedServices) => prevSelectedServices.filter((selected) => selected.isHomeVisit));
      } else {
        setSelectedCardServices((prevSelectedServices) => prevSelectedServices.filter((selected) => !selected.isHomeVisit));
      }
      setSelectedCardServices((prevSelectedServices) => [...prevSelectedServices, service]);
    }
  };
  useEffect(() => {
    setSelectedCardServices([]);
  }, [selectedWorkspaceServices]);
  useEffect(() => {
    console.log(selectedCardServices);
  }, [selectedCardServices]);
  const [editedAddressess, setEditedAddress] = useState("");
  const [editAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [editedType, setEditedType] = useState("");
  const [editedFlatNo, setEditedFlatNo] = useState("");
  const [editedPincode, setEditedPincode] = useState("");
  const [editedCity, setEditedCity] = useState("");
  const [editedLatLng, setEditedLatLng] = useState("");
  const [editedAddressId, setEditedAddressId] = useState("");
  const openEditAddressModal = (address) => {
    setEditedType(address.type || "");
    setEditedFlatNo(address.flatNo || "");
    setEditedAddress(address.address || "");
    setEditedPincode(address.pincode || "");
    setEditedCity(address.city || "");
    setEditedLatLng(`${address.latitude || ""}, ${address.longitude || ""}`);
    setEditedAddressId(address.id || "");
    // Set editedLatLng
    setEditAddressModalOpen(true); // Open the modal
  };

  const closeEditAddressModal = () => {
    setEditAddressModalOpen(false);
  };

  const handleEditAddressSubmit = async () => {
    try {
      const requestBody = {
        id: editedAddressId,
        userId: selectedPetParentId,
        type: editedType,
        flatNo: editedFlatNo,
        latitude: editedLatLng.split(",")[0].trim(),
        longitude: editedLatLng.split(",")[1].trim(),
        address: editedAddressess,
        pincode: editedPincode,
        city: editedCity,
        state: "MH",
        default: false,
      };
      const editedAddress = {
        type: editedType,
        flatNo: editedFlatNo,
        address: editedAddressess,
        pincode: editedPincode,
        city: editedCity,
        latitude: editedLatLng.split(",")[0].trim(),
        longitude: editedLatLng.split(",")[1].trim(),
      };
      console.log("Edited Address:", editedAddress);
      setLoading(true);
      const response = await api.EditAppointmentAddress(selectedPetParentId, editedAddressId, requestBody);
      setLoading(false);
      const updatedAddresses = petParentAddress.map((address, index) => (index === selectedAddress ? editedAddress : address));
      setPetParentAddress(updatedAddresses);
      toast.success("Address Edited successfully!");
      console.log(response);
      closeEditAddressModal();
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address: " + error.message);
      setLoading(false);
      closeEditAddressModal();
    }
  };
  const [bookedSlot, setbookedSlot] = useState([]);
  useEffect(() => {
    const fetchBookedSlot = async () => {
      try {
        const bookedSlots = await api.getBookedSlot(selectedWorkspaceId, selectedPetParentId);
        setbookedSlot(bookedSlots);
        console.log("BookedSlot", bookedSlots);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchBookedSlot();
  }, [selectedWorkspaceId, selectedPetParentId]);
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <form className="modalForm">
                    <span className="form-group" style={{ fontSize: 20 }}>
                      <strong>Add Appointment</strong>
                    </span>
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Select Service</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="service"
                            id="Veterinary"
                            value="Veterinary"
                            checked={selectedService === "Veterinary"}
                            onChange={() => setSelectedService("Veterinary")}
                          />
                          <label className="form-check-label" htmlFor="Veterinary">
                            Veterinary
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="service"
                            id="Groomers"
                            value="Groomers"
                            checked={selectedService === "Groomers"}
                            onChange={() => setSelectedService("Groomers")}
                          />
                          <label className="form-check-label" htmlFor="Groomers">
                            Groomer
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="inputPetsSpecies">Select Collaborator*</label>
                      {workspaceOptions.length > 0 && (
                        <select id="inputPetsSpecies" className="form-control" onChange={handleWorkspaceChange} value={selectedWorkspaceName}>
                          {workspaceOptions.map((workspace, index) => (
                            <option key={index} value={workspace}>
                              {workspace}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    {/* <div className="form-group mt-2">
                      <label htmlFor="inputPetsSpecies">WorkPlace Name*</label>
                      <select id="inputPetsSpecies" className="form-control">
                        <option value="" selected>
                          {selectedWorkplaceNames}
                        </option>
                      </select>
                    </div> */}
                    <div className="form-group mt-2">
                      <label htmlFor="inputPetParent">Select Pet Parent*</label>
                      <select id="inputPetParent" className="form-control" onChange={(e) => setSelectedPetParentId(e.target.value)}>
                        <option value="" selected>
                          Select a Pet Parent
                        </option>
                        {petParent &&
                          petParent.map((parent, index) => (
                            <option key={index} value={parent.id}>
                              {parent.mobile} - {parent.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="inputPetName">Pet Parent Name*</label>
                      {/* {contact && contact.mobile ? contact.mobile : "null"} */}
                      <input
                        id="myInput"
                        type="text"
                        name="myCountry"
                        value={selectedPetParent && selectedPetParent.name ? selectedPetParent.name : "null"}
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="inputPetName">Select Pet Name*</label>
                      <select
                        id="inputPetName"
                        className="form-control"
                        onChange={(e) => {
                          // Get the selected pet name
                          const selectedPetName = e.target.value;
                          // Find the corresponding pet object by name
                          const selectedPet = selectedPetNames.find((pet) => pet.petName === selectedPetName);
                          // If the pet is found, log its ID
                          if (selectedPet) {
                            setSelectedPetId(selectedPet.id);
                          }
                        }}
                      >
                        <option selected>Choose a Pet</option>
                        {petListing.map((pet, index) => (
                          <option key={index} value={pet}>
                            {pet}
                          </option>
                        ))}
                      </select>
                    </div>
                    <h5 className=" form-group mt-2">Schedule Details</h5>
                    <label className="radioBtnText form-group">Appointment Type*</label>
                    <div className="col-md-12 form-group">
                      <div className="col-md-12" style={{ marginBottom: 20 }}>
                        {selectedService === "Groomers"
                          ? selectedWorkspaceServices.map((service, index) => (
                              <div className="mt-3" key={index}>
                                <CardGroup
                                  className="p-3"
                                  style={{
                                    border: "1px solid #beb7b7",
                                    borderRadius: 10,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      id={service.name}
                                      name={service.name}
                                      value={service.name}
                                      onChange={() => handleServiceSelection(service)}
                                      checked={selectedCardServices.some((selected) => {
                                        console.log("Selected service name:", selected.name);
                                        console.log("Iterated service name:", service.name);
                                        return selected.name === service.name;
                                      })}
                                    />
                                    <div>
                                      <div style={{ flexDirection: "column" }}>
                                        <div>
                                          {service.name} <span>({service.isHomeVisit === true ? "Home visit" : "Center"})</span>
                                        </div>
                                      </div>
                                      <div>Service Cost</div>
                                      <div>Convenience Fee</div>
                                      <div>In-Hand Amount</div>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    ></div>
                                    <br></br>
                                    <div>₹{service.rate}</div>
                                    <div>{service.convenienceFee}</div>
                                    <div>₹{service.inHandAmount}</div>
                                  </div>
                                </CardGroup>
                              </div>
                            ))
                          : selectedService === "Veterinary"
                          ? selectedWorkspaceServices.map((service, index) => (
                              <div className="mt-3" key={index}>
                                <CardGroup
                                  className="p-3"
                                  style={{
                                    border: "1px solid #beb7b7",
                                    borderRadius: 10,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <input
                                      type="radio"
                                      id={service.name}
                                      name="appointmentType"
                                      value={service.name}
                                      onChange={() => handleServiceSelection(service)}
                                    />
                                    <div>
                                      <div style={{ flexDirection: "column" }}>
                                        <div>{service.name}</div>
                                      </div>
                                      <div>Service Cost</div>
                                      <div>Convenience Fee</div>
                                      <div>In-Hand Amount</div>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    ></div>
                                    <br></br>
                                    <div>₹{service.rate}</div>
                                    <div>₹{service.convenienceFee}</div>
                                    <div>₹{((parseFloat(service.rate) - parseFloat(service.convenienceFee))).toFixed(0)}
</div>
                                  </div>
                                </CardGroup>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    {(selectedCardServices.some((service) => service.name === "Home Visit") ||
                      selectedCardServices.some((service) => service.isHomeVisit === true)) && (
                      <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <h5 className="form-group mt-2">Select Address</h5>
                          <Button style={{ marginRight: 10 }} color="primary" onClick={toggleModal}>
                            Add
                          </Button>
                        </div>
                        {petParentAddress.map((service, index) => (
                          <div className="form-group mt-3" key={index}>
                            <CardGroup
                              className="p-3"
                              style={{
                                border: selectedAddress === index ? "2px solid blue" : "1px solid #beb7b7",
                                borderRadius: 10,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              onClick={() => selectAddress(index)}
                            >
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                  <div style={{ flexDirection: "column" }}>
                                    <div>{service.type}</div>
                                  </div>
                                  <div>{service.flatNo}</div>
                                  <div>{service.address}</div>
                                  <div>
                                    {service.city},{service.pincode}
                                  </div>
                                  <div>
                                    {service.latitude},{service.longitude}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <Button onClick={() => openEditAddressModal(service)}>Edit</Button>
                              </div>
                            </CardGroup>
                          </div>
                        ))}
                      </div>
                    )}

                    <Modal isOpen={editAddressModalOpen} toggle={closeEditAddressModal}>
                      <ModalHeader toggle={closeEditAddressModal}>Edit Address</ModalHeader>
                      <ModalBody>
                        <div className="form-group">
                          <label htmlFor="type">Type:</label>
                          <div className="form-check form-check-inline mx-3">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Home"
                              value="Home"
                              checked={editedType === "Home"}
                              onChange={() => setEditedType("Home")}
                            />
                            <label className="form-check-label" htmlFor="Home">
                              Home
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Work"
                              value="Work"
                              checked={editedType === "Work"}
                              onChange={() => setEditedType("Work")}
                            />
                            <label className="form-check-label" htmlFor="Work">
                              Work
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Hotel"
                              value="Hotel"
                              checked={editedType === "Hotel"}
                              onChange={() => setEditedType("Hotel")}
                            />
                            <label className="form-check-label" htmlFor="Hotel">
                              Hotel
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Other"
                              value="Other"
                              checked={editedType === "Other"}
                              onChange={() => setEditedType("Other")}
                            />
                            <label className="form-check-label" htmlFor="Other">
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="flatNo">Flat No:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="flatNo"
                            value={editedFlatNo}
                            onChange={(e) => setEditedFlatNo(e.target.value)}
                            placeholder="Enter Flat No."
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address">Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={editedAddressess}
                            onChange={(e) => setEditedAddress(e.target.value)}
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            value={editedPincode}
                            onChange={(e) => setEditedPincode(e.target.value)}
                            placeholder="Enter Pincode"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="city">City:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            value={editedCity}
                            onChange={(e) => setEditedCity(e.target.value)}
                            placeholder="Enter City"
                          />
                        </div>
                        <div className="form-group">
                          <label>Location*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Latitude, Longitude"
                            value={editedLatLng}
                            onChange={(e) => setEditedLatLng(e.target.value)}
                          />
                        </div>
                      </ModalBody>

                      <ModalFooter>
                        <Button color="primary" onClick={handleEditAddressSubmit}>
                          Save Changes
                        </Button>
                        <Button color="secondary" onClick={closeEditAddressModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                      <ModalHeader toggle={toggleModal}>Add Address</ModalHeader>
                      <ModalBody>
                        <div className="form-group">
                          <label htmlFor="flatNo">Type:</label>
                          <div className="form-check form-check-inline mx-3 ">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Home"
                              value="Home"
                              checked={type === "Home"}
                              onChange={() => setType("Home")}
                            />
                            <label className="form-check-label" htmlFor="Home">
                              Home
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Work"
                              value="Work"
                              checked={type === "Work"}
                              onChange={() => setType("Work")}
                            />
                            <label className="form-check-label" htmlFor="Work">
                              Work
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Hotel"
                              value="Hotel"
                              checked={type === "Hotel"}
                              onChange={() => setType("Hotel")}
                            />
                            <label className="form-check-label" htmlFor="Hotel">
                              Hotel
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Other"
                              value="Other"
                              checked={type === "Other"}
                              onChange={() => setType("Other")}
                            />
                            <label className="form-check-label" htmlFor="Other">
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="flatNo">Flat No:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="flatNo"
                            value={flatNo}
                            onChange={(e) => setFlatNo(e.target.value)}
                            placeholder="Enter Flat No."
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address">Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            placeholder="Enter Pincode"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="city">City:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="Enter City"
                          />
                        </div>
                        <div className="form-group">
                          <label>Location*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Latitude, Longitude"
                            value={latLng}
                            onChange={(e) => setLatLng(e.target.value)}
                          />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={addAddress}>
                          Save
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <h5 className=" form-group mt-2">Select Days</h5>
                    <div className="form-group mt-2">
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {/* Render badges for days */}
                        {selectedWorkspaceTime &&
                          selectedWorkspaceTime.days &&
                          selectedWorkspaceTime.days.length > 0 &&
                          nextDays.map((day, index) => {
                            const dayName = moment(day).format("ddd");
                            return (
                              selectedWorkspaceTime.days.includes(dayName) && (
                                <Badge
                                  key={index}
                                  style={{
                                    marginRight: 10,
                                    cursor: "pointer",
                                    fontSize: 15,
                                    color: selectedDaySlot && selectedDaySlot.date === day ? "black" : "white",
                                  }}
                                  onClick={() => setSelectedDaySlot({ date: day, time: selectedDaySlot ? selectedDaySlot.time : null })}
                                >
                                  {moment(day).format("DD")} {dayName}
                                </Badge>
                              )
                            );
                          })}
                      </div>
                    </div>
                    <h5 className="form-group mt-2">Select Slot</h5>
                    <div className="form-group mt-2">
                      <div style={{ display: "flex", flexWrap: "wrap", gap: 20, rowGap: 20 }}>
                        {slots.map((slot, index) => {
                          const slotDateTime = moment(selectedDaySlot ? selectedDaySlot.date : null).format("YYYY-MM-DD") + " " + slot.value;
                          const isBooked = bookedSlot.some((booked) => {
                            const bookedStartDateTime = moment(booked.startTime).format("YYYY-MM-DD HH:mm");
                            return bookedStartDateTime === slotDateTime;
                          });

                          return (
                            <Badge
                              key={index}
                              style={{
                                marginRight: 10,
                                cursor: "pointer",
                                fontSize: 15,
                                color: isBooked ? "grey" : selectedDaySlot && selectedDaySlot.time === slot.value ? "black" : "white",
                              }}
                              onClick={() => !isBooked && setSelectedDaySlot({ date: selectedDaySlot ? selectedDaySlot.date : null, time: slot.value })}
                            >
                              {slot.label}
                            </Badge>
                          );
                        })}
                      </div>
                    </div>
                  </form>
                  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    <Button style={{ marginRight: 10 }} color="primary" onClick={addAppointment}>
                      Add
                    </Button>
                    <Button onClick={() => navigate(-1)} color="secondary">
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddAppointment;
