import { useContext } from 'react';
import { AppContext } from './states';

export function useBlogData() {
  const { state, dispatch } = useContext(AppContext);
  const currentBlogData = state.blogState;
  const setblogData = data => {
    sessionStorage.setItem('blogData', JSON.stringify(data));
    dispatch({ type: 'blogState', payload: data });
  };
  const getblogData = id => {
    const storedData = sessionStorage.getItem('blogData');
    const blogData = storedData ? JSON.parse(storedData) : currentBlogData;
    if (id) {
      return blogData && blogData.length > 0
        ? blogData.find(i => i.id === id) || null
        : null;
    } else {
      return blogData;
    }
  };
  return {
    blogData: currentBlogData,
    setblogData ,
    getblogData,
  };
}
