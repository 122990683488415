import React, { useState, useEffect } from "react";
import {
  Table,
  Tooltip,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api";
import AppointmentTopBar from "../../component/AppointmentTopBar";
import { useAppointment } from "../services/appointmentmanagementState";
import ServiceSelect from "../../component/ServiceSelect";
import moment from "moment";
import LoaderModal from "../../component/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialog from "../../component/common/ConfirmDialog";
const data = [
  {
    "Pet Name": "Evana",
    "Collab Name": "Pune",
    "Service Type": "Grooming",
    Date: "15 Apr 2023",
    Amount: "2000",
    Status: "Completed",
  },
];
const AppointmentList = () => {
  const api = new Apis();
  const [loading, setLoading] = useState(false);
  const { setappointmentData } = useAppointment();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = ["checkbox", ...Object.keys(data[0])];
  const [appointmentList, setAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  useEffect(() => {
    const fetchappointments = async () => {
      try {
        const appointments = await api.getAppointmentList();
        setappointmentData(appointments);
        appointments.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
        setAppointments(appointments);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching appointmentList:", error);
      }
    };
    fetchappointments();
  }, []);
  const totalPages = Math.ceil(appointmentList.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const customTableHeadings = ["checkbox", "ID", "Designation Name", "Service Name", "City", "Type", "Workspace Name", "Appointment Date", "Status", "Actions"];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const [selectedServices, setSelectedServices] = useState("Veterinary");
  const handleServiceSelect = (service) => {
    setSelectedServices(service);
  };
  const filteredAppointments = appointmentList.filter((item) => {
    return item.serviceName === selectedServices;
  });
  const filteredPetParent = appointmentList.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const workspaceNameLower = (item.workspaceName || "").toLowerCase();
    const serviceNameLower = (item.serviceName || "").toLowerCase();
    const collaboratorNameLower = (item.collaboratorName || "").toLowerCase();
    const idLower = (item.id || "").toLowerCase();
    return (
      workspaceNameLower.includes(searchQueryLower) ||
      serviceNameLower.includes(searchQueryLower) ||
      collaboratorNameLower.includes(searchQueryLower) ||
      idLower.includes(searchQueryLower)
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPetParent.slice(indexOfFirstItem, indexOfLastItem);
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => filteredAppointments[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const [tooltipOpen, setTooltipOpen] = useState(Array(data.length).fill(false));
  const toggleTooltip = (rowIndex) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[rowIndex] = !newTooltipOpen[rowIndex];
    setTooltipOpen(newTooltipOpen);
  };
  const handleEdit = (item) => {
    navigate(`/AppointmentList/AppointmentDetails/${item.id}`);
  };
  const handleDelete = async (item, rowIndex) => {
    try {
      setLoading(true);
      const response = await api.deleteAppointment(item.id);
      setLoading(false);
      const originalIndex = indexOfFirstItem + rowIndex;
      setAppointments((prevWebinars) => {
        const updatedAppointments = [...prevWebinars];
        updatedAppointments.splice(originalIndex, 1);
        return updatedAppointments;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("appointmentList deleted successfully!");
      navigate("/AppointmentList");
      console.log(response);
    } catch (error) {
      console.error("Error deleting the appointmentList:", error);
    } finally {
      setLoading(false);
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  // Live date published date Created Date
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex, setRowIndex] = useState();
  const handleToggleChange = async (item) => {
    try {
      setLoading(true);
      item.status = "Cancelled";
      await webinarEdit(item);
      setAppointments((prevAppointments) => {
        const index = prevAppointments.findIndex((a) => a.id === item.id);
        const updatedAppointments = [...prevAppointments];
        updatedAppointments[index] = item;
        return updatedAppointments;
      });
      setLoading(false);
      // toast.success("Appointment Cancelled Successfully!");
    } catch (error) {
      console.error("Error updating appointment status:", error);
      toast.error("Error Cancelling Appointment!");
      setLoading(false);
    }
  };
  const webinarEdit = async (itemToUpdate) => {
    try {
      const requestBody = {
        id: itemToUpdate.id,
        bookedBy: itemToUpdate.bookedBy,
        workspaceId: itemToUpdate.workspaceId,
        startTime: new Date(itemToUpdate.startTime).getTime(),
        endTime: new Date(itemToUpdate.endTime).getTime(),
        isActive: itemToUpdate.isActive,
        petId: itemToUpdate.petId,
        paymentId: itemToUpdate.paymentId,
        type: itemToUpdate.type,
        subType: itemToUpdate.subType,
        bookedServices: itemToUpdate.bookedServices,
        couponCode: itemToUpdate.couponCode,
        address: itemToUpdate.address,
        status: itemToUpdate.status,
        appointmentDate: itemToUpdate.appointmentDate,
      };
      setLoading(true);
      const response = await api.updateAppointment(itemToUpdate.workspaceId, 1, requestBody);
      setLoading(false);
      toast.success("Appointment Status Changed!");
      console.log(response);
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.error("Appointment Status Cant be Changed!");
      setLoading(false);
    }
  };
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          {/* <ServiceSelect onSelectType={handleServiceSelect} /> */}
          <AppointmentTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} />
          <Table hover style={{ tableLayout: "auto" }}>
            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <tr>
                {customTableHeadings.map((heading, index) => (
                  <th key={index} style={{ whiteSpace: "nowrap" }}>
                    {heading === "checkbox" ? (
                      <input
                        type="checkbox"
                        checked={selectedRows.length === currentItems.length}
                        onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                      />
                    ) : (
                      heading
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {customTableHeadings.map((heading, colIndex) => (
                    <td key={colIndex}>
                      {heading === "Actions" ? (
                        <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                            {/* Three vertical dots icon */}
                            <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                            {(item.status === "Created" || item.status === "Active") && (
                              <DropdownItem style={{ backgroundColor: "red", color: "white" }} onClick={() => handleToggleChange(item)}>
                                Cancel Appointment
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      ) : heading === "ID" ? (
                        <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} id={`tooltip-${rowIndex}`}>
                          {item.id.slice(0, 8)}
                          {copiedItems[rowIndex] && (
                            <Tooltip placement="right" isOpen={tooltipOpen[rowIndex]} target={`tooltip-${rowIndex}`} toggle={() => toggleTooltip(rowIndex)}>
                              Copied
                            </Tooltip>
                          )}
                          <Tooltip placement="top" isOpen={tooltipOpen[rowIndex]} target={`tooltip-${rowIndex}`} toggle={() => toggleTooltip(rowIndex)}>
                            {item.id}
                          </Tooltip>
                        </span>
                      ) : heading === "checkbox" ? (
                        <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                      ) : heading === "Workspace Name" ? (
                        item.workspaceName
                      ) : heading === "Service Name" ? (
                        item.serviceName
                      ) : heading === "Designation Name" ? (
                        <a href style={{ cursor: "pointer" }} onClick={() => navigate(`/AppointmentList/AppointmentDetails/${item.id}`)}>
                          {item.designationName}
                        </a>
                      ) : heading === "Type" ? (
                        item.serviceName === "Grooming" ? (
                          JSON.parse(item.bookedServices)[0]?.isHomeVisit ? (
                            "Home visit"
                          ) : (
                            "Center"
                          )
                        ) : (
                          JSON.parse(item.bookedServices)[0]?.name || "N/A"
                        )
                      ) : heading === "City" ? (
                        item.city
                      ) : heading === "Created" ? (
                        item.joinedDate
                      ) : heading === "Status" ? (
                        item.status
                      ) : heading === "Appointment Date" ? (
                        <span>{moment.utc(item.appointmentDate).local().format("DD-MM-YYYY hh:mm A")}</span>
                      ) : (
                        item[heading]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30, 40, 50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleDelete(itemId, selectedrowIndex)}
            message="Are you sure you want to remove the listing?"
          />
        </>
      )}
    </>
  );
};

export default AppointmentList;
