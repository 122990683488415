import React, { useState, useEffect } from "react";
import { Table, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import Apis from "../utils/api";
import moment from "moment";
import dataURItoBlob, { isAlphaNumeric } from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { useWebinarState } from "./services/webinarState";
import { useNavigate } from "react-router-dom";
import LoaderModal from "../component/common/Loader";
import "react-toastify/dist/ReactToastify.css";
const data = [
  {
    "Roll ID": "#6B7280",
    Likes: "10000",
    Views: "1000",
    "User Type": "Groomer",
    "Workspace Name": "Kataria vet Clinic",
    Reports: "10000",
    "18+ Video": "Evana",
  },
];
const data2 = [
  {
    Name: "Abhay",
    Phone: "8007692186",
    "View Date": "22-10-2023",
    "View Count": 2,
    Status: "Completed",
    Paid: "250",
    Downloaded: "",
  },
];

const EditWebinar = () => {
  const api = new Apis();
  const { id } = useParams();
  const [min, setMin] = useState(20);
  const { getwebinarData } = useWebinarState();
  const [dataWeb] = useState(getwebinarData(id));
  const [webtype, setWebtype] = useState(dataWeb.paid ? "Paid" : "Free");
  const [type, setType] = useState(dataWeb.mode);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const [subscriberList, setsubsciberList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  useEffect(() => {
    const fetchSubscriber = async () => {
      try {
        const subscriber = await api.getPurchasedList(id);
        setsubsciberList(subscriber);
        setCurrentPage(1);
        console.log(subscriber);
      } catch (error) {
        console.error("Error fetching RollSList:", error);
      }
    };
    fetchSubscriber();
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subscriberList.slice(indexOfFirstItem, indexOfLastItem);
  const customTableHeadings = ["checkbox", "ID", "Name", "Mobile", "Email", "Purchased Date", "Payment Status"];
  const data4 = [
    {
      // Id: "#6B7280",
      Likes: dataWeb.likedCount,
      Views: dataWeb.viewedCount,
      "Share Count": dataWeb.sharedCount,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // const columns = ["checkbox", ...Object.keys(data[0])];
  const columns2 = ["checkbox", ...Object.keys(data2[0])];

  const columns4 = ["checkbox", ...Object.keys(data4[0])];
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState();
  const handleImgChange = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancelImage = () => {
    setSelectedImage(null);
  };
  const handleImgupload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleImgChange);
    fileInput.click();
  };

  const [webinarDateTime, setDateTime] = useState(moment(dataWeb.eventOn).format("YYYY-MM-DDTHH:mm"));
  const [webinarpublishDate, setpublishdate] = useState(moment(dataWeb.publishDate).format("YYYY-MM-DDTHH:mm"));
  const [newTimeUnix, setNewTimeUnix] = useState();
  const [newPublishTimeUnix, setNewPublishTimeUnix] = useState();
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };

  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoUrl(file);
      setIsVideoUploaded(true);
    }
  };

  const deleteVideo = () => {
    setVideoUrl("");
    setIsVideoUploaded(false);
  };
  const handlepublishDate = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000; // Convert to milliseconds
    setpublishdate(inputDate);
    setNewPublishTimeUnix(unixTimestamp);
  };

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000;
    setDateTime(inputDate);
    setNewTimeUnix(unixTimestamp);
  };
  const [header, setHeader] = useState(dataWeb.title || null);
  const [description, setDescription] = useState(dataWeb.description || null);
  const [price, setPrice] = useState(dataWeb.price || null);
  const [selectedAuthor, setSelectedAuthor] = useState(dataWeb.author || null);

  const handleAuthorChange = (e) => {
    setSelectedAuthor(e.target.value);
  };
  const handleHeaderChange = (e) => {
    setHeader(e.target.value);
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const [formattedDateTime, setFormattedDateTime] = useState("");
  // This function converts the Unix timestamp to the desired format
  const formatUnixTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().slice(0, 16);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const formatted = formatUnixTimestamp(dataWeb.eventOn);
    setFormattedDateTime(formatted);
  }, []);
  const handleType = (event) => {
    setType(event.target.value);
  };
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const EditWebinar = async () => {
    try {
      // Basic validation checks
      if (!selectedAuthor || !isAlphaNumeric(selectedAuthor)) {
        throw new Error("Author is a required field");
      }
      if (!header) {
        throw new Error("Header is a required field");
      }
      if (!description) {
        throw new Error("Description is a required field");
      }
      if (!webinarDateTime) {
        throw new Error("Live date and time required.");
      }
      // removed validation for video and image because added while creating webinar
      //todo uploaded video is not appearing @abhay take a look
      const requestBody = {
        id: id,
        title: header,
        description: description,
        status: dataWeb.status,
        published: true,
        mode: type,
        price: webtype === "Free" ? 0 : price,
        author: selectedAuthor,
        eduCategory: "Webinar",
        eventOn: newTimeUnix || dataWeb.eventOn,
        publishDate: dataWeb.publishDate,
        featured:featuredSwitchState,
        sponsored:switchState
      };
      if (webtype === "Paid") {
        requestBody.paid = true;
      } else {
        requestBody.paid = false;
      }
      setLoading(true);
      const response = await api.updateWebinar(requestBody);
      setLoading(false);
      toast.success("Webinar Edited successfully!");
      console.log(response);
      if (response.id) {
        console.log(response.id);
        await uploadImage(response.id);
        setLoading(false);
      } else {
      }
      if (response.id) {
        console.log(response.id);
        await rollUpload(response.id);
        setLoading(false);
      } else {
        setLoading(false);
      }
      // if (response.id) {
      //   console.log(response.id);
      //   await uploadImage(response.id);
      //   toast.success("User image added successfully!");
      // } else {
      //   throw new Error("Response ID is undefined.");
      // }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error("Error adding Webinar:", error);
    }
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(selectedImage), fileName);
      const response = await api.uploadWebinarPoster(userId, formData);
      console.log("Img", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const rollUpload = async (id) => {
    if (!videoUrl) {
      console.error("No Video Selected");
      // Optionally throw an error or handle the case where videoUrl is not present.
      return;
    }
    console.error("No Video Selected");
    const formData = new FormData();
    formData.append("file", videoUrl);
    try {
      // Basic validation checks
      setLoading(true);
      const response = await api.updateWebinarVideo(id, dataWeb.files[0].id, formData);
      setLoading(false);
      toast.success("Video Uploaded SuccessFully");
      console.log(response);
      setSelectedVideo(null);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };
  const handleListingTypeChange = (event) => {
    setWebtype(event.target.value);
  };
  const [switchState, setSwitchState] = useState(dataWeb.sponsored);

  const handleSwitchChange = async (event) => {
    try {
      const newSwitchState = !switchState; // Calculate the new state first
      setSwitchState(newSwitchState); // Update the state
      await api.webinarFeaturedStatus(id, "sponsored", newSwitchState); // Use the updated state
    } catch (error) {
      // Handle API call errors
      console.error("Error updating status:", error);
    }
  };
  const [featuredSwitchState, setfeaturedSwitchState] = useState(dataWeb.featured);
  const handleFeaturedSwitch = async (event) => {
    try {
      const newSwitchState = !featuredSwitchState; // Calculate the new state first
      setfeaturedSwitchState(newSwitchState); // Update the state
      await api.webinarFeaturedStatus(id, "featured", newSwitchState); // Use the updated state
    } catch (error) {
      // Handle API call errors
      console.error("Error updating status:", error);
    }
  };
  const [videoCount, setVideoCount] = useState();
  const [statstype, statssetType] = useState("Likes"); // Set default type

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getStatsViewsLikesShares(id, "EDU");
        console.log(response);
        setData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const openModal = (type) => {
    const filteredData = filterData(type);
    setModalData(filteredData);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const filterData = (type) => {
    switch (type) {
      case "viewed":
        return data.filter((item) => item.viewed);
      case "liked":
        return data.filter((item) => item.liked);
      case "shared":
        return data.filter((item) => item.shared);
      default:
        return [];
    }
  };
  const fetchLikeViewShare = async () => {
    try {
      const fetchLikeViewShare = await api.getVideoStats(id, statstype);
      setVideoCount(fetchLikeViewShare);
    } catch (error) {
      console.error("Error fetching video stats:", error);
    }
  };

  useEffect(() => {
    fetchLikeViewShare();
  }, [id, statstype]); // Fetch data when id or type changes
  const currentItemsStats = videoCount && videoCount.slice(indexOfFirstItem, indexOfLastItem);
  const customstatsTableHeadings = ["checkbox", "ID", "Name", "Mobile", "City", "Email"];
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "appointments",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const utcTimestamp = dataWeb.createAt;
  const localDate = new Date(utcTimestamp);
  const localDateString = localDate.toLocaleString();
  const formattedLocalDateString = localDateString.replace(",", "");
  let localModifiedAt = "";
  if (dataWeb.modifiedAt === 0) {
    localModifiedAt = "0";
  } else {
    const modifiedAt = new Date(dataWeb.modifiedAt);
    localModifiedAt = modifiedAt.toLocaleString();
  }
  const [testState, setTeststate] = useState(dataWeb.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateTestStatus("WEBINAR_COURSE", dataWeb.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontSize: 20, marginLeft: "9%" }}>
              <strong>Webinar Details</strong>
            </span>
            <span style={{ fontSize: 20, marginRight: "9%" }}>
              <Button
                style={{
                  marginRight: 5,
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                Active
              </Button>
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="row col-md-10">
              <div className="col-md-12 mt-2">
                <div className="row">
                  <div className="col-md-4">
                    {isVideoUploaded ? (
                      <div>
                        <video controls src={URL.createObjectURL(videoUrl)} width="100%" height="auto">
                          Your browser does not support the video tag.
                        </video>
                        <Button onClick={deleteVideo}>Remove Video</Button>
                      </div>
                    ) : (
                      <div>
                        <video controls src={dataWeb.files && dataWeb.files[0] ? dataWeb.files[0].filePath : null} width="100%" height="auto">
                          Your browser does not support the video tag.
                        </video>
                        <Button>
                          <label htmlFor="video-upload" style={{ cursor: "pointer" }}>
                            Upload New Video *
                          </label>
                          <input id="video-upload" type="file" accept="video/*" onChange={handleVideoUpload} style={{ display: "none" }} />
                        </Button>
                      </div>
                    )}
                    <div className="mt-2">
                      <label>By Author*</label>
                      {/* <select id="inputPetParent" className="form-control" value={selectedAuthor} onChange={handleAuthorChange}>
                        <option selected value="Abhay">
                          Abhay
                        </option>
                        <option value="Rajesh">Rajesh</option>
                      </select> */}
                      <input id="myInput" type="text" name="myCountry" value={selectedAuthor} onChange={handleAuthorChange} className="form-control" />
                    </div>
                    <div style={{ flexDirection: "row" }} className="mt-2">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Online"
                          id="Online"
                          value="Online"
                          checked={type === "Online"}
                          onChange={handleType}
                        />
                        <label className="form-check-label" htmlFor="Online">
                          Online
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Offline"
                          id="Offline"
                          value="Offline"
                          checked={type === "Offline"}
                          onChange={handleType}
                        />
                        <label className="form-check-label" htmlFor="Offline">
                          Offline
                        </label>
                      </div>
                    </div>
                    <div style={{ flexDirection: "row" }} className="mt-2">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Free"
                          id="Free"
                          value="Free"
                          checked={webtype === "Free"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Free">
                          Free
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Paid"
                          id="Paid"
                          value="Paid"
                          checked={webtype === "Paid"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Paid">
                          Paid
                        </label>
                      </div>
                    </div>
                    {webtype === "Paid" && (
                      <div className="mt-2">
                        <label>Price*</label>
                        <input id="myInput" type="number" name="price" className="form-control" value={price} onChange={handlePriceChange} />
                      </div>
                    )}

                    <div className=" mt-2">
                      <label>Published Date and Time*</label>
                      <input
                        type="datetime-local"
                        className="form-control form-control mt-2"
                        value={webinarpublishDate}
                        disabled={true}
                        id="startDateTime"
                        onChange={handlepublishDate}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Live Date and Time*</label>
                      <input
                        type="datetime-local"
                        className="form-control form-control mt-2"
                        id="startDateTime"
                        value={webinarDateTime}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="mt-2">
                      <label>Created At*</label>
                      <input type="text" className="form-control form-control mt-2" id="startDateTime" disabled={true} value={formattedLocalDateString} />
                    </div>
                    <div className="mt-2">
                      <label>Modified At*</label>
                      <input type="da" className="form-control form-control mt-2" id="startDateTime" disabled={true} value={localModifiedAt} />
                    </div>
                  </div>
                  <div className="col-md-8  ">
                    <div className="">
                      {/* <label>Poster Image Uploded</label> */}
                      <div>
                        {selectedImage ? (
                          <div style={{ flexDirection: "column", display: "flex" }}>
                            <img
                              src={selectedImage}
                              alt="Uploaded"
                              style={{
                                maxWidth: "30%",
                                maxHeight: "100%",
                              }}
                            />
                            <Button onClick={handleImgupload} color="secondary" as="span" style={{ width: "30%", marginTop: 20 }}>
                              Upload New Poster
                            </Button>
                          </div>
                        ) : dataWeb.poster && dataWeb.poster.length > 0 ? (
                          <div>
                            <img src={dataWeb.poster} alt="Prepopulated" style={{ width: 200 }} />
                            <div style={{ marginTop: 10 }}>
                              <Button onClick={handleImgupload} color="secondary" as="span">
                                Upload New Poster*
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <label htmlFor="uploadInput">
                            <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleImgChange} />
                            <Button onClick={handleImgupload} color="secondary" as="span">
                              Upload New Poster*
                            </Button>
                          </label>
                        )}
                      </div>
                    </div>
                    <div className=" ">
                      <label>Header*</label>
                      <input id="myInput" type="text" name="myCountry" value={header} onChange={handleHeaderChange} className="form-control" />
                    </div>
                    <div className=" mt-2">
                      <label>Description*</label>
                      <textarea value={description} className="form-control" rows={10} onChange={handleDescriptionChange}></textarea>
                    </div>
                    <div style={{display:"flex",gap:20}}>
                    <div className=" mt-2">
                      <label>Featured*</label>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeaturedSwitch} />
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="mt-2">
                      <label>Sponsored*</label>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="mt-2">
                  <label>Test</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                    </FormGroup>
                  </Form>
                </div>
                </div>
                  </div>
                </div>
              </div>
              <div>
                <hr className="my-3" />
                {dataWeb.paid === true && (
                  <div
                    style={{
                      borderRadius: 5,
                      borderColor: "#E5E7EB",
                      padding: 10,
                      border: "1px solid #E5E7EB",
                      overflow: "auto",
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <strong>Subscribed by</strong>
                    </span>
                    <Table hover style={{ tableLayout: "auto" }}>
                      <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        <tr>
                          {customTableHeadings.map((heading, index) => (
                            <th key={index} style={{ whiteSpace: "nowrap" }}>
                              {heading === "checkbox" ? (
                                <input
                                  type="checkbox"
                                  checked={selectedRows.length === currentItems.length}
                                  onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                                />
                              ) : (
                                heading
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            {customTableHeadings.map((heading, colIndex) => (
                              <td key={colIndex}>
                                {heading === "ID" ? (
                                  <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                                    {item.userId.slice(0, 5)}
                                    {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                                  </span>
                                ) : heading === "Mobile" ? (
                                  item.mobile.slice(0, 10)
                                ) : heading === "Caption" ? (
                                  item.description
                                ) : heading === "Email" ? (
                                  item.email
                                ) : heading === "Purchased Date" ? (
                                  moment(item.paymentDate).format("YYYY-MM-DD HH:mm:ss")
                                ) : heading === "Name" ? (
                                  item.name
                                ) : heading === "Payment Status" ? (
                                  item.status
                                ) : (
                                  item[heading]
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                <div
                  style={{
                    borderRadius: 5,
                    borderColor: "#E5E7EB",
                    padding: 10,
                    border: "1px solid #E5E7EB",
                    overflow: "auto",
                    marginTop: 20,
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    <strong>Video Stats</strong>
                  </span>
                  <Table hover>
                    <thead>
                      <tr>
                        {columns4.map((column, index) => (
                          <th key={index}>
                            {column === "checkbox" ? (
                              <input
                                type="checkbox"
                                checked={selectedRows.length === data4.length}
                                onChange={() => setSelectedRows(selectedRows.length === data4.length ? [] : data4.map((_, index) => index))}
                              />
                            ) : (
                              column
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data4.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>
                            <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                          </td>
                          {columns4.slice(1).map((column, colIndex) => (
                            <td key={colIndex}>
                              {column === "Likes" ? (
                                <Link onClick={() => openModal("liked")}>{item[column]}</Link>
                              ) : column === "Views" ? (
                                <Link onClick={() => openModal("viewed")}>{item[column]}</Link>
                              ) : column === "Share Count" ? (
                                <Link onClick={() => openModal("shared")}>{item[column]}</Link>
                              ) : (
                                item[column]
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Modal isOpen={isModalOpen} toggle={closeModal} size="lg">
                  <ModalHeader>Stats</ModalHeader>
                  <ModalBody>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </div>
              {/* <div>
            <hr className="my-3" />
            <span style={{ fontSize: 20 }}>
              <strong>Intrested</strong>
            </span>
            <div
              style={{
                borderRadius: 5,
                borderColor: "#E5E7EB",
                padding: 10,
                border: "1px solid #E5E7EB",
                overflow: "auto",
              }}
            >
              <Table hover>
                <thead>
                  <tr>
                    {columns3.map((column, index) => (
                      <th key={index}>
                        {column === "checkbox" ? (
                          <input
                            type="checkbox"
                            checked={selectedRows.length === data2.length}
                            onChange={() =>
                              setSelectedRows(
                                selectedRows.length === data2.length
                                  ? []
                                  : data2.map((_, index) => index)
                              )
                            }
                          />
                        ) : (
                          column
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data3.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(rowIndex)}
                          onChange={() => toggleRowSelection(rowIndex)}
                        />
                      </td>
                      {columns3.slice(1).map((column, colIndex) => (
                        <td key={colIndex}>
                          {column === "name" ? (
                            <Link
                              to="/EmailList/EmailDetails"
                              style={{ cursor: "pointer" }}
                            >
                              {item[column]}
                            </Link>
                          ) : (
                            item[column]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div> */}
              <div style={{ marginTop: 20 }}>
                <h5>Internal Note</h5>
                <div className="chat-container">
                  <div className="chat-messages">
                    {messages.map((message, index) => (
                      <div key={index} className={`message ${message.sender}`}>
                        <div className="message-text">{message.text}</div>
                        <div className="message-time">{getCurrentLocalDateTime()}</div>
                      </div>
                    ))}
                    {internalNotes.map((note) => (
                      <div key={note.id} className={`message bot`}>
                        <div className="message-text">{note.note}</div>
                        <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                      </div>
                    ))}
                  </div>
                  <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                    <textarea
                      className="form-control"
                      rows={5}
                      type="text"
                      placeholder="Type your message..."
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                    />
                    <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                      <button className="form-control" style={{ width: 100 }} type="submit">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-5   " style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Button
                      onClick={EditWebinar}
                      style={{
                        marginRight: 5,
                        background: "#007bff",
                        border: "none",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        border: "none",
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
        className="mt-5 row col-md-8"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Button
              style={{
                marginRight: 5,
                background: "#007bff",
                border: "none",
              }}
            >
              Save
            </Button>
            <Button
              style={{
                marginRight: 5,
                border: "none",
              }}
            >
              Cancel
            </Button>
          </div>
          <Button color={isBlock ? "success" : "danger"} onClick={handleBlock}>
            {isBlock ? "Unblock" : "Block"}
          </Button>
        </div>
      </div> */}
        </div>
      )}
    </>
  );
};

export default EditWebinar;
