import React,{useState} from "react";
import { Button } from "reactstrap";
const EditNotification = () => {
    const [isScheduled, setIsScheduled] = useState(false);

    const handleRadioChange = (event) => {
      setIsScheduled(event.target.value === 'option2');
    };
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
      <span style={{ fontSize: 20 }}>
        <strong>Edit Notification</strong>
      </span>
      <div className="pt-2">
        <div>
          <span style={{ fontWeight: 600 }}>General information*</span>
        </div>
      </div>
      <div
        className="pt-2"
        style={{
          marginRight: 10,
          display: "flex",
          flexDirection: "row", 
          alignItems: "center",
        }}
      >
        <div>
          <span>Notification Title*</span>
          <input
            style={{ borderRadius: 6 }}
            className="form-control"
            placeholder="Enter Notification Title"
            value={"Test"}
          />
        </div>
        <div style={{ marginLeft: 20 }}>
          <span>Notification Name (optional)</span>
          <input
            style={{ borderRadius: 6 }}
            className="form-control"
            placeholder="Enter Notification Name"
          />
        </div>
      </div>
      <div
        className="pt-2"
        style={{
          marginRight: 10,
          display: "flex",
          flexDirection: "row",
          //   justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span>Notification Text*</span>
          <input
            style={{ borderRadius: 6 }}
            className="form-control"
            placeholder="Enter Notification Title"
            value={"This is a test Notification"}
          />
        </div>
        <div style={{ marginLeft: 20 }}>
          <span>Notification Image*</span>
          
        </div>
      </div>
      <div className="pt-2">
        <div>
          <span style={{ fontWeight: 600 }}>Target*</span>
        </div>
      </div>
      <div className="mt-2">
        <div style={{ flexDirection: "row" }}>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              name="Afternoon"
              id="Afternoon"
              value="option1"
            />
            <label className="form-check-label" for="inlineRadio2">
              All
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              name="Afternoon"
              id="Afternoon"
              value="option2"
            />
            <label className="form-check-label" for="inlineRadio2">
              FurrCrew (Android)
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              name="Afternoon"
              id="Afternoon"
              value="option3"

            />
            <label className="form-check-label" for="inlineRadio2">
              FurrCrew (iOS)
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value="option4"
              checked={"option4"}
            />
            <label className="form-check-label" for="inlineRadio2">
              FurrPartner (Android)
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value="option5"
              checked={"option5"}
            />
            <label className="form-check-label" for="inlineRadio2">
              FurrPartner (iOS)
            </label>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <div>
          <span style={{ fontWeight: 600 }}>Target Users*</span>
        </div>
      </div>
      <div className="mt-2">
        <div style={{ flexDirection: "row" }}>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              value="All"
              checked={"All"}
            />
            <label className="form-check-label" for="inlineRadio2">
              All
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value="Vet"
              checked={"Vet"}
            />
            <label className="form-check-label" for="inlineRadio2">
              Veterinary
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value="Groomer"
              checked={"Groomer"}
            />
            <label className="form-check-label" for="inlineRadio2">
              Groomers
            </label>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <div>
          <span style={{ fontWeight: 600 }}>Schedule Notification*</span>
        </div>
      </div>
      <div className="mt-2">
        <div style={{ flexDirection: "row" }}>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              value="option1"
              checked={"option1"}
            />
            <label className="form-check-label" for="inlineRadio2">
              One Time Notification
            </label>
          </div>
          <div
            className="form-check form-check-inline"
            style={{ marginLeft: 5 }}
          >
            <input className="form-check-input" type="radio" value="option2" />
            <label className="form-check-label" for="inlineRadio2">
              Recurring notification
            </label>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="mt-2">
      <div style={{ flexDirection: "row" }}>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            value="option1"
            onChange={handleRadioChange}
            checked={!isScheduled}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            Now
          </label>
        </div>
        <div
          className="form-check form-check-inline"
          style={{ marginLeft: 5 }}
        >
          <input
            className="form-check-input"
            type="radio"
            value="option2"
            onChange={handleRadioChange}
            checked={isScheduled}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            Schedule
          </label>
        </div>
      </div>
      {isScheduled && (
       <div className="col-md-6 mt-2">
       <div className="row">
         <div className="col-md-6">
           <label>Start Date and Time</label>
           <input type="datetime-local" className="form-control" id="startDateTime" />
         </div>
         <div className="col-md-6">
           <label>End Date and Time</label>
           <input type="datetime-local" className="form-control" id="endDateTime" />
         </div>
       </div>
     </div>
      )}
    </div>
      <div className="mt-5">
        <div>
          <Button
            style={{
              marginRight: 5,
              background: "#007bff",
              border: "none",
            }}
          >
            Edit Notification
          </Button>
          <Button
            style={{
              marginRight: 5,
              border: "none",
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditNotification;
