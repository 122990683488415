import React from "react";
import "../../component/css/MiscellaneousList.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faAngleRight,
  faUserFriends,
  faUserAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const data = [
  {
    name: "Otto",
    designation: "Pune",
    userName: "VSHARMA02",
    dateJoined: "15 Apr 2023",
    access: "15 Apr 2023",
  },
];

const MiscellaneousList = () => {
  const columns = Object.keys(data[0]);
  const navigate = useNavigate();
  return (
    <>
      <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <span style={{ fontSize: 20 }}>
          <strong>Miscelloneous</strong>
        </span>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <span>Manage miscelloneous service for furrcrew</span>
        </div>
        <div className="mt-2">
           <div class="col-md-12 d-flex justify-content-space-between mt-3"> 
            <div className="col-md-5 cardBackground">
              <a
                style={{ cursor: "pointer " }}
                onClick={() => navigate("/BlogList")}
              >
                <div class="row miscelloneousRow">
                  <div
                    class="col-md-1 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faEdit}
                    />
                  </div>
                  <div class="col-md-9">
                    <div class="row justify-content-start">
                      <div class="col-md-12">
                        <span>
                          <b>Manage/Create Blogs</b>
                        </span>
                      </div>
                      <div class="col-md-12">
                        <span  class="contentSubline">
                          manage and create blog to poblish on furrcrew website
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faAngleRight}
                    />
                  </div>
                </div>
              </a>
            </div>

            <div
              className="col-md-5 cardBackground" style={{marginLeft:10}}>
              <a
                style={{ cursor: "pointer " }}
                onClick={() => navigate("/AdminList")}
              >
                <div class="row miscelloneousRow">
                  <div
                    class="col-md-1 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faUserFriends}
                    />
                  </div>
                  <div class="col-md-9">
                    <div class="row justify-content-start">
                      <div class="col-md-12">
                        <span>
                          <b>Manage Admin</b>
                        </span>
                      </div>
                      <div class="col-md-12">
                        <span class="contentSubline">
                          Manage access grant for superadmin
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faAngleRight}
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-space-between mt-3">
            <div
              className="col-md-5 cardBackground"
            >
              <a
                style={{ cursor: "pointer " }}
                onClick={() => navigate("/MyAccount")}
              >
                <div class="row miscelloneousRow">
                  <div
                    class="col-md-1 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faUserAlt}
                    />
                  </div>
                  <div class="col-md-9">
                    <div class="row justify-content-start">
                      <div class="col-md-12">
                        <span>
                          <b>My Account</b>
                        </span>
                      </div>
                      <div class="col-md-12">
                        <span class="contentSubline">
                          View/Edit your account details
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faAngleRight}
                    />
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-md-5 cardBackground " style={{marginLeft:10}}
            >
              <a
                style={{ cursor: "pointer " }}
                onClick={() => navigate("/MiscellaneousList/RollsPermissionList")}
              >
                <div class="row miscelloneousRow">
                  <div
                    class="col-md-1 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faLock}
                    />
                  </div>
                  <div class="col-md-9">
                    <div class="row justify-content-start">
                      <div class="col-md-12">
                        <span>
                          <b>Rolls Permission</b>
                        </span>
                      </div>
                      <div class="col-md-12">
                        <span class="contentSubline">
                          View/Edit Rolls Permission
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 d-flex justify-content-center"
                    style={{ alignItems: "center" }}
                  >
                    <FontAwesomeIcon
                      color="#007bff"
                      fontSize={30}
                      icon={faAngleRight}
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiscellaneousList;
