import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/FurrCrewLogo.png";
import "../component/css/login.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Apis from "../utils/api";
import { Alert } from 'reactstrap';
import {TailSpin} from "react-loader-spinner";

const Login = ({ onLogin }) => {
  const api = new Apis();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleLogin = async () => {
    setIsLoading(true); 
    const requestBody = {
      email: email,
      password: password,
    };
    try {
      const response = await api.authLogin(requestBody);
      if (response.status === 200) {
        //alert("Success");
        onLogin();
        navigate("/PetParent");
      } else {
        //alert("Login failed");
        <Alert color="danger">Error</Alert>

      }
    } catch (error) {
      //alert("An error occurred");  
      setIsLoading(false);
      setIsError(true);
    } finally {
      //setIsError(false);
      setIsLoading(false);
    }
  };
  return (
    <div className="container-fluid">
      
       {isLoading ? (
        <div  style={{position:'absolute',top:'50%',left:"50%"}}>
          <TailSpin
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
        </div>
      ) : (
      <div className="row justify-content-center loginCard">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <div className="text-center mb-4">
                <img src={logo} alt="Logo" width="275" height="50" />
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail" className="labelname">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email} // Bind the input value to the email state
                  onChange={(e) => setEmail(e.target.value)} // Update email state on change
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputPassword" className="labelname">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="inputPassword"
                  placeholder="Password"
                  value={password} // Bind the input value to the password state
                  onChange={(e) => setPassword(e.target.value)} // Update password state on change
                />
              </div>
              <div className="text-center mb-3 forgetPassword">
                <Link to="/AppointmentList/AppointmentDetails">
                  Forgot password
                </Link>
              </div>

              <div className="row justify-content-center mt-4">
                <button
                  className="btn btn-primary loginbtn text-center"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
          {
      isError ? (
      <div className="text-center mt-3">
        <Alert color="danger">Invalid Credentials</Alert>
        </div>
      ) : (
        null)}
        </div>
      </div>
        )}
    </div>
  );
};

export default Login;
