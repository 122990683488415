import axios from 'axios';
import { unauthorizedHandler } from './helpers';
import Login from '../views/Login';

export default class Apis {
  constructor() {
    this.myAxios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    this.id = localStorage.getItem('userId') || null; 
    this.token = localStorage.getItem('token') || null;

    this.setupInterceptors();
  }

  setupInterceptors() {
    this.myAxios.interceptors.request.use(
      (config) => {
        if (this.token && !config.url.includes('/super/admin/login')) {
          config.headers.Authorization = `Bearer ${this.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.myAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response && error.response.status === 403) {
          console.error('Token expired or invalid');
          await this.handleTokenExpiration();
        }
        return Promise.reject(error);
      }
    );
  }

  async handleTokenExpiration() {
    localStorage.clear();
    window.location.href = '/login';
  }

  async authLogin(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/login`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        this.id = response.data.id;
        this.token = response.data.token;
        localStorage.setItem('token', this.token);
        localStorage.setItem('userId', this.id);
        console.log("Token", this.token);
        return response;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }


  async getPetParentList() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/pet/parent/user?status&pageNumber=2&resultSize=30`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async getCollaborator(type) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/collaborators?type=${type}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getEventBookingDetails(eventId) {
    const apiResponse = await this.myAxios.get(`events/v1/event/booking/details?eventId=${eventId}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async getAllCollaborators(type) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/collaborators?type=${type}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {  
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  
  async getAdminList() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/general/users`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async addAdmin(payload) {
    const apiResponse = await this.myAxios.post(`admin/v1/super/admin/create/user?adminId=${this.id}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async addPetParent(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/1dqwf/pet/parent/user`, payload);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addTestUser(payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/test/user?createdBy=${this.id}`, payload);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addCollaborator(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/${this.id}/collaborator/register`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }

  async EditPetParent(petParentID,payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/${this.id}/pet/parent/user?petParentId=${petParentID}`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addPet(petParentID,payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/user/${petParentID}/pets`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getPets(petParentID) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/user/${petParentID}/pets`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async updatePetDetails(petParentId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/user/${petParentId}/pets`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async getWorkspace(serviceType) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/workspace/${serviceType}/details`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getWorkSpaceDocument(workspaceId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/workspace/collaborator/${workspaceId}/documents`)
      .then(resp => {
        console.log("res", resp); 
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async addWorkspace(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/workspace/1/create?adminId=${this.id}`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addNgoWorkspace(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/NGO?collaboratorId=1`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async editNgoWorkspace(workspaceId,payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/NGO/{id}/update?workspaceId=${workspaceId}&adminId=${this.id}`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addBankDetails(workspaceId,payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/workspace/${workspaceId}/bank/detail`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async uploadWorkspaceImages(workspaceId, collabId, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `/admin/v1/super/admin/workspace/${workspaceId}/images/update`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            collaboratorId: collabId,
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async getBankDetails(workspaceId,CollabId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/workspace/${workspaceId}/bank/detail?collaboratorId=${CollabId}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateWorkspaceData(workspaceId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/workspace/${workspaceId}/modify?adminId=${this.id}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateNGO(workspaceId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/NGO?workspaceId=${workspaceId}&adminId=${this.id}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateBank(workspaceId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/workspace/${workspaceId}/bank/detail`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async getAppointmentList() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/pet/parents/appointments`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }

  async getRollsLst() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/rolls?collaboratorId=${this.id}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async uploadRolls(collaboratorId,type,isAdult,workspaceId,featured,sponsored,isTest,formData) {
    try {
      const response = await this.myAxios.post(
        `admin/v1/super/admin/rolls`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            collaboratorId:collaboratorId,
            type: type,
            isAdult: isAdult,
            workspaceId:workspaceId,
            featured:featured,
            sponsored:sponsored,
            isTest:isTest
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updateRolls(collaboratorId,isAdult,status,formData) {
    try {
      const apiResponse = await this.myAxios.put(
        ` /rolls`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            collaboratorId: collaboratorId,
            isAdult: isAdult,
            status: status
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async getAdoptionList() {
    const apiResponse = await this.myAxios.get(`/admin/v1/super/admin/pet/adoption`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async addAdoption(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/pet/adoption?userId=${this.id}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateAdoption(payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/pet/adoption?userId=${this.id}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateRollDetails(rollId,caption,statuss,tag,isAdult,type,collaboratorId,workspaceId,featured,sponsored) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/details?rollId=${rollId}&description=${caption}&status=${statuss}&tag=${tag}&isAdult=${isAdult}&type=${type}&collaboratorId=${collaboratorId}&workspaceId=${workspaceId}&featured=${featured}&sponsored=${sponsored}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async uploadAdoptionImage(petID, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/pet/adoption/${petID}/images/${this.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updateAdoptionImage(petID, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin/pet/adoption/${petID}/images/${this.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async deleteAdoptionImage(petID,refId, formData) {
    try {
      const apiResponse = await this.myAxios.delete(
        `admin/v1/super/admin/pet/adoption/${petID}/images/${refId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  // async editedRoll(collaboratorId,isAdult,rollId,formData) {
  //   try {
  //     const apiResponse = await this.myAxios.put(
  //       `admin/v1/super/admin/rolls`,
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //         params: {
  //           collaboratorId: collaboratorId,
  //           isAdult: isAdult,
  //           rollId:rollId,
  //         },
  //       }
  //     );
  //     const data = apiResponse.data;
  //     if (data.statusCode === 200) {
  //       return data.data;
  //     } else {
  //       console.error('API error:', data);
  //       return data;
  //     }
  //   } catch (error) {
  //     console.error('Error uploading workspace images:', error);
  //     throw error;
  //   }
  // }
  async getpetAppointments(PetID) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/pet/parents/appointments?petId=${PetID}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getPresciption(appointmentId,petId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/appointment/${appointmentId}/pet/${petId}/prescription`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async uploadPetParentImage(petParentId, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/${petParentId}/pet/parent/user/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updatePetParentImage(petParentId, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin/${petParentId}/pet/parent/user/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadPetImage(petParentId,petId, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/${petParentId}/pet/${petId}/profile/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updatePetImage(petParentId,petId, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin/${petParentId}/pet/${petId}/profile/image?isUpdate=true`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadCollaboratorImage(collaboratorId, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin//collaborator/${collaboratorId}/profile/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updateCollabImg(collaboratorId, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin//collaborator/${collaboratorId}/profile/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async getCollabDetails(collabId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/collaborator/${collabId}/profile`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async EditCollab(collaboratorId,payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/${this.id}/collaborator/${collaboratorId}/profile`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getCouponList() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/coupon`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getFaq(catergory) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/faq?type=${catergory}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updatefaq(payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/faq`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addfaq(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/faq`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getBlogsList(catergory) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/blogs?status&category=${catergory}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async addBlog(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/${this.id}/blogs`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateBlog(payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/${this.id}/blogs`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async uploadBLogImage(blogID, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/${this.id}/blogs/images?blogId=${blogID}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadBlogCoverImage(blogID, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/blogs/cover/image?blogId=${blogID}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async createCupon(collabId,payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/${this.id}/coupon?collaboratorId=${collabId}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateCoupon(collabId,payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/${this.id}/coupon?collaboratorId=${collabId}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateAddress(payload) {
    try {
      const response = await this.myAxios.put(`events/v1/event/update?modifiedBy=${this.id}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deletePrice(id) {
    try {
      const response = await this.myAxios.delete(`events/v1/event/price/update?id=${id}`);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addEventPrice(payload) {
    try {
      const response = await this.myAxios.post(`events/v1/event/price/update?createdBy=${this.id}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateEventPrice(payload) {
    try {
      const response = await this.myAxios.put(`events/v1/event/price/update?modifiedBy=${this.id}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async  createEvents(payload) {
    try {
      const response = await this.myAxios.post(`events/v1/event/create`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getEventList() {
    const apiResponse = await this.myAxios.get(`events/v1/event?deviceType=All&userId=${this.id}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async uploadEventImage(eventid, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `events/v1/event/image?eventId=${eventid}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadEventCoverImage(eventid, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `events/v1/event/cover/image?eventId=${eventid}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async deleteEvents(eventID) {
    try {
      const response = await this.myAxios.delete(`events/v1/event/${eventID}/delete`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }

  async updateEvents(eventId,payload) {
    try {
      const response = await this.myAxios.put(`events/v1/event/${eventId}/update`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateEventsTestStatus(type,dataId,test) {
    try {
      const response = await this.myAxios.put(`events/v1/update/test/data/?type=${type}&dataId=${dataId}&isTest=${test}`);
      console.log("Response:", response);
      if (response.status === 200) {
        return response;
      } else {
        console.error("Unexpected status code:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async  createWebinar(payload) {
    try {
      const response = await this.myAxios.post(`admin/v1/super/admin/edu/webinar`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async  getVideoStats(id,type) {
    try {
      const response = await this.myAxios.get(`admin/v1/super/admin/edu/stat?eventId=${id}?type=${type}`);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async  updateWebinar(payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/edu/webinar`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getWebinar() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/edu/webinar`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async uploadWebinar(webinarId,title,description,videoDuration,episodeNo, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/edu/webinar/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            webinarId: webinarId,
            title: title,
            description:description,
            videoDuration:videoDuration,
            episodeNo:episodeNo
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updateWebinarVideo(eduId,videoId, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin/edu/course/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            eduId:eduId,
            videoId:videoId
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadWebinarPoster(webinarid, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/edu/webinar/poster?webinarId=${webinarid}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updateEventPoster(eventID, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `events/v1/event/image?eventId=${eventID}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async getCourse() {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/edu/course`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateCourse(courseId,videoId,title,description,videoDuration,episodeNo) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/edu/webinar/files?eduId=${courseId}&videoId=${videoId}&title=${title}&description=${description}&videoDuration=${videoDuration}&episodeNo=${episodeNo}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async deletePetParent(petparentid) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/${this.id}/pet/parent/user?petParentId=${petparentid}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deleteWorkspaceImage(workspaceId,payload) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/image/${workspaceId}/delete`,payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async uploadAadhar(workspaceId,collabId,docnumber, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/workspace/${workspaceId}/collaborator/documents/images?type=AADHAR&collaboratorId=${collabId}&docNumber=${docnumber}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async updateAdvertismentImage(id, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `events/v1/advertisement/image?advertisementId=${id}`,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`, // Set the boundary explicitly
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadPan(workspaceId,collabId,docnumber,formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/workspace/${workspaceId}/collaborator/documents/images?type=PAN&collaboratorId=${collabId}&docNumber=${docnumber}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadDocLicense(workspaceId,collabId,docnumber, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/workspace/${workspaceId}/collaborator/documents/images?type=DOCTOR_LICENSE&collaboratorId=${collabId}&docNumber=${docnumber}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async uploadQualification(workspaceId,collabId,docnumber, formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `admin/v1/super/admin/workspace/${workspaceId}/collaborator/documents/images?type=QUALIFICATION_CERTIFICATE&collaboratorId=${collabId}&docNumber=${docnumber}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async  createAdvertisment(payload) {
    try {
      const response = await this.myAxios.post(`events/v1/advertisement`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateAdvertisment(Adid,payload) {
    try {
      const response = await this.myAxios.put(`events/v1/advertisement/?advertisementId=${Adid}`, payload);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getAdvertisment() {
    const apiResponse = await this.myAxios.get(`events/v1/advertisement`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getPurchasedList(eduId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/edu/purchase/user?eventId=${eduId}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async uploadAdvertisment(adminId,viewType,formData) {
    try {
      const apiResponse = await this.myAxios.post(
        `events/v1/advertisement/image?advertisementId=${adminId}&viewType=${viewType}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async deleteWorkspace(workspaceId) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/workspace/${workspaceId}/modify?adminId=${this.id}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deleteRolls(rollId) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/rolls?rollId=${rollId}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    } 
  }
  async deleteWebinar(webinarid) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/edu/category?educationId=${webinarid}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deleteCollaborator(collabId) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/${this.id}/collaborators?collaboratorId=${collabId}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async rollStatus(rollId,status) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/roll/${rollId}/status?status=${status}`);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async webinarFeaturedStatus(eduId,key,status) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/edu/update/featured/sponsored?eduId=${eduId}&key=${key}&value=${status}`);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deleteBlogs(blogID) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/${this.id}/blogs?blogId=${blogID}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async updateRollVideo(rollid, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin/${rollid}/video`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async deleteEvent(eventID) {
    try {
      const response = await this.myAxios.delete(`events/v1/event/${eventID}/delete`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async addAppointment(workspaceId,collabId,payload) {
    const apiResponse = await this.myAxios.post(`admin/v1/super/admin/create/appointment?workspaceId=${workspaceId}&collaboratorId=${collabId}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateAppointment(workspaceId,collabId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/create/appointment?workspaceId=${workspaceId}&collaboratorId=${collabId}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async deleteAppointment(appointmentId) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/pet/parents/appointments?appointmentId=${appointmentId}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deleteAdoption(adoptionId) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/pet/adoption?adoptionId=${adoptionId}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async deleteCoupon(couponId) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/${this.id}/coupon?couponId=${couponId}`,);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getInternalNotes(id) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/internal/notes?refId=${id}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async editInternalNote(payload) {
    try {
      const response = await this.myAxios.put(`admin/v1/super/admin/internal/notes?isUpdated=true`, payload);
      console.log("Response:", response);
  
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async uploadCoverImg(type,id, formData) {
    try {
      const apiResponse = await this.myAxios.put(
        `admin/v1/super/admin/cover/image/upload?type=${type}&refId=${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = apiResponse.data;
      if (data.statusCode === 200) {
        return data.data;
      } else {
        console.error('API error:', data);
        return data;
      }
    } catch (error) {
      console.error('Error uploading workspace images:', error);
      throw error;
    }
  }
  async getParentAddress(petParentId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/${this.id}/pet/parent/${petParentId}/address`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async deleteParentAddress(petParentId,addressID) {
    const apiResponse = await this.myAxios.delete(`admin/v1/super/admin/${this.id}/pet/parent/${petParentId}/address?addressId=${addressID}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async addAppointmentAddress(petParentId,payload) {
    const apiResponse = await this.myAxios.post(`admin/v1/super/admin/${this.id}/pet/parent/${petParentId}/address`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async EditAppointmentAddress(petParentId,addressId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/${this.id}/pet/parent/${petParentId}/address?addressId=${addressId}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async deleteDocumentImages(workspaceId,id) {
    try {
      const response = await this.myAxios.delete(`admin/v1/super/admin/workspace/${workspaceId}/collaborator/documents/images?docId=${id}`);
      console.log("Response:", response);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Unexpected status code:", response.status);
        return null; 
      }
    } catch (error) {
      console.error("Network error:", error);
      throw error;
    }
  }
  async getStatsViewsLikesShares(id,type) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/stats/data?refId=${id}&type=${type}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getBookedSlot(workspaceId,userId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/booked/appointments?workplaceId=${workspaceId}&userId=${userId}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async getVersionWorkspace(workspaceId) {
    const apiResponse = await this.myAxios.get(`admin/v1/super/admin/workspace/version?workspaceId=${workspaceId}`)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async EditVersionData(versionId,payload) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/workspace/version?adminId=${this.id}&versionId=${versionId}`,payload)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
  async updateTestStatus(type,dataId,test) {
    const apiResponse = await this.myAxios.put(`admin/v1/super/admin/update/test/data/?type=${type}&dataId=${dataId}&isTest=${test}`,)
      .then(resp => {
        console.log("res", resp);
        const data = resp.data;
        if (data.statusCode === 200) {
          return data.data;
        }
        return resp.data;
      }).catch(({ response }) => {
        unauthorizedHandler(response.data);
        return response.data;
      });
    return apiResponse;
  }
}