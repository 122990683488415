import React, { useState, useEffect } from "react";
import { CardGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { useBlogData } from "./services/blogState";
import dataURItoBlob, { isAlphaNumeric } from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import { Form, FormGroup, Input } from "reactstrap";
const EditBlogDetails = () => {
  const { getblogData } = useBlogData();
  const api = new Apis();
  const { id } = useParams();
  const [blog] = useState(getblogData(id));
  const [loading, setLoading] = useState(false);
  const [bodyText, setBodyText] = useState(blog.data);
  const [title, setTitle] = useState(blog.title);
  const [customTitle, setCustomTitle] = useState(blog.slug || "");
  const defaultTime = blog.readTimeSpan ? parseInt(blog.readTimeSpan, 10) : "";
  const [time, setTime] = useState(defaultTime);
  const [name, setName] = useState(blog.author);
  const [selectedImage, setSelectedImage] = useState(null);
  const [thumbselectedImage, setThumbSelectedImage] = useState(null);
  const navigate = useNavigate();
  const handleEditorChange = (content) => {
    setBodyText(content);
  };
  const [imageName, setImageName] = useState();
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageName(file);
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 1920 && img.height === 1080) {
            setSelectedImage(file);
          } else {
            toast.error("Image should be 1920 x 1080");
          }
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = () => {
    setSelectedImage(null);
  };
  const [preloadedCoverImage, setPreloadedCoverImage] = useState(null);
  const handleThumbImageUpload = (e) => {
    const file = e.target.files[0];
    const img = new Image();

    img.onload = function () {
      if (img.width === 2160 && img.height === 2160) {
        setThumbSelectedImage(file);
        console.log(file);
      } else {
        toast.error("Thumb image must be 2160 by 2160 pixels.");
      }
    };

    if (file) {
      img.src = URL.createObjectURL(file);
    }
  };
  const handleThumbImageDelete = () => {
    setThumbSelectedImage(null);
  };
  useEffect(() => {
    if (blog.coverImage && blog.coverImage.length > 0) {
      const coverImage = new Image();
      coverImage.src = blog.coverImage;
      coverImage.onload = () => setPreloadedCoverImage(coverImage);
    }
  }, [blog.coverImage]);
  const BlogAdd = async () => {
    try {
      if (!name || !isAlphaNumeric(name)) {
        throw new Error("Please provide valid name.");
      }
      if (!title) {
        throw new Error("Please provide a title for the blog.");
      }
      if (!time) {
        throw new Error("Please provide the read time span for the blog.");
      }
      if (!bodyText) {
        throw new Error("Please provide the content of the blog.");
      }
      if (publishType === "WEB" || publishType === "BOTH") {
        if (!thumbselectedImage && !blog.coverImage) {
          throw new Error("Cover image is required for WEB or BOTH publishType.");
        }
      }
      const requestBody = {
        id: id,
        title: title,
        status: true,
        type: "",
        readTimeSpan: time + " Min",
        data: bodyText,
        category: blog.category,
        author: name,
        deviceType: publishType,
        featured: featuredSwitchState,
        sponsored: switchState,
        slug:customTitle
      };
      setLoading(true);
      const response = await api.updateBlog(requestBody);
      setLoading(false);
      toast.success("Blog Edited SuccessFully");
      console.log(response);
      if (selectedImage) {
        console.log(id);
        setLoading(true);
        await uploadImage(id);
        setLoading(false);
      } else {
        setLoading(false);
      }
      if (thumbselectedImage) {
        console.log(id);
        setLoading(true);
        await uploadCoverImage(id);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding admin:", error);
      setLoading(false);
    }
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        console.log("No new image selected for upload.");
        return;
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("files", selectedImage, fileName);
      // error in uploading image @abhay
      const response = await api.uploadBLogImage(userId, formData);
      console.log("Img", response);
      // navigate(-1);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbselectedImage) {
        console.log("No new image selected for upload.");
        return;
      }
      console.log(userId);
      const formData = new FormData();
      formData.append("file", thumbselectedImage);
      const response = await api.uploadBlogCoverImage(userId, formData);
      console.log("CoverImg", response);
      // navigate(-1);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const timeOptions = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);
  // console.log("🚀 ~ file: EditBlogDetails.js:97 ~ EditBlogDetails ~ timeOptions:", timeOptions);
  const nameTitle = blog.category.charAt(0).toUpperCase() + blog.category.slice(1);

  // Update the default time if the blog.readTimeSpan changes
  useEffect(() => {
    const newDefaultTime = blog.readTimeSpan ? parseInt(blog.readTimeSpan, 10) : "";
    setTime(newDefaultTime);
  }, [blog.readTimeSpan]);
  const [publishType, setPublishType] = useState(blog.deviceType || "MOBILE");
  const handlePublishType = (event) => {
    setPublishType(event.target.value);
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "blogs",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const [switchState, setSwitchState] = useState(blog.sponsored);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(blog.featured);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const [testState, setTeststate] = useState(blog.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateTestStatus("BLOGS_ARTICLES", blog.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div className="row mt-2">
          <div className="col-md-4">
            <CardGroup
              className="p-3"
              style={{
                border: "1px solid #beb7b7",
                borderRadius: 10,
                marginRight: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="col-md-12">
                <h4 className="card-title form-group">Author Details</h4>
              </div>
              <div className="col-md-12">
                <p className="card-subtitle mb-2 text-muted form-group">Specify details for the author of the {nameTitle}</p>
              </div>
              <hr />
              <div className="col-md-12">
                <div className="form-group">
                  <label className="pt-10 lableText">Name</label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="Name of the author"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mt-2">
                <label className="pt-10 lableText">
                  Image
                  <span className="required">*</span>
                </label>
                <div className="imageUploadBox">
                  <div className="text-right">
                    <label htmlFor="imageUploadInput" className="btn btn-light navbar-buttons">
                      <span className="vetUploadButton">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                      </span>
                      <span className="navbar-button-text pl-2">Upload</span>
                      <input id="imageUploadInput" type="file" accept="image/*" style={{ display: "none" }} onChange={handleImageUpload} />
                    </label>
                    {selectedImage && (
                      <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleImageDelete}>
                        <span className="vetUploadButton">
                          <FontAwesomeIcon fontSize={20} icon={faTrash} />
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex flex-wrap">
                        <div className="col-md-4 d-flex align-items-center pt-3">
                          {selectedImage ? (
                            <img src={URL.createObjectURL(selectedImage)} alt="Uploaded" style={{ maxWidth: "100%" }} />
                          ) : (
                            blog.images && blog.images.length > 0 && <img src={blog.images[0].value} alt="Prepopulated" style={{ width: 200 }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {publishType !== "MOBILE" && (
                <div className="form-group mt-2">
                  <label className="pt-10 lableText">
                    Thumb Image
                    <span className="required">*</span>
                  </label>
                  <div className="imageUploadBox">
                    <div className="text-right">
                      <label htmlFor="imageUpload" className="btn btn-light navbar-buttons">
                        <span className="vetUploadButton">
                          <i className="fa fa-upload" aria-hidden="true"></i>
                        </span>
                        <span className="navbar-button-text pl-2">Upload</span>
                        <input id="imageUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                      </label>
                      {thumbselectedImage && (
                        <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                          <span className="vetUploadButton">
                            <FontAwesomeIcon fontSize={20} icon={faTrash} />
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex flex-wrap">
                          <div className="col-md-4 d-flex align-items-center pt-3">
                            {thumbselectedImage ? (
                              <img src={URL.createObjectURL(thumbselectedImage)} alt="Uploaded" style={{ width: 200 }} />
                            ) : (
                              preloadedCoverImage && <img src={preloadedCoverImage.src} alt="Prepopulated" style={{ width: 200 }} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </CardGroup>
          </div>
          <div className="col-md-8 p-3 " style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
            <div
              style={{
                marginRight: 10,
              }}
            >
              <div>
                <h4 className="form-group">Edit {nameTitle}</h4>
                <div className="form-group">
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>
                        <label>Featured</label>
                      </span>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                        </FormGroup>
                      </Form>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <label>Sponsored</label>
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                        </FormGroup>
                      </Form>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Test</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                    </FormGroup>
                  </Form>
                </div>
                  </div>
                  <div style={{ flexDirection: "row", marginTop: 10 }} className="">
                    <div>
                      <label className="lableText">
                        <span style={{ color: "#6b7280", fontWeight: 600 }}>Publish For</span>
                        <span className="required">*</span>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="MOBILE"
                        id="MOBILE"
                        value="MOBILE"
                        checked={publishType === "MOBILE"}
                        onChange={handlePublishType}
                      />
                      <label className="form-check-label" htmlFor="MOBILE">
                        FurrCrewApp
                      </label>
                    </div>  
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WEB"
                        id="WEB"
                        value="WEB"
                        checked={publishType === "WEB"}
                        onChange={handlePublishType}
                      />
                      <label className="form-check-label" htmlFor="WEB">
                        WebSite
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="BOTH"
                        id="BOTH"
                        value="BOTH"
                        checked={publishType === "BOTH"}
                        onChange={handlePublishType}
                      />
                      <label className="form-check-label" htmlFor="BOTH">
                        Both
                      </label>
                    </div>
                  </div>
                  <label className="lableText">
                    {nameTitle} Title
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control form-control-lg mt-2"
                    placeholder="Enter main blog title here"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                      <label className="lableText">
                        Custom Title
                      </label>
                      <input
                        type="text"
                        name="blogTitle"
                        className="form-control form-control-lg mt-2"
                        placeholder="Enter custom title here"
                        value={customTitle}
                        onChange={(e) => setCustomTitle(e.target.value)}
                      />
                </div>
                <div className="form-group">
                  <label className="lableText">Read Time*</label>
                  <div
                    className="col-md-12"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <select name="secondaryBlogTitle" className="form-control form-control-lg mt-2" value={time} onChange={(e) => setTime(e.target.value)}>
                      <option value="">Select Time</option>
                      {timeOptions.map((option) => (
                        <option key={option} value={option}>
                          {option} min
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label className="lableText">
                    Body Text
                    <span className="required">*</span>
                  </label>

                  <Editor
                    apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                    value={bodyText}
                    init={{
                      selector: "textarea#basic-example",
                      height: 500,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                <div className="mt-5">
                  <button onClick={BlogAdd} type="submit" className="btn btn-primary modalFooterButton">
                    Update
                  </button>
                  <button onClick={() => navigate(-1)} type="button" className="btn btn-secondary modalFooterButton">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <h5>Internal Note</h5>
            <div className="chat-container">
              <div className="chat-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <div className="message-text">{message.text}</div>
                    <div className="message-time">{getCurrentLocalDateTime()}</div>
                  </div>
                ))}
                {internalNotes.map((note) => (
                  <div key={note.id} className={`message bot`}>
                    <div className="message-text">{note.note}</div>
                    <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                  </div>
                ))}
              </div>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                <textarea
                  className="form-control"
                  rows={5}
                  type="text"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                  <button className="form-control" style={{ width: 100 }} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBlogDetails;
