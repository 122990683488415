import React, { useState, useEffect } from "react";
import {
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFileExport,
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import { usePetparentState } from "../views/services/petparentState";
import { useServiceList } from "../views/services/serviceListState";
const AppointmentTopBar = ({ setSearchQuery, onClick }) => {
  const api = new Apis();
  const { getServiceListData } = useServiceList();
  const [petListing, setPetListing] = useState([]);
  const [workspaceServices, setWorkspaceServices] = useState([]);
  const [WorkspacelistId, setWorkspacelistId] = useState(null);
  const { getPetparentData, getPetParentDatabyId } = usePetparentState();
  const [petParent] = useState(getPetparentData());
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [numberofPets, setNumberofPets] = useState([]);
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [selectedPetParentId, setSelectedPetParentId] = useState(null);
  const [contact, setContact] = useState();
  const [selectedWorkspaceServices, setSelectedWorkspaceServices] = useState(
    []
  )
  const [selectedService, setSelectedService] = useState("Veterinary");
  const [selectedFilter, setselectedFilter] = useState("Veterinary");
  const [selectedWorkplaceNames, setSelectedWorkplaceNames] = useState("");
  const navigate = useNavigate();
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update the search query state
  };
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");
  useEffect(() => {
    const fetchPetsForSelectedParent = async () => {
      if (selectedPetParentId) {
        try {
          const pets = await api.getPets(selectedPetParentId);
          const contact = getPetparentData(selectedPetParentId);
          setContact(contact);
          setNumberofPets(pets);
          console.log(pets);
        } catch (error) {
          console.error("Error fetching pets:", error);
        }
      } else {
        setNumberofPets([]);
      }
    };
    fetchPetsForSelectedParent();
  }, [selectedPetParentId]);
  useEffect(() => {
    const serviceData = getServiceListData(selectedService);
    console.log(serviceData);
    setWorkspacelistId(serviceData);
  }, [selectedService]); // Remove getServiceListData from the dependency array  
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceData = await api.getWorkspace(WorkspacelistId.id);
        setSelectedWorkspacDatas(workspaceData);
        console.log(workspaceData);
        const designationNames = workspaceData.map(
          (item) => item.designationName
        );
        const designationServices = workspaceData.map((item) => item.services);
        setWorkspaceOptions(designationNames);
        setWorkspaceServices(designationServices);
        console.log("WorkspaceData", workspaceData);
      } catch (error) {
        console.error("Error fetching pets:", error);
      }
    };
    fetchWorkspace();
  }, [WorkspacelistId]);
  const [selectedPetId, setSelectedPetId] = useState(null);
  useEffect(() => {
    setPetListing(numberofPets.map((pet) => pet.petName));
    setSelectedPetId(numberofPets);
  }, [numberofPets]);
  useEffect(() => {
    if (petListing.length > 0 && selectedPetId) {
      const selectedPet = numberofPets.find(
        (pet) => pet.petName === selectedPetId.name
      );
      if (selectedPet) {
        console.log("Selected Pet:", selectedPet);
      }
    }
  }, [petListing, selectedPetId, numberofPets]);
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    try {
      const selectedWorkspace = workspaceDatas.find(
        (workspace) => workspace.designationName === event.target.value
      );
      if (selectedWorkspace) {
        setSelectedWorkspaceServices(selectedWorkspace.services);
        setSelectedWorkplaceNames(selectedWorkspace.workplaceName);
      } else {
        setSelectedWorkspaceServices([]);
        setSelectedWorkplaceNames("");
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };
  const handleFilterApply = () => {
    toggleFilterModal();
  };
  return (
    <div
      className="col-md-12 mt-2"
      style={{ justifyContent: "space-between", display: "flex" }}
    >
      <div>
        <button style={styles.iconButton}>
          <Button
            style={styles.exportButton}
            color="#ffffff"
            onClick={toggleFilterModal}
          >
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faFilter} />
            <span style={{ marginLeft: "5px" }}>Filter</span>
          </Button>
        </button>
        <Modal isOpen={isFilterModalOpen} toggle={toggleFilterModal}>
          <ModalHeader toggle={toggleFilterModal}>Filter Options</ModalHeader>
          <ModalBody>
            <div className="form-group mt-2">
              <label className="radioBtnText">Select Filter by</label>
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="WorkPlace"
                    id="WorkPlace"
                    value="WorkPlace"
                    checked={selectedFilter === "WorkPlace"}
                    onChange={() => setselectedFilter("WorkPlace")}
                  />
                  <label className="form-check-label" htmlFor="WorkPlace">
                    WorkPlace
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="PetParent"
                    id="PetParent"
                    value="PetParent"
                    checked={selectedFilter === "PetParent"}
                    onChange={() => setselectedFilter("PetParent")}
                  />
                  <label className="form-check-label" htmlFor="PetParent">
                    Pet Parent
                  </label>
                </div>
              </div>
            </div>
            {/* ----------WorkSpace----------- */}
            {selectedFilter === "WorkPlace" && (
              <div>
                <div className="form-group mt-2">
                  <label className="radioBtnText">Select Service</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service"
                        id="Veterinary"
                        value="Veterinary"
                        checked={selectedService === "Veterinary"}
                        onChange={() => setSelectedService("Veterinary")}
                      />
                      <label className="form-check-label" htmlFor="Veterinary">
                        Veterinary
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service"
                        id="Groomer"
                        value="Groomer"
                        checked={selectedService === "Grooming"}
                        onChange={() => setSelectedService("Grooming")}
                      />
                      <label className="form-check-label" htmlFor="Groomer">
                        Groomer
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-2">
                  <label htmlFor="inputPetsSpecies">Select Collaborator</label>
                  <select
                    id="inputPetsSpecies"
                    className="form-control"
                    onChange={handleWorkspaceChange}
                    value={selectedWorkspaceName}
                  >
                    {workspaceOptions.map((workspace) => (
                      <option key={workspace} value={workspace}>
                        {workspace}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-2">
                  <label htmlFor="inputPetsSpecies">WorkPlace Name</label>
                  <select id="inputPetsSpecies" className="form-control">
                    <option value="" selected>
                      {selectedWorkplaceNames}
                    </option>
                  </select>
                </div>
              </div>
            )}
            {/* -----------Pet Parent------------ */}
            {selectedFilter === "PetParent" && (
              <div>
                <div className="form-group mt-2">
                  <label htmlFor="inputPetParent">Select Pet Parent</label>
                  <select
                    id="inputPetParent"
                    className="form-control"
                    onChange={(e) => setSelectedPetParentId(e.target.value)}
                  >
                    <option value="" selected>
                      Select a Pet Parent
                    </option>
                    {petParent &&
                      petParent.map((parent, index) => (
                        <option key={index} value={parent.id}>
                          {parent.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group mt-2">
                  <label htmlFor="inputPetName">Select Pet Name</label>
                  <select id="inputPetName" className="form-control">
                    <option selected>Choose a Pet</option>
                    {petListing.map((pet, index) => (
                      <option key={index} value={pet}>
                        {pet}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-2">
                  <label htmlFor="inputPetName">Contact</label>
                  {/* {contact && contact.mobile ? contact.mobile : "null"} */}
                  <input
                    id="myInput"
                    type="text"
                    name="myCountry"
                    value={contact && contact.mobile ? contact.mobile : "null"}
                    className="form-control"
                    disabled={true}
                  />
                </div>
              </div>
            )}
            <div>
              <div className="form-group mt-2">
                <label htmlFor="inputPetName">From</label>
                {/* {contact && contact.mobile ? contact.mobile : "null"} */}
                <input
                  id="myInput"
                  type="date"
                  name="myCountry"
                  className="form-control"
                />
              </div>
            </div>
            <div>
              <div className="form-group mt-2">
                <label htmlFor="inputPetName">To</label>
                {/* {contact && contact.mobile ? contact.mobile : "null"} */}
                <input
                  id="myInput"
                  type="date"
                  name="myCountry"
                  className="form-control"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleFilterApply}>
              Apply
            </Button>
            <Button color="secondary" onClick={toggleFilterModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={styles.findInput}>
          <InputGroup>
            <Button>
              <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
            </Button>
            <Input
              placeholder="Find Something"
              onChange={handleSearchInputChange} // Call the handler on input change
            />
          </InputGroup>
        </div>

        <button style={styles.iconButton}>
          <Button onClick={onClick} style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon
              color="#6B7280"
              fontSize={20}
              icon={faFileExport}
            />
            <span style={{ marginLeft: "5px" }}>Export</span>
          </Button>
        </button>
        <button style={styles.iconButton}>
          <Button
            style={styles.addButton}
            color="#ffffff"
            onClick={() => navigate("/AppointmentList/AddAppointment")}
          >
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
            <span style={{ marginLeft: "5px" }}>New Appointment</span>
          </Button>
        </button>
      </div>
    </div>
  );
};

const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default AppointmentTopBar;
