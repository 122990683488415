import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "reactstrap";
const NewTemplate = () => {
  const [bodyText, setBodyText] = useState("");

  const handleEditorChange = (content) => {
    setBodyText(content);
  };

  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
      <span style={{ fontSize: 20 }}>
        <strong>New Template</strong>
      </span>
      <div style={{ flexDirection: "row" }} className="mt-2">
        <span>Template Name*</span>
        <div class="col-md-6 mt-1">
          <div>
          <input
                class="form-control"
                placeholder="Enter template Name"
              />
          </div>
        </div>
        
        <div className="mt-3">
          <span>Mail Header*</span>
          <div class="col-md-12 mt-1">
            <div>
              <input
                class="form-control"
                placeholder="Enter Mail Header"
              />
            </div>
          </div>
        </div>
      
        <div className="mt-3">
        <span>Mail Body*</span>
        <div className="col-md-12 mt-1">
          <Editor
            apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
            value={bodyText} 
            init={{
              selector: "textarea#basic-example",
              height: 500,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
            }}
            onEditorChange={handleEditorChange}  
          />
        </div>
      </div>
      <div style={{flexDirection:'row'}} className="mt-3">
      <Button style={{marginRight:5,backgroundColor:'#0514A7'}}>Send Template</Button>
      <Button style={{marginRight:5}}>Preview</Button>
      <Button style={{background:'#F43F5E',border:'none',}}>Cancel</Button>
      </div>
      </div>
    </div>
  );
};

export default NewTemplate;
