import React, { createContext, useReducer } from "react";
const initialState = {
  workspaceState: [],
  petparentState: [],
  collaboratorState: [],
  serviceListState: [],
  petsState: [],
  appointmentState: [],
  rollsState: [],
  adoptionState: [],
  blogState: [],
  couponState: [],
  eventState: [],
  webinarState: [],
  courseState: [],
  advertismentState: [],
  faqState: [],
};
function reducer(state, action) {
  switch (action.type) {
    case "workspaceState":
      return { ...state, workspaceState: action.payload };
    case "petparentState":
      return { ...state, petparentState: action.payload };
    case "collaboratorState":
      return { ...state, collaboratorState: action.payload };
    case "serviceListState":
      return { ...state, serviceListState: action.payload };
    case "petsState":
      return { ...state, petsState: action.payload };
    case "appointmentState":
      return { ...state, appointmentState: action.payload };
    case "rollsState":
      return { ...state, rollsState: action.payload };
    case "adoptionState":
      return { ...state, adoptionState: action.payload };
    case "blogState":
      return { ...state, blogState: action.payload };
    case "couponState":
      return { ...state, couponState: action.payload };
    case "eventState":
      return { ...state, eventState: action.payload };
    case "webinarState":
      return { ...state, webinarState: action.payload };
    case "courseState":
      return { ...state, courseState: action.payload };
    case "advertismentState":
      return { ...state, advertismentState: action.payload };
      case "faqState":
        return { ...state, faqState: action.payload };
    default:
      return state;
  }
}
export function WorkspaceStateProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
}

export const AppContext = createContext();
