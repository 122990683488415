import React, { useState } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import dataURItoBlob, { isAlphaNumeric } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import LoaderModal from "../component/common/Loader";
import { isValidEmail, isValidMobile } from "../utils/helpers";
const AddCollaborator = () => {
  const api = new Apis();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [gender, setGender] = useState("Male");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [city,setCity]= useState();
  const [latLng, setLatLng] = useState("0.0, 0.0");
  const [imageName,setImageName]=useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancelImage = () => {
    setSelectedImage(null);
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };
  const addCollaborator = async () => {
    try {
      if (!name || !isAlphaNumeric(name)) {
        throw new Error("Please fill out the valid collaborator name.");
      }
      if (!isValidMobile(phone)) {
        throw new Error("Please fill out a valid phone number.");
      }
      if (!isValidEmail(email)) {
        throw new Error("Please fill out a valid email address.");
      }
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      const requestBody = {
        countryCode: "+91",
        mobile: phone,
        collaboratorName: name,
        email: email,
        isVerified: false,
        isEmailVerified: false,
        city: city,
        status: "Created",
      };
      setLoading(true);
      const response = await api.addCollaborator(requestBody);
      setLoading(false);
      console.log("API response:", response);
      if (response.id) {
        console.log(response.id);
        setLoading(true);
        await uploadImage(response.id);
        setLoading(false);
        toast.success("Collaborator added successfully!");
      } else {
        toast.error("Collaborator image not Uploaded");
        setLoading(false);
        throw new Error("Response ID is undefined.");
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Request failed with status code 500") {
        toast.error("Error: Phone number or email is duplicate");
      } else {
        toast.error("Error: " + error.message);
      }
      console.error("Error adding collaborator:", error);
    }
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(selectedImage),fileName);
      setLoading(true);
      const response = await api.uploadCollaboratorImage(userId, formData);
      setLoading(false);
      console.log("Img", response);
    } catch (error) {
      setLoading(false);
      console.error("Error uploading image:", error);
      toast.error("Error uploading image:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingRight: "20%", paddingLeft: "20%" }}>
          <span style={{ fontSize: 20 }}>
            <strong>Add Collaborator</strong>
          </span>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div className="col-md-6">
              <label for="inputName"> Name* </label>
              <input type="text" className="form-control" placeholder="Enter name" required value={name} onChange={(e) => setName(e.target.value)} />
              {/* {(!isNameValid) ? null: ""} */}
            </div>
            <div style={{ marginLeft: 20 }} className="col-md-6">
              <label for="inputEmail"> Email* </label>
              <input type="text" className="form-control" placeholder="Enter Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div className="col-md-6">
              <label for="inputName"> Phone* </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Mobile no."
                required
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setPhone(e.target.value);
                  }
                }}
                maxLength="10"
              />
            </div>
            <div style={{ marginLeft: 20 }} className="col-md-6">
              <span>City*</span>
              <input type="text" className="form-control" placeholder="Enter City" value={city}  onChange={(e) => setCity(e.target.value)} />
            </div>
            {/* <div style={{ marginLeft: 20 }} className="col-md-6">
              <label for="inputName"> Location</label>
              <input type="text" className="form-control" value={"18.01923123,18.00093482"} placeholder="Enter Location" required disabled={true} />
            </div> */}
            {/* <div style={{ marginLeft: 20 }} className="col-md-6">
              <span>Location*</span>
              <input type="text" className="form-control" placeholder="Enter Latitude, Longitude" value={latLng} onChange={(e) => setLatLng(e.target.value)} />
            </div> */}
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
          {/* <div className="col-md-6">
            <label htmlFor="inputPetsSpecies">Gender</label>
            <select id="inputPetsSpecies" className="form-control" onChange={(e) => setGender(e.target.value)} value={gender}>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div> */}
            </div>
          <label className="mt-2" for="inputName">
            Upload Image*
          </label>
          <div className="mt-2 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: "#EEEEEE",
                borderRadius: 5,
                height: 200,
                width: "50%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {selectedImage ? (
                <>
                  <img
                    src={selectedImage}
                    alt="Uploaded"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                  <div style={{ position: "absolute", top: 10, right: 10 }}>
                    <Button style={{ backgroundColor: "red", border: "none" }} onClick={handleCancelImage}>
                      <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                    </Button>
                  </div>
                </>
              ) : (
                <label htmlFor="uploadInput">
                  <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleFileChange} />
                  <Button onClick={handleUploadClick} color="secondary" as="span">
                    Upload
                  </Button>
                </label>
              )}
            </div>
          </div>
          {/* {selectedImage && (
        <Button color="danger" onClick={handleCancelImage}>
          Cancel
        </Button>
      )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button onClick={addCollaborator} style={{ marginRight: 10 }} color="primary">
              Add
            </Button>
            <Button color="secondary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCollaborator;
