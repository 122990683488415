import React, { useState, useEffect } from "react";
import { Button,Form,FormGroup,Input } from "reactstrap";
import { useAdoptionState } from "./services/adoptionState";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import ConfirmDialog from "../component/common/ConfirmDialog";
import { Editor } from "@tinymce/tinymce-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isAlphaNumeric, isValidMobile, isValidPinCode, isNum } from "../utils/helpers";
import dataURItoBlob from "../utils/helpers";
import LoaderModal from "../component/common/Loader";
const EditAdoption = () => {
  const api = new Apis();
  const { id } = useParams();
  const { getAdoptionListData } = useAdoptionState();
  // Define state variables and initial values for prepopulation
  const [adoption] = useState(getAdoptionListData(id));
  const [listingType, setListingType] = useState(adoption ? adoption.listedBy : null);
  const [phoneNumber, setPhoneNumber] = useState(adoption ? adoption.mobile : null);
  const [userName, setUserName] = useState(adoption ? adoption.refId : null);
  const [address, setAddress] = useState(adoption ? adoption.address : null);
  const [City, setCity] = useState(adoption ? adoption.city : null);
  const [state, setState] = useState(adoption ? adoption.state : null);
  const [pincode, setPincode] = useState(adoption ? adoption.pincode : null);
  const [name, setName] = useState(adoption ? adoption.petName : null);
  const [breed, setBreed] = useState(adoption ? adoption.breed : null);
  const [sex, setSex] = useState(adoption ? adoption.sex : null);
  const [link, setLink] = useState(adoption ? adoption.link : null);
  const [size, setSize] = useState(adoption ? adoption.size : null);
  const [coatLength, setCoatLength] = useState(adoption ? adoption.coatLength : null);
  const [color, setColor] = useState(adoption ? adoption.color : null);
  const [weight, setWeight] = useState(adoption ? adoption.weight : null);
  const [petStory, setPetStory] = useState(adoption ? adoption.story : null);
  // const [images, setImages] = useState(adoption && adoption.imagesList ? adoption.imagesList.map((image) => image.value) : []);
  const [images, setImages] = useState(
    adoption.imagesList && adoption.imagesList.length > 0
      ? adoption.imagesList.map((image) => ({
          value: image.value,
          key: image.key,
        }))
      : []
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState(adoption.type); // Default value
  const [breeds, setBreeds] = useState([]); // State for breeds
  const [newlyAddedImages, setNewlyAddedImages] = useState([]);
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          // Handle case when token is not available
          console.error("Token not available");
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}admin/v1/super/admin/pet/category?type=${selectedSpecies}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setBreeds(data || []);
      } catch (error) {
        console.error("Error fetching pet category:", error);
      }
    };
    fetchCategory();
  }, [selectedSpecies]);
  const handleDelete = () => {
    console.log("Deleting...");
  };
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const handlePhoneNumberChange = (e) => {
    if (e.target.value.length <= 10) {
      setPhoneNumber(e.target.value);
    }
  };
  const handleUserNameChange = (e) => setUserName(e.target.value);
  const handleAddress = (e) => setAddress(e.target.value);
  const handleCityChange = (e) => setCity(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleSelectedSpeciesChange = (e) => setSelectedSpecies(e.target.value);
  const handleBreedChange = (e) => setBreed(e.target.value);
  const handleSexType = (event) => {
    setSex(event.target.value);
  };
  const handleSize = (event) => {
    setSize(event.target.value);
  };
  const handleCoatLength = (event) => {
    setCoatLength(event.target.value);
  };
  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  const handleWeightChange = (e) => setWeight(e.target.value);
  const handlePetStoryChange = (e) => setPetStory(e.target.value);
  const handleStateChange = (e) => setState(e.target.value);
  const handlePincodeChange = (e) => setPincode(e.target.value);
  const handleLinkChange = (e) => setLink(e.target.value);
  const [uploading, setUploading] = useState(false);
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];
    const newlyAdded = [...newlyAddedImages];
    // Ensure that the total number of images does not exceed 5
    if (updatedImages.length + files.length > 5) {
      toast.error("You can upload a maximum of 5 images.");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImage = { key: Date.now(), value: e.target.result };
        updatedImages.push(newImage);
        newlyAdded.push(newImage.value);
        setImages(updatedImages);
        setNewlyAddedImages(newlyAdded);
        setUploading(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    try {
      const formData = new FormData();
      const currentDatetime = new Date().toISOString().replace(/[:]/g, "-").slice(0, -5); // Format: YYYYMMDDHHmm
      for (let i = 0; i < newlyAddedImages.length; i++) {
        const timestampedName = `image_${currentDatetime}_${i + 1}.png`;
        formData.append("files", dataURItoBlob(newlyAddedImages[i]), timestampedName);
        console.log("Adoptopn Name", timestampedName);
      }
      // Call the API with only newly added images
      setLoading(true);
      const workspaceImage = await api.updateAdoptionImage(id, formData);
      setLoading(false);
      // Handle success if needed
      console.log(workspaceImage);
      setUploading(false);
      toast.success("Uploaded New Image Successfully");
    } catch (error) {
      console.error("Error uploading images:", error);
      // Handle error appropriately, e.g., show a notification to the user
      setLoading(false);
      toast.error("Cannot Upload New Image");
    }
  };
  const handleDeleteImage = async (index, imageid) => {
    const updatedImages = [...images];
    const updatedNewlyAddedImages = [...newlyAddedImages];
    updatedImages.splice(index, 1);
    updatedNewlyAddedImages.splice(index, 1);
    setImages(updatedImages);
    setNewlyAddedImages(updatedNewlyAddedImages);
    setLoading(true);
    try {
      const deleteImages = await api.deleteAdoptionImage(id, adoption.refId, {
        data: [imageid],
      });
      console.log("Adoption Image:", deleteImages);
      toast.success("Deleted Adoption Image Successfully");
    } catch (error) {
      console.error("Error deleting Adoption image:", error);
      toast.error("Error deleting Adoption image");
    } finally {
      setLoading(false);
    }
  };
  const handleListingTypeChange = (event) => {
    setListingType(event.target.value);
  };
  const editAdoption = async () => {
    try {
      // if (!name || !isAlphaNumeric(name)) {
      //   throw new Error("Name is a required field");
      // }
      if (!address) {
        throw new Error("Address is a required field");
      }
      if (!City) {
        throw new Error("City is a required field");
      }
      if (!state) {
        throw new Error("State is a required field");
      }
      if (!selectedSpecies) {
        throw new Error("Species is a required field");
      }
      if (!breed) {
        throw new Error("Breed is a required field");
      }
      if (!sex) {
        throw new Error("Sex is a required field");
      }
      if (!color) {
        throw new Error("Color is a required field");
      }
      if (!weight) {
        throw new Error("Weight is a required field");
      }
      if (!size) {
        throw new Error("Size is a required field");
      }
      if (!coatLength) {
        throw new Error("Coat Length is a required field");
      }
      if (!petStory) {
        throw new Error("Pet Story is a required field");
      }
      if (!thumbselectedImage && !preloadedCoverImage) {
        throw new Error("Cover Image is a required field");
      }
      const requestBody = {
        id: id,
        refId: (() => {
          switch (listingType) {
            case "INDIVIDUAL":
              return selectedPetParent;
            case "NGOs":
              return selectedNgo;
            case "3rd Parties":
              return userName;
            default:
              return userName;
          }
        })(),
        petName: name,
        address: address,
        city: City || null,
        state: state,
        pincode: pincode || null,
        type: selectedSpecies,
        breed: breed,
        sex: sex,
        month: months,
        year: years,
        color: color,
        weight: weight,
        size: size,
        coatLength: coatLength,
        story: petStory,
        status: adoption.status,
        mobile: phoneNumber,
        link: link || null,
        phone: phoneNumber,
        listedBy: listingType,
        active: adoption.active,
        featured:featuredSwitchState,
        sponsored:switchState
      };
      //todo add loader here
      setLoading(true);
      const response = await api.updateAdoption(requestBody);
      if (thumbselectedImage) {
        uploadCoverImage(id);
      } else {
        setLoading(false);
      }
      setLoading(false);
      if (response) {
        toast.success("Edit Completed");
      } else {
        throw new Error("Response not generated");
      }
      console.log("API response:", response);
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.error("" + error);
    }
  };
  const navigate = useNavigate();
  const [years, setYears] = useState(parseInt(adoption.year) || 0);
  const [months, setMonths] = useState(parseInt(adoption.month) || 0);
  const handleYearIncrement = () => {
    setYears(parseInt(years, 10) + 1);
  };
  const handleYearDecrement = () => {
    if (years > 0) {
      setYears(parseInt(years, 10) - 1);
    }
  };
  const handleMonthIncrement = () => {
    if (months < 11) {
      setMonths(parseInt(months, 10) + 1);
    } else {
      setMonths(0);
      // Increment years only if it's not already 1
      if (years !== 1) {
        setYears(parseInt(years, 10) + 1);
      }
    }
  };
  const handleMonthDecrement = () => {
    if (months > 0) {
      setMonths(parseInt(months, 10) - 1);
    } else {
      if (years > 0) {
        setMonths(11);
        setYears(parseInt(years, 10) - 1);
      }
    }
  };

  const [indexItem, setIndexItem] = useState("");
  const [imageId, setImageID] = useState("");
  const [ngoList, setNgoList] = useState([]);
  const [petParent, setPetParentList] = useState([]);
  const [selectedPetParent, setSelectedPetParent] = useState();
  const [selectedNgo, setSelectedNgo] = useState();
  useEffect(() => {
    const fetchpetParent = async () => {
      try {
        const fetchpetParent = await api.getPetParentList();
        setPetParentList(fetchpetParent);

        // Prepopulate Pet Parent dropdown if listingType is INDIVIDUAL
        if (adoption.listedBy === "INDIVIDUAL") {
          setSelectedPetParent(adoption.refId);
        }
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchpetParent();
  }, [adoption.listedBy, adoption.refId]);
  useEffect(() => {
    const listNgo = async () => {
      try {
        const listNgo = await api.getWorkspace("5e4a1033-c48e-11ed-abc1-02817ef0919c");
        setNgoList(listNgo);

        // Prepopulate NGO dropdown if listingType is NGOs
        if (adoption.listedBy === "NGOs") {
          setSelectedNgo(adoption.refId);
        }
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    listNgo();
  }, [adoption.listedBy, adoption.refId]);
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "adoption",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const [thumbselectedImage, setThumbSelectedImage] = useState(null);
  const [preloadedCoverImage, setPreloadedCoverImage] = useState(null);
  const handleThumbImageUpload = (e) => {
    const file = e.target.files[0];
    const img = new Image();

    img.onload = function () {
      if (img.width === 1920 && img.height === 1080) {
        setThumbSelectedImage(file);
      } else {
        toast.error("Thumb image must be 1920 by 1080 pixels.");
      }
    };

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleThumbImageDelete = () => {
    setThumbSelectedImage(null);
  };
  useEffect(() => {
    if (adoption.coverImage && adoption.coverImage.length > 0) {
      const coverImage = new Image();
      coverImage.src = adoption.coverImage;
      coverImage.onload = () => setPreloadedCoverImage(coverImage);
    }
  }, [adoption.coverImage]);
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbselectedImage) {
        console.log("No new image selected for upload.");
        return;
      }
      const timestamp = Date.now();
      const fileData = thumbselectedImage.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      console.log(userId);
      const formData = new FormData();
      formData.append("file", thumbselectedImage, fileName);
      const response = await api.uploadCoverImg("ADOPTION", userId, formData);
      console.log("CoverImg", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const [switchState, setSwitchState] = useState(adoption.sponsored);
  const [featuredSwitchState, setfeaturedSwitchState] = useState(adoption.featured);
  const handleSwitchChange = () => {
    try {
      const newSwitchState = !switchState;
      setSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const handleFeturedSwitch = () => {
    try {
      const newSwitchState = !featuredSwitchState;
      setfeaturedSwitchState(newSwitchState);
    } catch (error) {
      console.error("error");
    }
  };
  const [testState, setTeststate] = useState(adoption.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateTestStatus("ADOPTION", adoption.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <span style={{ fontSize: 20 }}>
            <strong>Edit Listing</strong>
          </span>
          <div className="row">
            <div style={{ flexDirection: "row" }} className="mt-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="listingType"
                  id="INDIVIDUAL"
                  value="INDIVIDUAL"
                  checked={listingType === "INDIVIDUAL"}
                  onChange={handleListingTypeChange}
                />
                <label className="form-check-label" htmlFor="INDIVIDUAL">
                  INDIVIDUAL
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="listingType"
                  id="NGOs"
                  value="NGOs"
                  checked={listingType === "NGOs"}
                  onChange={handleListingTypeChange}
                />
                <label className="form-check-label" htmlFor="NGOs">
                  NGOs
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="listingType"
                  id="3rd Parties"
                  value="3rd Parties"
                  checked={listingType !== "NGOs" && listingType !== "INDIVIDUAL"}
                  onChange={handleListingTypeChange}
                />
                <label className="form-check-label" htmlFor="3rd Parties">
                  3rd Parties
                </label>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Featured</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={featuredSwitchState} onChange={handleFeturedSwitch} />
                  </FormGroup>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>Sponsored</label>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={switchState} onChange={handleSwitchChange} />
                  </FormGroup>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>Test</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                    </FormGroup>
                  </Form>
                </div>
            </div>
            <div className="pt-2">
              <div>
                <span style={{ fontWeight: 600 }}>General user information*</span>
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <span>Phone Number*</span>
                <input
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  placeholder="Enter Phone Number*"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </div>
              <div style={{ marginLeft: 20 }}>
                {listingType === "INDIVIDUAL" && (
                  <div style={{ marginLeft: 20 }}>
                    <span>Pet Parent*</span>
                    <select
                      style={{ borderRadius: 6 }}
                      className="form-control"
                      value={selectedPetParent}
                      onChange={(e) => setSelectedPetParent(e.target.value)}
                    >
                      <option selected value="" disabled>
                        Select Pet Parent
                      </option>
                      {petParent.map((petParent) => (
                        <option key={petParent.id} value={petParent.id}>
                          {petParent.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {listingType === "NGOs" && (
                  <div style={{ marginLeft: 20 }}>
                    <span>NGO*</span>
                    <select style={{ borderRadius: 6 }} className="form-control" value={selectedNgo} onChange={(e) => setSelectedNgo(e.target.value)}>
                      {/* map through ngoList to populate options */}
                      <option selected value="" disabled>
                        Select NGO
                      </option>
                      {ngoList.map((ngo) => (
                        <option key={ngo.id} value={ngo.id}>
                          {ngo.workplaceName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {listingType !== "NGOs" && listingType !== "INDIVIDUAL" && (
                  <div style={{ marginLeft: 20 }}>
                    <span>User Name</span>
                    <input
                      style={{ borderRadius: 6 }}
                      className="form-control"
                      placeholder="Enter User Name"
                      value={userName}
                      onChange={handleUserNameChange}
                    />
                  </div>
                )}
              </div>
              <div style={{ marginLeft: 20 }}>
                <span>Link</span>
                <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter Link" value={link} onChange={handleLinkChange} />
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <span>City*</span>
                <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter City" value={City} onChange={handleCityChange} />
              </div>
              <div style={{ marginLeft: 20 }}>
                <span>State*</span>
                <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter State " value={state} onChange={handleStateChange} />
              </div>
              <div style={{ marginLeft: 20 }}>
                <span>Pincode</span>
                <input style={{ borderRadius: 6 }} className="form-control" placeholder="Enter Pincode" value={pincode} onChange={handlePincodeChange} />
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <span>Address*</span>
                <input
                  style={{ borderRadius: 6, width: "475px" }}
                  className="form-control"
                  placeholder="Enter User Name"
                  value={address}
                  onChange={handleAddress}
                />
              </div>
            </div>
            <div className="pt-2">
              <div>
                <span style={{ fontWeight: 600 }}>Listing Information*</span>
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="col-md-2">
                <span>Name*</span>
                <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. Oscar*" value={name} onChange={handleNameChange} />
              </div>
              <div className="col-md-2" style={{ marginLeft: 20 }}>
                <span>Species*</span>
                <select id="inputPetsSpecies" className="form-control " value={selectedSpecies} onChange={handleSelectedSpeciesChange}>
                  <option value="dog">Dog</option>
                  <option value="cat">Cat</option>
                  <option value="bird">Bird</option>
                  <option value="exotic">Exotic</option>
                </select>
              </div>
              <div className="col-md-2" style={{ marginLeft: 20 }}>
                <span>Breed*</span>
                <select id="inputPetBreed" className="form-control" value={breed} onChange={handleBreedChange}>
                  <option value="" disabled selected>
                    Select a breed
                  </option>
                  {breeds.map((breed, index) => (
                    <option key={index} value={breed}>
                      {breed}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="col-md-2">
                <span>Sex*</span>
                {/* <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. Male*" value={sex} onChange={(e) => setSex(e.target.value)} /> */}
                <select id="inputSex" className="form-control " value={sex} onChange={handleSexType}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div style={{ marginLeft: 20, textAlign: "center" }}>
                <span>Age*</span>
                <div>
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={handleYearDecrement}
                  >
                    <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                  </Button>
                  {years} years
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={handleYearIncrement}
                  >
                    <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={handleMonthDecrement}
                  >
                    <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                  </Button>
                  {months} months
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={handleMonthIncrement}
                  >
                    <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                  </Button>
                </div>
              </div>
              <div className="col-md-2" style={{ marginLeft: 20 }}>
                <span>Size*</span>
                {/* <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. Small" value={size} onChange={(e) => setSize(e.target.value)} /> */}
                <select id="inputSex" className="form-control " value={size} onChange={handleSize}>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                </select>
              </div>
            </div>
            <div
              className="pt-2"
              style={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="col-md-2">
                <span>Coat Length*</span>
                <select id="inputSex" className="form-control " value={coatLength} onChange={handleCoatLength}>
                  <option value="Short">Short</option>
                  <option value="Medium">Medium</option>
                  <option value="Long">Long</option>
                </select>
                {/* <input
            style={{ borderRadius: 6 }}
            className="form-control"
            placeholder="Eg. Short"
            value={coatLength}
            onChange={(e) => setCoatLength(e.target.value)}
          /> */}
              </div>
              <div className="col-md-2" style={{ marginLeft: 20 }}>
                <span>Color*</span>
                {/* <input style={{ borderRadius: 6 }} className="form-control" placeholder="Eg. White" value={color} onChange={(e) => setColor(e.target.value)} /> */}
                <select id="inputSex" className="form-control " value={color} onChange={handleColorChange}>
                  <option value="Black">Black</option>
                  <option value="White">White</option>
                  <option value="Golden">Golden</option>
                  <option value="Brown">Brown</option>
                  <option value="Black & White">Black & White</option>
                  <option value="Brown & White"> Brown & White</option>
                </select>
              </div>
              <div className="col-md-2" style={{ marginLeft: 20 }}>
                <span>Weight*</span>
                <input
                  type="number"
                  style={{ borderRadius: 6 }}
                  className="form-control"
                  placeholder="Eg. 13 kgs"
                  value={weight}
                  onChange={handleWeightChange}
                />
              </div>
            </div>
            <div className="mt-2">
              <span>Pet's Story*</span>
              <input
                style={{ borderRadius: 6, height: 100 }}
                className="form-control"
                placeholder="Write Something..."
                value={petStory}
                onChange={handlePetStoryChange}
              />
            </div>
            <div className="mt-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <strong>Media</strong>
                </div>
                <div>
                  {uploading ? (
                    <Button onClick={handleSave}>Save</Button>
                  ) : (
                    <Button>
                      <label
                        htmlFor="imageUpload"
                        style={{
                          marginRight: 5,
                          border: "none",
                          padding: "5px 10px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Upload
                        <input type="file" id="imageUpload" multiple onChange={handleImageUpload} style={{ display: "none" }} />
                      </label>
                    </Button>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {images.map((image, index) => (
                  <div key={image.key} className="image-item" style={{ position: "relative", marginRight: 10 }}>
                    <img src={image.value} className="img-fluid" style={{ width: 100, height: "auto" }} alt={`Image ${index}`} />
                    <Button
                      style={{ background: "transparent", border: "none" }}
                      onClick={() => {
                        setIsConfirmOpen(true);
                        setIndexItem(index);
                        setImageID(image.key);
                      }}
                      className="delete-button"
                    >
                      <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                    </Button>
                  </div>
                ))}
              </div>
              <div className="mt-2">
                <label className="pt-10 lableText">
                  Cover Image
                  <span className="required">*</span>
                </label>
                <div className="imageUploadBox">
                  <div className="text-right">
                    <label htmlFor="coverUpload" className="btn btn-light navbar-buttons">
                      <span className="vetUploadButton">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                      </span>
                      <span className="navbar-button-text pl-2">Upload</span>
                      <input id="coverUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                    </label>
                    {thumbselectedImage && (
                      <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                        <span className="vetUploadButton">
                          <FontAwesomeIcon fontSize={20} icon={faTrash} />
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex flex-wrap">
                        <div className="col-md-4 d-flex align-items-center pt-3">
                          {thumbselectedImage ? (
                            <img src={URL.createObjectURL(thumbselectedImage)} alt="Uploaded" style={{ width: 200 }} />
                          ) : (
                            preloadedCoverImage && <img src={preloadedCoverImage.src} alt="Prepopulated" style={{ width: 200 }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <h5>Internal Note</h5>
              <div className="chat-container">
                <div className="chat-messages">
                  {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                      <div className="message-text">{message.text}</div>
                      <div className="message-time">{getCurrentLocalDateTime()}</div>
                    </div>
                  ))}
                  {internalNotes.map((note) => (
                    <div key={note.id} className={`message bot`}>
                      <div className="message-text">{note.note}</div>
                      <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                    </div>
                  ))}
                </div>
                <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                  <textarea
                    className="form-control"
                    rows={5}
                    type="text"
                    placeholder="Type your message..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                  />
                  <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                    <button className="form-control" style={{ width: 100 }} type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="mt-5" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div>
                  <Button
                    style={{
                      marginRight: 5,
                      background: "#007bff",
                      border: "none",
                    }}
                    onClick={editAdoption}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      marginRight: 5,
                      border: "none",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <ConfirmDialog
              isOpen={isConfirmOpen}
              toggle={() => setIsConfirmOpen(!isConfirmOpen)}
              onConfirm={() => handleDeleteImage(indexItem, imageId)}
              message="Are you sure you want to Delete Image?"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EditAdoption;
