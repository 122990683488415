import { useContext } from 'react';
import { AppContext } from './states';

export function useServiceList() {
  const { state, dispatch } = useContext(AppContext);
  const currentServiceListData = state.serviceListState;
  const setServiceListData = data => {
    // Save data to session storage
    sessionStorage.setItem('serviceListData', JSON.stringify(data));
    dispatch({ type: 'serviceListState', payload: data });
  };
  const getServiceListData = type => {
    // Check session storage for data
    const storedData = sessionStorage.getItem('serviceListData');
    const serviceListData = storedData ? JSON.parse(storedData) : currentServiceListData;
    return serviceListData && serviceListData.length > 0
      ? serviceListData.find(i => i.type === type) || null
      : null;
  };
  const getServiceListDatabyId = id => {
    // Check session storage for data
    const storedData = sessionStorage.getItem('serviceListData');
    const serviceListData = storedData ? JSON.parse(storedData) : currentServiceListData;
    return serviceListData && serviceListData.length > 0
      ? serviceListData.find(i => i.id === id) || null
      : null;
  };
  return {
    ServiceListData: currentServiceListData,
    setServiceListData,
    getServiceListData,
    getServiceListDatabyId,
  };
}
