import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button } from "reactstrap";

const WorkspaceTopBar = ({ setSearchQuery, onClick }) => {
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const navigate = useNavigate();
  return (
    <div style={styles.topBar}>
      <div style={styles.leftButtons}>
        <div style={styles.findInput}>
        <InputGroup>
            <Button>
              <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
            </Button>
            <Input
              placeholder="Find Something"
              onChange={handleSearchInputChange}
            />
          </InputGroup>
        </div>
        <button style={styles.iconButton}>
          <Button onClick={onClick} style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faFileExport} />
            <span style={{ marginLeft: "5px" }}>Export</span>
          </Button>
        </button>
      </div>
      <div style={styles.rightButtons}>
        <button style={styles.iconButton}>
          <Button
            style={styles.addButton} // Add this new style
            color="#ffffff"
          >
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
            <a href onClick={() => navigate("/WorkSpaceList/NewWorkspace")}>
              <span style={{ marginLeft: "5px" }}>New Workspace</span>
            </a>
          </Button>
        </button>
      </div>
    </div>
  );
};
const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end", // Use space-between to separate left and right buttons
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center", // Align the items vertically
  },
  findInput: {
    marginRight: "10px", // Adjust spacing between input and export button
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default WorkspaceTopBar;
