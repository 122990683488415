import { useContext } from 'react';
import { AppContext } from './states';

export function usePetState() {
  const { state, dispatch } = useContext(AppContext);
  const currentpetData = state.petState;
  return {
    petData: currentpetData,
    setpetData: data => {
      dispatch({ type: 'petState', payload: data });
    },
    getpetData: id => {
      if (id) {
        return currentpetData && currentpetData.length > 0
          ? currentpetData.find(i => i.id === id) || null
          : null;
      } else {
        return currentpetData;
      }
    },
  };
}
