import React, { useState } from "react";
import { Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import "../component/css/Loader.css";
import { isAlphaNumeric, isValidEmail, isValidMobile } from "../utils/helpers";
import dataURItoBlob from "../utils/helpers";
import LeafletMap from "../component/common/LeafletMap";
import "react-toastify/dist/ReactToastify.css";
const AddUser = () => {
  const navigate = useNavigate();
  const api = new Apis();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [city, setCity] = useState();
  const [gender, setGender] = useState("Male");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const toggleModal = () => {
    setIsOpen(!modalIsOpen);
  };
  const [imageName,setImageName]=useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancelImage = () => {
    setSelectedImage(null);
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };
  const [latLng, setLatLng] = useState("0.0, 0.0");
  const addUser = async () => {
    try {
      if (!name || !isAlphaNumeric(name)) {
        throw new Error("Please fill out the name.");
      }
      if (!isValidMobile(phone)) {
        throw new Error("Please fill out a valid phone number.");
      }
      if (!isValidEmail(email)) {
        throw new Error("Please fill out a valid email address.");
      }
      if (!gender) {
        throw new Error("Please select a gender.");
      }
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      const requestBody = {
        countryCode: "+91",
        mobile: phone,
        name: name,
        email: email,
        verified: false,
        emailVerified: false,
        city: city,
        gender: gender,
        sos: false,
        sosTill: "",
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
        status: "Created",
      };
      setLoading(true);
      const response = await api.addPetParent(requestBody);
      setLoading(false);  
      console.log("API response:", response);
      toast.success("User added successfully!");
      if (response.id) {
        await uploadImage(response.id);
        setLoading(false);
        // navigate(-1);
      } else {
        setLoading(false);
        throw new Error("Response ID is undefined.");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      setLoading(false);
      //Added validation for existing user if already present
      if (error.message === "Request failed with status code 500") {
        toast.error("Error: Phone number or email is duplicate");
      } else {
        toast.error("Error: " + error.message);
      }
    }
  };
  const uploadImage = async (userId) => {
    try {
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("file", dataURItoBlob(selectedImage),fileName);
      const response = await api.uploadPetParentImage(userId, formData);
      console.log("Img", response);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingRight: "20%", paddingLeft: "20%" }}>
          <div>
            <span style={{ fontSize: 20 }}>
              <strong>Add PetParent</strong>
            </span>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div className="col-md-6">
                <label for="inputName"> Name*</label>
                <input type="text" className="form-control" placeholder="Enter name" required value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div style={{ marginLeft: 20 }} className="col-md-6">
                <label for="inputEmail"> Email*</label>
                <input type="text" className="form-control" placeholder="Enter Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div className="col-md-6">
                <label for="inputName"> Phone*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile no."
                  required
                  value={phone}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setPhone(e.target.value);
                    }
                  }}
                />
              </div>
              <div style={{ marginLeft: 20 }} className="col-md-6">
              <span>Location*</span>
              <input type="text" className="form-control" placeholder="Enter Latitude, Longitude" value={latLng} onChange={(e) => setLatLng(e.target.value)} />
            </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div className="col-md-6">
                <label htmlFor="inputPetsSpecies">Gender*</label>
                <select id="inputPetsSpecies" className="form-control" onChange={(e) => setGender(e.target.value)} value={gender}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div style={{ marginLeft: 20 }} className="col-md-6">
                <label htmlFor="inputPetsSpecies">City*</label>
                <input type="text" className="form-control" placeholder="Enter City" required value={city} onChange={(e) => setCity(e.target.value)} />
              </div>
            </div>
            <label className="mt-2" for="inputName">
              Upload Image*
            </label>
            <div className="mt-2 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: 5,
                  height: 200,
                  width: "50%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {selectedImage ? (
                  <>
                    <img
                      src={selectedImage}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                    <div style={{ position: "absolute", top: 10, right: 10 }}>
                      <Button style={{ backgroundColor: "red", border: "none" }} onClick={handleCancelImage}>
                        <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                      </Button>
                    </div>
                  </>
                ) : (
                  <label htmlFor="uploadInput">
                    <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleFileChange} />
                    <Button onClick={handleUploadClick} color="secondary" as="span">
                      Upload
                    </Button>
                  </label>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button style={{ marginRight: 10 }} color="primary" onClick={addUser}>
                Add
              </Button>
              <Button onClick={() => navigate(-1)} color="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddUser;
