import React, { useState } from "react";
import { Input, InputGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFileExport,
  faSearch,
  faFileImport,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const CollaboratorTopBar = ({ setSearchQuery,onClick }) => { // Accept setSearchQuery as a prop
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(""); // New state for the search input
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value); // Update the search input state
    setSearchQuery(event.target.value); // Update the search query state in the parent component
  };
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}  className="mt-2" >
      {/* <div style={styles.leftButtons}>
        <button style={styles.iconButton}>
          <Button style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon
              color="#6B7280"
              fontSize={20}
              icon={faFilter}
            />
            <span style={{ marginLeft: "5px" }}>Filter</span>
          </Button>
        </button>
      </div> */}
      <div style={styles.rightButtons}>
        <div style={styles.findInput}>
          <InputGroup>
            <Button>
              <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
            </Button>
            <Input
              placeholder="Find Something"
              value={searchInput}
              onChange={handleSearchInputChange} // Call the handler on input change
            />
          </InputGroup>
        </div>

        <button style={styles.iconButton}>
          <Button onClick={onClick}  style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon
              color="#6B7280"
              fontSize={20}
              icon={faFileExport}
            />
            <span style={{ marginLeft: "5px" }}>Export</span>
          </Button>
        </button>
        {/* <button style={styles.iconButton}>
          <Button style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon
              color="#6B7280"
              fontSize={20}
              icon={faFileImport}
            />
            <span style={{ marginLeft: "5px" }}>Import</span>
          </Button>
        </button> */}
        <div style={styles.rightButtons}>
          <button style={styles.iconButton}>
            <Button
              style={styles.addButton}
              color="#ffffff"
              onClick={() => navigate("/CollaboratorList/AddCollaborator")}
            >
              <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
              <span style={{ marginLeft: "5px" }}>Add Collaborator</span>
            </Button>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default CollaboratorTopBar;
