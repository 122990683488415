import { useContext } from 'react';
import { AppContext } from './states';

export function useFaqData() {
  const { state, dispatch } = useContext(AppContext);
  const currentFaqData = state.faqState;
  const setfaqData = data => {
    sessionStorage.setItem('faqData', JSON.stringify(data));
    dispatch({ type: 'faqState', payload: data });
  };
  const getfaqData = id => {
    const storedData = sessionStorage.getItem('faqData');
    const faqData = storedData ? JSON.parse(storedData) : currentFaqData;
    if (id) {
      return faqData && faqData.length > 0
        ? faqData.find(i => i.id === id) || null
        : null;
    } else {
      return faqData;
    }
  };
  return {
    faqData: currentFaqData,
    setfaqData ,
    getfaqData,
  };
}
