import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import moment from "moment";
import { type } from "os";
import dataURItoBlob from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import { useAdvertismentState } from "./services/advertismentState";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
const EditAdvertisment = () => {
  const api = new Apis();
  const { id } = useParams();
  const { getadvertismentData } = useAdvertismentState();
  const [advertismentData] = useState(getadvertismentData(id));
  console.log(advertismentData);
  const [loading, setLoading] = useState(false);
  const [advertisementFor, setAdvertisementFor] = useState(advertismentData.type || "");
  const [selectedImages, setSelectedImages] = useState(
    advertismentData.file ? [advertismentData.file] : [] // Prepopulate the selected images
  );
  const [isVideoAllowed, setIsVideoAllowed] = useState(false);
  const [selectedPetParentId, setSelectedPetParentId] = useState(null);
  const [isListDetails, setIsListDetails] = useState(advertismentData.subType === "List");
  const [subtype, setSubType] = useState();
  const [title, setTitle] = useState(advertismentData.title || "");
  const [discription, setDescription] = useState(advertismentData.description || "");
  const [startDate, setStartDate] = useState(moment(advertismentData.startDate).format("yyyy-MM-DD"));
  const [newStartDate, setNewStartDate] = useState();
  const [newEndDate, setNewEndDate] = useState();
  const [endDate, setEndDate] = useState(moment(advertismentData.endDate).format("yyyy-MM-DD"));
  const handleStartDate = (e) => {
    const inputDate = e.target.value;
    setStartDate(inputDate);
    const dateMoment = moment(inputDate);
    const milliseconds = dateMoment.valueOf();
    setNewStartDate(milliseconds);
  };
  const handleEndDate = (e) => {
    const inputDate = e.target.value;
    const dateMoment = moment(inputDate);
    const milliseconds = dateMoment.valueOf();
    setEndDate(inputDate);
    setNewEndDate(milliseconds);
  };
  // const handleRadioChange = (e) => {
  //   setIsInternalLink(e.target.value === "option1");
  // };
  const handleListRadio = (e) => {
    setIsListDetails(e.target.value === "List");
    const setSubtype = e.target.value;
    setSubType(setSubtype);
  };
  const handleAdvertisementChange = (e) => {
    const selectedOption = e.target.value;
    setAdvertisementFor(selectedOption);
    setIsVideoAllowed(selectedOption === "ROLL");
  };
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const [fileInputKey, setFileInputKey] = useState(0);
  // const handleImageChange = (e) => {
  //   const files = e.target.files;
  //   const selectedFiles = [];
  //   for (let i = 0; i < files.length; i++) {
  //     selectedFiles.push(URL.createObjectURL(files[i]));
  //   }
  //   setSelectedImages(selectedFiles);
  // };
  const handleImageChange = (event) => {
    const files = event.target.files;
    const updatedImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedImages.push(e.target.result);
        setSelectedImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
    // Clear prepopulated image
    if (advertismentData.file) {
      setSelectedImages([]);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleRadioChangee = (e) => {
    const value = e.target.value;
    setIsVideoAllowed(value === "Video");
    setSelectedImages([]);
    setFileInputKey((prevKey) => prevKey + 1);
  };
  const [webinarList, setWebinarList] = useState();
  const [COURSE, setCouseList] = useState();
  useEffect(() => {
    // Fetch additional data if needed
    // For example, fetching webinar and course lists
    const fetchWebinarList = async () => {
      try {
        const webinarList = await api.getWebinar();
        setWebinarList(webinarList);
      } catch (error) {
        console.error("Error fetching Advertisment:", error);
      }
    };
    const fetchCourseList = async () => {
      try {
        const courseList = await api.getCourse();
        setCouseList(courseList);
      } catch (error) {
        console.error("Error fetching Course:", error);
      }
    };
    // Call the fetch functions
    fetchWebinarList();
    fetchCourseList();
  }, []);

  const addAdvertisment = async () => {
    if (!title || !discription || !startDate || !endDate) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (advertisementFor === "WEBINAR" && isListDetails && !selectedPetParentId) {
      toast.error("Please select an Advertisment.");
      return;
    }

    if (advertisementFor === "COURSE" && isListDetails && !selectedPetParentId) {
      toast.error("Please select a Course.");
      return;
    }

    if (isInternalLink && !internalLink) {
      toast.error("Please enter the Internal Link.");
      return;
    }

    if (!isInternalLink && !externalLink) {
      toast.error("Please enter the External Link.");
      return;
    }

    if (selectedImages.length === 0) {
      toast.error("Please upload a workspace image.");
      return;
    }
    try {
      const requestBody = {
        id: id,
        title: title || advertismentData.title,
        description: discription || advertismentData.description,
        status: "Active",
        startDate: newStartDate || advertismentData.startDate,
        endDate: newEndDate || advertismentData.endDate,
        advertiserName: "aac",
        rawData: "axcfde",
        filePath: "bhn",
        type: advertisementFor || advertismentData.type,
        externalLink: isInternalLink ? null : externalLink,
        internalLink: isInternalLink ? internalLink : null,
        viewedCount: 2,
        subType: subtype === "Details" ? "Details" : "List",
        refId: subtype === "Details" ? selectedPetParentId : "",
      };
      setLoading(true);
      const response = await api.updateAdvertisment(id, requestBody);
      setLoading(false);

      console.log(response.id);
      if (advertisementFor === "ROLL" && isVideoAllowed) {
        // Handle video upload
        // Create a FormData for video upload
        const videoFormData = new FormData();
        for (let i = 0; i < selectedImages.length; i++) {
          videoFormData.append("file", dataURItoBlob(selectedImages[i]), `image_${i + 1}.png`);
        }
        const viewType = "video";
        videoFormData.append("viewType", viewType);
        setLoading(true);
        const videoResponse = await api.updateAdvertismentImage(response.id, videoFormData);
        setLoading(false);
        console.log(videoResponse);
      } else {
        // Handle image upload
        // Handle image upload
        const imageFormData = new FormData();

        for (let i = 0; i < selectedImages.length; i++) {
          imageFormData.append("file", dataURItoBlob(selectedImages[i]), `image_${i + 1}.png`);
        }
        // Add additional form dat
        const viewType = "image";
        imageFormData.append("viewType", viewType); // Corrected this line

        try {
          setLoading(true);
          const imageResponse = await api.updateAdvertismentImage(response.id, imageFormData);
          setLoading(false);
          console.log(imageResponse);
        } catch (error) {
          setLoading(false);
          console.error("Error uploading image:", error);
        }
      }
      toast.success("Edited Advertisment Successfully.");
    } catch (error) {
      console.error("Error adding Advertisment:", error);
      toast.error("Error Editing Advertisment.");
      setLoading(false);
    }
  };
  const [isInternalLink, setIsInternalLink] = useState(advertismentData.externalLink ? false : true);
  const [internalLink, setInternalLink] = useState(advertismentData.externalLink ? "" : advertismentData.internalLink);
  const [externalLink, setExternalLink] = useState(advertismentData.externalLink || "");

  const handleRadioChange = (e) => {
    setIsInternalLink(e.target.value === "option1");
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "advertisment",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <div className="row">
            <div className="col-md-12">
              <div>
                <form className="modalForm">
                  <span className="form-group" style={{ fontSize: 20 }}>
                    <strong>Edit Advertisement</strong>
                  </span>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Advertisement For</label>
                    <select id="inputPetsSpecies" className="form-control" onChange={handleAdvertisementChange} value={advertisementFor}>
                      <option value="ROLL">ROLL</option>
                      <option value="EVENT">EVENT</option>
                      <option value="BLOG">BLOG</option>
                      <option value="ARTICLE">ARTICLE</option>
                      <option value="WEBINAR">WEBINAR</option>
                      <option value="COURSE">COURSE</option>
                      <option value="VET">VET</option>
                      <option value="GROOMER">GROOMER</option>
                      <option value="NGO">NGO</option>
                      <option value="ADOPTION">ADOPTION</option>
                    </select>
                  </div>
                  {advertisementFor === "WEBINAR" && (
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Advertisement In</label>
                      <br />
                      <input className="form-check-input" type="radio" value="List" onChange={handleListRadio} checked={isListDetails} />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                        List
                      </label>
                      <input
                        style={{ marginLeft: 10 }}
                        className="form-check-input"
                        type="radio"
                        value="Details"
                        onChange={handleListRadio}
                        checked={!isListDetails}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                        Details
                      </label>
                      <div className="mt-2">
                        {isListDetails ? null : (
                          <select
                            id="inputPetParent"
                            className="form-control"
                            value={advertismentData.refId}
                            onChange={(e) => setSelectedPetParentId(e.target.value)}
                          >
                            <option value="" selected>
                              Select a Advertisment
                            </option>
                            {webinarList &&
                              webinarList.map((parent, index) => (
                                <option key={index} value={parent.id}>
                                  {parent.title}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                  {advertisementFor === "COURSE" && (
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Advertisement In</label>
                      <br />
                      <input className="form-check-input" type="radio" value="option1" onChange={handleListRadio} checked={isListDetails} />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                        List
                      </label>
                      <input
                        style={{ marginLeft: 10 }}
                        className="form-check-input"
                        type="radio"
                        value="option2"
                        onChange={handleListRadio}
                        checked={!isListDetails}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                        Details
                      </label>
                      <div className="mt-2">
                        {isListDetails ? null : (
                          <select id="inputPetParent" className="form-control" onChange={(e) => setSelectedPetParentId(e.target.value)}>
                            <option value="" selected>
                              Select a Course
                            </option>
                            {COURSE &&
                              COURSE.map((parent, index) => (
                                <option key={index} value={parent.id}>
                                  {parent.title}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group mt-2"></div>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Title</label>
                    <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Description</label>
                    <textarea value={discription} onChange={(e) => setDescription(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6 mt-2">
                      <label>Start Date</label>
                      <input value={startDate} onChange={handleStartDate} type="date" className="form-control mt-2" id="startDateTime" />
                    </div>
                    <div className="col-md-6 mt-2">
                      <label>End Date</label>
                      <input value={endDate} onChange={handleEndDate} type="date" className="form-control mt-2" id="endDateTime" />
                    </div>
                  </div>
                  <div className="form-group mt-2">
                    <label className="radioBtnText">Advertisement Link</label>
                    <br />
                    <input className="form-check-input" type="radio" value="option1" onChange={handleRadioChange} checked={isInternalLink} />
                    <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                      Internal Link
                    </label>
                    <input
                      style={{ marginLeft: 10 }}
                      className="form-check-input"
                      type="radio"
                      value="option2"
                      onChange={handleRadioChange}
                      checked={!isInternalLink}
                    />
                    <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                      External Link
                    </label>
                  </div>
                  <div className="form-group mt-2">
                    {isInternalLink ? (
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setInternalLink(e.target.value)}
                        value={internalLink}
                        placeholder="Enter Internal Link"
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setExternalLink(e.target.value)}
                        value={externalLink}
                        placeholder="Enter External Link"
                      />
                    )}
                  </div>
                  {advertisementFor === "ROLL" ? (
                    <div className="form-group mt-2">
                      <label className="radioBtnText">Upload</label>
                      <input
                        style={{ marginLeft: 5 }}
                        className="form-check-input"
                        type="radio"
                        value="Image"
                        checked={!isVideoAllowed}
                        onChange={handleRadioChangee}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio1">
                        Image
                      </label>
                      <input
                        style={{ marginLeft: 10 }}
                        className="form-check-input"
                        type="radio"
                        value="Video"
                        checked={isVideoAllowed}
                        onChange={handleRadioChangee}
                      />
                      <label style={{ marginLeft: 10 }} className="form-check-label" htmlFor="inlineRadio2">
                        Video
                      </label>
                      <input key={fileInputKey} type="file" accept={isVideoAllowed ? "video/*" : "image/*"} onChange={handleImageChange} />
                      <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                        {isVideoAllowed ? (
                          selectedImages[0] ? (
                            <div>
                              <video controls width="200" height="150">
                                <source src={selectedImages[0]} type="video/mp4" />
                              </video>
                              <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(0)} className="delete-button">
                                <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                              </Button>
                            </div>
                          ) : null
                        ) : (
                          selectedImages.map((image, index) => (
                            <div key={index}>
                              <img src={image} alt={`Selected Image ${index + 1}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                              <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(index)} className="delete-button">
                                <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                              </Button>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="form-group mt-2">
                      <label className="radioBtnText" style={{ marginRight: 10 }}>
                        Upload Image
                      </label>
                      <input key={fileInputKey} type="file" accept="image/*" onChange={handleImageChange} />
                      <div style={{ display: "flex", marginTop: 10 }}>
                        {selectedImages.map((image, index) => (
                          <div key={index}>
                            <img src={image} alt={`Selected Image ${index + 1}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                            <Button style={{ background: "transparent", border: "none" }} onClick={() => handleDeleteImage(index)} className="delete-button">
                              <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </form>
                <div style={{ marginTop: 20, padding: "0 25px" }}>
                    <h5>Internal Note</h5>
                    <div className="chat-container">
                      <div className="chat-messages">
                        {messages.map((message, index) => (
                          <div key={index} className={`message ${message.sender}`}>
                            <div className="message-text">{message.text}</div>
                            <div className="message-time">{getCurrentLocalDateTime()}</div>
                          </div>
                        ))}
                        {internalNotes.map((note) => (
                          <div key={note.id} className={`message bot`}>
                            <div className="message-text">{note.note}</div>
                            <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                          </div>
                        ))}
                      </div>
                      <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                        <textarea
                          className="form-control"
                          rows={5}
                          type="text"
                          placeholder="Type your message..."
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                        />
                        <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                          <button className="form-control" style={{ width: 100 }} type="submit">
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={addAdvertisment} style={{ marginRight: 10 }} color="primary">
                    Add
                  </Button>
                  <Button color="secondary">Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAdvertisment;
