import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFileExport,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { InputGroup, Input, Button } from "reactstrap";

const WebinarTopBar = ({ setSearchQuery ,onClick}) => {
  const navigate = useNavigate();
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); 
  };
  return (
    <div style={styles.topBar}>
      <div style={styles.leftButtons}>
        <div style={styles.findInput}>
        <InputGroup>
            <Button>
              <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
            </Button>
            <Input
              placeholder="Find Something"
              onChange={handleSearchInputChange}
            />  
          </InputGroup>
        </div>
        <button style={styles.iconButton}>
          <Button  onClick={onClick} style={styles.exportButton} color="#ffffff">
            <FontAwesomeIcon
              color="#6B7280"
              fontSize={20}
              icon={faFileExport}
            />
            <span style={{ marginLeft: "5px" }}>Export</span>
          </Button>
        </button>
      </div>
      <div style={styles.rightButtons}>
        <button style={styles.iconButton}>
          <Button style={styles.addButton} color="#ffffff">
            <FontAwesomeIcon color="#6B7280" fontSize={20} icon={faPlus} />
            <a onClick={() => navigate("/WebinarList/AddWebinar")}>
              <span style={{ marginLeft: "5px" }}>Add Webinar</span>
            </a>
          </Button>
        </button>
      </div>
    </div>
  );
};

const styles = {
  topBar: {
    display: "flex",
    justifyContent: "flex-end", // Use space-between to separate left and right buttons
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

export default WebinarTopBar;
