import { useContext } from 'react';
import { AppContext } from './states';

export function useCouponState() {
  const { state, dispatch } = useContext(AppContext);
  const currentCouponData = state.couponState;
  const setCouponData = data => {
    sessionStorage.setItem('couponData', JSON.stringify(data));
    dispatch({ type: 'couponState', payload: data });
  };
  const getCouponData = id => {
    const storedData = sessionStorage.getItem('couponData');
    const couponData = storedData ? JSON.parse(storedData) : currentCouponData;

    return couponData && couponData.length > 0
      ? couponData.find(i => i.id === id) || null
      : null;
  };
  return {
    couponData: currentCouponData,
    setCouponData,
    getCouponData,
  };
}
