import React, { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api";
import AdminTopBar from "../../component/AdminTopBar";

const AdminList = () => {
  const api = new Apis();
  const [adminData, setAdminListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminDatas = await api.getAdminList();
        adminDatas.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAdminListData(adminDatas);
      } catch (error) {
        console.error("Error fetching Data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = ["checkbox", ...Object.keys(adminData[0] || {})];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = adminData.slice(indexOfFirstItem, indexOfLastItem);

  const navigate = useNavigate();

  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const totalPages = Math.ceil(adminData.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <>
      <AdminTopBar />
      <Table hover>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                {column === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === currentItems.length
                          ? []
                          : currentItems.map((_, index) => index)
                      )
                    }
                  />
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => toggleRowSelection(rowIndex)}
                />
              </td>
              {columns.slice(1).map((column, colIndex) => (
                <td key={colIndex}>
                  {column === "createdAt" ? (
                    new Date(item[column]).toLocaleDateString('en-US', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric'
                    })
                  ) : typeof item[column] === "boolean" ? (
                    item[column].toString() // Convert boolean to string
                  ) : (
                    item[column]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ marginTop: "10px" }}>
        {pageNumbers.map((pageNum) => (
          <Button
            key={pageNum}
            onClick={() => handlePageClick(pageNum)}
            style={{ marginRight: "5px" }}
            color={pageNum === currentPage ? "primary" : "secondary"}
          >
            {pageNum}
          </Button>
        ))}
      </div>
    </>
  );
};

export default AdminList;
