import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import EmailTopBar from '../../component/EmailTopBar';

const data = [
  {
    name: 'Otto',
    designation: 'Pune',
    userName: 'VSHARMA02',
    dateJoined: '15 Apr 2023',
    access: '15 Apr 2023',
  },
];

const EmailList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = ['checkbox', ...Object.keys(data[0])];

  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };

  return (
    <>
      <EmailTopBar />
      <Table hover>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                {column === 'checkbox' ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === data.length}
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === data.length ? [] : data.map((_, index) => index)
                      )
                    }
                  />
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => toggleRowSelection(rowIndex)}
                />
              </td>
              {columns.slice(1).map((column, colIndex) => (
                <td key={colIndex}>
                  {column === 'name' ? (
                    <Link to="/EmailList/EmailDetails" style={{ cursor: 'pointer' }}>
                      {item[column]}
                    </Link>
                  ) : (
                    item[column]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default EmailList;
