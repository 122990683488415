import { useContext } from 'react';
import { AppContext } from './states';

export function useAdoptionState() {
  const { state, dispatch } = useContext(AppContext);
  const currentAdoptionData = state.adoptionState;
  const setAdoptionListData = data => {
    sessionStorage.setItem('adoptionData', JSON.stringify(data));
    dispatch({ type: 'adoptionState', payload: data });
  };
  const getAdoptionListData = id => {
    const storedData = sessionStorage.getItem('adoptionData');
    const adoptionData = storedData ? JSON.parse(storedData) : currentAdoptionData;
    return adoptionData && adoptionData.length > 0
      ? adoptionData.find(i => i.id === id) || null
      : null;
  };
  return {
    AdoptionListData: currentAdoptionData,
    setAdoptionListData,
    getAdoptionListData,
  };
}
