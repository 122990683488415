import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faAdd, faXmark, faEllipsisV, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import dataURItoBlob, { isAlphaOnly, isNum, isValidPinCode } from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import LoaderModal from "../component/common/Loader";
import { useEventState } from "./services/eventState";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../component/common/ConfirmDialog";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table,Form,FormGroup,Input } from "reactstrap";
import { Link } from "react-router-dom";
import LeafletMap from "../component/common/LeafletMap";
import "react-toastify/dist/ReactToastify.css";
const data2 = [
  {
    Id: "#1238",
    Name: "Abhay",
    Phone: "8007692186",
    "View Date": "22-10-2023",
    Status: "Completed",
    Paid: "250",
  },
];
const EditEventDetails = () => {
  const api = new Apis();
  const { id } = useParams();
  const { getEventData } = useEventState();
  const [eventData] = useState(getEventData(id));
  const navigate = useNavigate();
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const columns2 = ["checkbox", ...Object.keys(data2[0])];
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const [title, setTitle] = useState(eventData.title);
  const [pincode, setPincode] = useState(eventData.address.pinCode);
  const [state, setState] = useState(eventData.address.state);
  const [city, setCity] = useState(eventData.address.city);
  const [address, setAddress] = useState(eventData.address.address);
  const [description, setDescription] = useState(eventData.description);
  const [customTitle, setCustomTitle] = useState(eventData.slug || "");
  const [entryFee, setEntryFee] = useState("");
  const [link, setLink] = useState(eventData.bookingLink);
  const [tags, setTags] = useState(eventData.tags);
  // const [link, setLink] = useState(eventData.bookingLink);
  // const [tags, setTags] = useState(eventData.tags);
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [thumbselectedImage, setthumbSelectedImage] = useState(null);
  const [listingType, setListingType] = useState(eventData.eventType);
  const [eventDateTime, setEventDateTime] = useState(moment(eventData.scheduledDate).format("YYYY-MM-DDTHH:mm"));
  const [newTimeUnix, setNewTimeUnix] = useState();
  const [loading, setLoading] = useState(false);
  const [inputDate, setInputDate] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmEventPrice, setConfirmEvent] = useState(false);
  const [terms, setTerms] = useState(eventData.terms);
  const [coordinates, setCoordinates] = useState({
    lat: parseFloat(eventData.address.latitude),
    lng: parseFloat(eventData.address.longitude),
  });
  const [IsMapOpen, setIsMapOpen] = useState(false);
  const toggleMapModal = () => {
    setIsMapOpen(!IsMapOpen);
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await api.deleteEvents(id);
      toast.success("Event deleted successfully!");
      console.log(response);
      navigate(-1);
    } catch (error) {
      console.error("Error deleting the event:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleListingTypeChange = (event) => {
    setListingType(event.target.value);
  };
  const handleEditorChange = (content) => {
    setDescription(content);
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0]; // Assuming only one file is selected

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        setSelectedImage(imageDataUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleTermsChnage = (content) => {
    setTerms(content);
  };
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000;
    setEventDateTime(inputDate);
    setNewTimeUnix(unixTimestamp);
  };
  const timeOptions = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);
  const [entryFees, setEntryFees] = useState([
    {
      eventPrice: "",
      ticketType: "",
      maxPerson: "",
    },
  ]);
  const [eventPrice, setEventPrice] = useState();
  const [ticketType, setTicketType] = useState();
  const [maxPerson, setMaxPerson] = useState();
  const addEntryFee = async () => {
    try {
      if (!eventPrice || !isNum(eventPrice)) {
        throw new Error("Invalid event price.");
      }
      if (!isAlphaOnly(ticketType)) {
        throw new Error("Invalid ticket type.");
      }
      if (!maxPerson || !isNum(maxPerson)) {
        throw new Error("Invalid max persons.");
      }
      const requestBody = {
        eventId: id,
        eventPrice: eventPrice,
        ticketType: ticketType,
        maxPerson: maxPerson,
      };
      const updatedEntryFees = entryFees ? [...entryFees, requestBody] : [requestBody];
      setEntryFees(updatedEntryFees);
      setLoading(true);
      const response = await api.addEventPrice(requestBody);
      setLoading(false);
      toast.success("Added Event Price Successfully");
      console.log(response);
      setModalOpen(false);
      setEventPrice("");
      setTicketType("");
      setMaxPerson("");
    } catch (error) {
      setLoading(false);
      toast.error("Error: " + error.message);
      console.error("Error adding event:", error);
    }
  };
  const handleDeleteImage = () => {
    // Set selectedImage to null when deleting
    setSelectedImage(null);
  };
  useEffect(() => {
    // Populate entryFees with the data from eventData.prices
    setEntryFees(eventData.prices);
  }, [eventData.prices]);
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  // Handle changes to entry fee inputs
  // const handleEntryFeeChange = (index, field, value) => {
  //   const updatedEntryFees = [...entryFees];
  //   updatedEntryFees[index][field] = value;
  //   setEntryFees(updatedEntryFees);
  // };
  const [deletedEntryId, setDeletedEntryId] = useState(null);
  const removeEntryFee = async (index) => {
    if (entryFees.length === 0) {
      return;
    }
    const deletedEntry = entryFees[index];
    console.log(`Deleted Entry ID: ${deletedEntry.id}`);
    try {
      setLoading(true);
      const priceDelete = await api.deletePrice(deletedEntry.id);
      console.log(priceDelete);
      const updatedEntryFees = [...entryFees];
      updatedEntryFees.splice(index, 1);
      setEntryFees(updatedEntryFees);
      toast.success("Deleted Price Successfully");
      setDeletedEntryId(deletedEntry.id);
    } catch (error) {
      toast.error("Error deleting entry: " + error.message);
      console.error("Error deleting entry:", error);
    } finally {
      setLoading(false);
    }
  };
  const [phone, setPhone] = useState(eventData.phone);
  console.log(coordinates.lat);
  const handleLocationChange = (e) => {
    const input = e.target.value;
    const [lat, lng] = input.split(",").map((coord) => parseFloat(coord.trim()));
    // Check if lat and lng are valid numbers
    if (!isNaN(lat) && !isNaN(lng)) {
      setCoordinates({ lat, lng });
      console.log("New Coordinates:", { lat, lng }); // Log the updated coordinates
    }
  };
  const EditEvent = async () => {  
    try {
      // Basic validation checks
      if (!city) {
        toast.error("City is required.");
      }
      if (!state) {
        toast.error("State is required.");
      }
      if (!isValidPinCode(pincode)) {
        toast.error("Pincode is invalid.");
      } 
      if (!address) {
        toast.error("Address is required.");
      }
      if (coordinates.lat === 0.0 && coordinates.lng === 0.0) {
        toast.error("Location is required field");
      }
      if (publishType === "WEB" || publishType === "BOTH") {
        if (!thumbselectedImage && !eventData.coverImage) {
          toast.error("Cover image is required for WEB or BOTH publishType.");
          return; // Add this line to stop further execution if cover image is missing
        }
      }
      
      if (!eventDateTime) {
       toast.error("Event date and time is required.");
      }
      if (!title) {
       toast.error("Title is required.");
      }
      if (!description) {
       toast.error("Description is required.");
      }
      if (!link) {
       toast.error("Booking Link is required.");
      }
      if (!listingType) {
       toast.error("Listing type is required.");
      }
      if (entryFees.length === 0) {
       toast.error("You must specify at least one entry fee.");
      }
      
      const requestBody = {
        id: id,
        scheduledDate: newTimeUnix || eventData.scheduledDate,
        title: title,
        description: description,
        bookingLink: link,
        eventType: listingType,
        entryFee: "500",
        status: "Active",
        likes: "2",
        shares: "4",
        terms: terms,
        phone: phone,
        deviceType: publishType,
        slug:customTitle,
        tba:tba,
        upcoming:upcoming
      };
      const addressBody = {
        id: eventData.address.id,
        address: address,
        city: city,
        state: state,
        pinCode: pincode,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      };
      setLoading(true);
      const response = await api.updateEvents(id, requestBody);
      const addressRes = await api.updateAddress(addressBody);
      setLoading(false);
      toast.success("Event edited successfully!");
      console.log(response);
      console.log(addressRes);
      if (id) {
        console.log(id);
        await uploadImage(id);
        setLoading(false);
      } else {
      }
      if (id) {
        console.log(id);
        await uploadCoverImage(id);
        setLoading(false);
      } else {
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error: " + error.response.data.message || error.message);
      console.error("Error adding event:", error);
    }
  };
  const [removeEntryIndex, setRemoveEntryIndex] = useState(null);
  const handleRemoveEntryFee = (index) => {
    setConfirmEvent(true);
    setRemoveEntryIndex(index);
  };
  const uploadImage = async (userId) => {
    try {
      if (!selectedImage) {
        throw new Error("No image selected for upload.");
      }
      console.log(userId);
      const formData = new FormData();
      const timestamp = Date.now();
      const fileData = imageName.name.split(".");
      const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
      formData.append("files", dataURItoBlob(selectedImage),fileName);
      setLoading(true);
      const response = await api.updateEventPoster(userId, formData);
      setLoading(false);
      console.log("Img", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const uploadCoverImage = async (userId) => {
    try {
      if (!thumbselectedImage) {
        throw new Error("No image selected for upload.");
      }
      console.log(userId);
      const formData = new FormData();
      const currentTimestamp = new Date().getTime();

      // Format the timestamp to use in the file name (you can adjust the format as needed)
      const formattedTimestamp = new Date(currentTimestamp).toISOString().replace(/[-:.]/g, "");

      // Append the formatted timestamp to the file name
      const fileName = `image_${formattedTimestamp}.png`;
      formData.append("file", dataURItoBlob(thumbselectedImage),fileName);
      setLoading(true);
      const response = await api.uploadEventCoverImage(userId, formData);
      setLoading(false);
      console.log("Img", response);
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  const [activeEntry, setActiveEntry] = useState(null);
  const toggleMenu = (index) => {
    if (activeEntry === index) {
      setActiveEntry(null);
    } else {
      setActiveEntry(index);
    }
  };
  const [editModal, setEditModal] = useState(false);
  const [selectedEntryFee, setSelectedEntryFee] = useState(null);
  const handleEditEntryFee = (index) => {
    console.log("Edit button clicked.");
    setSelectedEntryFee(entryFees[index]);
    setEditModal(true);
  };
  const handleSave = async () => {
    try {
      if (!selectedEntryFee.eventPrice) {
        throw new Error("Event price has invalid value.");
      }
      if (!selectedEntryFee.ticketType) {
        throw new Error("Ticket type has invalid value.");
      }
      if (!selectedEntryFee.maxPerson) {
        throw new Error("Max Person has invalid value.");
      }
      setLoading(true);
      const updatedEntryFees = entryFees.map((entry) => (entry.id === selectedEntryFee.id ? selectedEntryFee : entry));
      const updateEventPrice = await api.updateEventPrice(selectedEntryFee);
      setLoading(false);
      console.log(updateEventPrice);
      toast.success("Edited Event Price SuccessFully");
      setEntryFees(updatedEntryFees);
      console.log("Edited Entry Fee:", selectedEntryFee);
      setEditModal(false);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("An error occurred:", error);
    }
  };
  const handleImgupload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleImgChange);
    fileInput.click();
  };
  const [imageName,setImageName]=useState();
  const handleImgChange = (event) => {
    const file = event.target.files[0];
    setImageName(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 1920 && img.height === 1080) {
            setSelectedImage(e.target.result);
          } else {
            toast.error("Image should be 1920 x 1080");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleThmubImgupload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleThumbImgChange);
    fileInput.click();
  };
  const handleThumbImgChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
  
        image.onload = () => {
          if (image.width === 2160 && image.height === 2160) {
            setthumbSelectedImage(e.target.result);
          } else {
            // Display an error or take appropriate action
            toast.error("Image dimensions Should be 2160 x 2160 px");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingDetails, setBookingDetails] = useState(); // Corrected typo
  const [copiedItems, setCopiedItems] = useState({});
  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const fetchedBookingDetails = await api.getEventBookingDetails(id);
        fetchedBookingDetails.sort((a, b) => moment(b.scheduledDate).valueOf() - moment(a.scheduledDate).valueOf());

        // Parse the 'tickets' property into an array, handling undefined case
        const parsedBookingDetails = fetchedBookingDetails.map((detail) => ({
          ...detail,
          tickets: detail.tickets ? JSON.parse(detail.tickets) : [], // Check if 'tickets' is defined
        }));

        setBookingDetails(parsedBookingDetails);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };
    // Invoke the fetchBookingDetails function
    fetchBookingDetails();
    // Include 'id' in the dependencies array
  }, [id]);
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // Update the state to indicate that the item has been copied
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    // Clear the "Copied" message after a few seconds
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000); // 2000 milliseconds = 2 seconds
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(bookingDetails) && bookingDetails.length > 0 ? bookingDetails.slice(indexOfFirstItem, indexOfLastItem) : [];
  const customTableHeadings = ["checkbox", "ID", "Name", "Mobile", "City", "Created", "Email","Tickets"];
  const [publishType, setPublishType] = useState(eventData.deviceType||"MOBILE");
  const handlePublishType = (event) => {
    setPublishType(event.target.value);
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes()
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "appointments",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };  
  const [testState, setTeststate] = useState(eventData.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      setLoading(true);
      const response = await api.updateEventsTestStatus("EVENTS", eventData.id, newSwitchState);
      setLoading(false);
      toast.success("Updated Test Status");
      console.log(response);
    } catch (error) {
      // Handle API call errors
      setLoading(false);
      console.error("Error updating status:", error);
    }
  };
  const [tba, setTba] = useState(eventData.tba);
  const toggleTba = () => {
    setTba(!tba);
  };
  const [upcoming, setUpcoming] = useState(eventData.upcoming);
  const toggleUpcoming = () => {
    setUpcoming(!upcoming);
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div className="row mt-2">
          <div className="col-md-4 p-3 mx-3" style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
          <div className="form-group" style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>To be Announced</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={tba} onChange={toggleTba} />
                  </FormGroup>
                </Form>
              </div>
              {!tba && (<div>
            <div className="form-group mt-2">
              <label className="lableText">
                Enter City
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="city"
                className="form-control form-control-lg mt-2"
                placeholder="Enter City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label className="lableText">
                Enter State
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="State"
                className="form-control form-control-lg mt-2"
                placeholder="Enter state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label className="lableText">
                Event Pincode
                <span className="required">*</span>
              </label>
              <input
                type="number"
                name="blogTitle"
                className="form-control form-control-lg mt-2"
                placeholder="Enter Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label className="lableText">
                Event Address
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="blogTitle"
                className="form-control form-control-lg mt-2"
                placeholder="Event Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="form-group mt-2 z-2">
              <label className="lableText">
                Location
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="location"
                className="form-control mt-2"
                placeholder="Location (latitude, longitude)"
                value={`${coordinates.lat},${coordinates.lng}`}
                onChange={(e) => handleLocationChange(e)}
              />
            </div>
            </div>)}
            <div className="form-group mt-2">
              <label className="lableText">
                Event Google Sheet Link
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="blogTitle"
                className="form-control  mt-2"
                placeholder="Enter Event Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label className="lableText">
                Event Phone
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="blogTitle"
                className="form-control form-control-lg mt-2"
                placeholder="Event Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          
            <div className="form-group col-md-12 mt-2 z-2">
              <div className="">
                {/* <label>Poster Image Uploded</label> */}
                <div>
                  {selectedImage ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <img src={selectedImage} alt="Uploaded"  style={{ width: 200 }}  />
                      <Button onClick={handleImgupload} color="secondary" as="span" style={{ marginTop: 20 }}>
                        Upload New Poster
                      </Button>
                    </div>
                  ) : eventData.images && eventData.images.length > 0 ? (
                    <div>
                      <img src={eventData.images[0].value} alt="Prepopulated" style={{ width: 200 }} />
                      <div style={{ marginTop: 10 }}>
                        <Button onClick={handleImgupload} color="secondary" as="span">
                          Upload New Poster*
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="uploadInput">
                      {/* <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleImgChange} /> */}
                      <Button onClick={handleImgupload} color="secondary" as="span">
                        Upload New Poster*
                      </Button>
                    </label>
                  )}
                </div>
              </div>
            </div>
            {publishType !== "MOBILE" && (
            <div className="form-group col-md-12 mt-2 z-2">
              <div className="">
                {/* <label>Poster Image Uploded</label> */}
                <div>
                  {thumbselectedImage ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <img src={thumbselectedImage} alt="Uploaded"  style={{ width: 200 }}  />
                      
                      <Button onClick={handleThmubImgupload} className="col-md-7" color="secondary" as="span" style={{ marginTop: 20 }}>
                        Upload New Cover
                      </Button>
                    </div>
                  ) : eventData.coverImage && eventData.coverImage.length > 0 ? (
                    <div>
                      <img src={eventData.coverImage} alt="Prepopulated" style={{ width: 200 }} />
                      <div style={{ marginTop: 10 }}>
                        <Button onClick={handleThmubImgupload} color="secondary" as="span">
                          Upload New Cover*
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="uploadInput">
                      {/* <input type="file" id="uploadInput" style={{ display: "none" }} onChange={handleImgChange} /> */}
                      <Button onClick={handleThmubImgupload} color="secondary" as="span">
                        Upload New Cover*
                      </Button>
                    </label>
                  )}
                </div>
              </div>
            </div>
            )}
            {/* </div>
            </div> */}
          </div>
          <div className="col-md-7 p-3 mx-3" style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
            <div style={{}}>
              <div>
                <div className="mt-3 col-md-12" style={{ display: "flex", justifyContent: "space-between" }}>
                  <h4 className="form-group">Edit Event Details</h4>
                  <button
                    type="button"
                    style={{ backgroundColor: "red", border: "none" }}
                    className="btn btn-secondary modalFooterButton"
                    onClick={() => setIsConfirmOpen(true)}
                  >
                    Delete
                  </button>
                </div>
                <div>
                <div style={{ flexDirection: "row", marginTop: 10 }} className="form-group">
                  <div>
                    <label className="lableText">
                     <span style={{color:'#6b7280',fontWeight:600}}>Publish For</span> 
                      <span className="required">*</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="MOBILE" id="MOBILE" value="MOBILE" checked={publishType === "MOBILE"} onChange={handlePublishType} />
                    <label className="form-check-label" htmlFor="MOBILE">
                      FurrCrewApp
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="WEB"
                      id="WEB"
                      value="WEB"
                      checked={publishType === "WEB"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="WEB">
                      WebSite
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="BOTH"
                      id="BOTH"
                      value="BOTH"
                      checked={publishType === "BOTH"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="BOTH">
                      Both
                    </label>
                  </div>
                </div>
                <div className="col-md-4 form-group mt-2">
                <div style={{display:"flex",justifyContent:'space-between'}} className="mt-2">
                  <label>Test</label>
                  <Form>
                    <FormGroup switch>
                      <Input type="switch" role="switch" checked={testState} onChange={handleTestChange} />
                    </FormGroup>
                  </Form>
                </div>
                  </div>
                  <div className="col-md-6 form-group mt-2">
                    <label className="lableText">
                      Event Type
                      <span className="required">*</span>
                    </label>
                    <div style={{ flexDirection: "row" }} className="mt-2">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="listingType"
                          id="Online"
                          value="Online"
                          checked={listingType === "Online"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Online">
                          Online
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="listingType"
                          id="Offline"
                          value="Offline"
                          checked={listingType === "Offline"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Offline">
                          Offline
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-2">
                  <label className="lableText">
                    Event Title
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control form-control-lg mt-2"
                    placeholder="Enter Event title here"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
               
                     <div className="form-group mt-2">
                      <label className="lableText">
                        Custom Title
                      </label>
                      <input
                        type="text"
                        name="blogTitle"
                        className="form-control form-control-lg mt-2"
                        placeholder="Enter custom title here"
                        value={customTitle}
                        onChange={(e) => setCustomTitle(e.target.value)}
                      />
                      </div>
                <div className="form-group mt-2 z-0">
                  <label className="lableText">
                    Event description
                    <span className="required">*</span>
                  </label>
                  <Editor
                    apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                    value={description}
                    init={{
                      selector: "textarea#basic-example",
                      height: 500,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                <div className="form-group mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Upcoming</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={upcoming} onChange={toggleUpcoming} />
                  </FormGroup>
                </Form>
              </div>
              {!upcoming && (
                <div className="form-group mt-2 row">
                  <div className="col-md-6">
                    <label>Event Date and Time*</label>
                    <input
                      type="datetime-local"
                      className="form-control form-control-lg mt-2"
                      id="startDateTime"
                      value={eventDateTime}
                      onChange={handleDateChange}
                    />
                  </div>
                </div>
              )}
                <div className="form-group row mt-2">
                  <div className="col-md-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <FontAwesomeIcon color="#0514A7" style={{ cursor: "pointer" }} onClick={toggleModal} fontSize={25} icon={faAdd} />
                  </div>
                </div>
                <div>
                  {entryFees &&
                    entryFees.length > 0 &&
                    entryFees.map((entry, index) => (
                      <div key={index} className="form-group row mt-2">
                        <div className="col-md-3">
                          <label className="lableText">
                            Event Entry Fee
                            <span className="required">*</span>
                          </label>
                          <input type="number" name="entryFee" className="form-control mt-2" placeholder="Enter Event Fee" value={entry.eventPrice} disabled />
                        </div>
                        <div className="col-md-4">
                          <label>Ticket Type</label>
                          <input type="text" name="ticketType" className="form-control mt-2" value={entry.ticketType} disabled />
                        </div>
                        <div className="col-md-3">
                          <label>Max Person1</label>
                          <input disabled type="number" name="maxPerson" className="form-control  mt-2" value={entry.maxPerson} />
                        </div>
                        <div
                          className="col-md-2"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <FontAwesomeIcon color="#0514A7" style={{ cursor: "pointer" }} onClick={() => toggleMenu(index)} fontSize={20} icon={faEllipsisV} />
                            {activeEntry === index && (
                              <Dropdown
                                isOpen={true}
                                toggle={() => toggleMenu(index)}
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  top: 0,
                                }}
                              >
                                <DropdownToggle
                                  caret={false}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                >
                                  {/* Empty toggle button */}
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem onClick={() => handleEditEntryFee(index)}>
                                    <FontAwesomeIcon icon={faEdit} /> Edit Entry
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleRemoveEntryFee(index)}>
                                    <FontAwesomeIcon color="red" icon={faTrash} /> Remove Entry
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group  row ">
            <div className=" col-md-12">
              <label className="lableText">
                T&C
                <span className="required">*</span>
              </label>
              <Editor
                apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                value={terms}
                init={{
                  selector: "textarea#basic-example",
                  height: 500,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                }}
                onEditorChange={handleTermsChnage}
              />
            </div>
          </div>
          <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)}>
            <ModalHeader toggle={() => setEditModal(!editModal)}>Edit Entry Fee</ModalHeader>
            <ModalBody>
              {selectedEntryFee && (
                <div>
                  <label>Event Entry Fee*</label>
                  <input
                    type="number"
                    name="entryFee"
                    className="form-control mt-2"
                    placeholder="Enter Event Fee"
                    value={selectedEntryFee.eventPrice}
                    onChange={(e) => {
                      setSelectedEntryFee({
                        ...selectedEntryFee,
                        eventPrice: e.target.value,
                      });
                    }}
                  />
                  <label>Ticket Type*</label>
                  <input
                    type="text"
                    name="ticketType"
                    className="form-control mt-2"
                    value={selectedEntryFee.ticketType}
                    onChange={(e) => {
                      setSelectedEntryFee({
                        ...selectedEntryFee,
                        ticketType: e.target.value,
                      });
                    }}
                  />
                  <label>Max Person*</label>
                  <input
                    type="number"
                    name="maxPerson"
                    className="form-control mt-2"
                    value={selectedEntryFee.maxPerson}
                    onChange={(e) => {
                      setSelectedEntryFee({
                        ...selectedEntryFee,
                        maxPerson: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button color="secondary" onClick={() => setEditModal(!editModal)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Add Entry Fee</ModalHeader>
            <ModalBody>
              <label>Event Price*</label>
              <input
                type="text"
                name="Event Price"
                className="form-control  mt-2"
                placeholder="Enter Event Price here"
                value={eventPrice}
                onChange={(e) => setEventPrice(e.target.value)}
              />
              <label className="mt-2">Ticket Type*</label>
              <input
                type="text"
                name="Event Price"
                className="form-control  mt-2"
                placeholder="Enter Event Ticket Type here"
                value={ticketType}
                onChange={(e) => setTicketType(e.target.value)}
              />
              <label className="mt-2"> Max Person*</label>
              <input
                type="text"
                name="Event Max Person"
                className="form-control  mt-2"
                placeholder="Enter Max Person here"
                value={maxPerson}
                onChange={(e) => setMaxPerson(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={addEntryFee}>
                Save
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={handleDelete}
            message="Are you sure you want to Remove the Listing?"
          />
          <ConfirmDialog
            isOpen={confirmEventPrice}
            toggle={() => setConfirmEvent(false)}
            onConfirm={() => {
              removeEntryFee(removeEntryIndex);
              setConfirmEvent(false);
            }}
            message="Are you sure you want to Remove the Entry Fee?"
          />
          <div
            style={{
              borderRadius: 5,
              borderColor: "#E5E7EB",
              padding: "0 20px",
              overflow: "auto",
              marginTop: 20,
            }}
          >
            <span style={{ fontSize: 20 }}>
              <strong>Booking Details</strong>
            </span>
            <Table hover style={{ tableLayout: "auto" }}>
              <thead>
                <tr>
                  {customTableHeadings.map((heading, index) => (
                    <th key={index} style={{ whiteSpace: "nowrap" }}>
                      {heading === "checkbox" ? (
                        <input
                          type="checkbox"
                          checked={selectedRows.length === currentItems.length}
                          onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                        />
                      ) : (
                        heading
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {customTableHeadings.map((heading, colIndex) => (
                      <td key={colIndex}>
                        {heading === "ID" ? (
                          <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.eventId, rowIndex)} title={item.eventId}>
                            {item.eventId.slice(0, 5)}
                            {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                          </span>
                        ) : heading === "checkbox" ? (
                          <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                        ) : heading === "Name" ? (
                          item.name
                        ) : heading === "Mobile" ? (
                          item.mobile
                        ) : heading === "Verified" ? (
                          item.verified
                        ) : heading === "Created" ? (
                          <span>{moment.utc(item.scheduledDate).local().format("DD-MM-YYYY hh:mm A")}</span>
                        ) : heading === "Email" ? (
                          item.email
                        ) : heading === "City" ? (
                          item.city
                        ) : heading === "Status" ? (
                          item.status
                        ) : heading === "Tickets" ? (
                          // Display ticket details
                         <div>
                            {item.tickets.map((ticket, ticketIndex) => (
                              <div key={ticketIndex}>{` Quantity - ${ticket.qty}  ${ticket.type} Price - ${ticket.price}`} <hr></hr></div>
                            ))}
                          </div>
                        ) : (
                          item[heading]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 20 }}>
            <h5>Internal Note</h5>
            <div className="chat-container">
              <div className="chat-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <div className="message-text">{message.text}</div>
                    <div className="message-time">{getCurrentLocalDateTime()}</div>
                  </div>
                ))}
                {internalNotes.map((note) => (
                  <div key={note.id} className={`message bot`}>
                    <div className="message-text">{note.note}</div>
                    <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                  </div>
                ))}
              </div>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                <textarea
                  className="form-control"
                  rows={5}
                  type="text"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                 <div style={{ marginTop: 10,display:'flex',justifyContent:'flex-end' }}>
                  <button className="form-control" style={{width:100}} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="mt-3 d-flex" style={{ justifyContent: "flex-end", gap: "1rem" }}>
              <button onClick={EditEvent} type="submit" style={{ width: "10%" }} className="btn btn-primary">
                Save
              </button>
              <button onClick={() => navigate(-1)} style={{ width: "10%" }} className="btn btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditEventDetails;
