import React, { useState, useEffect } from "react";
import { Table, Form, FormGroup, Input, Modal, ModalBody, Button,Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AdvertismentTopBar from "../../component/AdvertismentTopBar";
import Apis from "../../utils/api";
import moment from "moment";
import { ToastContainer,toast } from "react-toastify";
import { useAdvertismentState } from "../services/advertismentState";
import LoaderModal from "../../component/common/Loader";
const AdvertismentList = () => {
  const api = new Apis();
  const [selectedRows, setSelectedRows] = useState([]);
  const [advertisment, setAdvertisment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const {setadvertismentData}=useAdvertismentState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const [clickedRow, setClickedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (rowIndex) => {
    setClickedRow(rowIndex);
    setIsModalOpen(true);
  };

  const closeVideoModal = () => {
    setClickedRow(null);
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchRolls = async () => {
      try {
        const advertisments = await api.getAdvertisment();
        advertisments.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
        setAdvertisment(advertisments);
        setadvertismentData(advertisments);
      } catch (error) {
        console.error("Error fetching RollSList:", error);
      }
    };
    fetchRolls();
  }, []);
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const totalPages = Math.ceil(advertisment.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const [state,setState]=useState(true);
  const filteredRolls = advertisment.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const uploaderNameLower = (item.type || "").toLowerCase();
    const title = (item.title || "").toLowerCase();
    const adId = (item.id || "").toLowerCase();
    return (
      (!state || item.status === "Active") && // Only include items with status "ACTIVE" if state is true
      (uploaderNameLower.includes(searchQueryLower) ||
      title.includes(searchQueryLower) ||
      adId.includes(searchQueryLower))
    );
  });  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRolls.slice(indexOfFirstItem, indexOfLastItem);

  const customTableHeadings = ["checkbox", "ID", "Advertisment For", "Title", "Discription", "Start Date", "End Date","status","Advertisment In"];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => filteredRolls[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const addAdvertisement = async (itemToUpdate) => {
    try {
      const requestBody = {
        id: itemToUpdate.id,
        title: itemToUpdate.title,
        description: itemToUpdate.description,
        status: itemToUpdate.status,
        startDate: itemToUpdate.startDate,
        endDate: itemToUpdate.endDate,
        advertiserName: itemToUpdate.advertiserName,
        rawData: itemToUpdate.rawData,
        filePath: itemToUpdate.filePath,
        type: itemToUpdate.type,
        externalLink: itemToUpdate.externalLink,
        internalLink: itemToUpdate.internalLink,
        viewedCount: itemToUpdate.viewedCount,
        subType: itemToUpdate.subType,
        refId: itemToUpdate.refId,
      };
      setLoading(true);
      const response = await api.updateAdvertisment(itemToUpdate.id, requestBody);
      setLoading(false);
      toast.success("Advertisement Status Changed!");
      console.log(response);
    } catch (error) {
      toast.error("Advertisement Status Can't be Changed!");
      console.error("Error updating advertisement:", error);
      setLoading(false);
    }
  };
  
  const handleToggleChange = async (event) => {
    const itemId = event.target.value;
    const updatedAds = [...advertisment];
    const itemToUpdate = updatedAds.find((item) => item.id === itemId);
  
    if (itemToUpdate) {
      try {
        // Call your API with the new status
        const newStatus = itemToUpdate.status === 'Active' ? 'Inactive' : 'Active';
        await addAdvertisement({ ...itemToUpdate, status: newStatus });
  
        // Update the local state if the API call is successful
        setAdvertisment(updatedAds.map((item) => (item.id === itemId ? { ...item, status: newStatus } : item)));
      } catch (error) {
        console.error("Error toggling Advertisement status:", error);
        toast.error("Advertisement Status Can't be Changed!");
      }
    }
  };
  
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  
  return (
    <>
     <ToastContainer position="top-right" autoClose={5000} />
    {loading ? (
      <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
    ) : (
    <>
      <AdvertismentTopBar   setSearchQuery={setSearchQuery}
        onClick={exportSelectedRowsToCSV}/>
        <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
      <Table hover>
        <thead style={{position:'sticky',top:0,zIndex:1}}>
          <tr>
            {customTableHeadings.map((heading, index) => (
              <th key={index} style={{ whiteSpace: "nowrap" }}>
                {heading === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                  />
                ) : (
                  heading
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {customTableHeadings.map((heading, colIndex) => (
                <td key={colIndex}>
                  {heading === "ID" ? (
                    <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                      {item.id.slice(0, 5)}
                      {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                    </span>
                  ) : heading === "checkbox" ? (
                    <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                  ) : heading === "Advertisment For" ? (
                    <a href onClick={() => navigate(`/AdvertismentList/EditAdvertisment/${item.id}`)} style={{ cursor: "pointer" }}>
                    {item.type}
                  </a>
                  ) : heading === "Title" ? (
                    item.title
                  ) : heading === "Discription" ? (
                    item.description
                  ) : heading === "Advertisment In" ? (
                    item.subType
                  ) : heading === "Start Date" ? (
                    <span>{moment.utc(item.startDate).local().format("DD-MM-YYYY hh:mm A")}</span>
                  ): heading === "End Date" ? (
                    <span>{moment.utc(item.endDate).local().format("DD-MM-YYYY hh:mm A")}</span>
                  ) : heading === "status" ? (
                    <Form>
                      <FormGroup switch>
                        <Input type="switch" role="switch" checked={item.status==="Active"} onChange={handleToggleChange} value={item.id} />
                      </FormGroup>
                    </Form>
                  ) : (
                    item[heading]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <Modal isOpen={isModalOpen} toggle={closeVideoModal}>
          <ModalBody>
            <video src={clickedRow !== null ? currentItems[clickedRow].location : ""} controls autoPlay style={{ width: "100%", height: "60%" }} />
          </ModalBody>
        </Modal>
      </Table>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
    </>
    )}
    </>
  );
};
<style>


</style>
export default AdvertismentList;
