import React, { useState } from "react";

const ServiceSelect = ({ onSelectType }) => {
  const [selectedType, setSelectedType] = useState("Veterinary");

  const handleCollaboratorSelect = (service) => {
    setSelectedType(service);
    onSelectType(service);
  };
  return (
    <div style={{ display: "flex" }}>
      <b
        style={{
          marginRight: 10,
          cursor: "pointer",
          color: selectedType === "Veterinary" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("Veterinary")}
      >
        Veterinary
      </b>
      <b
        style={{
          marginRight: 10,
          cursor: "pointer",
          color: selectedType === "Grooming" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("Grooming")}
      >
        Groomer
      </b>
     
    </div>
  );
};

export default ServiceSelect;