import React, { useState } from "react";

const CollabSelect = ({ onSelectType }) => {
  const [selectedType, setSelectedType] = useState("All");

  const handleCollaboratorSelect = (service) => {
    setSelectedType(service);
    onSelectType(service);
  };
  return (
    <div style={{ display: "flex" }}>
      <b
        style={{
          marginRight: 5,
          cursor: "pointer",
          color: selectedType === "All" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("All")}
      >
        All
      </b>
      {/* <b
        style={{
          marginRight: 10,
          cursor: "pointer",
          color: selectedType === "VETERINARY" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("VETERINARY")}
      >
        Veterinary
      </b>
      <b
        style={{
          marginRight: 10,
          cursor: "pointer",
          color: selectedType === "GROOMING" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("GROOMING")}
      >
        Groomer
      </b> */}
      {/* <b
        style={{
          marginRight: 5,
          cursor: "pointer",
          color: selectedType === "NGOs" ? "blue" : "black",
          transition: "color 0.3s ease-in-out",
        }}
        onClick={() => handleCollaboratorSelect("NGOs")}
      >
        onAadharTextChange
      </b> */}
    </div>
  );
};

export default CollabSelect;