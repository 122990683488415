import React, { useState } from "react";
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
const data = [
  {
    Role: "Super Admin",
    Permission: "True",
    'Created On': "12/7/23"
  },
  {
    Role: "Staff",
    Permission: "False",
    'Created On': "12/7/23"
  },
];

const RollsPermissionList = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [dropdownStates, setDropdownStates] = useState({}); // State for tracking individual dropdown states
    const [selectedRowActions, setSelectedRowActions] = useState({}); // To store selected actions for each row
    const columns = ['checkbox', ...Object.keys(data[0])];
  
    const toggleRowSelection = (rowIndex) => {
      if (selectedRows.includes(rowIndex)) {
        setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
      } else {
        setSelectedRows([...selectedRows, rowIndex]);
      }
    };
  
    const toggleDropdown = (rowIndex) => {
      setDropdownStates((prevState) => ({
        ...prevState,
        [rowIndex]: !prevState[rowIndex],
      }));
    };
  
    const handleActionSelection = (rowIndex, action) => {
      setSelectedRowActions({ ...selectedRowActions, [rowIndex]: action });
    };
  
  return (
    <>
      <Table hover>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                {column === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === data.length}
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === data.length
                          ? []
                          : data.map((_, index) => index)
                      )
                    }
                  />
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => toggleRowSelection(rowIndex)}
                />
              </td>
              {columns.slice(1).map((column, colIndex) => (
                <td key={colIndex}>
                  {column === 'name' ? (
                    <Link to="/NotificationList/NotificationDetails" style={{ cursor: 'pointer' }}>
                      {item[column]}
                    </Link>
                  ) : (
                    item[column]
                  )}
                </td>
              ))}
              <td>
                <Dropdown isOpen={dropdownStates[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                  <DropdownToggle >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleActionSelection(rowIndex, 'view')}>View</DropdownItem>
                    <DropdownItem onClick={() => handleActionSelection(rowIndex, 'edit')}>Edit</DropdownItem>
                    <DropdownItem onClick={() => handleActionSelection(rowIndex, 'delete')}>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default RollsPermissionList;
