import React, { useState, useEffect } from "react";
import { Button, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CardGroup } from "react-bootstrap";
import { useAppointment } from "./services/appointmentmanagementState";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Apis from "../utils/api";
import { usePetparentState } from "./services/petparentState";
import { useServiceList } from "./services/serviceListState";
import "../component/css/Appointment.css";
import { Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";

const AppointmentDetails = () => {
  const { getServiceListData } = useServiceList();
  const { getPetparentData } = usePetparentState();
  const api = new Apis();
  const { id } = useParams();

  const [WorkspacelistId, setWorkspacelistId] = useState(null);
  const [petListing, setPetListing] = useState([]);
  const [numberofPets, setNumberofPets] = useState([]);
  const { getappointmentData } = useAppointment();
  const [workspaceServices, setWorkspaceServices] = useState([]);
  const [appointment] = useState(getappointmentData(id));
  const [selectedService, setSelectedService] = useState(appointment.serviceName || "");
  const [loading, setLoading] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [selectedCardData, setSelectedCardData] = useState(null);
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  console.log("Appointment Details", appointment);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  // const bookedServices = JSON.parse(appointment.bookedServices);
  const bookedServices = selectedCardData
    ? [
        {
          convenienceFee: parseFloat(selectedCardData.convenienceFee),
          description: selectedCardData.description,
          gstPrice: parseFloat(selectedCardData.gstPrice),
          name: selectedCardData.title,
          rate: parseFloat(selectedCardData.serviceCost),
          totalCost: parseFloat(selectedCardData.serviceCost) + parseFloat(selectedCardData.convenienceFee),
          isHomeVisit: selectedCardData.isHomeVisit,
        },
      ]
    : [];
  const [selectedDateTime, setSelectedDateTime] = useState(moment(appointment.appointmentDate || "").format("YYYY-MM-DDTHH:mm"));
  // const [selectedEndTime, setSelectedEndTime] = useState(moment(appointment.appointmentDate).format("YYYY-MM-DDTHH:mm"));
  const handleDateTimeChange = (e) => {
    // Update the state with the user's input
    setSelectedDateTime(e.target.value);
  };
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState(appointment.designationName || "");
  const formattedCreatedAt = moment(appointment.createdAt).format("DD-MMM-YYYY HH:mm: A");
  const [selectedPetParentId] = useState(appointment.bookedBy || "");
  const navigate = useNavigate();
  const [selectedPetId, setSelectedPetId] = useState();
  const [selectedPetNames, setSelectedPetNames] = useState([]);
  const [editablePetName, setEditablePetName] = useState("");

  useEffect(() => {
    const fetchPetsForSelectedParent = async () => {
      if (selectedPetParentId) {
        try {
          const pets = await api.getPets(selectedPetParentId);
          setPetListing(pets);
          setSelectedPetNames(pets.map((pet) => ({ id: pet.id, petName: pet.petName })));
        } catch (error) {
          console.error("Error fetching pets:", error);
        }
      } else {
        setPetListing([]);
        setSelectedPetNames([]);
      }
    };

    fetchPetsForSelectedParent();
  }, [selectedPetParentId]);

  useEffect(() => {
    const selectedPet = petListing.find((pet) => pet.id === appointment.petId);
    console.log("Selected Pet", selectedPet);
    if (selectedPet) {
      setEditablePetName(selectedPet.petName);
      console.log("Id", selectedPet.id);
      console.log("name", selectedPet.petName);
    }
  }, [appointment.petId, petListing]);
  useEffect(() => {
    if (selectedService === "Veterinary") {
      setWorkspacelistId("d8d71038-c48c-11ed-abc1-02817ef0919c");
    } else if (selectedService === "Groomers") {
      setWorkspacelistId("5e4c6b60-c48e-11ed-abc1-02817ef0919c");
    }
  }, [selectedService]);
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceData = await api.getWorkspace(WorkspacelistId);
        const activeWorkspaceData = workspaceData.filter((item) => item.active === true);
        setSelectedWorkspacDatas(activeWorkspaceData);
        console.log(activeWorkspaceData);
        const designationNames = activeWorkspaceData.map((item) => item.designationName);
        const designationServices = activeWorkspaceData.map((item) => item.services);
        setWorkspaceOptions(designationNames);
        setWorkspaceServices(designationServices);
        console.log("WorkspaceData", activeWorkspaceData);
      } catch (error) {
        console.error("Error fetching pets:", error);
      }
    };
    fetchWorkspace();
  }, [WorkspacelistId]);
  useEffect(() => {
    if (petListing.length > 0 && selectedPetId) {
      const selectedPet = numberofPets.find((pet) => pet.petName === selectedPetId.name);
      console.log("Selected Pet:", selectedPet);
      if (selectedPet) {
        console.log("Selected Pet:", selectedPet);
      }
    }
  }, [petListing, selectedPetId, numberofPets]);
  const [workspaceDatas, setSelectedWorkspacDatas] = useState([]);
  const [selectedWorkspaceServices, setSelectedWorkspaceServices] = useState([]);
  const [selectedWorkplaceNames, setSelectedWorkplaceNames] = useState([]);
  const [selectedWorkspaceId, setSelectedWorkplaceId] = useState("");
  const [selectedCollabId, setSeletedCollabId] = useState("");
  console.log("WOrkplace Name", appointment.workspaceName);
  useEffect(() => {
    setSelectedWorkplaceNames(appointment.workspaceName || "");
  }, [appointment.workspaceName]);
  const [selectedWorkspaceTime, setSelectedWorkspaceTime] = useState("");
  const [selectedCollaborator, setSelectedCollaborator] = useState("");
  const handleWorkspaceChange = async (event) => {
    setSelectedWorkspaceName(event.target.value);
    try {
      const selectedWorkspace = workspaceDatas.find((workspace) => workspace.designationName === event.target.value);
      if (selectedWorkspace) {
        setSelectedWorkspaceServices(selectedWorkspace.services);
        setSelectedWorkplaceNames(selectedWorkspace.workplaceName);
        setSelectedWorkplaceId(selectedWorkspace.id);
        setSelectedWorkspaceTime(selectedWorkspace.workplaceTime);
        setSeletedCollabId(selectedWorkspace.collaboratorId);
      } else {
        setSelectedWorkspaceServices([]);
      }
    } catch (error) {
      console.error("Error fetching workspace services:", error);
    }
  };
  useEffect(() => {
    const fetchWorkspaceData = async () => {
      try {
        const activeWorkspaceDatas = workspaceDatas.filter((workspace) => workspace.active === true);
        const selectedWorkspace = activeWorkspaceDatas.find((workspace) => workspace.designationName === selectedWorkspaceName);
        if (selectedWorkspace) {
          setSelectedWorkspaceServices(selectedWorkspace.services);
          setSelectedWorkplaceNames(selectedWorkspace.workplaceName);
          setSelectedWorkplaceId(selectedWorkspace.id);
          setSelectedWorkspaceTime(selectedWorkspace.workplaceTime);
          setSeletedCollabId(selectedWorkspace.collaboratorId);
        } else {
          setSelectedWorkspaceServices([]);
        }
      } catch (error) {
        console.error("Error fetching workspace services:", error);
      }
    };
    if (selectedWorkspaceName && workspaceDatas.length > 0) {
      fetchWorkspaceData();
    }
  }, [selectedWorkspaceName, workspaceDatas]);
  const [selectedPetParentName, setSelectedPetParentName] = useState("");
  const [petParentList, setPetParentList] = useState([]);
  const [selectedPetParentContact, setSelectedContact] = useState();
  useEffect(() => {
    const fetchPetParents = async () => {
      try {
        const fetchedPetParentList = await api.getPetParentList();
        fetchedPetParentList.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
        setPetParentList(fetchedPetParentList);
        const matchedPetParent = fetchedPetParentList.find((parent) => parent.id === appointment.bookedBy);
        if (matchedPetParent) {
          setSelectedPetParentName(matchedPetParent.name);
          setSelectedContact(matchedPetParent.mobile);
        }
      } catch (error) {
        console.error("Error fetching petParents:", error);
      }
    };
    fetchPetParents();
  }, [appointment.bookedBy]);
  const [bookedSlot, setbookedSlot] = useState([]);
  useEffect(() => {
    const fetchBookedSlot = async () => {
      try {
        const bookedSlots = await api.getBookedSlot(appointment.workspaceId, appointment.bookedBy);
        setbookedSlot(bookedSlots);
        console.log("BookedSlot", bookedSlots);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchBookedSlot();
  }, []);
  const [selectedDaySlot, setSelectedDaySlot] = useState({
    date: moment(appointment.appointmentDate).format("YYYY-MM-DD"),
    time: moment(appointment.appointmentDate).format("HH:mm"),
  });
  const [startTimeMilli, setStartTimeMilli] = useState();
  const [endTimeMilli, setEndTimeMilli] = useState(null);
  const [slots, setSlots] = useState([]);
  useEffect(() => {
    const generateTimeSlots = () => {
      if (selectedWorkspaceTime && selectedWorkspaceTime.start_time && selectedWorkspaceTime.end_time && selectedWorkspaceTime.lunch_break) {
        const startTimeMoment = moment(selectedWorkspaceTime.start_time, "hh:mm a");
        const endTimeMoment = moment(selectedWorkspaceTime.end_time, "hh:mm a");
        const lunchBreakStartMoment = moment(selectedWorkspaceTime.lunch_break.start_time, "hh:mm a");
        const lunchBreakEndMoment = moment(selectedWorkspaceTime.lunch_break.end_time, "hh:mm a");
        const timeSlots = [];
        let currentSlot = startTimeMoment.clone();
        const currentDate = moment().format("YYYY-MM-DD");
        while (currentSlot.isBefore(endTimeMoment)) {
          if (
            currentSlot.isBefore(lunchBreakStartMoment) ||
            (currentSlot.isSameOrAfter(lunchBreakEndMoment) && moment(`${currentDate} ${currentSlot.format("HH:mm")}`, "YYYY-MM-DD HH:mm").isAfter(moment()))
          ) {
            timeSlots.push({
              label: currentSlot.format("hh:mm A"),
              value: currentSlot.format("HH:mm"),
            });
          }
          currentSlot.add(30, "minutes");
        }
        setSlots(timeSlots);
      }
    };
    generateTimeSlots();
  }, [selectedWorkspaceTime]);
  const nextDays = Array.from({ length: 5 }, (_, index) => moment().add(index, "days").format("YYYY-MM-DD"));
  useEffect(() => {
    if (selectedDaySlot) {
      const combinedDateTime = moment(`${selectedDaySlot.date} ${selectedDaySlot.time}`, "YYYY-MM-DD HH:mm");
      const endTime = combinedDateTime.clone().add(30, "minutes");
      const combinedMillis = combinedDateTime.valueOf();
      const endMillis = endTime.valueOf();
      console.log("Combined and converted to milliseconds:", combinedMillis);
      console.log("End time and converted to milliseconds:", endMillis);
      setStartTimeMilli(combinedMillis);
      setEndTimeMilli(endMillis);
    }
  }, [selectedDaySlot]);
  const updateAppointment = async () => {
    try {
      if (!editablePetName) {
        throw new Error("No Pet Was Selected.");
      }
      const parsedBookedServices = JSON.parse(appointment.bookedServices || "[]");
      const requestBody = {
        id: id,
        amount: 1180,
        bookedBy: appointment.bookedBy,
        paymentId: appointment.paymentId || null,
        bookedServices: selectedCardServices || parsedBookedServices,
        currency: "INR",
        endTime: endTimeMilli,
        petId: selectedPetId || appointment.petId,
        serviceId: WorkspacelistId || appointment.serviceId,
        startTime: startTimeMilli,
        subType: "Hair Cut, Nail Cut",
        type: (() => {
          if (appointment.serviceName === "Veterinary") {
            return selectedCardServices[0].name || parsedBookedServices[0].name;
          } else if (appointment.serviceName === "Groomers") {
            return selectedCardServices[0].isHomeVisit ? "Home Visit" : "Offline"|| parsedBookedServices[0].isHomeVisit ? "Home Visit" : "Offline";
          }
        })(),
        workspaceId: selectedWorkspaceId || appointment.workspaceId,
        status: appointment.status,
        address:
        selectedCardServices.some((service) => service.name === "Home Visit") || selectedCardServices.some((service) => service.isHomeVisit === true)
          ? (addressSelected && addressSelected.id) || appointment.addressId || null
          : null,
            active: appointment.active,
      };
      setLoading(true);
      const response = await api.updateAppointment(selectedWorkspaceId, selectedCollabId || appointment.collaboratorId, requestBody);
      toast.success("Appointment Edited Successfully");
      setLoading(false);
      navigate(-1);
      console.log("API response:", response);
    } catch (error) {
      if (error.message === "Request failed with status code 500") {
        toast.error("Error: Phone number or email is duplicate");
      } else {
        toast.error("Error: " + error.message);
      }
      console.error("Error adding collaborator:", error);
    }
  };
  const [selectedCardServices, setSelectedCardServices] = useState([]);
  const handleServiceSelection = (service) => {
    if (selectedService === "Veterinary") {
      setSelectedCardServices([service]);
    } else {
      if (service.isHomeVisit) {
        setSelectedCardServices((prevSelectedServices) => prevSelectedServices.filter((selected) => selected.isHomeVisit));
      } else {
        setSelectedCardServices((prevSelectedServices) => prevSelectedServices.filter((selected) => !selected.isHomeVisit));
      }
      setSelectedCardServices((prevSelectedServices) => [...prevSelectedServices, service]);
    }
  };
  useEffect(() => {
    console.log(selectedCardServices);
  }, [selectedCardServices]);
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "appointments",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const [petParentAddress, setPetParentAddress] = useState([]);
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const address = await api.getParentAddress(selectedPetParentId);
        setPetParentAddress(address);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchAddress();
  }, [selectedPetParentId]);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [type, setType] = useState();
  const [flatNo, setFlatNo] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [latLng, setLatLng] = useState("0.0, 0.0");
  const addAddress = async () => {
    try {
      const requestBody = {
        userId: selectedPetParentId,
        type: type,
        flatNo: flatNo,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
        address: address,
        pincode: pincode,
        city: city,
        state: "MH",
        default: false,
      };
      setLoading(true);
      const response = await api.addAppointmentAddress(selectedPetParentId, requestBody);
      setLoading(false);
      const newAddressId = response.id;
      const newAddress = {
        id: newAddressId,
        type,
        flatNo,
        address,
        pincode,
        city,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
      };
      toast.success("Address added successfully!");
      console.log(response);
      setPetParentAddress([...petParentAddress, newAddress]);
      toggleModal();
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address: " + error.message);
      setLoading(false);
      toggleModal();
    }
  };
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const selectAddress = (index) => {
    setSelectedAddress(index);
    console.log("Selected Address:", petParentAddress[index]);
    setAddressSelected(petParentAddress[index]);
  };
  useEffect(() => {
    const prepopulatedAddressIndex = petParentAddress.findIndex((address) => address.id === appointment.addressId);

    if (prepopulatedAddressIndex !== -1) {
      setSelectedAddress(prepopulatedAddressIndex);
    }
  }, [appointment, petParentAddress]);

  useEffect(() => {
    if (appointment && appointment.bookedServices) {
      const parsedBookedServices = JSON.parse(appointment.bookedServices);
      setSelectedCardServices(parsedBookedServices);
    }
  }, [appointment]);
  const [editedAddressess, setEditedAddress] = useState("");
  const [editAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [editedType, setEditedType] = useState("");
  const [editedFlatNo, setEditedFlatNo] = useState("");
  const [editedPincode, setEditedPincode] = useState("");
  const [editedCity, setEditedCity] = useState("");
  const [editedLatLng, setEditedLatLng] = useState("");
  const [editedAddressId, setEditedAddressId] = useState("");
  const openEditAddressModal = (address) => {
    setEditedType(address.type || "");
    setEditedFlatNo(address.flatNo || "");
    setEditedAddress(address.address || "");
    setEditedPincode(address.pincode || "");
    setEditedCity(address.city || "");
    setEditedLatLng(`${address.latitude || ""}, ${address.longitude || ""}`);
    setEditedAddressId(address.id || "");
    // Set editedLatLng
    setEditAddressModalOpen(true); // Open the modal
  };

  const closeEditAddressModal = () => {
    setEditAddressModalOpen(false);
  };

  const handleEditAddressSubmit = async () => {
    try {
      const requestBody = {
        id: editedAddressId,
        userId: selectedPetParentId,
        type: editedType,
        flatNo: editedFlatNo,
        latitude: editedLatLng.split(",")[0].trim(),
        longitude: editedLatLng.split(",")[1].trim(),
        address: editedAddressess,
        pincode: editedPincode,
        city: editedCity,
        state: "MH",
        default: false,
      };
      const editedAddress = {
        type: editedType,
        flatNo: editedFlatNo,
        address: editedAddressess,
        pincode: editedPincode,
        city: editedCity,
        latitude: editedLatLng.split(",")[0].trim(),
        longitude: editedLatLng.split(",")[1].trim(),
      };
      console.log("Edited Address:", editedAddress);
      setLoading(true);
      const response = await api.EditAppointmentAddress(selectedPetParentId, editedAddressId, requestBody);
      setLoading(false);
      const updatedAddresses = petParentAddress.map((address, index) => (index === selectedAddress ? editedAddress : address));
      setPetParentAddress(updatedAddresses);
      toast.success("Address Edited successfully!");
      console.log(response);
      closeEditAddressModal();
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address: " + error.message);
      setLoading(false);
      closeEditAddressModal();
    }
  };
  const shouldRenderBadge = !nextDays.includes(moment(selectedDaySlot.date).format("YYYY-MM-DD"));
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <form className="modalForm">
                    <span className="" style={{ fontSize: 20 }}>
                      <strong>Edit Appointment</strong>
                    </span>
                    <div className="mt-2">
                      <label className="radioBtnText"> Service</label>
                      <div>
                        {appointment.serviceName === "Veterinary" && (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="service"
                              id="Veterinary"
                              value="Veterinary"
                              checked={selectedService === "Veterinary"}
                              onChange={() => setSelectedService("Veterinary")}
                            />
                            <label className="form-check-label" htmlFor="Veterinary">
                              Veterinary
                            </label>
                          </div>
                        )}
                        {appointment.serviceName === "Groomers" && (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="service"
                              id="Groomers"
                              value="Groomers"
                              checked={selectedService === "Groomers"}
                              onChange={() => setSelectedService("Groomers")}
                            />
                            <label className="form-check-label" htmlFor="Groomers">
                              Groomer
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="  mt-2">
                      <label for="inputPetsSpecies"> Collaborator*</label>
                      <select id="inputPetsSpecies" className="form-control" onChange={handleWorkspaceChange} value={selectedWorkspaceName}>
                      <option value="" selected disabled={true}>
                          {appointment.designationName||""}
                        </option>
                      </select>
                    </div> */}
                    <div className="  mt-2">
                      <label for="inputPetsSpecies">Collaborator*</label>
                      <input type="Text" className="form-control" id="startDateTime" disabled={true} value={appointment.designationName || ""} />
                    </div>
                    <div className="  mt-2">
                      <label for="inputPetsSpecies">Workspace Name*</label>
                      <input type="Text" className="form-control" id="startDateTime" disabled={true} value={selectedWorkplaceNames} />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="inputPetsSpecies">Pet Parent*</label>
                      <select
                        id="inputPetsSpecies"
                        className="form-control"
                        value={selectedPetParentName}
                        onChange={(e) => setSelectedPetParentName(e.target.value)}
                        disabled
                      >
                        {selectedPetParentName && (
                          <option key={selectedPetParentName} value={selectedPetParentName}>
                            {selectedPetParentName}
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="  mt-2">
                      <label for="inputPetsSpecies">Contact*</label>
                      <input type="Text" className="form-control" id="startDateTime" disabled={true} value={selectedPetParentContact} />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="inputPetName">Select Pet Name*</label>
                      <select
                        id="inputPetName"
                        className="form-control"
                        value={editablePetName}
                        onChange={(e) => {
                          const selectedPetName = e.target.value;
                          setEditablePetName(selectedPetName);
                          const selectedPet = selectedPetNames.find((pet) => pet.petName === selectedPetName);
                          console.log("Trial pet", selectedPet);
                          if (selectedPet) {
                            setSelectedPetId(selectedPet.id);
                          }
                        }}
                      >
                        <option value="" disabled>
                          Choose a Pet
                        </option>
                        {selectedPetNames.map((pet, index) => (
                          <option key={index} value={pet.petName}>
                            {pet.petName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="  mt-2">
                      <label for="inputPetsSpecies">Appointment Id</label>
                      <input type="Text" className="form-control" id="startDateTime" disabled={true} value={id} />
                    </div>
                    <h5 className="  mt-2">Schedule Details</h5>
                    <h5 className="  mt-2">Select Days</h5>
                    <div className=" mt-2">
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {shouldRenderBadge ? (
                          <Badge
                            style={{
                              marginRight: 10,
                              cursor: "pointer",
                              fontSize: 15,
                              color: "yellow",
                            }}
                          >
                            {moment(selectedDaySlot.date).format("DD ddd")}
                          </Badge>
                        ) : null}
                        {selectedWorkspaceTime &&
                          selectedWorkspaceTime.days &&
                          selectedWorkspaceTime.days.length > 0 &&
                          nextDays.map((day, index) => {
                            const dayName = moment(day).format("ddd");
                            return (
                              selectedWorkspaceTime.days.includes(dayName) && (
                                <Badge
                                  key={index}
                                  style={{
                                    marginRight: 10,
                                    cursor: "pointer",
                                    fontSize: 15,
                                    color: selectedDaySlot && selectedDaySlot.date === day ? "black" : "white",
                                  }}
                                  onClick={() => setSelectedDaySlot({ date: day, time: selectedDaySlot ? selectedDaySlot.time : null })}
                                >
                                  {moment(day).format("DD")} {dayName}
                                </Badge>
                              )
                            );
                          })}
                      </div>
                    </div>
                    <h5 className=" mt-2">Select Slot</h5>
                    <div className=" mt-2">
                      <div style={{ display: "flex", flexWrap: "wrap", gap: 20, rowGap: 20 }}>
                        {slots.map((slot, index) => {
                          const slotTimeMillis = moment(`${selectedDaySlot.date} ${slot.value}`, "YYYY-MM-DD HH:mm").valueOf();
                          const isSelected = selectedDaySlot && selectedDaySlot.time === slot.value;
                          const isBooked = bookedSlot.some((booked) => {
                            return slotTimeMillis >= booked.startTime && slotTimeMillis < booked.endTime;
                          });
                          return (
                            <Badge
                              key={index}
                              style={{
                                marginRight: 10,
                                cursor: "pointer",
                                fontSize: 15,
                                color: isSelected ? "black" : isBooked ? "darkgrey" : "white",
                              }}
                              onClick={() => setSelectedDaySlot({ date: selectedDaySlot ? selectedDaySlot.date : null, time: slot.value })}
                            >
                              {slot.label}
                            </Badge>
                          );
                        })}
                      </div>
                    </div>
                    <h5 className=" mt-2">Select Service</h5>
                    <div className="col-md-12" style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-12 ">
                        <div className="col-md-12" style={{ marginBottom: 20 }}>
                          {selectedService === "Groomers" || selectedService === "Veterinary" ? (
                            selectedWorkspaceServices && selectedWorkspaceServices.length > 0 ? (
                              selectedWorkspaceServices.map((service, index) => {
                                return (
                                  <div className="mt-3" key={index}>
                                    <CardGroup
                                      className="p-3"
                                      style={{
                                        border: "1px solid #beb7b7",
                                        borderRadius: 10,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {selectedService === "Groomers" ? (
                                          <input
                                            type="checkbox"
                                            id={service.name}
                                            name={service.name}
                                            value={service.name}
                                            onChange={() => handleServiceSelection(service)}
                                            checked={selectedCardServices.some((selected) => {
                                              console.log("Selected service name:", selected.name);
                                              console.log("Iterated service name:", service.name);
                                              return selected.name === service.name;
                                            })}
                                          />
                                        ) : (
                                          <input
                                            type="radio"
                                            id={service.name}
                                            name="appointmentType"
                                            value={service.name}
                                            onChange={() => handleServiceSelection(service)}
                                            checked={selectedCardServices.some((selected) => selected.name === service.name)}
                                          />
                                        )}
                                        <div>
                                          <div style={{ flexDirection: "column" }}>
                                            <div>
                                              {service.name} <span>({service.isHomeVisit ? "Home visit" : "Center"})</span>
                                            </div>
                                          </div>
                                          <div>Service Cost</div>
                                          <div>Convenience Fee</div>
                                          <div>In-Hand Amount</div>
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            flexDirection: "column",
                                            display: "flex",
                                            alignItems: "flex-end",
                                          }}
                                        ></div>
                                        <br></br>
                                        <div>₹{service.rate}</div>
                                        <div>₹{service.convenienceFee}</div>
                                        <div>₹{((parseFloat(service.rate) - parseFloat(service.convenienceFee))).toFixed(0)}
</div>
                                      </div>
                                    </CardGroup>
                                  </div>
                                );
                              })
                            ) : (
                              <p>No services available</p>
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {(selectedCardServices.some((service) => service.name === "Home Visit") ||
                      selectedCardServices.some((service) => service.isHomeVisit === true)) && (
                      <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <h5 className="form-group mt-2">Select Address</h5>
                          <Button style={{ marginRight: 10 }} color="primary" onClick={toggleModal}>
                            Add
                          </Button>
                        </div>
                        {petParentAddress.map((service, index) => (
                          <div className="form-group mt-3" key={index}>
                            <CardGroup
                              className="p-3"
                              style={{
                                border: selectedAddress === index ? "2px solid blue" : "1px solid #beb7b7",
                                borderRadius: 10,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              onClick={() => selectAddress(index)}
                            >
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                  <div style={{ flexDirection: "column" }}>
                                    <div>{service.type}</div>
                                  </div>
                                  <div>{service.flatNo}</div>
                                  <div>{service.address}</div>
                                  <div>
                                    {service.city},{service.pincode}
                                  </div>
                                  <div>
                                    {service.latitude},{service.longitude}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <Button onClick={() => openEditAddressModal(service)}>Edit</Button>
                              </div>
                            </CardGroup>
                          </div>
                        ))}
                      </div>
                    )}

                    <Modal isOpen={editAddressModalOpen} toggle={closeEditAddressModal}>
                      <ModalHeader toggle={closeEditAddressModal}>Edit Address</ModalHeader>
                      <ModalBody>
                        <div className="form-group">
                          <label htmlFor="type">Type:</label>
                          <div className="form-check form-check-inline mx-3">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Home"
                              value="Home"
                              checked={editedType === "Home"}
                              onChange={() => setEditedType("Home")}
                            />
                            <label className="form-check-label" htmlFor="Home">
                              Home
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Work"
                              value="Work"
                              checked={editedType === "Work"}
                              onChange={() => setEditedType("Work")}
                            />
                            <label className="form-check-label" htmlFor="Work">
                              Work
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Hotel"
                              value="Hotel"
                              checked={editedType === "Hotel"}
                              onChange={() => setEditedType("Hotel")}
                            />
                            <label className="form-check-label" htmlFor="Hotel">
                              Hotel
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Other"
                              value="Other"
                              checked={editedType === "Other"}
                              onChange={() => setEditedType("Other")}
                            />
                            <label className="form-check-label" htmlFor="Other">
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="flatNo">Flat No:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="flatNo"
                            value={editedFlatNo}
                            onChange={(e) => setEditedFlatNo(e.target.value)}
                            placeholder="Enter Flat No."
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address">Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={editedAddressess}
                            onChange={(e) => setEditedAddress(e.target.value)}
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode:</label>
                          <input
                            type="number"
                            className="form-control"
                            id="pincode"
                            value={editedPincode}
                            onChange={(e) => setEditedPincode(e.target.value)}
                            placeholder="Enter Pincode"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="city">City:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            value={editedCity}
                            onChange={(e) => setEditedCity(e.target.value)}
                            placeholder="Enter City"
                          />
                        </div>
                        <div className="form-group">
                          <label>Location*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Latitude, Longitude"
                            value={editedLatLng}
                            onChange={(e) => setEditedLatLng(e.target.value)}
                          />
                        </div>
                      </ModalBody>

                      <ModalFooter>
                        <Button color="primary" onClick={handleEditAddressSubmit}>
                          Save Changes
                        </Button>
                        <Button color="secondary" onClick={closeEditAddressModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                      <ModalHeader toggle={toggleModal}>Add Address</ModalHeader>
                      <ModalBody>
                        <div className="form-group">
                          <label htmlFor="flatNo">Type:</label>
                          <div className="form-check form-check-inline mx-3 ">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Home"
                              value="Home"
                              checked={type === "Home"}
                              onChange={() => setType("Home")}
                            />
                            <label className="form-check-label" htmlFor="Home">
                              Home
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Work"
                              value="Work"
                              checked={type === "Work"}
                              onChange={() => setType("Work")}
                            />
                            <label className="form-check-label" htmlFor="Work">
                              Work
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Hotel"
                              value="Hotel"
                              checked={type === "Hotel"}
                              onChange={() => setType("Hotel")}
                            />
                            <label className="form-check-label" htmlFor="Hotel">
                              Hotel
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="Other"
                              value="Other"
                              checked={type === "Other"}
                              onChange={() => setType("Other")}
                            />
                            <label className="form-check-label" htmlFor="Other">
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="flatNo">Flat No:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="flatNo"
                            value={flatNo}
                            onChange={(e) => setFlatNo(e.target.value)}
                            placeholder="Enter Flat No."
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address">Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            placeholder="Enter Pincode"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="city">City:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="Enter City"
                          />
                        </div>
                        <div className="form-group">
                          <label>Location*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Latitude, Longitude"
                            value={latLng}
                            onChange={(e) => setLatLng(e.target.value)}
                          />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={addAddress}>
                          Save
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </form>
                  <div style={{ marginTop: 20 }}>
                    <h5>Internal Note</h5>
                    <div className="chat-container">
                      <div className="chat-messages">
                        {messages.map((message, index) => (
                          <div key={index} className={`message ${message.sender}`}>
                            <div className="message-text">{message.text}</div>
                            <div className="message-time">{getCurrentLocalDateTime()}</div>
                          </div>
                        ))}
                        {internalNotes.map((note) => (
                          <div key={note.id} className={`message bot`}>
                            <div className="message-text">{note.note}</div>
                            <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                          </div>
                        ))}
                      </div>
                      <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                        <textarea
                          className="form-control"
                          rows={5}
                          type="text"
                          placeholder="Type your message..."
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                        />
                        <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                          <button className="form-control" style={{ width: 100 }} type="submit">
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 20,
                      padding: 20,
                    }}
                  >
                    <div>
                      <Button
                        style={{ marginRight: 10 }}
                        color="primary"
                        onClick={updateAppointment}
                        disabled={appointment.status === "Complete" || appointment.status === "Completed" || appointment.status === "Cancelled"}
                      >
                        Save
                      </Button>
                      <Button color="secondary" onClick={() => navigate(-1)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AppointmentDetails;
