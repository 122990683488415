import React, { useState, useEffect } from "react";
import { CardGroup, Button } from "react-bootstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoaderModal from "../component/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "../component/css/WorkspaceDetails.css";
import { useWorkSpaceState } from "./services/workspaceState";
import { Navigate, useParams } from "react-router-dom";
import moment from "moment";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useServiceList } from "./services/serviceListState";
// import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import ConfirmDialog from "../component/common/ConfirmDialog";
const VersionWorspace = () => {
  const navigate = useNavigate();
  const userid = localStorage.getItem("userId") || null;
  console.log("USERSID", userid);
  const api = new Apis();
  const { id, collaboratorId, selectedService } = useParams();
  const { getServiceListDatabyId } = useServiceList();
  const [serviceName, setServiceName] = useState(getServiceListDatabyId(selectedService));
  const { getWorkspaceData } = useWorkSpaceState();
  const [workspaceData, setWorkspaceData] = useState(getWorkspaceData(id));
  const [versionData, setVersionData] = useState();
  useEffect(() => {
    const fetchVersionData = async () => {
      try {
        const fetchVersion = await api.getVersionWorkspace(id);
        setVersionData(fetchVersion);
        console.log("FetchVersion", fetchVersion);
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    };

    fetchVersionData();
  }, []);
  const highlightChanges = (key) => {
    return workspaceData[key] !== versionData[key] ? { color: "red" } : {};
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDot, setSelectedDot] = useState(0);
  // const [editedEmail, setEditedEmail] = useState("");
  const [editedContact, setEditedContact] = useState("");
  const [editedCity, setEditedCity] = useState("");
  const [editedEducation, setEditedEducation] = useState("");
  const [editedAreaofexpertise, setEditedAreaofexpertise] = useState("");
  const [editedExperince, setEditedExperince] = useState("");
  const [editedLocation, setEditedLocation] = useState("");
  const [editedLink, setEditedLink] = useState("");
  const [editedRegistration, setEditedRegistration] = useState("");
  const [editedAchievment, setEditedAchievment] = useState("");
  const [editedAreaofFocus, setEditedAreaofFocus] = useState("");
  const [noteText, setNoteText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("Online");

  const [selectedVeterinaryCardTypes, setSelectedVeterinaryCardTypes] = useState([]);
  const [visitType, setVisitType] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [dataFilled, setDataFilled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);

  const [isGstEnabled, setIsGstEnabled] = useState(workspaceData.gstEnabled);
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  console.log(workspaceData);
  const [serviceId] = useState(workspaceData.serviceId);
  const [selectedServicees, setSelectedServicees] = useState("");
  const [servicesName, setServicesName] = useState(selectedServicees === "Veterinary" ? selectedType : "");
  useEffect(() => {
    if (serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c") {
      setSelectedServicees("Groomers");
    } else if (serviceId === "d8d71038-c48c-11ed-abc1-02817ef0919c") {
      setSelectedServicees("Veterinary");
    } else {
      setSelectedServicees("NGO");
    }
  }, [serviceId]);
  const [images, setImages] = useState(
    workspaceData.doctorImages.map((image) => ({
      value: image.value,
      key: image.key,
    }))
  );
  const [serviceDropdown, setServiceDropDown] = useState(false);
  const originalUser = [
    {
      Contact: workspaceData.phone || "null",
      city: workspaceData.city || "null",
      education: workspaceData.education || "null",
      expertise: workspaceData.expertise || "null",
      experience: workspaceData.experience || "null",
      latitude: workspaceData.latitude || "null",
      longitude: workspaceData.longitude || "null",
      registrationNumber: workspaceData.registrationNumber || "",
      achievement: workspaceData.achievement || "",
      areaOfFocus: workspaceData.areaOfFocus || "",
      link: workspaceData.link || "",
    },
  ];

  const [user, setUser] = useState(originalUser);

  useEffect(() => {
    // Compare workspaceData with versionData and update state accordingly
    if (
      versionData &&
      workspaceData &&
      (versionData.phone !== workspaceData.phone ||
        versionData.city !== workspaceData.city ||
        versionData.education !== workspaceData.education ||
        versionData.expertise !== workspaceData.expertise ||
        versionData.experience !== workspaceData.experience ||
        versionData.latitude !== workspaceData.latitude ||
        versionData.longitude !== workspaceData.longitude ||
        versionData.registrationNumber !== workspaceData.registrationNumber ||
        versionData.achievement !== workspaceData.achievement ||
        versionData.areaOfFocus !== workspaceData.areaOfFocus ||
        versionData.link !== workspaceData.link)
    ) {
      setUser([
        {
          Contact: versionData.phone || "null",
          city: versionData.city || "null",
          education: versionData.education || "null",
          expertise: versionData.expertise || "null",
          experience: versionData.experience || "null",
          latitude: versionData.latitude || "null",
          longitude: versionData.longitude || "null",
          registrationNumber: versionData.registrationNumber || "",
          achievement: versionData.achievement || "",
          areaOfFocus: versionData.areaOfFocus || "",
          link: versionData.link || "",
        },
      ]);
    }
  }, [versionData, workspaceData]);

  const handleSaveEdits = () => {
    const updateduser = [...user];
    updateduser[selectedDot].Contact = editedContact;
    updateduser[selectedDot].city = editedCity;
    updateduser[selectedDot].education = educationArray;
    updateduser[selectedDot].expertise = expertiseArray;
    updateduser[selectedDot].experience = editedExperince;
    updateduser[selectedDot].link = editedLink;
    updateduser[selectedDot].registrationNumber = editedRegistration;
    updateduser[selectedDot].achievement = editedAchievment;
    updateduser[selectedDot].areaOfFocus = editedAreaofFocus;
    updateduser[selectedDot].latitude = editedLocation.split(",")[0].trim(); // Update longitude
    updateduser[selectedDot].longitude = editedLocation.split(",")[1].trim(); // Update latitude
    setIsEditModalOpen(false);
  };
  const [uploading, setUploading] = useState(false);
  const [newlyAddedImages, setNewlyAddedImages] = useState([]);
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const updatedImages = [...images];
    if (updatedImages.length + files.length > 5) {
      toast.error("Workspace images cannot be more than 5.");
      return;
    }
    const newlyAdded = [...newlyAddedImages];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const extension = getFileExtension(file.name);
        const newImage = { key: Date.now(), value: e.target.result, extension: extension };
        updatedImages.push(newImage);
        newlyAdded.push(newImage);
        setImages(updatedImages);
        setNewlyAddedImages(newlyAdded);
        setUploading(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      const currentDatetime = new Date().toISOString().replace(/[-T:]/g, "").slice(0, -5);
      for (let i = 0; i < newlyAddedImages.length; i++) {
        const image = newlyAddedImages[i];
        const extension = image.extension || "png"; // Use 'png' as default if extension is not available
        const timestampedName = `image_${currentDatetime}_${i + 1}.${extension}`;
        formData.append("files", dataURItoBlob(image.value), timestampedName);
      }
      setLoading(true);
      const workspaceImage = await api.uploadWorkspaceImages(id, collaboratorId, formData);
      setLoading(false);
      setUploading(false);
      toast.success("Uploaded New Image Successfully");
    } catch (error) {
      console.error("Error uploading images:", error);
      setLoading(false);
      toast.error("Cannot Upload New Image");
    }
  };

  const handleDeleteImage = async (index, imageid) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    setLoading(true);
    console.log(imageid);
    try {
      const deleteImages = await api.deleteWorkspaceImage(id, {
        data: [imageid],
      });
      console.log("Workspace Image:", deleteImages);
      toast.success("Deleted Workspace Image Successfully");
    } catch (error) {
      console.error("Error deleting workspace image:", error);
      toast.error("Error deleting workspace image");
    } finally {
      setLoading(false);
    }
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const originalEducationArray = workspaceData.education ? [...workspaceData.education] : [];
  const originalExpertiseArray = workspaceData.expertise ? [...workspaceData.expertise] : [];

  const [educationArray, setEducationArray] = useState(originalEducationArray);
  const [expertiseArray, setExpertiseArray] = useState(originalExpertiseArray);

  useEffect(() => {
    // Compare workspaceData with versionData and update state accordingly
    if (versionData && versionData.education && versionData.education !== originalEducationArray) {
      setEducationArray(versionData.education);
    }
    if (versionData && versionData.expertise && versionData.expertise !== originalExpertiseArray) {
      setExpertiseArray(versionData.expertise);
    }
  }, [versionData]);
  const addEducation = () => {
    setEducationArray((prevState) => [...prevState, ""]);
  };

  const addExpertise = () => {
    setExpertiseArray((prevState) => [...prevState, ""]);
  };
  const originalSelectedDays = workspaceData.workplaceTime.days || [];
  const [selectedDays, setSelectedDays] = useState(originalSelectedDays);
  const [selectDaysChanges, setSelectDaysChanges] = useState({});

  useEffect(() => {
    const versionSelectedDays = versionData && versionData.workplaceTime && versionData.workplaceTime.days ? versionData.workplaceTime.days : [];
    if (JSON.stringify(versionSelectedDays) !== JSON.stringify(selectedDays)) {
      setSelectedDays(versionSelectedDays);
    }
  }, [versionData]);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const toggleDay = (day) => {
    const updatedSelectedDays = selectedDays.includes(day) ? selectedDays.filter((d) => d !== day) : [...selectedDays, day];
    setSelectedDays(updatedSelectedDays);
  };

  useEffect(() => {
    // Compare workspaceData with versionData and update state accordingly
    const versionDays = versionData && versionData.workplaceTime && versionData.workplaceTime.days;
    const workspaceDays = workspaceData && workspaceData.workplaceTime && workspaceData.workplaceTime.days;
    if (versionDays && workspaceDays && JSON.stringify(versionDays) !== JSON.stringify(workspaceDays)) {
      setSelectDaysChanges({
        ...selectDaysChanges,
        days: true,
      });
    } else {
      setSelectDaysChanges({
        ...selectDaysChanges,
        days: false,
      });
    }
  }, [versionData, workspaceData]);

  useEffect(() => {
    if (id) {
    }
  }, [id, getWorkspaceData]);
  const originalName = workspaceData.workplaceName || "null";
  const originalAddress = workspaceData.address || "null";
  const originalAbout = workspaceData.about || "null";
  const originaldesignationName = workspaceData.designationName || "null";
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(originalName);
  const [address, setAddress] = useState(originalAddress);
  const [about, setAbout] = useState(originalAbout);
  const [designationNames, setDesignationNames] = useState(originaldesignationName);
  useEffect(() => {
    // Compare workspaceData with versionData and update state accordingly
    if (versionData && versionData.workplaceName !== originalName) {
      setName(versionData.workplaceName);
    }
    if (versionData && versionData.address !== originalAddress) {
      setAddress(versionData.address);
    }
    if (versionData && versionData.about !== originalAbout) {
      setAbout(versionData.about);
    }
    if (versionData && versionData.designationName !== originaldesignationName) {
      setDesignationNames(versionData.designationName);
    }
  }, [versionData]);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setName(originalName);
    setAddress(originalAddress);
    setAbout(originalAbout);
    setDesignationNames(designationNames);
    setIsEditing(false);
  };
  const [dropdownStates, setDropdownStates] = useState(workspaceData.services ? workspaceData.services.map(() => false) : []);
  const toggleDropdown = (index) => {
    const updatedStates = [...dropdownStates];
    updatedStates[index] = !updatedStates[index];
    setDropdownStates(updatedStates);
  };
  // Original time values from workspaceData
  const originalStartTime = moment(workspaceData.workplaceTime?.start_time, "hh:mm A").format("HH:mm") || "";
  const originalEndTime = moment(workspaceData.workplaceTime?.end_time, "hh:mm A").format("HH:mm") || "";
  const originalStartBreakTime = moment(workspaceData.workplaceTime?.lunch_break.start_time, "hh:mm A").format("HH:mm") || "";
  const originalEndBreakTime = moment(workspaceData.workplaceTime?.lunch_break.end_time, "hh:mm A").format("HH:mm") || "";

  // State variables for time fields
  const [startTime, setStartTime] = useState(originalStartTime);
  const [endTime, setEndTime] = useState(originalEndTime);
  const [startBreakTime, setStartBreakTime] = useState(originalStartBreakTime);
  const [endBreakTime, setEndBreakTime] = useState(originalEndBreakTime);

  useEffect(() => {
    // Compare original time values with the ones from versionData
    // If there's a change, update the state
    if (versionData && versionData.workplaceTime) {
      const formattedStartTime = moment(versionData.workplaceTime.start_time, "hh:mm A").format("HH:mm") || "";
      const formattedEndTime = moment(versionData.workplaceTime.end_time, "hh:mm A").format("HH:mm") || "";
      const formattedStartBreakTime = moment(versionData.workplaceTime.lunch_break.start_time, "hh:mm A").format("HH:mm") || "";
      const formattedEndBreakTime = moment(versionData.workplaceTime.lunch_break.end_time, "hh:mm A").format("HH:mm") || "";

      if (
        formattedStartTime !== originalStartTime ||
        formattedEndTime !== originalEndTime ||
        formattedStartBreakTime !== originalStartBreakTime ||
        formattedEndBreakTime !== originalEndBreakTime
      ) {
        setStartTime(formattedStartTime);
        setEndTime(formattedEndTime);
        setStartBreakTime(formattedStartBreakTime);
        setEndBreakTime(formattedEndBreakTime);
      }
    }
  }, [versionData]);

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };
  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };
  const handleBreakStartTimeChange = (e) => {
    setStartBreakTime(e.target.value);
  };
  const handleBreakEndTimeChange = (e) => {
    setEndBreakTime(e.target.value);
  };
  const [showServiceAdd, setShowServiceAdd] = useState(false);
  const handleServiceSelection = (type) => {
    if (selectedVeterinaryCardTypes.includes(type)) {
      setShowServiceAdd(true); // Open the modal
    } else {
      setSelectedServiceType(null);
      setSelectedType(type);
      setShowServiceAdd(true); // Open the modal
    }
  };
  const handleModify = (type) => {
    setSelectedServiceType(null);
  };
  const handleDeletes = (serviceType) => {
    setWorkspaceData((prevWorkspaceData) => {
      const updatedServices = prevWorkspaceData.services.filter((service) => service.name !== serviceType);
      const updatedGroomerServices = selectedServicees === "Groomers" ? groomerServices.filter((service) => service.name !== serviceType) : groomerServices;

      return {
        ...prevWorkspaceData,
        services: updatedServices,
        groomerServices: updatedGroomerServices,
      };
    });
  };
  const [groomerServices, setGroomerServices] = useState([]);
  const handleSaves = () => {
    if (selectedVeterinaryCardTypes.includes(selectedType)) {
      setShowServiceAdd(false);
      return;
    }
    const newService = {
      name: servicesName || selectedType,
      visit: visitType,
      convenienceFee: platformFee,
      type: selectedType,
      description: serviceDescription,
      rate: serviceRate,
      ...(isGstEnabled && { gstPrice: gst }),
      isHomeVisit: visitType === "Home visit" ? true : false,
      inHandAmount: inHandAmount,
    };
    setWorkspaceData((prevWorkspaceData) => {
      let updatedServices = [];
      if (selectedServicees === "Veterinary") {
        updatedServices = [...prevWorkspaceData.services, newService];
        setSelectedServices(updatedServices);
        setSelectedVeterinaryCardTypes([...selectedVeterinaryCardTypes, selectedType]);
        setDataFilled(true);
        setShowServiceAdd(false);
        setServiceDescription("");
        setServiceRate("");
        setPlatformFee(0);
        setInHandAmount(0);
      } else if (selectedServicees === "Groomers") {
        setGroomerServices((prevGroomerServices) => [...prevGroomerServices, newService]);
        updatedServices = [...prevWorkspaceData.services, newService];
        setSelectedServices(updatedServices);
        setSelectedServicees("Groomers");
        setDataFilled(true);
        setShowServiceAdd(false);
        setServicesName("");
        setVisitType("");
        setServiceDescription("");
        setServiceRate("");
        setIsGstEnabled(false);
        setPlatformFee(0);
        setInHandAmount(0);
      }
      return {
        ...prevWorkspaceData,
        services: updatedServices,
      };
    });
  };
  // console.log("WorkspaceInfo", workspaceData);
  // console.log(selectedService)
  const handleCreate = async () => {
    try {
      const updatedServices = selectedService === "d8d71038-c48c-11ed-abc1-02817ef0919c" ? selectedServices : versionData.services;
      const latitude = editedLocation ? editedLocation.split(",")[0].trim() : undefined;
      const longitude = editedLocation ? editedLocation.split(",")[1].trim() : undefined;
      const requestBody = {
        id: id,
        designationName: designationNames,
        about: about,
        workplaceName: name,
        collaboratorId: collaboratorId,
        serviceId: selectedService,
        address: address,
        town: versionData.city,
        services: selectedServices.length === 0 ? versionData.services : updatedServices,
        pincode: versionData.pincode,
        state: versionData.state,
        education: educationArray || versionData.education,
        city: editedCity || (editedCity === "" ? versionData.city : undefined),
        expertise: expertiseArray || versionData.expertise,
        workplaceTime: {
          days: selectedDays || versionData.workplaceTime?.days,
          start_time: startTime || versionData.workplaceTime.start_time,
          end_time: endTime || versionData.workplaceTime.end_time,
          lunch_break: {
            start_time: startBreakTime || versionData.workplaceTime.lunch_break.start_time,
            end_time: endBreakTime || versionData.workplaceTime.lunch_break.end_time,
          },
        },
        createdBy: userid,
        createdAt: "",
        latitude: latitude || versionData.latitude,
        longitude: longitude || versionData.longitude,
        phone: editedContact || (editedContact === "" ? versionData.phone : undefined),
        gstEnabled: "false",
        experience: editedExperince || (editedExperince === "" ? versionData.experience : undefined),
        default: "false",
        active: true,
        status: "Active",
        registraionNumber: editedRegistration,
        locked: false,
      };
      setLoading(true);

      if (selectedService === "d8d71038-c48c-11ed-abc1-02817ef0919c" || selectedService === "5e4c6b60-c48e-11ed-abc1-02817ef0919c") {
        const response = await api.EditVersionData(versionData.id, requestBody);
        console.log("API response:", response);
        toast.success("Workspace Edited Successfully");
      } else {
        console.log("Ngo");
      }
      // console.log(resonseNgo);
      navigate("/WorkSpaceList");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error editing user:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const [selectedServiceData, setSelectedServiceData] = useState(null);
  const handleSavev = () => {
    const rate = parseFloat(serviceRate);
    const updatedServiceData = {
      ...selectedServiceData,
      rate: rate,
      description: serviceDescription,
      convenienceFee: platformFee,
      inHandAmount: inHandAmount,
    };
    const updatedVersionData = {
      ...versionData,
      services: versionData.services.map((service) => (service.name === updatedServiceData.name ? updatedServiceData : service)),
    };
    setVersionData(updatedVersionData);
    const updatedServicesToShow = servicesToShow.map((service) => {
      if (service.name === selectedServiceData.name) {
        return updatedServiceData;
      }
      return service;
    });
    setServicesToShow(updatedServicesToShow);

    setShowModal(false);
  };
  const [serviceRate, setServiceRate] = useState("");
  const [platformFee, setPlatformFee] = useState(0);
  const [gst, setGst] = useState(0);
  const [serviceDescription, setServiceDescription] = useState("");
  const [inHandAmount, setInHandAmount] = useState(0);
  useEffect(() => {
    if (serviceRate) {
      const rate = parseFloat(serviceRate);
      const platformFeePercentage = 0.1;
      const gstPercentage = 0.18;
      const calculatedPlatformFee = rate * platformFeePercentage;
      let calculatedInHandAmount = rate - calculatedPlatformFee;
      // Deduct GST if isGstEnabled is true
      if (isGstEnabled) {
        calculatedInHandAmount -= calculatedInHandAmount * gstPercentage;
      }
      const calculatedGst = ((rate - calculatedPlatformFee) / 100) * 18;
      setPlatformFee(calculatedPlatformFee);
      setGst(calculatedGst);
      setInHandAmount(calculatedInHandAmount);
    }
  }, [serviceRate, isGstEnabled]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isEducationChanged = () => {
    const currentEducation = user[selectedDot].education;
    const originalEducation = workspaceData.education;

    if (!Array.isArray(currentEducation) && !Array.isArray(originalEducation)) {
      return currentEducation !== originalEducation;
    }

    // If both are arrays, we compare their lengths and content
    if (currentEducation.length !== originalEducation.length) {
      return true;
    }

    return !currentEducation.every((value, index) => value === originalEducation[index]);
  };

  const isExpertiseChanged = () => {
    const currentExpertise = user[selectedDot].expertise;
    const originalExpertise = workspaceData.expertise;

    if (!Array.isArray(currentExpertise) && !Array.isArray(originalExpertise)) {
      return currentExpertise !== originalExpertise;
    }

    // If both are arrays, we compare their lengths and content
    if (currentExpertise.length !== originalExpertise.length) {
      return true;
    }

    return !currentExpertise.every((value, index) => value === originalExpertise[index]);
  };

  const [indexItem, setIndexItem] = useState("");
  const [imageId, setImageID] = useState("");
  const [workspaceDocument, setWorkspaceDocument] = useState([]);
  useEffect(() => {
    const fetchWorkspaceDocument = async () => {
      try {
        const documents = await api.getWorkSpaceDocument(collaboratorId);
        setWorkspaceDocument(documents);
      } catch (error) {
        console.error("Error fetching workspace documents:", error);
      }
    };
    fetchWorkspaceDocument();
  }, []);
  console.log("docID", workspaceDocument);
  console.log("Groomer Service", groomerServices);
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "workspace",
        changedBy: "1",
        doneBy: "xyz",
      };
      setLoading(true);
      const response = await api.editInternalNote(requestBody);
      setLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setLoading(false);
    }
  };
  const [thumbselectedImage, setThumbSelectedImage] = useState(null);
  const [preloadedCoverImage, setPreloadedCoverImage] = useState(null);

  const [highlightedServices, setHighlightedServices] = useState([]);
  const [servicesToShow, setServicesToShow] = useState([]);

  useEffect(() => {
    if (workspaceData && workspaceData.services && versionData && versionData.services) {
      const updatedHighlightedServices = versionData.services.map((versionService, index) => {
        const workspaceService = workspaceData.services[index];
        if (!workspaceService) {
          return true; // Newly added service
        }
        return (
          versionService.name !== workspaceService.name ||
          versionService.type !== workspaceService.type ||
          versionService.description !== workspaceService.description ||
          versionService.rate !== workspaceService.rate
        );
      });
      setHighlightedServices(updatedHighlightedServices);

      const updatedServicesToShow = updatedHighlightedServices.some((isDifferent) => isDifferent) ? versionData.services : workspaceData.services;
      setServicesToShow(updatedServicesToShow);
    }
  }, [workspaceData, versionData]);
  const deletedServices =
    workspaceData && workspaceData.services && versionData && versionData.services
      ? workspaceData.services.filter((service, index) => !versionData.services[index])
      : [];

  const highlightStyle = {
    color: "red",
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div>
          {/* <WorkspaceTopBar /> */}
          <div className="row mt-2 d-flex" style={{ justifyContent: "center" }}>
            <div className="col-md-10 p-3 " style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
              <div
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {workspaceData.serviceId === "d8d71038-c48c-11ed-abc1-02817ef0919c" ? (
                    <span className="vetName">Veterinary</span>
                  ) : workspaceData.serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c" ? (
                    <span className="vetName">Grooming</span>
                  ) : (
                    <span className="vetName">NGO</span>
                  )}
                </div>
              </div>
              {serviceName.name === "NGOs" ? null : <div></div>}
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                {isEditing ? (
                  <>
                    <button type="button" className="btn btn-light navbar-buttons" onClick={handleSaveClick} style={{ marginRight: 10 }}>
                      <span className="navbar-button-text">Save</span>
                    </button>
                    <button type="button" className="btn btn-light navbar-buttons" onClick={handleCancelClick}>
                      <span className="navbar-button-text">Cancel</span>
                    </button>
                  </>
                ) : (
                  <button type="button" className="btn btn-light navbar-buttons" onClick={handleEditClick}>
                    <span className="navbar-button-text">Edit</span>
                  </button>
                )}
              </div>
              <div style={{ marginTop: 10, marginRight: 10 }}>
                <div>
                  <div>
                    {isEditing ? (
                      <input className="form-control" type="text" value={designationNames} onChange={(e) => setDesignationNames(e.target.value)} />
                    ) : (
                      <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <label style={workspaceData && versionData && highlightChanges("designationName")}>Designation Name</label>
                        <span className="about" style={workspaceData && versionData && highlightChanges("designationName")}>
                          {workspaceData && versionData && workspaceData.designationName !== versionData.designationName
                            ? versionData.designationName
                            : workspaceData && workspaceData.designationName}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="pt-2">
                    {isEditing ? (
                      <input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    ) : (
                      <div style={{ justifyContent: "space-between", display: "flex" }}>
                        <label style={workspaceData && versionData && highlightChanges("workplaceName")}>Workspace Name</label>
                        <span className="about" style={workspaceData && versionData && highlightChanges("workplaceName")}>
                          {name && versionData && name !== versionData.workplaceName ? versionData.workplaceName : name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-2">
                  {isEditing ? (
                    <input className="form-control" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                  ) : (
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      <label style={workspaceData && versionData && highlightChanges("address")}>Address</label>
                      <span className="about" style={workspaceData && versionData && highlightChanges("address")}>
                        {address && versionData && address !== versionData.address ? versionData.address : address}
                      </span>
                    </div>
                  )}
                </div>
                <div className="pt-2">
                  {isEditing ? (
                    <input className="form-control" type="text" value={about} onChange={(e) => setAbout(e.target.value)} />
                  ) : (
                    <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                      <label style={workspaceData && versionData && highlightChanges("about")}>About</label>
                      <div className="col-md-10" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="about" style={workspaceData && versionData && highlightChanges("about")}>
                          {about && versionData && about !== versionData.about ? versionData.about : about}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={{ fontWeight: 600 }}>Details</span>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-light navbar-buttons"
                    onClick={() => {
                      setEditedContact(user[selectedDot].Contact);
                      setEditedCity(user[selectedDot].city);
                      setEditedEducation(user[selectedDot].education);
                      setEditedAreaofexpertise(user[selectedDot].expertise);
                      setEditedExperince(user[selectedDot].experience);
                      setEditedLink(user[selectedDot].link);
                      setEditedRegistration(user[selectedDot].registrationNumber);
                      setEditedAchievment(user[selectedDot].achievement);
                      setEditedAreaofFocus(user[selectedDot].areaOfFocus);
                      setEditedLocation(`${user[selectedDot].latitude}, ${user[selectedDot].longitude}`); // Corrected line
                      setIsEditModalOpen(true);
                    }}
                  >
                    <span class="navbar-button-text">Edit</span>
                  </button>
                </div>
              </div>

              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={workspaceData && versionData && highlightChanges("phone")}>Contact</span>
                </div>
                <div>
                  <span style={workspaceData && versionData && highlightChanges("phone")}>{user[selectedDot].Contact}</span>
                </div>
              </div>
              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={workspaceData && versionData && highlightChanges("city")}>City</span>
                </div>
                <div>
                  <span style={workspaceData && versionData && highlightChanges("city")}>{user[selectedDot].city}</span>
                </div>
              </div>
              <div
                className="pt-2"
                style={{
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={workspaceData && versionData && highlightChanges("latitude")}>Location</span>
                </div>
                <div>
                  <span
                    style={workspaceData && versionData && highlightChanges("latitude")}
                  >{`${user[selectedDot].latitude}, ${user[selectedDot].longitude}`}</span>
                </div>
              </div>
              {serviceName.name === "Groomers" ? (
                <div
                  className="pt-2"
                  style={{
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span style={workspaceData && versionData && highlightChanges("experience")}>Experience</span>
                  </div>
                  <div>
                    <span style={workspaceData && versionData && highlightChanges("experience")}>{user[selectedDot].experience}</span>
                  </div>
                </div>
              ) : (
                <div>
                  {serviceName.name === "NGOs" ? (
                    <div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Link</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.link || null}</span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Registration Number</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.registrationNumber || null}</span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Achievment</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.achievement || null}</span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>Area of Focus</span>
                        </div>
                        <div>
                          <span>{user[selectedDot]?.areaOfFocus || null}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span style={{ color: isEducationChanged() ? "red" : "inherit" }}>Education</span>
                        </div>
                        <div>
                          <span style={{ color: isEducationChanged() ? "red" : "inherit" }}>
                            {Array.isArray(user[selectedDot].education) && user[selectedDot].education.length > 0
                              ? user[selectedDot].education.join(",")
                              : user[selectedDot].education}
                          </span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span style={{ color: isExpertiseChanged() ? "red" : "inherit" }}>Area of Expertise</span>
                        </div>
                        <div>
                          <span style={{ color: isExpertiseChanged() ? "red" : "inherit" }}>
                            {Array.isArray(user[selectedDot].expertise) && user[selectedDot].expertise.length > 0
                              ? user[selectedDot].expertise.join(",")
                              : user[selectedDot].expertise}
                          </span>
                        </div>
                      </div>
                      <div
                        className="pt-2"
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span style={workspaceData && versionData && highlightChanges("experience")}>Experience</span>
                        </div>
                        <div>
                          <span style={workspaceData && versionData && highlightChanges("experience")}>{user[selectedDot].experience}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-3">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <span style={JSON.stringify(originalSelectedDays) !== JSON.stringify(selectedDays) ? highlightStyle : {}}>
                      <strong>Workplace Timing</strong>
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {daysOfWeek.map((day) => (
                    <div className="form-check form-check-inline" key={day}>
                      <input className="form-check-input" type="checkbox" id={day} checked={selectedDays.includes(day)} onChange={() => toggleDay(day)} />
                      <label className="form-check-label" htmlFor={day} style={selectDaysChanges.days ? highlightStyle : {}}>
                        {day}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div className="col-md-3">
                    <small className="text-muted">Select Start time</small>
                    <input
                      type="time"
                      className="form-control"
                      value={startTime}
                      style={{ color: startTime !== originalStartTime ? "red" : "inherit" }}
                      onChange={handleStartTimeChange}
                      placeholder="Select start time"
                    />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: 20 }}>
                    <small className="text-muted">Select End time</small>
                    <input
                      type="time"
                      className="form-control"
                      value={endTime}
                      style={{ color: endTime !== originalEndTime ? "red" : "inherit" }}
                      onChange={handleEndTimeChange}
                      placeholder="Select end time"
                    />
                  </div>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <div className="col-md-3">
                    <small className="text-muted">Select Start Break time</small>
                    <input
                      type="time"
                      className="form-control"
                      value={startBreakTime}
                      style={{ color: startBreakTime !== originalStartBreakTime ? "red" : "inherit" }}
                      placeholder="Select start time"
                      onChange={handleBreakStartTimeChange}
                    />
                  </div>
                  <div className="col-md-3" style={{ marginLeft: 20 }}>
                    <small className="text-muted">Select End Break time</small>
                    <input
                      type="time"
                      className="form-control"
                      value={endBreakTime}
                      style={{ color: endBreakTime !== originalEndBreakTime ? "red" : "inherit" }}
                      placeholder="Select end time"
                      onChange={handleBreakEndTimeChange}
                    />
                  </div>
                </div>
              </div>

              {/* Services Card */}
              {servicesToShow && servicesToShow.length > 0 ? (
                <>
                  <div className="pt-3">
                    <span style={{ fontWeight: 600 }}>Services Offered</span>
                  </div>
                  {selectedServicees === "Veterinary" && (
                    <>
                      {!servicesToShow.some((service) => service.name === "Online" || service.type === "Online") && (
                        <Button
                          onClick={() => handleServiceSelection("Online")}
                          disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Online")}
                        >
                          Online
                        </Button>
                      )}
                      {!servicesToShow.some((service) => service.name === "Offline" || service.type === "Offline") && (
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={() => handleServiceSelection("Offline")}
                          disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Offline")}
                        >
                          Offline
                        </Button>
                      )}
                      {!servicesToShow.some((service) => service.name === "Home Visit" || service.type === "Home Visit") && (
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={() => handleServiceSelection("Home Visit")}
                          disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Home Visit")}
                        >
                          Home Visit
                        </Button>
                      )}
                    </>
                  )}
                  {selectedServicees === "Groomers" && (
                    <div style={{ flexDirection: "row" }}>
                      <Button onClick={() => handleServiceSelection("Add Service")}>Add Service</Button>
                    </div>
                  )}
                  {servicesToShow
                    .filter((service, index) => !deletedServices.includes(service))
                    .map((service, index) => (
                      <div>
                        <CardGroup
                          key={index}
                          className="p-3"
                          style={{
                            marginTop: 10,
                            border: `1px solid ${highlightedServices[index] ? "red" : "#beb7b7"}`,
                            borderRadius: 10,
                            marginRight: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div style={{ flexDirection: "column" }}>
                              {service.name || service.type}
                              {workspaceData.serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c" && (
                                <span>({service.isHomeVisit === true ? "Home Visit" : "Center"})</span>
                              )}
                              <div>{service.description}</div>
                            </div>
                            <div>Service Cost:</div>
                            <div>Platform Fee (10%):</div>
                            {workspaceData.gstEnabled && <div>Gst:</div>}
                            <div>In-Hand Amount:</div>
                          </div>
                          <div>
                            <div
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <Dropdown isOpen={dropdownStates[index]} toggle={() => toggleDropdown(index)}>
                                <DropdownToggle style={{ background: "none", border: "none" }}>
                                  <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedServiceData(service);
                                      setServiceRate(service.rate.toString()); // Populate the service rate field as a string
                                      setServiceDescription(service.description); // Populate other fields as needed
                                      setPlatformFee(service.convenienceFee);
                                      setShowModal(true);
                                    }}
                                  >
                                    Modify
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDeletes(service.name)}>Delete</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div>
                              <br></br>
                            </div>
                            <div>₹{service.rate}</div>
                            <div>₹{service.convenienceFee}</div>
                            {workspaceData.gstEnabled && (
  <div>{service.gstPrice}</div>
)}

                            <div>₹{service.rate - service.convenienceFee}</div>
                          </div>
                        </CardGroup>
                      </div>
                    ))}
                </>
              ) : (
                <div>
                  {selectedServicees === "NGO" ? null : (
                    <div className="mt-2">
                      <span>Service Offered*</span>
                      {selectedServicees === "Groomers" ? (
                        <div style={{ flexDirection: "row" }}>
                          <Button onClick={() => handleServiceSelection("Add Service")}>Add Service</Button>
                        </div>
                      ) : (
                        <div style={{ flexDirection: "row" }}>
                          <Button
                            onClick={() => handleServiceSelection("Online")}
                            disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Online")}
                          >
                            Online
                          </Button>
                          <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => handleServiceSelection("Offline")}
                            disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Offline")}
                          >
                            Offline
                          </Button>
                          <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => handleServiceSelection("Home Visit")}
                            disabled={selectedServicees === "Veterinary" && selectedVeterinaryCardTypes.includes("Home Visit")}
                          >
                            Home Visit
                          </Button>
                        </div>
                      )}

                      {dataFilled && selectedServicees === "Groomers" && (
                        <div>
                          {groomerServices.map((service, index) => (
                            <CardGroup
                              key={index}
                              className="p-3"
                              style={{
                                marginTop: 10,
                                border: "1px solid #beb7b7",
                                borderRadius: 10,
                                marginRight: 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div style={{ flexDirection: "column" }}>
                                  <div>
                                    {service.name} ({service.isHomeVisit === true ? "Home Visit" : "Center"})
                                  </div>
                                  <div>{service.description}</div>
                                </div>
                                <div>Service Cost</div>
                                <div> Platform Fee (10%):</div>
                                <div>In-Hand Amount</div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Dropdown isOpen={selectedServiceType === service.name} toggle={() => toggleDropdown(service.name)}>
                                    <DropdownToggle tag="span" data-toggle="dropdown">
                                      <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem onClick={() => handleModify(service.name)}>Modify</DropdownItem>
                                      <DropdownItem onClick={() => handleDeletes(service.name)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                                <div>
                                  <br></br>
                                </div>
                                <div>₹{service.rate}</div>
                                <div>₹{platformFee.toFixed(2)}</div>
                                <div>₹{service.inHandAmount}</div>
                              </div>
                            </CardGroup>
                          ))}
                        </div>
                      )}
                      {dataFilled &&
                        selectedServicees !== "Groomers" &&
                        selectedServices.map((service, index) => (
                          <CardGroup
                            key={index}
                            className="p-3"
                            style={{
                              marginTop: 10,
                              border: "1px solid #beb7b7",
                              borderRadius: 10,
                              marginRight: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div style={{ flexDirection: "column" }}>
                                <div>{service.type}</div>
                                <div>{service.description}</div>
                              </div>
                              <div>Service Cost</div>
                              <div>Convenience Fee</div>
                              <div>In-Hand Amount</div>
                            </div>
                            <div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Dropdown isOpen={selectedServiceType === service.type} toggle={() => toggleDropdown(service.type)}>
                                  <DropdownToggle tag="span" data-toggle="dropdown">
                                    <FontAwesomeIcon color="#007bff" fontSize={20} icon={faEllipsisVertical} style={{ cursor: "pointer" }} />
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <DropdownItem onClick={() => handleModify(service.type)}>Modify</DropdownItem>
                                    <DropdownItem onClick={() => handleDeletes(service.type)}>Delete</DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                              <div>₹{service.rate}</div>
                              <div>₹{platformFee.toFixed(2)}</div>
                              <div>₹{service.inHandAmount}</div>
                            </div>
                          </CardGroup>
                        ))}
                    </div>
                  )}
                </div>
              )}
              {deletedServices.length > 0 && (
                <>
                  <div className="pt-3">
                    <span style={{ fontWeight: 600 }}>Deleted Services</span>
                  </div>
                  {deletedServices.map((service, index) => (
                    <CardGroup
                      key={index}
                      className="p-3"
                      style={{
                        marginTop: 10,
                        border: `1px solid red`, // Highlight deleted services
                        borderRadius: 10,
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div style={{ flexDirection: "column" }}>
                          {service.name || service.type}
                          {workspaceData.serviceId === "5e4c6b60-c48e-11ed-abc1-02817ef0919c" && (
                            <span>({service.isHomeVisit === true ? "Home Visit" : "Center"})</span>
                          )}
                          <div>{service.description}</div>
                        </div>
                        <div>Service Cost:</div>
                        <div>Platform Fee (10%):</div>
                        <div>In-Hand Amount:</div>
                      </div>
                      <div>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        ></div>
                        <div>
                          <br></br>
                        </div>
                        <div>₹{service.rate}</div>
                        <div>₹{service.convenienceFee}</div>
                        <div>₹{service.rate - service.convenienceFee}</div>
                      </div>
                    </CardGroup>
                  ))}
                </>
              )}
              {/* 
              <div className="mt-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <strong>Media</strong>
                  </div>
                  <div>
                    {uploading ? (
                      <Button onClick={handleSave}>Save</Button>
                    ) : (
                      <Button>
                        <label
                          htmlFor="imageUpload"
                          style={{
                            marginRight: 5,
                            border: "none",
                            padding: "5px 10px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Upload
                          <input type="file" id="imageUpload" multiple onChange={handleImageUpload} style={{ display: "none" }} />
                        </label>
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {images.map((image, index) => (
                    <div key={image.key} className="image-item" style={{ position: "relative", marginRight: 10 }}>
                      <img src={image.value} className="img-fluid" style={{ width: 100, height: "auto" }} alt={`Image ${index}`} />
                      <Button
                        style={{ background: "transparent", border: "none" }}
                        onClick={() => {
                          setIsConfirmOpen(true);
                          setIndexItem(index);
                          setImageID(image.key);
                        }}
                        className="delete-button"
                      >
                        <FontAwesomeIcon color="#007bff" fontSize={20} icon={faXmark} />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" mt-2">
                <label className="pt-10 lableText">
                  Cover Image
                  <span className="required">*</span>
                </label>
                <div className="coverUpload">
                  <div className="text-right">
                    <label htmlFor="coverUpload" className="btn btn-light navbar-buttons">
                      <span className="vetUploadButton">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                      </span>
                      <span className="navbar-button-text pl-2">Upload</span>
                      <input id="coverUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                    </label>
                    {thumbselectedImage && (
                      <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                        <span className="vetUploadButton">
                          <FontAwesomeIcon fontSize={20} icon={faTrash} />
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex flex-wrap">
                        <div className="col-md-4 d-flex align-items-center pt-3">
                          {thumbselectedImage ? (
                            <img src={URL.createObjectURL(thumbselectedImage)} alt="Uploaded" style={{ width: 200 }} />
                          ) : (
                            preloadedCoverImage && <img src={preloadedCoverImage.src} alt="Prepopulated" style={{ width: 200 }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* Add Service Modal */}
          <Modal isOpen={showServiceAdd} toggle={() => setShowServiceAdd(false)}>
            <ModalHeader toggle={() => setShowServiceAdd(false)}>{selectedType}</ModalHeader>
            <ModalBody>
              {selectedServicees === "Groomers" ? (
                <div>
                  <div className="  mt-2">
                    <label for="inputPetBreed">Service Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Service Name"
                      value={servicesName}
                      onChange={(e) => setServicesName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="  mt-2">
                    <label for="inputPetBreed">Visit Type</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label" htmlFor="center">
                          Center
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="visitType"
                          id="center"
                          value="Center"
                          onChange={(e) => setVisitType(e.target.value)}
                        />
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label" htmlFor="homevisit">
                          Home Visit
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="visitType"
                          id="homevisit"
                          value="Home visit"
                          onChange={(e) => setVisitType(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="  mt-2">
                <label for="inputPetBreed">Service Discription</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Service Description"
                  value={serviceDescription}
                  onChange={(e) => setServiceDescription(e.target.value)}
                  required
                />
              </div>
              <div className="  mt-2">
                <label for="inputPetBreed">Service Rate*</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Service Rate"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  required
                />
              </div>
              {selectedServicees === "Groomers" && (
                <div className=" mt-2">
                  <label>Enable GST</label>
                  <Form>
                    <FormGroup switch>
                      <Input id="gstSwitch" type="switch" role="switch" onChange={() => setIsGstEnabled(!isGstEnabled)} checked={isGstEnabled} />
                    </FormGroup>
                  </Form>
                </div>
              )}
              <div className="mt-2">
                <span>
                  <strong>Details</strong>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    Platform Fee (10%):
                  </span>
                  {isGstEnabled && selectedServicees === "Groomers" ? <span style={{ flexDirection: "row" }}>GST (18%):</span> : null}
                </div>
                <div>
                  <span
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    ₹{platformFee.toFixed(2)}
                  </span>
                  {isGstEnabled && selectedServicees === "Groomers" ? <span style={{ flexDirection: "row" }}>₹{gst.toFixed(2)}</span> : null}
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>In-Hand Amount**:</span>
                <span>₹{inHandAmount.toFixed(2)}</span>
              </div>
              <div className="mt-2">
                <span>*Amount Will be Charged to customer</span>
                <span>**Amount Will be transferred to Collaborator</span>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowServiceAdd(false)}>
                Close
              </Button>
              <Button color="primary" onClick={handleSaves}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
          {/* Modify Service Modal */}

          <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
            <ModalHeader toggle={() => setShowModal(false)}>{selectedServiceData ? "Modify Service" : ""}</ModalHeader>
            <ModalBody>
              {selectedServiceData && (
                <>
                  {/* Populate and edit service data */}
                  <div className=" mt-2">
                    <label htmlFor="inputServiceName">Service Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Service Name"
                      value={selectedServiceData.name}
                      onChange={(e) =>
                        setSelectedServiceData({
                          ...selectedServiceData,
                          name: e.target.value,
                        })
                      }
                      required
                    />
                    <div className=" mt-2">
                      <label htmlFor="inputServiceDescription">Service Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Service Description"
                        value={serviceDescription}
                        onChange={(e) => setServiceDescription(e.target.value)}
                        required
                      />
                    </div>
                    <div className="  mt-2">
                      <label for="inputPetBreed">Service Rate*</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Service Rate"
                        value={serviceRate}
                        onChange={(e) => setServiceRate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mt-2">
                      <span>
                        <strong>Details</strong>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span style={{ flexDirection: "row", display: "flex" }}>Platform Fee (10%):</span>
                        {/* <span style={{ flexDirection: "row"  }}>
                            GST (18%):
                          </span> */}
                      </div>
                      <div>
                        <span style={{ flexDirection: "row", display: "flex" }}>₹{platformFee.toFixed(2)}</span>
                        {/* <span style={{ flexDirection: "row" }}>
                            ₹{gst.toFixed(2)}
                          </span> */}
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>In-Hand Amount**:</span>
                      <span>₹{inHandAmount.toFixed(2)}</span>
                    </div>
                    <div className="mt-2">
                      <span>*Amount Will be Charged to customer</span>
                      <span>**Amount Will be transferred to Collaborator</span>
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button color="primary" onClick={handleSavev}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
          {/* Edit user Modal */}
          <Modal isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} contentLabel="Edit Modal">
            <ModalHeader style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Edit User</div>
            </ModalHeader>
            <ModalBody className="px-5">
              <div>
                <label>Contact:</label>
                <input className="form-control" type="text" value={editedContact} onChange={(e) => setEditedContact(e.target.value)} />
              </div>
              <div>
                <label>City:</label>
                <input className="form-control" type="text" value={editedCity} onChange={(e) => setEditedCity(e.target.value)} />
              </div>
              <div>
                <label>Location:</label>
                <input className="form-control" type="text" value={editedLocation} onChange={(e) => setEditedLocation(e.target.value)} />
              </div>
              {serviceName.name === "Groomers" ? (
                <div>
                  <label>Experience:</label>
                  <input className="form-control" type="text" value={editedExperince} onChange={(e) => setEditedExperince(e.target.value)} />
                </div>
              ) : (
                <div>
                  {serviceName.name === "NGOs" ? (
                    <div>
                      <label>Link:</label>
                      <input className="form-control" type="text" value={editedLink} onChange={(e) => setEditedLink(e.target.value)} />
                      <label>Registration Number:</label>
                      <input className="form-control" type="text" value={editedRegistration} onChange={(e) => setEditedRegistration(e.target.value)} />
                      <label>Achievment:</label>
                      <input className="form-control" type="text" value={editedAchievment} onChange={(e) => setEditedAchievment(e.target.value)} />
                      <label>Area of Focus:</label>
                      <input className="form-control" type="text" value={editedAreaofFocus} onChange={(e) => setEditedAreaofFocus(e.target.value)} />
                    </div>
                  ) : (
                    <div>
                      <div className="">
                        <label>Education:</label>
                        {educationArray.map((education, index) => (
                          <div key={index}>
                            <input
                              className="form-control mt-2"
                              type="text"
                              value={education}
                              onChange={(e) => {
                                const updateEducationArray = [...educationArray];
                                updateEducationArray[index] = e.target.value;
                                setEducationArray(updateEducationArray);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <FontAwesomeIcon onClick={addEducation} color="#0D6EFD" fontSize={20} icon={faPlus} />
                      </div>
                      <div className="">
                        <label>Area of Expertise:</label>
                        {expertiseArray.map((expertise, index) => (
                          <div key={index}>
                            <input
                              className="form-control mt-2"
                              type="text"
                              value={expertise}
                              onChange={(e) => {
                                const updatedExpertiseArray = [...expertiseArray];
                                updatedExpertiseArray[index] = e.target.value;
                                setExpertiseArray(updatedExpertiseArray);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <FontAwesomeIcon onClick={addExpertise} color="#0D6EFD" fontSize={20} icon={faPlus} />
                      </div>
                      <div>
                        <label>Experience:</label>
                        <input className="form-control" type="text" value={editedExperince} onChange={(e) => setEditedExperince(e.target.value)} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleSaveEdits} style={{ marginRight: 10 }} color="primary">
                Save
              </Button>
              <Button onClick={() => setIsEditModalOpen(false)} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <div style={{ marginTop: 20, padding: "0 80px" }}>
            <h5>Internal Note</h5>
            <div className="chat-container">
              <div className="chat-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <div className="message-text">{message.text}</div>
                    <div className="message-time">{getCurrentLocalDateTime()}</div>
                  </div>
                ))}
                {internalNotes.map((note) => (
                  <div key={note.id} className={`message bot`}>
                    <div className="message-text">{note.note}</div>
                    <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                  </div>
                ))}
              </div>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                <textarea
                  className="form-control"
                  rows={5}
                  type="text"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                  <button className="form-control" style={{ width: 100 }} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "8%",
              marginTop: 10,
            }}
          >
            <Button onClick={handleCreate} style={{ marginRight: 10 }} color="primary">
              Apply
            </Button>
            <Button onClick={() => navigate(-1)} color="secondary">
              Cancel
            </Button>
          </div>
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleDeleteImage(indexItem, imageId)}
            message="Are you sure you want to Delete Image?"
          />
        </div>
      )}
    </>
  );
};

export default VersionWorspace;
