import { useContext } from 'react';
import { AppContext } from './states';

export function useRollsState() {
  const { state, dispatch } = useContext(AppContext);
  const currentRollsListData = state.rollsState;
  const setRollsListData = data => {
    sessionStorage.setItem('rollsListData', JSON.stringify(data));
    dispatch({ type: 'rollsState', payload: data });
  };
  const getRollsListData = id => {
    const storedData = sessionStorage.getItem('rollsListData');
    const rollsListData = storedData ? JSON.parse(storedData) : currentRollsListData;

    return rollsListData && rollsListData.length > 0
      ? rollsListData.find(i => i.id === id) || null
      : null;
  };
  return {
    RollsListData: currentRollsListData,
    setRollsListData,
    getRollsListData,
  };
}
