import React, { useState, useEffect } from "react";
import { Table, Form, FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Label, Pagination, PaginationItem, PaginationLink,Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CouponTopBar from "../../component/CouponTopBar";
import Apis from "../../utils/api";
import { useCouponState } from "../services/couponState";
import { ToastContainer,toast } from "react-toastify";
import LoaderModal from "../../component/common/Loader";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../component/common/ConfirmDialog";
const CouponList = () => {
  const api = new Apis();
  const { setCouponData } = useCouponState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [coupon, setCouponList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [state, setState] = useState(true);
  const filteredName = coupon.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const couponLower = (item.code || "").toLowerCase();
    const idLower = (item.id || "").toLowerCase();
    return (
      (state || item.active === true) && 
      (couponLower.includes(searchQueryLower) || idLower.includes(searchQueryLower))
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredName.slice(indexOfFirstItem, indexOfLastItem);
  const [filterValue, setFilterValue] = useState("");
  const totalPages = Math.ceil(coupon.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  useEffect(() => {
    const fetchpetParent = async () => {
      try {
        const otppetParent = await api.getCouponList();
        setCouponData(otppetParent);
        setCouponList(otppetParent);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching coupon:", error);
      }
    };
    fetchpetParent();
  }, []);
  const [loading, setLoading] = useState(false);
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const customTableHeadings = ["checkbox", "ID", "Coupon", "Used Count", "Quantity", "Discount", "Discount Type", "Active","Actions"];
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const handleToggleChange = async (event) => {
    const itemId = event.target.value;
    const updatedCoupon = [...coupon];
    const itemToUpdate = updatedCoupon.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        itemToUpdate.active = !itemToUpdate.active;
        await CouponAdd(itemToUpdate);
        setCouponList(updatedCoupon);
      } catch (error) {
        console.error("Error toggling blog status:", error);
        itemToUpdate.active = !itemToUpdate.active;
        setCouponList(updatedCoupon);
      }
    }
  };
  const CouponAdd = async (itemToUpdate) => {
    try {
      const requestBody = {
        id: itemToUpdate.id,
        code: itemToUpdate.code,
        createdBy: itemToUpdate.createdBy,
        expiryDate: itemToUpdate.expiryDate,
        couponName: itemToUpdate.couponName,
        quantity: itemToUpdate.quantity,
        discount: itemToUpdate.discount,
        active: itemToUpdate.active,
        usedCount: itemToUpdate.usedCount,
        endDate: itemToUpdate.endDate,
        split: itemToUpdate.split,
        publishedFor: itemToUpdate.publishedFor,
        published: itemToUpdate.published,
        collabDiscount: itemToUpdate.collabDiscount,
        discountType: itemToUpdate.discountType,
        type:itemToUpdate.type,
        description: itemToUpdate.description,
        startDate: itemToUpdate.startDate,
        refId:itemToUpdate.refId
      };
      setLoading(true);
      const response = await api.updateCoupon(1, requestBody);
      setLoading(false);
      toast.success("Coupon Status Changed!");
      console.log(response);
    } catch (error) {
      toast.error("Coupon Status Cant be Changed!");
      console.error("Error adding admin:", error);
      setLoading(false);
    }
  };
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 1000);
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => coupon[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleEdit = (item) => {
    navigate(`/CouponList/EditCoupon/${item.id}`);
  };

  const handleDelete = async (item, rowIndex) => {
    try {
      setLoading(true);
      const response = await api.deleteCoupon(item.id);
      setLoading(false);
      // Calculate the index in the original Webinar array
      const originalIndex = indexOfFirstItem + rowIndex;
      // Remove the deleted item from the state
      setCouponList((prevWebinars) => {
        const updatedCoupon = [...prevWebinars];
        updatedCoupon.splice(originalIndex, 1);
        return updatedCoupon;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("Coupon deleted successfully!");
      navigate("/CouponList");
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Coupon:", error);
    } finally {
      setLoading(false);
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  // Live date published date Created Date
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex, setRowIndex] = useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); 
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} />
  {loading ? (
    <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
  ) : (
  
    <>
      <CouponTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} filterValue={filterValue} setFilterValue={setFilterValue} />
      <Button onClick={() => setState(!state)}>{state ? "Show Active" : "Show All"}</Button>
      <Table hover>
      <thead style={{position:'sticky',top:0,zIndex:1}}>
          <tr>
            {customTableHeadings.map((heading, index) => (
              <th key={index} style={{ whiteSpace: "nowrap" }}>
                {heading === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                  />
                ) : (
                  heading
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {/* <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => toggleRowSelection(rowIndex)}
                />
              </td> */}
              {customTableHeadings.map((heading, colIndex) => (
                <td key={colIndex}>
                  {heading === "Actions" ? (
                        <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                          <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : heading === "ID" ? (
                    <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                      {item.id.slice(0, 8)}
                      {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                    </span>
                  ) : heading === "Coupon" ? (
                    <a href onClick={() => navigate(`/CouponList/EditCoupon/${item.id}`)} style={{ cursor: "pointer" }}>
                      {item.code}
                    </a>
                  ) : heading === "checkbox" ? (
                    <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                  ) : heading === "Used Count" ? (
                    item.usedCount
                  ) : heading === "Quantity" ? (
                    item.quantity
                  ) : heading === "Discount" ? (
                    item.discount
                  ) : heading === "Discount Type" ? (
                    item.discountType
                  ) : heading === "Active" ? (
                    <Form>
                      <FormGroup switch>
                        <Input type="switch" role="switch" checked={item.active} onChange={handleToggleChange} value={item.id} />
                      </FormGroup>
                    </Form>
                  ) : (
                    item[heading]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>  
      </Table>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
      <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleDelete(itemId, selectedrowIndex)}
            message="Are you sure you want to remove the listing?"
          />
    </>
  )}</>
  );
};

export default CouponList;
