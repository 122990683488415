import React, { useState,useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
const AddedCourses = () => {
  const api = new Apis();
  const [episodeUploadProgress, setEpisodeUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState("");
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [min, setMin] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const { selectedEpisodes, id } = useParams();
  const [webtype, setWebtype] = useState("Free");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const handleListingTypeChange = (event) => {
    setWebtype(event.target.value);
  };
  const handleCancelVideo = () => {
    setSelectedVideo(null);
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(file);
    }
  };
  const handleMinChange = (event) => {
    setMin(event.target.value);
  };
  const handleDescriptionChange = (content, editor) => {
    setDescription(content);
  };
  const handleHeaderChange = (event) => {
    setHeader(event.target.value);
  };
  const [episodeVideoUploaded, setEpisodeVideoUploaded] = useState(Array.from({ length: selectedEpisodes }, () => false));
  const markEpisodeAsUploaded = (index) => {
    const updatedUploadStatus = [...episodeVideoUploaded];
    updatedUploadStatus[index] = true;
    setEpisodeVideoUploaded(updatedUploadStatus);
  };
  const episodeUpload = async (index) => {
    const episodenumber = index + 1;
    setLoading(true);
    const formData = new FormData();
    formData.append("files", selectedVideo);
    try {
      if (!header) {
        throw new Error("header is a required field");
      }
      if (!description) {
        throw new Error("description is a required field");
      }
      if (!min) {
        throw new Error("duration is a required field");
      }
      if (!episodenumber) {
        throw new Error("episodenumber is a required field");
      }
      if (!selectedVideo) {
        throw new Error("selectedVideo is a required field");
      }
      // Basic validation checks
      const response = await api.uploadWebinar(
        id,
        header,
        description,
        min,
        episodenumber, //episode Number
        formData
      );
      console.log(response);
      setSelectedVideo(null);
      toast.success("Video uploaded successfully!");
      setLoading(false);
      markEpisodeAsUploaded(index);
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("Error uploading video: " + error.message);
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const areAllEpisodesUploaded = episodeVideoUploaded.every((uploaded) => uploaded);
  useEffect(() => {
    if (areAllEpisodesUploaded) {
      // Navigate to the course page when all episodes are uploaded
      navigate(`/CoursesList`);
    }
  }, [areAllEpisodesUploaded, id, navigate]);
  return (
    <>
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <>
          <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <ToastContainer position="top-right" autoClose={5000} />
            {Array.from({ length: selectedEpisodes }, (_, index) => (
              <Accordion key={index} flush open={open} toggle={toggle}>
                <AccordionHeader targetId={index + 1}>Episode {index + 1}</AccordionHeader>
                <div style={{ marginBottom: 20 }}>
                  <AccordionItem>
                    <AccordionBody accordionId={index + 1}>
                      <div className="pt-2 row">
                        <div className="col-md-7">
                          <div className=" mt-2">
                            <label>Title*</label>
                            <input type="text" name="header" className="form-control" value={header} onChange={handleHeaderChange} />
                          </div>
                          <div className=" mt-2">
                            <label>Description*</label>
                            <Editor
                              apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                              init={{
                                selector: "textarea#basic-example",
                                height: 500,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "charmap",
                                  "preview",
                                  "anchor",
                                  "searchreplace",
                                  "visualblocks",
                                  "code",
                                  "fullscreen",
                                  "insertdatetime",
                                  "media",
                                  "table",
                                  "help",
                                  "wordcount",
                                ],
                                toolbar:
                                  "undo redo | blocks | " +
                                  "bold italic backcolor | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | " +
                                  "removeformat | help",
                                content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                              }}
                              value={description}
                              onEditorChange={handleDescriptionChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className=" mt-2 col-md-12">
                            <label>Duration*</label>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input type="number" name="header" className="form-control" value={min} onChange={handleMinChange} />
                                <span style={{ marginLeft: 10 }}>Min</span>
                              </div>
                            </div>
                          </div>
                          <div className=" mt-3">
                            <label>Upload Course*</label>
                            <div style={{ display: "flex" }}>
                              {selectedVideo ? (
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    // maxHeight: "60vh",
                                    overflow: "auto",
                                  }}
                                >
                                  <video controls style={{ width: "100%" }}>
                                    <source src={URL.createObjectURL(selectedVideo)} />
                                  </video>
                                </div>
                              ) : (
                                <div style={{}}>
                                  <Button color="secondary" onClick={handleUploadClick}>
                                    Upload
                                  </Button>
                                </div>
                              )}
                            </div>
                            {selectedVideo && (
                              <Button color="danger" onClick={handleCancelVideo}>
                                Remove
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pt-2 row" style={{ justifyContent: "flex-end", display: "flex" }}>
                        <div
                          className="col-md-6"
                          style={{
                            justifyContent: "flex-end",
                            display: "flex",
                            gap: "1rem",
                          }}
                        >
                         <Button onClick={() => episodeUpload(index)} color="primary" disabled={episodeVideoUploaded[index]}>
                            Next
                          </Button>
                          <Button onClick={() => navigate(-1)} color="primary" disabled={episodeVideoUploaded[index]}>
                            Back
                          </Button>
                        </div>
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                </div>
              </Accordion>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default AddedCourses;
