import React, { useState, useEffect } from "react";
import { Table, Form, FormGroup, Input,Button,Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import AdoptionTopBar from "../../component/AdoptionTopBar";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api";
import moment from "moment";
import { useAdoptionState } from "../services/adoptionState";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../component/common/ConfirmDialog";
import LoaderModal from "../../component/common/Loader";
const data = [
  {
    name: "Otto",
    designation: "Pune",
    userName: "VSHARMA02",
    dateJoined: "15 Apr 2023",
    access: "15 Apr 2023",
  },
];

const AdoptionList = () => {
  const { setAdoptionListData } = useAdoptionState();
  const api = new Apis();
  const [adoptionList, setAdoptionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchAdoption = async () => {
      try {
        const adoption = await api.getAdoptionList();
        setAdoptionListData(adoption);
        adoption.sort((a, b) => moment(b.joinedDate).valueOf() - moment(a.joinedDate).valueOf());
        setAdoptionList(adoption);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching RollSList:", error);
      }
    };
    fetchAdoption();
  }, []);
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = ["checkbox", ...Object.keys(data[0])];
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const [state,setState]=useState(true);
  const filteredAdoption = adoptionList.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const mobileLower = (item.mobile || "").toLowerCase();
    const breedLower = (item.breed || "").toLowerCase();
    const petNameLower = (item.petName || "").toLowerCase();
    const idLower = (item.id || "").toLowerCase();
    const idSpecies = (item.id || "").toLowerCase();
    return (
      (!state || item.status === "Active") && // Only include items with status "ACTIVE" if state is true
      (mobileLower.includes(searchQueryLower) ||
      breedLower.includes(searchQueryLower) ||
      petNameLower.includes(searchQueryLower) ||
      idLower.includes(searchQueryLower))
    );
  });  
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const totalPages = Math.ceil(adoptionList.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAdoption.slice(indexOfFirstItem, indexOfLastItem);
  const handleToggleChange = async (event) => {
    const itemId = event.target.value;
    const updatedAdoption = [...adoptionList];
    const itemToUpdate = updatedAdoption.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        itemToUpdate.status = event.target.checked ? "Active" : "Created";
        itemToUpdate.active = event.target.checked;

        await adoptionEdit(itemToUpdate);
        setAdoptionList(updatedAdoption);
      } catch (error) {
        console.error("Error toggling blog status:", error);
        itemToUpdate.status = event.target.checked ? "Created" : "Active";
        itemToUpdate.active = !event.target.checked;
        setAdoptionList(updatedAdoption);
      }
    }
  };
  const handleAdoptionChange = async (event) => {
    const itemId = event.target.value;
    const updatedAdoption = [...adoptionList];
    const itemToUpdate = updatedAdoption.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        itemToUpdate.status = event.target.checked ? "Adopted" : "Inactive";
        itemToUpdate.active = event.target.checked;

        await adoptionEdit(itemToUpdate);
        setAdoptionList(updatedAdoption);
      } catch (error) {
        console.error("Error toggling blog status:", error);
        itemToUpdate.status = event.target.checked ? "Inactive" : "Adopted";
        itemToUpdate.active = !event.target.checked;
        setAdoptionList(updatedAdoption);
      }
    }
  };
  const adoptionEdit = async (itemToUpdate) => {
    setLoading(true);
    try {
      const requestBody = {
        id: itemToUpdate.id,
        refId: itemToUpdate.refId,
        petName: itemToUpdate.petName,
        address: itemToUpdate.address,
        city: itemToUpdate.city,
        state: itemToUpdate.state,
        pincode: itemToUpdate.pincode,
        type: itemToUpdate.type,
        breed: itemToUpdate.breed,
        sex: itemToUpdate.sex,
        month: itemToUpdate.month,
        year: itemToUpdate.year,
        color: itemToUpdate.color,
        weight: itemToUpdate.weight,
        size: itemToUpdate.size,
        coatLength: itemToUpdate.coatLength,
        story: itemToUpdate.story,
        status: itemToUpdate.status,
        mobile: itemToUpdate.mobile,
        link: itemToUpdate.link,
        phone: itemToUpdate.phoneNumber,
        active: itemToUpdate.active,
        listedBy:itemToUpdate.listedBy,
        featured:itemToUpdate.featured,
        sponsored:itemToUpdate.sponsored
      };
      const response = await api.updateAdoption( requestBody);
      toast.success("Adoption Status Changed!");
      console.log(response);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.success("Adoption Status Cant be Changed!");
      setLoading(false);
    }
  };
  const customTableHeadings = ["checkbox", "ID", "Pet Name", "Species", "City", "Breed", "Mobile", "Active","Adopted","Actions"];
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => adoptionList[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const navigate = useNavigate();
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));

  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex, setRowIndex] = useState();
  const handleEdit = (item) => {
    navigate(`/AdoptionList/EditAdoption/${item.id}`);
  };

  const handleDelete = async (item, rowIndex) => {
    try {
      setLoading(true);
      const response = await api.deleteAdoption(item.id);
      setLoading(false);
      // Calculate the index in the original Webinar array
      const originalIndex = indexOfFirstItem + rowIndex;
      // Remove the deleted item from the state
      setAdoptionList((prevWebinars) => {
        const updatedWebinars = [...prevWebinars];
        updatedWebinars.splice(originalIndex, 1);
        return updatedWebinars;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("Adoption deleted successfully!");
      navigate("/AdoptionList");
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Webinar:", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} />
    {loading ? (
      <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
    ) : (
    <>
     <ToastContainer position="top-right" autoClose={5000} />
      <AdoptionTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} />
      <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
      <Table hover style={{ tableLayout: "auto" }}>
      <thead style={{position:'sticky',top:0,zIndex:1}}>
          <tr>
            {customTableHeadings.map((heading, index) => (
              <th key={index} style={{ whiteSpace: "nowrap" }}>
                {heading === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                  />
                ) : (
                  heading
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {customTableHeadings.map((heading, colIndex) => (
                <td key={colIndex}>
                  {heading === "Actions" ? (
                        <Dropdown style={{ textAlign: "center" }} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                          <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) :heading === "ID" ? (
                    <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                      {item.id.slice(0, 8)}
                      {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                    </span>
                  ) : heading === "Pet Name" ? (
                    <a href style={{ cursor: "pointer" }} onClick={() => navigate(`/AdoptionList/EditAdoption/${item.id}`)}>
                      {" "}
                      {item.petName}
                    </a>
                  ) : heading === "checkbox" ? (
                    <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                  ) : heading === "Mobile" ? (
                    item.mobile
                  ) : heading === "Species" ? (
                    item.type
                  ) : heading === "City" ? (
                    item.city
                  ) : heading === "Breed" ? (
                    item.breed
                  ) : heading === "Active" ? (
                    <Form>
                      <FormGroup switch>
                        <Input type="switch" role="switch" checked={item.status === "Active"} value={item.id} onChange={handleToggleChange} />
                      </FormGroup>
                    </Form>
                    ) : heading === "Adopted" ? (
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" checked={item.status === "Adopted"} onChange={handleAdoptionChange} value={item.id}/>
                        </FormGroup>
                      </Form>
                  )  :(
                    item[heading]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
      <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={() => handleDelete(itemId, selectedrowIndex)}
            message="Are you sure you want to remove the listing?"
          />
    </>
    )}
    </>
  );
};

export default AdoptionList;
