import { useContext } from 'react';
import { AppContext } from './states';

export function useWorkSpaceState() {
  const { state, dispatch } = useContext(AppContext);
  const currentWorkspaceData = state.workspaceState;
  const setWorkspaceData = data => {
    sessionStorage.setItem('workspaceData', JSON.stringify(data));
    dispatch({ type: 'workspaceState', payload: data });
  };
  const getWorkspaceData = id => {
    const storedData = sessionStorage.getItem('workspaceData');
    const workspaceData = storedData ? JSON.parse(storedData) : currentWorkspaceData;

    if (id) {
      return workspaceData && workspaceData.length > 0
        ? workspaceData.find(i => i.id === id) || null
        : null;
    } else {
      return workspaceData;
    }
  };
  return {
    WorkspaceData: currentWorkspaceData,
    setWorkspaceData,
    getWorkspaceData,
  };
}
