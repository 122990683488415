import React, { useState } from "react";
import { CardGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faAdd } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import dataURItoBlob, { isAlphaOnly, isValidMobile, isValidPinCode } from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import LoaderModal from "../component/common/Loader";
import LeafletMap from "../component/common/LeafletMap";
import {Form,FormGroup,Input,} from "reactstrap";
const EventDetails = () => {
  const api = new Apis();
  const navigate = useNavigate();
  const [bodyText, setBodyText] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [terms, setTerms] = useState("");
  const [canclePolicy, setCancelPolicy] = useState("");
  const [link, setLink] = useState("");
  // const [tags, setTags] = useState("");
  const [time, setTime] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const [listingType, setListingType] = useState("Online");
  const [eventDateTime, setEventDateTime] = useState(""); // Initialize with an empty string
  const [loading, setLoading] = useState(false);
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix() * 1000; // Convert to milliseconds
    setEventDateTime(unixTimestamp);
  };
  const handleListingTypeChange = (event) => {
    setListingType(event.target.value);
  };
  const handleEditorChange = (content) => {
    setDescription(content);
  };
  const handleTermsChnage = (content) => {
    setTerms(content);
  };
  const handleCanclePolicyChange = (content) => {
    setCancelPolicy(content);
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    setImageName(files[0]);
    const updatedImages = [...selectedImage];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (img.width === 1920 && img.height === 1080) {
              updatedImages.push(e.target.result);
              setSelectedImage(updatedImages);
            } else {
              toast.error("Image should be 1920 x 1080");
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };  
  const handleImageDelete = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);
    setSelectedImage(updatedImages);
  };
  const timeOptions = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);
  const [addressess, setAddressess] = useState([
    {
      address: "",
      city: "",
      state: "",
      pinCode: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [entryFees, setEntryFees] = useState([
    {
      entryFee: "",
      ticketType: "",
      maxPerson: "",
    },
  ]);
  const addEntryFee = () => {
    setEntryFees([...entryFees, { entryFee: "", ticketType: "", maxPerson: "" }]);
  };

  const removeEntryFee = (index) => {
    if (entryFees.length === 1) {
      return; // Prevent removing the last entry fee
    }
    const updatedEntryFees = [...entryFees];
    updatedEntryFees.splice(index, 1);
    setEntryFees(updatedEntryFees);
  };

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [IsMapOpen, setIsMapOpen] = useState(false);

  const toggleMapModal = () => {
    setIsMapOpen(!IsMapOpen);
  };
  // Create functions to handle input changes
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const handleEventAddressChange = (e) => {
    setEventAddress(e.target.value);
  };
  const [coordinates, setCoordinates] = useState({ lat: 0.0, lng: 0.0 });
  console.log(coordinates.lat);
  const handleLocationChange = (e) => {
    const input = e.target.value;
    const [lat, lng] = input.split(",").map((coord) => parseFloat(coord.trim()));

    // Check if lat and lng are valid numbers
    if (!isNaN(lat) && !isNaN(lng)) {
      setCoordinates({ lat, lng });
      console.log("New Coordinates:", { lat, lng }); // Log the updated coordinates
    }
  };
  const [imageName,setImageName]=useState();
  const [customTitle, setCustomTitle] = useState("");
  const EventAdd = async () => {
    try {
      // Basic validation checks
      if (!tba) {
        if (!city) {
          toast.error("City is a required field.");
        }
        if (!state) {
          toast.error("State is a required field.");
        }
        if (!isValidPinCode(pincode)) {
          toast.error("Pincode is invalid");
        }
        if (!eventAddress) {
          toast.error("Address is a required field.");
        }
        if (coordinates.lat === 0.0 && coordinates.lng === 0.0) {
          toast.error("Location is a required field.");
        }
      }   
      if (!upcoming) {   
      if (!eventDateTime) {
         toast.error("Event Date and Time is a required field.");
      }
    }
      // if (!tags) {
      //    toast.error("Tags is a required field.");
      // }
      // if (!link) {
      //    toast.error("Link is a required field.");
      // }
      if (!isValidMobile(phone)) {
         toast.error("Phone is invalid.");
        
      }
      if (selectedImage.length === 0) {
         toast.error("Image is a required field.");
        
      }
      if (!title) {
         toast.error("Title is a required field.");
        
      }
      if (!description) {
         toast.error("Description is a required field.");
        
      }
      if (!entryFees[0].entryFee || !entryFees[0].maxPerson) {
         toast.error("Not valid required entry fee field");
        
      }
      if (!isAlphaOnly(entryFees[0].ticketType)) {
         toast.error("Event type should be alphabet");
        
      }
      if (publishType === "WEB" || publishType === "BOTH") {
        if (!thumbImage) {
           toast.error("Cover image is required for WEB or BOTH publishType.");
           return;  
        }
        if (!link) {
           toast.error("Booking Link is required for WEB or BOTH publishType.");
        }
       
      }
      const prices = entryFees.map((entryFee) => ({
        eventPrice: entryFee.entryFee,
        ticketType: entryFee.ticketType,
        maxPerson: entryFee.maxPerson,
      }));
      const today = Date.now();
      const requestBody = {
        scheduledDate: eventDateTime||today,
        title: title,
        description: description,
        bookingLink: link,
        eventType: listingType,
        terms: terms,
        entryFee: "500",
        status: "Active",
        likes: "2",
        shares: "4",
        deviceType: publishType,
        address: {
          address: eventAddress||" ",
          city: city||" ",
          state: state||" ",
          pinCode: pincode||411023,
          latitude: coordinates.lat||" ",
          longitude: coordinates.lng||" ",
        },
        prices: prices,
        phone: phone,
        test:test,
        slug:customTitle,
        tba:tba,
        upcoming:upcoming
      };
      setLoading(true);
      const response = await api.createEvents(requestBody);
      setLoading(false);
      toast.success("Event added successfully!");
      setLoading(false);
      if (selectedImage.length > 0) {
        const formData = new FormData();
        const timestamp = Date.now();
        const fileData = imageName.name.split(".");
        const fileName = `${timestamp}.${fileData[fileData.length - 1]}`;
        for (let i = 0; i < selectedImage.length; i++) {
          formData.append("files", dataURItoBlob(selectedImage[i]),fileName);
        }
        setLoading(true);
        const workspaceImage = await api.uploadEventImage(response.id, formData);
        setLoading(false);
        console.log(workspaceImage);
      }
      if (thumbImage.length > 0) {
        const formData = new FormData(); 
        const currentTimestamp = new Date().getTime();
        const formattedTimestamp = new Date(currentTimestamp).toISOString().replace(/[-:.]/g, "");
        const fileName = `image_${formattedTimestamp}.png`;
          formData.append("file", dataURItoBlob(thumbImage),fileName);
        setLoading(true);
        const eventCoverImage = await api.uploadEventCoverImage(response.id, formData);
        setLoading(false);
        console.log(eventCoverImage);
      }
      navigate(-1);
    } catch (error) {
      console.error("Error adding event:", error);
      // toast.error("Error adding event: " + error.message);
      setLoading(false);
    }
  };
  const [thumbImage, setThumbImage] = useState(null);

  const handleThumbImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 2160 && img.height === 2160) {
            setThumbImage(e.target.result);
          } else {
            // Image doesn't meet the size criteria
            toast.error("Please upload an image with dimensions 2160px by 2160px.");
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleThumbImageDelete = () => {
    setThumbImage(null);
  };
  const [publishType, setPublishType] = useState("MOBILE");
  const handlePublishType = (event) => {
    setPublishType(event.target.value);
  };
  const [test, setTest] = useState(false);
  const toggleTest = () => {
    setTest(!test);
  };
  const [tba, setTba] = useState(false);
  const toggleTba = () => {
    setTba(!tba);
  };
  const [upcoming, setUpcoming] = useState(false);
  const toggleUpcoming = () => {
    setUpcoming(!upcoming);
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div className="row mt-2">
          <div className="col-md-4 p-3 mx-3" style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
            <div className="d-flex form-group" style={{ justifyContent: "space-between" }}>
              <h4 className="">Event Address</h4>
              {/* <FontAwesomeIcon
                color="#0514A7"
                c
                style={{ cursor: "pointer" }}
                onClick={addAddress}
                fontSize={25}
                icon={faAdd}
              /> */}
            </div>
            <div className="form-group" style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>To be Announced</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={tba} onChange={toggleTba} />
                  </FormGroup>
                </Form>
              </div>

              {/* ---------- Address Section------------ */}
              {!tba && (
            <div>
              <div className="form-group mt-2">
                <label className="lableText">
                  Enter City
                  <span className="required">*</span>
                </label>
                <input type="text" name="city" className="form-control  mt-2" placeholder="Enter City" value={city} onChange={handleCityChange} />
              </div>
              <div className="form-group mt-2">
                <label className="lableText">
                  Enter State
                  <span className="required">*</span>
                </label>
                <input type="text" name="state" className="form-control  mt-2" placeholder="Enter state" value={state} onChange={handleStateChange} />
              </div>
              <div className="form-group mt-2">
                <label className="lableText">
                  Event Pincode
                  <span className="required">*</span>
                </label>
                <input type="number" name="pincode" className="form-control  mt-2" placeholder="Enter Pincode" value={pincode} onChange={handlePincodeChange} />
              </div>
              <div className="form-group mt-2">
                <label className="lableText">
                  Event Address
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="eventAddress"
                  className="form-control  mt-2"
                  placeholder="Event Address"
                  value={eventAddress}
                  onChange={handleEventAddressChange}
                />
              </div>
              <div className="form-group mt-2">
                <label className="lableText">
                  Location
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="location"
                  className="form-control mt-2"
                  placeholder="Location (latitude, longitude)"
                  value={`${coordinates.lat},${coordinates.lng}`}
                  onChange={(e) => handleLocationChange(e)}
                />
              </div>
            </div>
              )}
               <div className="form-group" style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Upcoming</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={upcoming} onChange={toggleUpcoming} />
                  </FormGroup>
                </Form>
              </div>
              {!upcoming && (
            <div className="form-group mt-2">
              <label>
                Event Date and Time
                <span className="required">*</span>
              </label>
              <input type="datetime-local" className="form-control  mt-2" id="startDateTime" onChange={handleDateChange} />
            </div>
              )}
            {/* <div className="form-group mt-2">
              <label className="lableText">
                Event Tags
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="blogTitle"
                className="form-control  mt-2"
                placeholder="Enter Tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              />
            </div> */}
            <div className="form-group mt-2">
              <label className="lableText">
                Event Google Sheet Link
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="blogTitle"
                className="form-control  mt-2"
                placeholder="Enter Event Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label className="lableText">
                Phone Number
                <span className="required">*</span>
              </label>
              <input
                type="text"
                name="blogTitle"
                className="form-control  mt-2"
                placeholder="Enter Phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label className="pt-10 lableText">
                Image
                <span className="required">*</span>
              </label>
              <div className="imageUploadBox">
                <div className="text-right">
                  <label htmlFor="uploadInput">
                    <input type="file" id="uploadInput" multiple={true} style={{ display: "none" }} onChange={handleImageUpload} />
                    <Button color="secondary" as="span">
                      Upload
                    </Button>
                  </label>
                  {selectedImage && (
                    <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleImageDelete}>
                      <span className="vetUploadButton">
                        <FontAwesomeIcon fontSize={20} icon={faTrash} />
                      </span>
                    </button>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap">
                      <div className="row ">
                        {selectedImage &&
                          selectedImage.map((image, index) => (
                            <div className="col-md-4 mt-2" key={index}>
                              <img style={{ maxWidth: "100%" }} src={image} alt={` ${index}`} />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {publishType !== "MOBILE" && (
            <div className="form-group mt-2 ">
              <label className="pt-10 lableText">
                Thumb Image
                <span className="required">*</span>
              </label>
              <div className="imageUploadBox">
                <div className="text-right">
                  <label htmlFor="thumbUploadInput">
                    <input type="file" id="thumbUploadInput" style={{ display: "none" }} onChange={handleThumbImageUpload} />
                    <Button
                      // onClick={handleUploadClick}
                      color="secondary"
                      as="span"
                    >
                      Upload
                    </Button>
                  </label>
                  {thumbImage && (
                    <button type="button" className="btn btn-light navbar-buttons trash-btn" onClick={handleThumbImageDelete}>
                      <span className="vetUploadButton">
                        <FontAwesomeIcon fontSize={20} icon={faTrash} />
                      </span>
                    </button>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap">
                      <div className="col-md-4 d-flex align-items-center pt-3">
                        {thumbImage && <img src={thumbImage} alt="Uploaded" style={{ maxWidth: "100%" }} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
          <div className="col-md-7 p-3 mx-3" style={{ border: "1px solid #beb7b7", borderRadius: 10 }}>
            <div style={{}}>
              <div>
                <h4 className="form-group">Event Details</h4>
                <div>
                  <div className="col-md-6 form-group">
                    <label className="lableText">
                      Event Type
                      <span className="required">*</span>
                    </label>
                    <div style={{ flexDirection: "row" }} className="mt-2">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="listingType"
                          id="Online"
                          value="Online"
                          checked={listingType === "Online"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Online">
                          Online
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="listingType"
                          id="Offline"
                          value="Offline"
                          checked={listingType === "Offline"}
                          onChange={handleListingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="Offline">
                          Offline
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flexDirection: "row", marginTop: 10 }} className="form-group">
                  <div>
                    <label className="lableText">
                     <span style={{color:'#6b7280',fontWeight:600}}>Publish For</span> 
                      <span className="required">*</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="MOBILE" id="MOBILE" value="MOBILE" checked={publishType === "MOBILE"} onChange={handlePublishType} />
                    <label className="form-check-label" htmlFor="MOBILE">
                      FurrCrewApp
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="WEB"
                      id="WEB"
                      value="WEB"
                      checked={publishType === "WEB"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="WEB">
                      WebSite
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="BOTH"
                      id="BOTH"
                      value="BOTH"
                      checked={publishType === "BOTH"}
                      onChange={handlePublishType}
                    />
                    <label className="form-check-label" htmlFor="BOTH">
                      Both
                    </label>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <label>Test</label>
                </span>
                <Form>
                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={test} onChange={toggleTest} />
                  </FormGroup>
                </Form>
              </div>
                </div>
               
                <div className="form-group mt-2">
                  <label className="lableText">
                    Event Title
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control  mt-2"
                    placeholder="Enter Event title here"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="form-group  mt-2">
                  <label className="lableText">
                    Custom Title
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control form-control-lg mt-2"
                    placeholder="Enter custom title here"
                    value={customTitle}
                    onChange={(e) => setCustomTitle(e.target.value)}
                  />
                </div>
                <div className="form-group mt-2">
                  <label className="lableText">
                    Event description
                    <span className="required">*</span>
                  </label>
                  <Editor
                    apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                    value={description}
                    init={{
                      selector: "textarea#basic-example",
                      height: 500,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                {/* <div className="form-group mt-2">
                  <label className="lableText">
                    Event Google Sheet Link
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="blogTitle"
                    className="form-control  mt-2"
                    placeholder="Enter Event Link"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
                <div className="form-group mt-2 row">
                  <div className="col-md-6">
                    <label className="lableText">
                      Event Tags
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="blogTitle"
                      className="form-control  mt-2"
                      placeholder="Enter Tags"
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Event Date and Time</label>
                    <input
                      type="datetime-local"
                      className="form-control  mt-2"
                      id="startDateTime"
                      onChange={handleDateChange}
                    />
                  </div>
                </div> */}
                <div>
                  <div className="form-group row mt-2">
                    <div className="col-md-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                      <FontAwesomeIcon color="#0514A7" style={{ cursor: "pointer" }} onClick={addEntryFee} fontSize={25} icon={faAdd} />
                    </div>
                  </div>
                  <div>
                    {entryFees.map((entry, index) => (
                      <div>
                        <div key={index} className="form-group row mt-2">
                          {/* <div className="col-md-12">
                          <label htmlFor="inputPetParent">Select Event City</label>
                            <select
                              id="inputPetParent"
                              className="form-control"
                            >
                              {addresses.map((address) => (
                                <option key={address.city} value={address.city}>
                                  {address.city}
                                </option>
                              ))}
                            </select>
                          </div> */}
                          <div className="col-md-6">
                            <label className="lableText">
                              Event Entry Fee
                              <span className="required">*</span>
                            </label>
                            <input
                              type="number"
                              name="entryFee"
                              className="form-control  mt-2"
                              placeholder="Enter Event Fee"
                              value={entry.entryFee}
                              onChange={(e) => {
                                const updatedEntryFees = [...entryFees];
                                updatedEntryFees[index].entryFee = e.target.value;
                                setEntryFees(updatedEntryFees);
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Ticket Type*</label>
                            <input
                              type="text"
                              name="ticketType"
                              className="form-control  mt-2"
                              placeholder="Enter Ticket Type"
                              value={entry.ticketType}
                              onChange={(e) => {
                                const updatedEntryFees = [...entryFees];
                                updatedEntryFees[index].ticketType = e.target.value;
                                setEntryFees(updatedEntryFees);
                              }}
                            />
                          </div>
                          <div className="col-md-6 mt-2">
                            <label>Max Person*</label>
                            <input
                              type="number"
                              name="maxPerson"
                              className="form-control  mt-2"
                              placeholder="Enter Max Person"
                              value={entry.maxPerson}
                              onChange={(e) => {
                                const updatedEntryFees = [...entryFees];
                                updatedEntryFees[index].maxPerson = e.target.value;
                                setEntryFees(updatedEntryFees);
                              }}
                            />
                          </div>
                          <div
                            className="col-md-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index === 0 ? null : (
                              <FontAwesomeIcon
                                color="#0514A7"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeEntryFee(index)}
                                fontSize={20}
                                icon={faTrash}
                              />
                            )}
                          </div>
                          <hr style={{ marginTop: 20 }}></hr>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-group  row ">
                  <div className=" col-md-12">
                    <label className="lableText">
                      T&C
                      <span className="required">*</span>
                    </label>
                    <Editor
                      apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                      value={terms}
                      init={{
                        selector: "textarea#basic-example",
                        height: 500,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | blocks | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                      }}
                      onEditorChange={handleTermsChnage}
                    />
                  </div>
                  {/* <div className=" col-md-6">
                    <label className="lableText">
                      Cancel Policy
                      <span className="required">*</span>
                    </label>
                    <Editor
                      apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                      value={canclePolicy}
                      init={{
                        height: 300,
                        width: "100%",
                        menubar: true,
                        plugins: [
                          "advlist autolink lists link image",
                          "charmap print preview anchor help",
                          "searchreplace visualblocks code",
                          "insertdatetime media table paste wordcount",
                        ],
                        toolbar: "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | ",
                      }}
                      onEditorChange={handleCanclePolicyChange}
                    />
                  </div> */}
                </div>
                <div className="form-group">
                  <div className="mt-5">
                    <button onClick={EventAdd} type="submit" className="btn btn-primary modalFooterButton">
                      Create
                    </button>
                    <button onClick={() => navigate(-1)} type="button" className="btn btn-secondary modalFooterButton">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EventDetails;
