import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Collapse,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  InputGroup,
  CardGroup,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import LoaderModal from "../component/common/Loader";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../component/common/ConfirmDialog";
import moment from "moment";
import "../component/css/PetParentDetails.css";
import dogImage from "../assets/dogProfile.png";
import userImageo from "../assets/favVet1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faUser,
  faAngleDown,
  faUserGroup,
  faClose,
  faPenToSquare,
  faPlus,
  faMinus,
  faTrash,
  faPrint,
  faSave,
  faAngleUp,
  faSearch,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import AddPet from "../component/AddPet";
import { usePetparentState } from "./services/petparentState";
import { useParams } from "react-router-dom";
import Apis from "../utils/api";
import { usePetState } from "./services/petsState";
import PetParent from "./list/PetParent";
import { isAlphaNumeric, isValidEmail, isValidMobile } from "../utils/helpers";
import dataURItoBlob from "../utils/helpers";
import { Editor } from "@tinymce/tinymce-react";
const PetParentDetails = () => {
  const { setpetData } = usePetState();
  const api = new Apis();
  const { id } = useParams();
  const navigate = useNavigate();
  const { getPetparentData } = usePetparentState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const handleNoteChange = (content) => {
    setNoteText(content);
  };
  const handleOpenImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const [petParent, setPetParent] = useState(getPetparentData(id));
  const [userImage, setUserImage] = useState(petParent.profileImage);
  const [userPetImage, setUserPetImage] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedLocation, setEditedLocation] = useState("");
  const [editedCity, setEditedCity] = useState("");
  const [editedContact, setEditedContact] = useState("");
  const [editedGender, setEditedGender] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedPetName, setEditedPetName] = useState("");
  const [editedPetSpecies, setEditedPetSpecies] = useState("");
  const [editedPetBreed, setEditedPetBreed] = useState("");
  const [EditedMonth, setEditedMonth] = useState("");
  const [EditedYear, setEditedYear] = useState("");
  const [editedPetGender, setEditedPetGender] = useState("");
  const [isPetEditModalOpen, setIsPetEditModalOpen] = useState(false);
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isAddressConfirmOpen, setIsAddressConfirmOpen] = useState(false);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [editedPrescription, setEditedPrescription] = useState("this is prescription");
  const [editedImage, setEditedImage] = useState(userPetImage || "");
  const [uploadedImages, setUploadedImages] = useState([userPetImage]);
  const [imageChanged, setImageChanged] = useState(false);
  const [petImageChanged, setPetImageChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [modalOpens, setModalOpens] = useState(false);
  const toggleModals = () => {
    setModalOpens(!modalOpens);
  };
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImageUrls = files.map((file) => URL.createObjectURL(file));
    setUploadedImages([...uploadedImages, ...newImageUrls]);
  };
  const handleOpenPrescriptionImageModal = (image) => {
    setEditedImage(image);
    toggleModal();
  };
  const handleYearIncrement = () => {
    setEditedYear(EditedYear + 1);
  };
  const handleYearDecrement = () => {
    if (EditedYear > 0) {
      setEditedYear(EditedYear - 1);
    }
  };

  const handleMonthIncrement = () => {
    setEditedMonth(EditedMonth + 1);
  };
  const handleMonthDecrement = () => {
    if (EditedMonth > 0) {
      setEditedMonth(EditedMonth - 1);
    }
  };
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await api.deletePetParent(id);
      toast.success("Event deleted successfully!");
      console.log(response);
      navigate(-1);
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting the petParent:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteAddress = async (itemId) => {
    setIsLoading(true);
    try {
      const response = await api.deleteParentAddress(id, itemId);
      toast.success("Address deleted successfully!");
      console.log(response);
      setPetParentAddress((prevAddresses) => prevAddresses.filter((address) => address.id !== itemId));

      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting the Address:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditImage = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", (event) => {
      const uploadedFile = event.target.files[0];
      if (uploadedFile) {
        const imageUrl = URL.createObjectURL(uploadedFile);
        setUserImage(imageUrl);
        setImageChanged(true);
      }
    });
    fileInput.click();
  };
  const handlePetEditImage = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", (event) => {
      const uploadedFile = event.target.files[0];
      console.log("uploadedFile:", uploadedFile);
      if (uploadedFile) {
        const imageUrl = URL.createObjectURL(uploadedFile);
        console.log("imageUrl:", imageUrl);
        const updatedPets = [...numberofPets]; // Create a copy of the pets array
        updatedPets[selectedDot].profileImage = imageUrl; // Update the profileImage of the selected pet
        setNumberofPets(updatedPets); // Update the state with the modified pets array
        setPetImageChanged(true);
      }
    });
    fileInput.click();
  };

  const handleSavePetEdits = () => {
    const updatedPets = [...numberofPets];
    updatedPets[selectedDot].petName = editedPetName;
    updatedPets[selectedDot].species = selectedType;
    updatedPets[selectedDot].breed = selectedBreed;
    updatedPets[selectedDot].month = EditedMonth;
    updatedPets[selectedDot].year = EditedYear;
    updatedPets[selectedDot].gender = editedPetGender;
    setNumberofPets(updatedPets);
    updatePet(updatedPets[selectedDot]);
    setIsPetEditModalOpen(false);
  };

  const updatePet = async (updatedPetData) => {
    try {
      const requestBody = {
        id: updatedPetData.id,
        petName: updatedPetData.petName,
        type: selectedType || updatedPetData.type,
        breed: selectedBreed || updatedPetData.breed,
        gender: updatedPetData.gender,
        year: EditedYear,
        month: EditedMonth,
        userId: id,
        weight: updatedPetData.weight,
        height: updatedPetData.height,
      };
      setIsLoading(true);
      const response = await api.updatePetDetails(id, requestBody);
      setIsLoading(false);
      toast.success("Updated Pet Details SuccessFully");
      console.log("API response:", response);
    } catch (error) {
      toast.error("Error: " + error);
      setIsLoading(false);
      console.error("Error updating pet:", error);
    }
  };

  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const [hoveredDot, setHoveredDot] = useState(null);
  const [selectedDot, setSelectedDot] = useState(0);

  const handleDotHover = (index) => {
    setHoveredDot(index);
  };
  const [numberofPets, setNumberofPets] = useState([]);

  const [pets] = useState([
    {
      name: petParent.name,
      Email: petParent.email,
      latitude: petParent.latitude + " " + petParent.longitude,
      city: petParent.city,
      Contact: petParent.mobile,
      gender: petParent.gender,
    },
    numberofPets,
  ]);
  const petsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * petsPerPage;

  const visiblePets = numberofPets ? numberofPets.slice(startIndex, startIndex + petsPerPage) : [];
  const handleDotClick = (index) => {
    setSelectedDot(index);
  };
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [addressOpen, setAddressOpen] = useState("4");
  const toggleOpen = (id) => {
    if (addressOpen === id) {
      setAddressOpen();
    } else {
      setAddressOpen(id);
    }
  };
  const [open2, setOpen2] = useState("3");
  const toggle2 = (id) => {
    if (open2 === id) {
      setOpen2();
    } else {
      setOpen2(id);
    }
  };
  const handleDeleteImage = (index) => {
    if (index === 0) {
      setUploadedImages(uploadedImages.slice(1));
      setEditedImage("");
    } else {
      setUploadedImages(uploadedImages.filter((_, i) => i !== index));
    }
  };
  useEffect(() => {
    if (id) {
    }
  }, [id, getPetparentData]);
  useEffect(() => {
    const fetchPet = async () => {
      try {
        const Pets = await api.getPets(id);
        setNumberofPets(Pets);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchPet();
  }, []);

  useEffect(() => {
    const fetchPet = async () => {
      try {
        const Pets = await api.getPets(id);
        setNumberofPets(Pets);
        console.log(Pets);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchPet();
  }, []);
  const handleSaveEdits = async () => {
    try {
      if (!editedName || !isAlphaNumeric(editedName)) {
        throw new Error("Please fill out the name.");
      }
      if (!isValidEmail(editedEmail)) {
        throw new Error("Please fill out a valid email address.");
      }
      if (!isValidMobile(editedContact)) {
        throw new Error("Please fill out a valid phone number.");
      }
      if (!editedGender) {
        throw new Error("Please select a gender.");
      }
      const updatedPets = [...pets];
      updatedPets.name = editedName;
      updatedPets.Email = editedEmail;
      updatedPets.latitude = editedLocation;
      updatedPets.city = editedCity;
      updatedPets.Contact = editedContact;
      updatedPets.gender = editedGender;
      const requestBody = {
        id: petParent.id,
        countryCode: "+91",
        mobile: editedContact,
        name: editedName,
        email: editedEmail,
        verified: false,
        emailVerified: false,
        city: editedCity,
        gender: editedGender,
        sos: false,
        sosTill: "",
        status: "Updated",
      };
      setIsLoading(true);
      const response = await api.EditPetParent(petParent.id, requestBody);
      setIsLoading(false);
      console.log("API response:", response);
      toast.success("Edited User SuccessFully");
      setPetParent((prevPetParent) => ({
        ...prevPetParent,
        gender: editedGender,
        name: editedName,
        email: editedEmail,
        city: editedCity,
        // Update other properties if needed
      }));
      setIsEditModalOpen(false);
    } catch (error) {
      toast.error("Error:" + error.message);
      console.error("Error editing user:", error);
      setIsLoading(false);
    }
  };
  const [breeds, setBreeds] = useState([]); // State for breeds
  const [selectedBreed, setSelectedBreed] = useState(numberofPets[selectedDot]?.breed);
  const handleBreedChange = (event) => {
    setSelectedBreed(event.target.value);
  };
  const [selectedType, setSelectedType] = useState(numberofPets[selectedDot]?.type);
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          // Handle case when token is not available
          console.error("Token not available");
          return;
        }

        const typeToFetch = selectedType || numberofPets[selectedDot]?.type;
        const response = await fetch(`${process.env.REACT_APP_API_URL}admin/v1/super/admin/pet/category?type=${typeToFetch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data);
        setBreeds(data || []);
      } catch (error) {
        console.error("Error fetching pet category:", error);
      }
    };
    fetchCategory();
  }, [selectedType, numberofPets[selectedDot]?.type]);
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const [appointments, setAppointments] = useState([]);
  const [visibleAppointments, setVisibleAppointments] = useState({});
  useEffect(() => {
    const fetchPetAppointment = async () => {
      try {
        if (numberofPets.length > 0 && selectedDot >= 0 && selectedDot < numberofPets.length) {
          const response = await api.getpetAppointments(numberofPets[selectedDot]?.id);
          setAppointments(response);
          console.log("Appointments Data", response);
        } else {
          console.error("Invalid selectedDot or numberofPets:", selectedDot, numberofPets);
        }
      } catch (error) {
        console.error("Error fetching pet appointments:", error);
      }
    };
    fetchPetAppointment();
  }, [numberofPets, selectedDot]);
  const [prescription, setPresciption] = useState();
  useEffect(() => {
    const fetchPrescription = async (appointmentId) => {
      try {
        if (appointments.length > 0 && numberofPets.length > 0 && selectedDot >= 0 && selectedDot < numberofPets.length) {
          const response = await api.getPresciption(appointmentId, numberofPets[selectedDot]?.id);
          console.log("Prescription Data", response);
          setPresciption(response);
        } else {
          console.error("Invalid selectedDot or numberofPets:", selectedDot, numberofPets);
        }
      } catch (error) {
        console.error("Error fetching prescription:", error);
      }
    };

    appointments.forEach((appointment, index) => {
      if (visibleAppointments[index]) {
        fetchPrescription(appointment.id);
      }
    });
  }, [appointments, visibleAppointments, numberofPets, selectedDot]);

  const toggleCollapse = (index) => {
    setVisibleAppointments((prevVisibleAppointments) => ({
      ...prevVisibleAppointments,
      [index]: !prevVisibleAppointments[index],
    }));
    // Toggle the button click state for the clicked data item
    setButtonClicked((prevButtonClicked) => {
      const newButtonClicked = [...prevButtonClicked];
      newButtonClicked[index] = !prevButtonClicked[index];
      return newButtonClicked;
    });
  };
  const [localAppointmentDate] = useState(moment.utc(appointments.createdAt).local().format("YYYY-MM-DD HH:mm A"));
  const [buttonClicked, setButtonClicked] = useState(Array(appointments.length).fill(false));
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setSearchPerformed(true);
    if (query.trim() === "") {
      setFilteredAppointments([]);
    } else {
      // Filter appointments based on the search query
      const filtered = appointments.filter((appointment) => {
        const serviceName = appointment.serviceName.toLowerCase();
        const collaboratorName = appointment.collaboratorName.toLowerCase();
        const workspaceName = appointment.workspaceName.toLowerCase();
        return serviceName.includes(query) || collaboratorName.includes(query) || workspaceName.includes(query);
      });
      setFilteredAppointments(filtered);
    }
  };
  const hasPetDetails = numberofPets[selectedDot] && numberofPets[selectedDot].petName;
  const uploadParentImage = async () => {
    try {
      if (!userImage) {
        throw new Error("No image selected for upload.");
      }
      let imageBlob = userImage;
      if (!(userImage instanceof Blob)) {
        // If userImage is not a Blob, assume it's a data URL and convert it to a Blob
        const response = await fetch(userImage);
        const blob = await response.blob();
        imageBlob = new File([blob], "filename.jpg"); // Replace "filename.jpg" with the desired file name
      }
      const formData = new FormData();
      formData.append("file", imageBlob);
      setIsLoading(true);
      const response = await api.updatePetParentImage(id, formData);
      setIsLoading(false);
      console.log("Img", response);
      toast.success("Image Added SuccessFully.");
      setImageChanged(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsLoading(false);
      toast.error("Error Adding Image.");
    }
  };
  const updatenewPetImage = async () => {
    setIsLoading(true);
    try {
      if (!userImage) {
        throw new Error("No image selected for upload.");
      }
      let imageBlob = userImage;
      if (!(userImage instanceof Blob)) {
        // If userImage is not a Blob, assume it's a data URL and convert it to a Blob
        const response = await fetch(userImage);
        const blob = await response.blob();
        imageBlob = new File([blob], "filename.jpg"); // Replace "filename.jpg" with the desired file name
      }
      const formData = new FormData();
      formData.append("file", imageBlob);
      const response = await api.updatePetImage(id, numberofPets[selectedDot].id, formData);
      console.log("Img", response);
      setIsLoading(false);
      setPetImageChanged(false);
      toast.success("Image Added SuccessFully.");
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsLoading(false);
      toast.error("Error Adding Image.");
    }
  };
  const handleLocationChange = (e) => {
    const value = e.target.value;
    setEditedLocation(value);
    // Check if value is a string before trying to split it
    if (typeof value === "string") {
      // Assuming that value is in the format "latitude, longitude"
      const [newLatitude, newLongitude] = value.split(",").map((part) => part.trim());
      // Update the latitude and longitude states
      // setLatitude(newLatitude);
      // setLongitude(newLongitude);
    }
  };
  const [messages, setMessages] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [inputText, setInputText] = useState("");
  const formatTimestampToLocalDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleString(undefined, options);
  };
  useEffect(() => {
    const fetchInternalNotes = async () => {
      try {
        const fetchedInternalNotes = await api.getInternalNotes(id);
        setInternalNotes(fetchedInternalNotes);
      } catch (error) {
        console.error("Error fetching internal notes:", error);
      }
    };
    fetchInternalNotes();
  }, []);
  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return now.toLocaleString(undefined, options);
  };
  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === "") return;
    const currentTime = getCurrentLocalDateTime();
    setMessages([...messages, { text: inputText, sender: "user", time: currentTime }]);
    setInputText("");
    try {
      const requestBody = {
        refId: id,
        note: inputText,
        reason: "User message",
        type: "userManagement",
        changedBy: "1",
        doneBy: "xyz",
      };
      setIsLoading(true);
      const response = await api.editInternalNote(requestBody);
      setIsLoading(false);
      toast.success("Internal Note Added Successfully");
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding internal note:", error);
      setIsLoading(false);
    }
  };
  const [editedAddressess, setEditedAddress] = useState("");
  const [editAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [editedType, setEditedType] = useState("");
  const [editedFlatNo, setEditedFlatNo] = useState("");
  const [editedPincode, setEditedPincode] = useState("");
  const [editedCitys, setEditedCitys] = useState("");
  const [editedLatLng, setEditedLatLng] = useState("");
  const [editedAddressId, setEditedAddressId] = useState("");
  const openEditAddressModal = (address) => {
    setEditedType(address.type || "");
    setEditedFlatNo(address.flatNo || "");
    setEditedAddress(address.address || "");
    setEditedPincode(address.pincode || "");
    setEditedCitys(address.city || "");
    setEditedLatLng(`${address.latitude || ""}, ${address.longitude || ""}`);
    setEditedAddressId(address.id || "");
    // Set editedLatLng
    setEditAddressModalOpen(true); // Open the modal
  };

  const closeEditAddressModal = () => {
    setEditAddressModalOpen(false);
  };

  const [petParentAddress, setPetParentAddress] = useState([]);
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const address = await api.getParentAddress(id);
        setPetParentAddress(address);
      } catch (error) {
        console.error("Error fetching petParent:", error);
      }
    };
    fetchAddress();
  }, []);

  const [type, setType] = useState();
  const [flatNo, setFlatNo] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [latLng, setLatLng] = useState("0.0, 0.0");
  const addAddress = async () => {
    try {
      const requestBody = {
        userId: id,
        type: type,
        flatNo: flatNo,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
        address: address,
        pincode: pincode,
        city: city,
        state: "MH",
        default: false,
      };
      setIsLoading(true);
      const response = await api.addAppointmentAddress(id, requestBody);
      setIsLoading(false);
      console.log("Id Response", response);
      const newAddressId = response.id;
      const newAddress = {
        id: newAddressId,
        type,
        flatNo,
        address,
        pincode,
        city,
        latitude: latLng.split(",")[0].trim(),
        longitude: latLng.split(",")[1].trim(),
      };
      toast.success("Address added successfully!");
      console.log(response);
      setPetParentAddress([...petParentAddress, newAddress]);
      toggleModals();
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address: " + error.message);
      setIsLoading(false);
      toggleModals();
    }
  };
  const selectAddress = (index) => {
    setSelectedAddress(index);
    console.log("Selected Address:", petParentAddress[index]);
    setAddressSelected(petParentAddress[index]);
  };
  const handleEditAddressSubmit = async () => {
    try {
      const requestBody = {
        id: editedAddressId,
        userId: id,
        type: editedType,
        flatNo: editedFlatNo,
        latitude: editedLatLng.split(",")[0].trim(),
        longitude: editedLatLng.split(",")[1].trim(),
        address: editedAddressess,
        pincode: editedPincode,
        city: editedCitys,
        state: "MH",
        default: false,
      };
      const editedAddress = {
        type: editedType,
        flatNo: editedFlatNo,
        address: editedAddressess,
        pincode: editedPincode,
        city: editedCitys,
        latitude: editedLatLng.split(",")[0].trim(),
        longitude: editedLatLng.split(",")[1].trim(),
      };
      console.log("Edited Address:", editedAddress);
      setIsLoading(true);
      const response = await api.EditAppointmentAddress(id, editedAddressId, requestBody);
      setIsLoading(false);
      const updatedAddresses = petParentAddress.map((address, index) => (index === selectedAddress ? editedAddress : address));
      setPetParentAddress(updatedAddresses);
      toast.success("Address Edited successfully!");
      console.log(response);
      closeEditAddressModal();
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("Error adding address: " + error.message);
      setIsLoading(false);
      closeEditAddressModal();
    }
  };
  const [itemId, setItemId] = useState();
  const [testState, setTeststate] = useState(petParent.test);
  const handleTestChange = async (event) => {
    try {
      const newSwitchState = !testState;
      setTeststate(newSwitchState);
      const status = newSwitchState ? "Active" : "InActive";
      const requestBody = {
        status: status,
        active: newSwitchState,
        userId: petParent.id,
      };
      setIsLoading(true);
      const response = await api.addTestUser(requestBody);
      setIsLoading(false);
      toast.success("Updated Test Status");
      console.log(response)
    } catch (error) {
      // Handle API call errors
      console.error("Error updating status:", error);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {isLoading ? (
        <LoaderModal isOpen={isLoading} toggle={() => setIsLoading(false)} />
      ) : (
        <div>
          <div>
            <AddPet id={id} />
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col
                lg="6"
                style={{
                  border: "1px solid #beb7b7",
                  borderRadius: 10,
                  // marginRight: 10,
                }}
              >
                <Accordion className="custom" flush open={open} toggle={toggle}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">User Details</AccordionHeader>
                    <AccordionBody accordionId="1">
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => {
                            setEditedEmail(petParent.email);
                            setEditedName(petParent.name);

                            setEditedContact(petParent.mobile);
                            setEditedGender(petParent.gender);
                            setIsEditModalOpen(true);
                          }}
                        >
                          <FontAwesomeIcon color="blue" fontSize={18} icon={faPenToSquare} />
                        </Button>
                      </div>
                      <div className="text-center px-5">
                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="row cardparentdetails justify-content-center">
                            <div
                              className="image-container"
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <img
                                src={userImage}
                                alt="Logo"
                                onClick={() => handleOpenImageModal(userImage)}
                                style={{
                                  margin: 0,
                                  width: 100,
                                  cursor: "pointer",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    position: "absolute",
                                    top: -5,
                                    right: 0,
                                  }}
                                  onClick={() => handleEditImage()}
                                >
                                  <FontAwesomeIcon color="blue" fontSize={20} icon={faPenToSquare} />
                                </Button>
                              </div>
                              {imageChanged && (
                                <Button
                                  onClick={uploadParentImage}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    position: "absolute",
                                    left: 0,
                                    top: -5,
                                  }}
                                >
                                  <FontAwesomeIcon color="blue" fontSize={20} icon={faSave} />
                                </Button>
                              )}
                            </div>
                          </div>
                          <div>
                            <h4 className="card-title">{petParent.name ? petParent.name : "null"}</h4>
                          </div>
                        </div>
                        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} contentLabel="Image Modal">
                          {selectedImage && <img src={selectedImage} alt="Modal" style={{ maxWidth: "100%" }} />}
                          <div>
                            <Button
                              style={{
                                backgroundColor: "red",
                                border: "none",
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                              onClick={() => setIsModalOpen(false)}
                            >
                              <FontAwesomeIcon color="white" fontSize={20} icon={faClose} />
                            </Button>
                          </div>
                        </Modal>
                      </div>
                      <hr />
                      {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap:'wrap'
                    }}
                    className="col-md-12"
                  >
                    <div>
                      <p className="petdetails">
                        <strong>Email:</strong>{" "}
                        {pets[selectedDot].Email
                          ? pets[selectedDot].Email
                          : "null"}
                      </p>
                    </div>
                    <div>
                      <p className="petdetails">
                        <strong>Contact:</strong>{" "}
                        {pets[selectedDot].Contact
                          ? pets[selectedDot].Contact
                          : "null"}
                      </p>
                    </div>
                    <div>
                      <p className="petdetails">
                        <strong>Gender:</strong>
                        {pets[selectedDot].gender
                          ? pets[selectedDot].gender
                          : "null"}
                      </p>
                    </div>
                    <div>
                       <p className="petdetails">
                        <strong>Location:</strong>
                        {pets[selectedDot].Location
                            ? pets[selectedDot].Location
                            : "null"}
                      </p>
                    </div>
                  </div> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: "wrap" }}>
                          <p className="petdetails">
                            <strong>Test</strong>
                          </p>
                          <p className="petdetails">
                            <strong>Email:</strong>
                          </p>
                          <p className="petdetails">
                            <strong>Contact:</strong>
                          </p>
                          <p className="petdetails">
                            <strong>Gender:</strong>
                          </p>
                        </div>
                        <div>
                          <p className="petdetails">
                            <Form>
                              <FormGroup switch>
                                <Input type="switch" role="switch" checked={testState}  onChange={handleTestChange} />
                              </FormGroup>
                            </Form>
                          </p>

                          <p className="petdetails">
                            <strong>{petParent.email ? petParent.email : "null"}</strong>
                          </p>
                          <p className="petdetails">
                            <strong>{petParent.mobile ? petParent.mobile : "null"}</strong>
                          </p>
                          <p className="petdetails">
                            <strong>{petParent.gender ? petParent.gender : "null"}</strong>
                          </p>
                        </div>
                      </div>
                      <Accordion flush open={addressOpen} toggle={toggleOpen}>
                        <AccordionItem>
                          <AccordionHeader targetId="4">
                            <b>Addresses</b>
                          </AccordionHeader>
                          <AccordionBody accordionId="4">
                            <div>
                              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button style={{ marginRight: 10 }} color="primary" onClick={toggleModals}>
                                  <FontAwesomeIcon color="White" fontSize={20} icon={faPlus} />
                                </Button>
                              </div>
                              {petParentAddress.map((service, index) => (
                                <div className=" mt-3" key={index}>
                                  <CardGroup
                                    className="p-3"
                                    style={{
                                      border: "1px solid #beb7b7",
                                      borderRadius: 10,
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={() => selectAddress(index)}
                                  >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                      <div>
                                        <div style={{ flexDirection: "column" }}>
                                          <div>{service.type}</div>
                                        </div>
                                        <div>{service.flatNo}</div>
                                        <div>{service.address}</div>
                                        <div>
                                          {service.city},{service.pincode}
                                        </div>
                                        <div>
                                          {service.latitude},{service.longitude}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <Button onClick={() => openEditAddressModal(service)}>
                                        <FontAwesomeIcon color="White" fontSize={20} icon={faPen} />
                                      </Button>
                                      <Button
                                        style={{ marginLeft: 10, backgroundColor: "red", border: "none" }}
                                        onClick={() => {
                                          setIsAddressConfirmOpen(true);
                                          setItemId(service.id);
                                          console.log(service.id);
                                        }}
                                      >
                                        <FontAwesomeIcon color="White" fontSize={20} icon={faTrash} />
                                      </Button>
                                    </div>
                                  </CardGroup>
                                </div>
                              ))}
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
                <Modal isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} contentLabel="Edit Modal">
                  <ModalHeader style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Edit User</div>
                  </ModalHeader>
                  <ModalBody className="px-5">
                    <div>
                      <label>Name*:</label>
                      <input className="form-control" type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
                    </div>
                    <div>
                      <label>Email*:</label>
                      <input className="form-control" type="text" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} />
                    </div>
                    <div>
                      <label>Contact*:</label>
                      <input className="form-control" type="text" value={editedContact} onChange={(e) => setEditedContact(e.target.value)} />
                    </div>
                    {/* <div>
                      <label>City*:</label>
                      <input className="form-control" type="text" value={editedCity} onChange={(e) => setEditedCity(e.target.value)} />
                    </div> */}
                    <div>
                      <label>Gender*:</label>
                      <select className="form-control" value={editedGender} onChange={(e) => setEditedGender(e.target.value)}>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    {/* <div style={{ marginTop: 5 }}>
                      <label>Location*:</label>
                      <input className="form-control" type="text" value={editedLocation} onChange={handleLocationChange} />
                    </div> */}
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={handleSaveEdits} style={{ marginRight: 10 }} color="primary">
                      Save
                    </Button>
                    <Button onClick={() => setIsEditModalOpen(false)} color="secondary">
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
              <Col
                lg="6"
                style={{
                  border: "1px solid #beb7b7",
                  borderRadius: 10,
                }}
              >
                {hasPetDetails ? (
                  <div>
                    <Accordion flush open={open} toggle={toggle}>
                      <AccordionItem>
                        <AccordionHeader targetId="1">Pet Details</AccordionHeader>
                        <AccordionBody accordionId="1">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={() => {
                                setSelectedPetId(numberofPets[selectedDot]?.id);
                                setEditedPetName(numberofPets[selectedDot]?.petName);
                                setEditedPetSpecies(numberofPets[selectedDot].type);
                                setSelectedBreed(numberofPets[selectedDot].breed);
                                setEditedYear(numberofPets[selectedDot].year);
                                setEditedMonth(numberofPets[selectedDot].month);
                                setEditedPetGender(numberofPets[selectedDot].gender);
                                setIsPetEditModalOpen(true);
                              }}
                            >
                              <FontAwesomeIcon color="blue" fontSize={18} icon={faPenToSquare} />
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={styles.dotsContainer}>
                              {visiblePets.map((pet, index) => (
                                <span
                                  key={index + startIndex}
                                  style={{
                                    ...styles.dot,
                                    cursor: "pointer",
                                    backgroundColor: hoveredDot === index + startIndex ? "#6B7280" : selectedDot === index + startIndex ? "#6B7280" : "#DDDDDD",
                                    color: selectedDot === index + startIndex ? "white" : "inherit",
                                  }}
                                  onClick={() => handleDotClick(index + startIndex)}
                                  onMouseEnter={() => handleDotHover(index + startIndex)}
                                  onMouseLeave={() => handleDotHover(null)}
                                >
                                  {index + startIndex + 1}
                                  {hoveredDot === index + startIndex && <span style={styles.tooltip}>{pet.petName || "null"}</span>}
                                </span>
                              ))}
                            </div>
                            {numberofPets.length > petsPerPage && (
                              <div style={styles.dropdownContainer}>
                                <select value={currentPage} onChange={(e) => handlePageChange(Number(e.target.value))}>
                                  {Array(Math.ceil(numberofPets.length / petsPerPage))
                                    .fill()
                                    .map((_, index) => (
                                      <option key={index} value={index + 1}>
                                        {index + 1}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            )}
                          </div>

                          <div className="text-center p-3">
                            <hr />
                            <div className="row cardparentdetails justify-content-center">
                              <div className="col-md-12">
                                <div
                                  className="image-container"
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={numberofPets[selectedDot]?.profileImage}
                                    alt="Pet Profile"
                                    onClick={() => handleOpenImageModal(numberofPets[selectedDot]?.profileImage)}
                                    style={{
                                      margin: 0,
                                      width: 100,
                                      cursor: "pointer",
                                    }}
                                  />

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                    }}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        position: "absolute",
                                        top: -5,
                                        right: 0,
                                      }}
                                      onClick={() => handlePetEditImage()}
                                    >
                                      <FontAwesomeIcon color="blue" fontSize={18} icon={faPenToSquare} />
                                    </Button>
                                  </div>
                                  {petImageChanged && (
                                    <Button
                                      onClick={updatenewPetImage}
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        position: "absolute",
                                        left: -5,
                                        top: -5,
                                      }}
                                    >
                                      <FontAwesomeIcon color="blue" fontSize={20} icon={faSave} />
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="row justify-content-center align-items-center">
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-md-3 d-flex align-items-center">
                            <span className="parentDetailHeaderIcon">
                              <FontAwesomeIcon
                                color="#6B7280"
                                fontSize={20}
                                icon={faUser}
                              />
                            </span>
                          </div>
                          <div className="col-md-9 d-flex flex-column pl-1">
                            <span>678</span>
                            <span className="subtextsmall">
                              Total Expenditure
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-md-3 d-flex align-items-center">
                            <span className="parentDetailHeaderIcon">
                              <FontAwesomeIcon
                                color="#6B7280"
                                fontSize={20}
                                icon={faUserGroup}
                              />
                            </span>
                          </div>
                          <div className="col-md-9 d-flex flex-column pl-1">
                            <span>678</span>
                            <span className="subtextsmall">
                              Total Expenditure
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                            <hr />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p className="petdetails">
                                <strong>Pet Name:</strong>
                              </p>
                              <p className="petdetails">
                                <strong>Pet's Species:</strong>
                                <span id="species"></span>
                              </p>
                              <p className="petdetails">
                                <strong>Pet's Breed:</strong>
                                <span id="breed"></span>
                              </p>
                              <p className="petdetails">
                                <strong>Pet's Age:</strong> <span id="age"></span>
                              </p>
                              <p className="petdetails">
                                <strong>Pet's Gender:</strong> <span id="gender"></span>
                              </p>
                            </div>
                            <div style={{ paddingRight: 10 }}>
                              <p className="petdetails">
                                <strong>{numberofPets[selectedDot]?.petName ? numberofPets[selectedDot]?.petName : "null"}</strong>
                              </p>
                              <p className="petdetails">
                                <strong>{numberofPets[selectedDot]?.species ? numberofPets[selectedDot]?.species : numberofPets[selectedDot]?.type}</strong>
                              </p>
                              <p className="petdetails">
                                <strong>{numberofPets[selectedDot]?.breed ? numberofPets[selectedDot]?.breed : "null"}</strong>
                              </p>
                              <p className="petdetails">
                                <strong>
                                  {numberofPets[selectedDot]?.year ? numberofPets[selectedDot]?.year : "0"}
                                  years {numberofPets[selectedDot]?.month ? numberofPets[selectedDot]?.month : "0"}
                                  month
                                </strong>
                              </p>
                              <p className="petdetails">
                                <strong>{numberofPets[selectedDot]?.gender ? numberofPets[selectedDot]?.gender : "null"}</strong>
                              </p>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    </Accordion>
                    <Modal isOpen={isPetEditModalOpen} onRequestClose={() => setIsPetEditModalOpen(false)} contentLabel="Pet Edit Modal">
                      <ModalHeader
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Edit Pet Details</div>
                      </ModalHeader>
                      <ModalBody className="px-5">
                        <div>
                          <label>Pet Name:*</label>
                          <input className="form-control" type="text" value={editedPetName} onChange={(e) => setEditedPetName(e.target.value)} />
                        </div>
                        <div>
                          <label htmlFor="inputPetsSpecies">Pet Species*</label>
                          <select
                            id="inputPetsSpecies"
                            className="form-control"
                            value={selectedType !== undefined ? selectedType : numberofPets[selectedDot]?.type || ""}
                            onChange={(e) => setSelectedType(e.target.value)}
                          >
                            <option value="dog">Dog</option>
                            <option value="cat">Cat</option>
                            <option value="bird">Bird</option>
                            <option value="exotic">Exotic</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="inputPetBreed">Pet Breed*</label>
                          <select id="inputPetBreed" className="form-control" value={selectedBreed} onChange={(e) => setSelectedBreed(e.target.value)}>
                            <option value="" disabled>
                              Select a breed
                            </option>
                            {breeds.map((breed, index) => (
                              <option key={index} value={breed}>
                                {breed}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label>Pet's Age:*</label>
                          <div>
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={handleYearDecrement}
                            >
                              <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                            </Button>
                            {EditedYear} years
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={handleYearIncrement}
                            >
                              <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={handleMonthDecrement}
                            >
                              <FontAwesomeIcon color="blue" fontSize={18} icon={faMinus} />
                            </Button>
                            {EditedMonth} months
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={handleMonthIncrement}
                            >
                              <FontAwesomeIcon color="blue" fontSize={18} icon={faPlus} />
                            </Button>
                          </div>
                        </div>
                        <div>
                          <div className=" col-md-6  mt-2">
                            <label htmlFor="inputPetsSpecies">Gender*</label>
                            <select id="inputPetsSpecies" className="form-control" value={editedPetGender} onChange={(e) => setEditedPetGender(e.target.value)}>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={handleSavePetEdits} style={{ marginRight: 10 }} color="primary">
                          Save
                        </Button>
                        <Button onClick={() => setIsPetEditModalOpen(false)} color="secondary">
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No pets added. Please add a pet.</p>
                    {/* You can add a button or link to initiate the process of adding a pet */}
                  </div>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                lg="12"
                className="p-3 mt-2"
                style={{
                  border: "1px solid #beb7b7",
                  borderRadius: 10,
                }}
              >
                <div className="overflow-hidden petparentdetails">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="col-md-12 petparentdetails d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h4 className="userActivityHeading">User Activity History</h4>
                        </div>
                        <div>
                          <InputGroup>
                            <Button>
                              <FontAwesomeIcon color="white" fontSize={20} icon={faSearch} />
                            </Button>
                            <Input placeholder="Find Something" value={searchQuery} onChange={handleSearchInputChange} />
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr />
                      </div>
                      {searchPerformed
                        ? filteredAppointments.map((appointment, index) => (
                            <div key={index}>
                              <div className="col-md-12 d-flex justify-content-between">
                                <div className="col-md-5">
                                  <h6>{appointment.serviceName}</h6>
                                  <span className="doctorName">{appointment.collaboratorName}</span>
                                </div>
                                <div
                                  className="col-md-4 doctorId"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>#GF5647</span>
                                  <Button
                                    className={`expandId ${visibleAppointments[index] ? null : "collapsed"}`}
                                    aria-expanded={visibleAppointments[index] ? "true" : "false"}
                                    aria-controls={`collapse${index}`}
                                    onClick={() => toggleCollapse(index)}
                                    style={{ marginLeft: 5 }}
                                  >
                                    <FontAwesomeIcon color="White" fontSize={20} icon={buttonClicked[index] ? faAngleUp : faAngleDown} />
                                  </Button>
                                  <Button aria-controls="collapse" style={{ marginLeft: 5 }} onClick={() => window.print()}>
                                    <FontAwesomeIcon color="White" fontSize={20} icon={faPrint} />
                                  </Button>
                                </div>
                              </div>
                              <Collapse isOpen={visibleAppointments[index]} className="mt-2" id={`collapse${index}`}>
                                <div className="col-md-12 d-flex justify-content-between pt-3 collapse">
                                  <div className="col-md-5">
                                    <p className="appointmentDetails">
                                      <strong>Clinic Name:</strong>
                                      <span>{appointment.workspaceName}</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="appointmentDetails">
                                        <strong>Appointment Type:</strong>
                                        <span>{appointment.type}</span>
                                      </p>
                                    </div>
                                    <p className="appointmentDetails">
                                      <strong>Appointment Status:</strong>
                                      <span>{appointment.status}</span>
                                    </p>
                                    <p className="appointmentDetails">
                                      <strong>Appointment Date:</strong>
                                      <span>{localAppointmentDate}</span>
                                    </p>
                                  </div>
                                  <div className="col-md-2"></div>
                                  <div className="col-md-5">
                                    <span>
                                      <strong>Bill Details</strong>
                                    </span>
                                    <hr />
                                    {JSON.parse(appointment.bookedServices).map((service, serviceIndex) => (
                                      <div key={serviceIndex}>
                                        <p>{service.name}</p>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p>Rate:</p> <p>{service.rate}</p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p>Platform Fee:</p> <p>{service.convenienceFee}</p>
                                        </div>
                                        <hr />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p>GST%</p> <p>{service.gstPrice}</p>
                                        </div>
                                        <hr className="totalBill" />
                                        <p>
                                          <strong>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <p> Total Bill </p> <p>{service.totalCost}</p>
                                            </div>
                                          </strong>
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className="appointmentDetails d-flex"
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <strong>Prescription:</strong>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {appointment?.petPrescriptionDTO?.images.map((url, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={url}
                                              alt={`Uploaded ${index + 1}`}
                                              style={{
                                                width: 100,
                                                objectFit: "cover",
                                                margin: "5px",
                                              }}
                                            />
                                            {/* <FontAwesomeIcon
                                              icon={faTrash}
                                              style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => handleDeleteImage(index)}
                                            /> */}
                                          </div>
                                        ))}
                                      </div>
                                      {/* <div>
                                        <Button
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            paddingRight: 20,
                                          }}
                                          onClick={handleOpenPrescriptionImageModal}
                                        >
                                          <FontAwesomeIcon color="blue" fontSize={18} icon={faPenToSquare} />
                                        </Button>
                                      </div> */}
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                      <label>Prescription Text</label>

                                      <textarea
                                        disabled={true}
                                        value={appointment.petPrescriptionDTO?.prescription || null}
                                        type="text"
                                        rows={4}
                                        style={{
                                          width: "80%",
                                          border: "1.25px solid #6b7280",
                                          borderRadius: 5,
                                          backgroundColor: "#f3f4f6",
                                          minHeight: 30,
                                          resize: "vertical",
                                          fontSize: 15,
                                          padding: 8,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))
                        : appointments.map((appointment, index) => (
                            <div key={index}>
                              <div className="col-md-12 d-flex justify-content-between">
                                <div className="col-md-5">
                                  <h6>{appointment.serviceName}</h6>
                                  <span className="doctorName">{appointment.collaboratorName}</span>
                                </div>
                                <div
                                  className="col-md-4 doctorId"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{appointment.id.substring(0, 8)}</span>
                                  <Button
                                    className={`expandId ${visibleAppointments[index] ? null : "collapsed"}`}
                                    aria-expanded={visibleAppointments[index] ? "true" : "false"}
                                    aria-controls={`collapse${index}`}
                                    onClick={() => toggleCollapse(index)}
                                    style={{ marginLeft: 5 }}
                                  >
                                    <FontAwesomeIcon color="White" fontSize={20} icon={buttonClicked[index] ? faAngleUp : faAngleDown} />
                                  </Button>
                                  <Button aria-controls="collapse" style={{ marginLeft: 5 }} onClick={() => window.print()}>
                                    <FontAwesomeIcon color="White" fontSize={20} icon={faPrint} />
                                  </Button>
                                </div>
                              </div>
                              <Collapse isOpen={visibleAppointments[index]} className="mt-2" id={`collapse${index}`}>
                                <div className="col-md-12 d-flex justify-content-between pt-3 collapse">
                                  <div className="col-md-5">
                                    <p className="appointmentDetails">
                                      <strong>Clinic Name:</strong>
                                      <span>{appointment.workspaceName}</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="appointmentDetails">
                                        <strong>Appointment Type:</strong>
                                        <span>
                                          {appointment.serviceName === "Veterinary" &&
                                            appointment.bookedServices &&
                                            JSON.parse(appointment.bookedServices)
                                              .map((service) => service.name)
                                              .join(", ")}
                                          {appointment.serviceName === "Groomers" &&
                                            appointment.bookedServices &&
                                            (() => {
                                              const groomerService = JSON.parse(appointment.bookedServices).find((service) => service.visit);
                                              return groomerService ? groomerService.visit : "Visit information not available";
                                            })()}
                                        </span>
                                      </p>
                                    </div>
                                    <p className="appointmentDetails">
                                      <strong>Appointment Status:</strong>
                                      <span>{appointment.status}</span>
                                    </p>
                                    <p className="appointmentDetails">
                                      <strong>Appointment Date:</strong>
                                      <span>{moment.utc(appointment.createdAt).local().format("DD-MM-YYYY hh:mm A")}</span>
                                    </p>
                                  </div>
                                  <div className="col-md-2"></div>
                                  <div className="col-md-5">
                                    <span>
                                      <strong>Bill Details</strong>
                                    </span>
                                    <hr />
                                    <div>
                                      {/* <b>{appo.name}</b> */}
                                      <p>
                                        <strong>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <p> Total Bill </p> <p>{appointment.paymentDetails && appointment.paymentDetails.total}</p>
                                          </div>
                                        </strong>
                                      </p>
                                      <hr className="totalBill" />
                                      {JSON.parse(appointment.bookedServices).map((service, serviceIndex) => {
                                        const rate = parseFloat(service.rate);
                                        const convenienceFee = parseFloat(service.convenienceFee);
                                        const gstPrice = parseFloat(service.gst);
                                        let inHandsAmount = rate - convenienceFee;

                                        // Joining service names into a single string
                                        const bookedServicesNames = JSON.parse(appointment.bookedServices)
                                          .map((service) => service.name)
                                          .join(", ");

                                        return (
                                          <div key={serviceIndex}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <b>{service.name}</b>
                                              <p>{service.rate}</p>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p>Platform Fee:</p> <p>{appointment.paymentDetails && appointment.paymentDetails.platformFee}</p>
                                      </div>
                                      {appointment.paymentDetails && appointment.paymentDetails.discount > 0 && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p>Discount:</p>
                                          <p>{appointment.paymentDetails.discount}</p>
                                        </div>
                                      )}

                                      {appointment.paymentDetails && appointment.paymentDetails.coupon && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p>Coupon(Used):</p>
                                          <p>{appointment.paymentDetails.coupon}</p>
                                        </div>
                                      )}
                                      {appointment.paymentDetails && appointment.paymentDetails.gst && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p>GST:</p>
                                          <p>{appointment.paymentDetails.gst}</p>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {/* <p>Partner Inhand Amount:</p> <p>{}</p> */}
                                      </div>
                                      {/* {appointment.serviceName === "Groomers" && (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <p>GST%</p> <p>{parseFloat(service.gst)}</p>
                                            </div>
                                          )} */}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="appointmentDetails d-flex"
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <strong>Prescription:</strong>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {appointment?.petPrescriptionDTO?.images.map((url, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={url}
                                              alt={`Uploaded ${index + 1}`}
                                              style={{
                                                width: 100,
                                                objectFit: "cover",
                                                margin: "5px",
                                              }}
                                            />
                                            {/* <FontAwesomeIcon
                                              icon={faTrash}
                                              style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => handleDeleteImage(index)}
                                            /> */}
                                          </div>
                                        ))}
                                      </div>
                                      {/* <div>
                                        <Button
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            paddingRight: 20,
                                          }}
                                          onClick={handleOpenPrescriptionImageModal}
                                        >
                                          <FontAwesomeIcon color="blue" fontSize={18} icon={faPenToSquare} />
                                        </Button>
                                      </div> */}
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                      <label>Prescription Text</label>

                                      <textarea
                                        disabled={true}
                                        value={appointment.petPrescriptionDTO?.prescription || null}
                                        type="text"
                                        rows={4}
                                        style={{
                                          width: "80%",
                                          border: "1.25px solid #6b7280",
                                          borderRadius: 5,
                                          backgroundColor: "#f3f4f6",
                                          minHeight: 30,
                                          resize: "vertical",
                                          fontSize: 15,
                                          padding: 8,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          ))}
                      <Modal isOpen={editAddressModalOpen} toggle={closeEditAddressModal}>
                        <ModalHeader toggle={closeEditAddressModal}>Edit Address</ModalHeader>
                        <ModalBody>
                          <div className="form-group">
                            <label htmlFor="type">Type:</label>
                            <div className="form-check form-check-inline mx-3">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Home"
                                value="Home"
                                checked={editedType === "Home"}
                                onChange={() => setEditedType("Home")}
                              />
                              <label className="form-check-label" htmlFor="Home">
                                Home
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Work"
                                value="Work"
                                checked={editedType === "Work"}
                                onChange={() => setEditedType("Work")}
                              />
                              <label className="form-check-label" htmlFor="Work">
                                Work
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Hotel"
                                value="Hotel"
                                checked={editedType === "Hotel"}
                                onChange={() => setEditedType("Hotel")}
                              />
                              <label className="form-check-label" htmlFor="Hotel">
                                Hotel
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Other"
                                value="Other"
                                checked={editedType === "Other"}
                                onChange={() => setEditedType("Other")}
                              />
                              <label className="form-check-label" htmlFor="Other">
                                Other
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="flatNo">Flat No:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="flatNo"
                              value={editedFlatNo}
                              onChange={(e) => setEditedFlatNo(e.target.value)}
                              placeholder="Enter Flat No."
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="address">Address:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              value={editedAddressess}
                              onChange={(e) => setEditedAddress(e.target.value)}
                              placeholder="Enter Address"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="pincode">Pincode:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="pincode"
                              value={editedPincode}
                              onChange={(e) => setEditedPincode(e.target.value)}
                              placeholder="Enter Pincode"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="city">City:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              value={editedCitys}
                              onChange={(e) => setEditedCitys(e.target.value)}
                              placeholder="Enter City"
                            />
                          </div>
                          <div className="form-group">
                            <label>Location*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Latitude, Longitude"
                              value={editedLatLng}
                              onChange={(e) => setEditedLatLng(e.target.value)}
                            />
                          </div>
                        </ModalBody>

                        <ModalFooter>
                          <Button color="primary" onClick={handleEditAddressSubmit}>
                            Save Changes
                          </Button>
                          <Button color="secondary" onClick={closeEditAddressModal}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <Modal isOpen={modalOpens} toggle={toggleModals}>
                        <ModalHeader toggle={toggleModals}>Add Address</ModalHeader>
                        <ModalBody>
                          <div className="form-group">
                            <label htmlFor="flatNo">Type:</label>
                            <div className="form-check form-check-inline mx-3 ">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Home"
                                value="Home"
                                checked={type === "Home"}
                                onChange={() => setType("Home")}
                              />
                              <label className="form-check-label" htmlFor="Home">
                                Home
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Work"
                                value="Work"
                                checked={type === "Work"}
                                onChange={() => setType("Work")}
                              />
                              <label className="form-check-label" htmlFor="Work">
                                Work
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Hotel"
                                value="Hotel"
                                checked={type === "Hotel"}
                                onChange={() => setType("Hotel")}
                              />
                              <label className="form-check-label" htmlFor="Hotel">
                                Hotel
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="Other"
                                value="Other"
                                checked={type === "Other"}
                                onChange={() => setType("Other")}
                              />
                              <label className="form-check-label" htmlFor="Other">
                                Other
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="flatNo">Flat No:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="flatNo"
                              value={flatNo}
                              onChange={(e) => setFlatNo(e.target.value)}
                              placeholder="Enter Flat No."
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="address">Address:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder="Enter Address"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="pincode">Pincode:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="pincode"
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                              placeholder="Enter Pincode"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="city">City:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              placeholder="Enter City"
                            />
                          </div>
                          <div className="form-group">
                            <label>Location*</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Latitude, Longitude"
                              value={latLng}
                              onChange={(e) => setLatLng(e.target.value)}
                            />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={addAddress}>
                            Save
                          </Button>
                          <Button color="secondary" onClick={toggleModal}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Edit Prescription</ModalHeader>
                        <ModalBody>
                          <div>
                            <label>Edit Prescription Text</label>
                            <textarea
                              value={editedPrescription}
                              onChange={(e) => setEditedPrescription(e.target.value)}
                              type="text"
                              rows={4}
                              style={{
                                width: "100%",
                                border: "1.25px solid #6b7280",
                                borderRadius: 5,
                                backgroundColor: "#f3f4f6",
                                minHeight: 30,
                                resize: "vertical",
                                fontSize: 15,
                                padding: 8,
                              }}
                            />
                          </div>
                          <div>
                            <label>Upload Prescription Image(s)</label>
                            <input type="file" accept="image/*" multiple onChange={handleImageUpload} />
                            <div>
                              {uploadedImages.map((url, index) => (
                                <img
                                  key={index}
                                  src={url}
                                  alt={`Uploaded ${index + 1}`}
                                  style={{
                                    width: "100px",
                                    objectFit: "cover",
                                    margin: "5px",
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={toggleModal}>
                            Save Changes
                          </Button>
                          <Button color="secondary" onClick={toggleModal}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 20 }}>
            <h5>Internal Note</h5>
            <div className="chat-container">
              <div className="chat-messages">
                {messages.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <div className="message-text">{message.text}</div>
                    <div className="message-time">{getCurrentLocalDateTime()}</div>
                  </div>
                ))}
                {internalNotes.map((note) => (
                  <div key={note.id} className={`message bot`}>
                    <div className="message-text">{note.note}</div>
                    <div className="message-time">{formatTimestampToLocalDateTime(note.createdAt)}</div>
                  </div>
                ))}
              </div>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleInputSubmit} className="input-form">
                <textarea
                  className="form-control"
                  rows={5}
                  type="text"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
                <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                  <button className="form-control" style={{ width: 100 }} type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          <ConfirmDialog
            isOpen={isConfirmOpen}
            toggle={() => setIsConfirmOpen(!isConfirmOpen)}
            onConfirm={handleDelete}
            message="Are you sure you want to Remove the Listing?"
          />
          <ConfirmDialog
            isOpen={isAddressConfirmOpen}
            toggle={() => setIsAddressConfirmOpen(!isAddressConfirmOpen)}
            onConfirm={() => handleDeleteAddress(itemId)}
            message="Are you sure you want to Remove the address?"
          />
        </div>
      )}
    </>
  );
};
const styles = {
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    color: "white",
    padding: "10px",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  findInput: {
    marginRight: "10px",
  },
  rightButtons: {
    display: "flex",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  exportButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  addButton: {
    borderColor: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  dotsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  dot: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#6B7280",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 5px",
    position: "relative",
  },
  moreDogsMessage: {
    marginLeft: "10px",
    color: "red",
  },
  tooltip: {
    position: "absolute",
    top: "-25px", // Adjust this value to position the tooltip correctly
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#000",
    color: "#FFF",
    padding: "5px",
    borderRadius: "5px",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
};
export default PetParentDetails;
