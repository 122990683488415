import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "reactstrap";
const NewMail = () => {
  const [bodyText, setBodyText] = useState("");

  const handleEditorChange = (content) => {
    setBodyText(content);
  };
  const [isScheduleChecked, setIsScheduleChecked] = useState(false);

  const handleScheduleToggle = () => {
    setIsScheduleChecked(!isScheduleChecked);
  };

  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
      <span style={{ fontSize: 20 }}>
        <strong>New Mail</strong>
      </span>
      <div style={{ flexDirection: "row" }} className="mt-2">
        <span>Select Template</span>
        <div class="col-md-6 mt-1">
          <div>
            <select
              v-model="role"
              class="form-control"
              id="role"
              aria-describedby="role"
            >
              <option value="admin">OTP</option>
              <option value="manager">Email</option>
            </select>
          </div>
        </div>
        <div className="mt-3">
          <span>From*</span>
          <div class="col-md-12 mt-1">
            <div>
              <select
                v-model="role"
                class="form-control"
                id="role"
                aria-describedby="role"
              >
                <option value="admin">support@furrcrew.com</option>
                <option value="manager">supportbvur@furrcrew.com</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <span>To*</span>
          <div class="col-md-12 mt-1">
            <div>
              <input
                class="form-control"
                placeholder="Enter receiver’s mail"
                type="Email"
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <span>Subject*</span>
          <div class="col-md-12 mt-1">
            <div>
              <input
                class="form-control"
                placeholder="Give a subject to your mail"
              />
            </div>
          </div>
        </div>
      
        <div className="mt-3">
        <span>Body Text*</span>
        <div className="col-md-12 mt-1">
          <Editor
            apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
            value={bodyText} 
            init={{
              selector: "textarea#basic-example",
              height: 500,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
            }}
            onEditorChange={handleEditorChange}  
          />
        </div>
      </div>
      <div className=" pt-4">
              <div className=" col-md-6">
              <label className="switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="scheduleCheckbox"
                    checked={isScheduleChecked}
                    onChange={handleScheduleToggle}
                  />
                  <span className="slider round"></span>
                </label>
                <span style={{marginLeft:5}}><b>Schedule Mail</b></span>
              </div>
            </div>
            <div className={`form-row ${isScheduleChecked ? 'collapse show' : 'collapse'}`} id="couponToggle">
              <div className="form-group col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label>Start Date</label>
                    <input type="date" className="form-control" id="startDate" />
                  </div>
                  <div className="col-md-6">
                    <label>End Date</label>
                    <input type="date" className="form-control" id="endDate" />
                  </div>
                </div>
              </div>
            </div>
      <div style={{flexDirection:'row'}} className="mt-3">
      <Button style={{marginRight:5,backgroundColor:'#0514A7'}}>Send Mail</Button>
      <Button style={{background:'#F43F5E',border:'none',}}>Cancel</Button>
      </div>
      </div>
    </div>
  );
};

export default NewMail;
