import React, { useState } from "react";
import { CardGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faAdd } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import Apis from "../utils/api";
import { useNavigate } from "react-router-dom";
import dataURItoBlob from "../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import MyComponent from "../component/common/GoogleMaps";
import moment from "moment/moment";
import LoaderModal from "../component/common/Loader";

const EventAddress = () => {
  const api = new Apis();
  const navigate = useNavigate();
  const [bodyText, setBodyText] = useState("");
  const [title, setTitle] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [link, setLink] = useState("");
  const [tags, setTags] = useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [listingType, setListingType] = useState("Online");
  const [eventDateTime, setEventDateTime] = useState(""); 
  const [loading, setLoading] = useState(false);
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const unixTimestamp = moment(inputDate).unix();
    setEventDateTime(unixTimestamp);
  };
  const handleListingTypeChange = (event) => {
    setListingType(event.target.value);
  };
  const handleEditorChange = (content) => {
    setDescription(content);
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files) {
      const imageArray = [];
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imageArray.push(e.target.result);
          // Check if this is the last image to load
          if (imageArray.length === files.length) {
            setSelectedImage(imageArray);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };
  const handleImageDelete = () => {
    setSelectedImage(null);
  };
  const timeOptions = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);
  const [addressess, setAddressess] = useState([
    {
      address: "",
      city: "",
      state: "",
      pinCode: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [entryFees, setEntryFees] = useState([
    {
      entryFee: "",
      ticketType: "",
      maxPerson: "",
    },
  ]);
  const addEntryFee = () => {
    setEntryFees([
      ...entryFees,
      { entryFee: "", ticketType: "", maxPerson: "" },
    ]);
  };

  const removeEntryFee = (index) => {
    if (entryFees.length === 1) {
      return; // Prevent removing the last entry fee
    }
    const updatedEntryFees = [...entryFees];
    updatedEntryFees.splice(index, 1);
    setEntryFees(updatedEntryFees);
  };
  const [addresses, setAddresses] = useState([
    {
      address: "",
      city: "",
      state: "",
      pincode: "",
      latitude: "18.123123",
      longitude: "18.8756545",
    },
  ]);

  const addAddress = () => {
    setAddresses([
      ...addresses,
      {
        address: "",
        city: "",
        state: "",
        pincode: "",
        latitude: "",
        longitude: "",
      },
    ]);
  };

  const removeAddress = (index) => {
    if (addresses.length === 1) {
      return; // Prevent removing the last address
    }
    const updatedAddresses = [...addresses];
    updatedAddresses.splice(index, 1);
    setAddresses(updatedAddresses);
  };
  const EventAdd = async () => {
    setLoading(true);
    try {
      // Basic validation checks
      // if (!eventDateTime || !title || !description || !link || !listingType || !entryFee || !address || !city || !state || !pincode) {
      //   throw new Error("Missing required fields. Please fill in all required fields.");
      // }

      if (entryFees.length === 0) {
        throw new Error("You must specify at least one entry fee.");
      }
      const prices = entryFees.map((entryFee) => ({
        eventPrice: entryFee.entryFee,
        ticketType: entryFee.ticketType,
        maxPerson: entryFee.maxPerson,
      }));

      const addressObjects = addresses.map((address) => ({
        address: address.address,
        city: address.city,
        state: address.state,
        pinCode: address.pincode,
        latitude: "123.1231",
        longitude: "123.123",
      }));

      const requestBody = {
        scheduledDate: eventDateTime,
        title: title,
        description: description,
        sheetLink: link,
        eventType: listingType,
        entryFee: "500",
        status: "Active",
        likes: "2",
        shares: "4",
        addresses: addressObjects,
        prices: prices,
      };

      const response = await api.createEvents(requestBody);
      toast.success("Event added successfully!");
      console.log(response);
      if (response.id) {
        console.log(response.id);
        await uploadImage(response.id);
        toast.success("Event image added successfully!");

        setLoading(false);
      } else {
        throw new Error("Response ID is undefined.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error adding event:", error);
      toast.error("Error adding event: " + error.message);
      setLoading(false);
    }
  };
  const [cityInput, setCityInput] = useState("");
  const [availableCities, setAvailableCities] = useState([]);
  const uploadImage = async (userId, imageList) => {
    try {
      if (!imageList || imageList.length === 0) {
        throw new Error("No images selected for upload.");
      }

      console.log(userId);

      for (const selectedImage of imageList) {
        const formData = new FormData();
        const currentTimestamp = new Date().getTime();
        const formattedTimestamp = new Date(currentTimestamp).toISOString().replace(/[-:.]/g, "");
        const fileName = `image_${formattedTimestamp}.png`;
        formData.append("files", dataURItoBlob(selectedImage),fileName);
        const response = await api.uploadEventImage(userId, formData);
        console.log("Img", response);
      }

      navigate(-1);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };
  const [sections, setSections] = useState([1]);
  // ...

  const addEmptySection = () => {
    setSections([...sections, {}]); // Add an empty object to the sections array
  };

  const removeSection = (index) => {
    if (index === 0) {
      // Prevent removing the initial section
      return;
    }
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };
  return (
    <>
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div className="row mt-2 container">
          <div
            style={{ display: "flex", justifyContent: "end", marginBottom: 5 }}
          >
            <FontAwesomeIcon
              color="#0514A7"
              c
              style={{ cursor: "pointer" }}
              onClick={addEmptySection}
              fontSize={25}
              icon={faAdd}
            />
          </div>
          <ToastContainer position="top-right" autoClose={5000} />
          <div
            className="col-md-12 p-3 mx-3 "
            style={{ border: "1px solid #beb7b7", borderRadius: 10 }}
          >
            {sections.map((section, index) => (
              <div key={index}>
                {index === 0 ? null : ( // Hide delete button for the initial section
                  <FontAwesomeIcon
                    color="#0514A7"
                    style={{ cursor: "pointer", marginTop: 20 }}
                    onClick={() => removeSection(index)}
                    fontSize={20}
                    icon={faTrash}
                  />
                )}

                <div
                  className="d-flex form-group"
                  style={{ justifyContent: "space-between" }}
                >
                  <h4 className="">Event Address</h4>

                  {/* <FontAwesomeIcon
                    color="#0514A7"
                    c
                    style={{ cursor: "pointer" }}
                    onClick={addAddress}
                    fontSize={25}
                    icon={faAdd}
                  /> */}
                </div>
                <div>
                  {addresses.map((address, index) => (
                    <div key={index}>
                      <div
                        className="col-md-12 form-group "
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {index === 0 ? null : (
                          <FontAwesomeIcon
                            color="#0514A7"
                            style={{ cursor: "pointer", marginTop: 20 }}
                            onClick={() => removeAddress(index)}
                            fontSize={20}
                            icon={faTrash}
                          />
                        )}
                      </div>
                      <div className="form-group mt-2">
                        <label className="lableText">
                          Enter City
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          className="form-control form-control-lg mt-2"
                          placeholder="Enter City"
                          value={address.city}
                          onChange={(e) => {
                            const updatedAddresses = [...addresses];
                            updatedAddresses[index].city = e.target.value;
                            setAddresses(updatedAddresses);
                          }}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="lableText">
                          Enter State
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="State"
                          className="form-control form-control-lg mt-2"
                          placeholder="Enter state"
                          value={address.state}
                          onChange={(e) => {
                            const updatedAddresses = [...addresses];
                            updatedAddresses[index].state = e.target.value;
                            setAddresses(updatedAddresses);
                          }}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="lableText">
                          Event Pincode
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="blogTitle"
                          className="form-control form-control-lg mt-2"
                          placeholder="Eneter Pincode"
                          value={address.pincode}
                          onChange={(e) => {
                            const updatedAddresses = [...addresses];
                            updatedAddresses[index].pincode = e.target.value;
                            setAddresses(updatedAddresses);
                          }}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="lableText">
                          Event Address
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="blogTitle"
                          className="form-control form-control-lg mt-2"
                          placeholder="Event Address"
                          value={address.address}
                          onChange={(e) => {
                            const updatedAddresses = [...addresses];
                            updatedAddresses[index].address = e.target.value;
                            setAddresses(updatedAddresses);
                          }}
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label className="lableText">
                          Location
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="blogTitle"
                          className="form-control form-control-lg mt-2"
                          placeholder="Event Address"
                          value={address.longitude}
                          onChange={(e) => {
                            const updatedAddresses = [...addresses];
                            updatedAddresses[index].longitude = e.target.value;
                            setAddresses(updatedAddresses);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="container mt-3">
                  <div>
                    <h4 className="form-group">Event Price</h4>
                    <div>
                      <div className="form-group row mt-2">
                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FontAwesomeIcon
                            color="#0514A7"
                            style={{ cursor: "pointer" }}
                            onClick={addEntryFee}
                            fontSize={25}
                            icon={faAdd}
                          />
                        </div>
                      </div>
                      <div>
                        {entryFees.map((entry, index) => (
                          <div>
                            <div key={index} className="form-group row mt-2">
                              <div className="col-md-12">
                                <label htmlFor="inputPetParent">
                                  Select Event City
                                </label>
                                <select
                                  id="inputPetParent"
                                  className="form-control"
                                >
                                  {addresses.map((address) => (
                                    <option
                                      key={address.city}
                                      value={address.city}
                                    >
                                      {address.city}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6 ">
                                <label className="lableText">
                                  Event Entry Fee
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="number"
                                  name="entryFee"
                                  className="form-control form-control-lg mt-2"
                                  placeholder="Enter Event Fee"
                                  value={entry.entryFee}
                                  onChange={(e) => {
                                    const updatedEntryFees = [...entryFees];
                                    updatedEntryFees[index].entryFee =
                                      e.target.value;
                                    setEntryFees(updatedEntryFees);
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <label>Ticket Type</label>
                                <input
                                  type="text"
                                  name="ticketType"
                                  className="form-control form-control-lg mt-2"
                                  value={entry.ticketType}
                                  onChange={(e) => {
                                    const updatedEntryFees = [...entryFees];
                                    updatedEntryFees[index].ticketType =
                                      e.target.value;
                                    setEntryFees(updatedEntryFees);
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <label>Max Person</label>
                                <input
                                  type="number"
                                  name="maxPerson"
                                  className="form-control form-control-lg mt-2"
                                  value={entry.maxPerson}
                                  onChange={(e) => {
                                    const updatedEntryFees = [...entryFees];
                                    updatedEntryFees[index].maxPerson =
                                      e.target.value;
                                    setEntryFees(updatedEntryFees);
                                  }}
                                />
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {index === 0 ? null : (
                                  <FontAwesomeIcon
                                    color="#0514A7"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeEntryFee(index)}
                                    fontSize={20}
                                    icon={faTrash}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>
            ))}
          </div>
          <div className="form-group">
            <div className="mt-5">
              <button
                onClick={EventAdd}
                type="submit"
                className="btn btn-primary modalFooterButton"
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary modalFooterButton"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventAddress;
