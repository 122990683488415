import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import NotificationTopBar from '../../component/NotificationTopBar';
import { useNavigate } from 'react-router-dom';
const data = [
  {
    name: 'Otto',
    designation: 'Pune',
    userName: 'VSHARMA02',
    dateJoined: '15 Apr 2023',
    access: '15 Apr 2023',
  },
];

const NotificationList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = ['checkbox', ...Object.keys(data[0])];

  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <NotificationTopBar />
      <Table hover>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                {column === 'checkbox' ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === data.length}
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === data.length ? [] : data.map((_, index) => index)
                      )
                    }
                  />
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
             <tr key={rowIndex}
             >
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => toggleRowSelection(rowIndex)}
                />
              </td>
              {columns.slice(1).map((column, colIndex) => (
                <td  onClick={() => navigate("/NotificationList/EditNotification")}
                style={{ cursor: "pointer" }} key={colIndex}>
                      {item[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default NotificationList;
