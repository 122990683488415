import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "reactstrap";
import Apis from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../component/common/Loader";
import { useNavigate } from "react-router-dom";
const AddFaq = () => {
  const api = new Apis();
  const [bodyText, setBodyText] = useState("");
  const [header, setHeader] = useState("");
  const navigate = useNavigate();
  const handleEditorChange = (content) => {
    setBodyText(content);
  };
  const [type, setType] = useState("user");
  const [loading, setLoading] = useState(false);
  const handleType = (event) => {
    setType(event.target.value);
  };
  const FaqAdd = async () => {
    try {
      if (!header) {
        throw new Error("Please add title.");
      }
      if (!bodyText) {
        throw new Error("Please add description.");
      }
      const requestBody = {
        title: header,
        description: bodyText,
        parentId: "1",
        status: true,
        type: type,
      };
      setLoading(true);
      const response = await api.addfaq(requestBody);
      toast.success("Faq Edited SuccessFully");
      setLoading(false);
      navigate(-1);
      console.log(response);
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error("Error adding admin:", error);
      setLoading(false);
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      {loading ? (
        <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
      ) : (
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          <span style={{ fontSize: 20 }}>
            <strong>Faq Details</strong>
          </span>
          <div style={{ flexDirection: "row",}} className="">
          <span>Type*</span>
            <div className="form-check form-check-inline mx-2">
              <input className="form-check-input" type="radio" name="user" id="user" value="user" checked={type === "user"} onChange={handleType} />
              <label className="form-check-label" htmlFor="user">
                User
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="collaborator"
                id="collaborator"
                value="collaborator"
                checked={type === "collaborator"}
                onChange={handleType}
              />
              <label className="form-check-label" htmlFor="collaborator">
                Partner
              </label>
            </div>
          </div>
          <div style={{ flexDirection: "row" }} className="mt-2">
            <div className="mt-3">
              <span>Faq Header*</span>
              <div class="col-md-12 mt-1">
                <div>
                  <input class="form-control" placeholder="Enter Mail Header" value={header} onChange={(e) => setHeader(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <span>Faq Body*</span>
              <div className="col-md-12 mt-1">
                <Editor
                  apiKey="mp95ob76ec4jr2gh2whh64cc5evlimtmplqq4b5z9jn8zebf"
                  value={bodyText}
                  init={{
                    selector: "textarea#basic-example",
                    height: 500,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 16px; }",
                  }}
                  onEditorChange={handleEditorChange}
                />
              </div>
            </div>
            <div style={{ flexDirection: "row", justifyContent: "flex-end", display: "flex" }} className="mt-3">
              <button onClick={FaqAdd} type="submit" className="btn btn-primary modalFooterButton">
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddFaq;
