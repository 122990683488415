import React, { useState, useEffect } from "react";
import { Table, Button, Form, FormGroup, Label, Input,Dropdown, DropdownToggle, DropdownMenu, DropdownItem , Pagination, PaginationItem, PaginationLink} from "reactstrap";
import { Link } from "react-router-dom";
import EventTopBar from "../../component/EventTopBar";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api";
import { useEventState } from "../services/eventState";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal from "../../component/common/Loader";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faPen,faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../component/common/ConfirmDialog";
const EventList = () => {
  const api = new Apis();
  const { setEventData } = useEventState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [eventList, setEventList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [loading, setLoading] = useState(false);
  const [state,setState]=useState(true);
  const filteredEvent = eventList.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const titleLower = (item.title || "").toLowerCase();
    const eventTypeLower = (item.eventType || "").toLowerCase();
    const petNameLower = (item.petName || "").toLowerCase();
    return (
      (!state || item.status === "Active") && 
      (titleLower.includes(searchQueryLower) ||
      eventTypeLower.includes(searchQueryLower) ||
      petNameLower.includes(searchQueryLower))
    );
  });
  
  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };
  const totalPages = Math.ceil(eventList.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const currentItems = filteredEvent.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const eventList = await api.getEventList();
        setEventData(eventList);
        setEventList(eventList);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };
    fetchEventList();
  }, []);
  const customTableHeadings = [
    "checkbox",
    "ID",
    "Event Name",
    "City",
    "Event Date",
    "Event Type",
    "Event Price",
    "Status",
    "Actions"
  ];
  const handleToggleChange = async (event) => {
    const itemId = event.target.value;
    const updatedEvent = [...eventList];
    const itemToUpdate = updatedEvent.find((item) => item.id === itemId);
    if (itemToUpdate) {
      try {
        // If the switch is turned on, set status to "Active" and active to true
        // If the switch is turned off, set status to "Pending" and active to false
        itemToUpdate.status = event.target.checked ? "Active" : "Inactive";
        await eventEdit(itemToUpdate);
        setEventList(updatedEvent);
      } catch (error) {
        console.error("Error toggling Event status:", error);
        // If there's an error, revert the status and active changes
        itemToUpdate.status = event.target.checked ? "Inactive" : "Active";
        setEventList(updatedEvent);
      }
    }
  };
  const eventEdit = async (itemToUpdate) => {
  
    try {
      const requestBody = {
        id: itemToUpdate.id,
        scheduledDate: itemToUpdate.scheduledDate,
        title: itemToUpdate.title,
        description: itemToUpdate.description,
        bookingLink: itemToUpdate.bookingLink,
        eventType: itemToUpdate.eventType,
        entryFee: itemToUpdate.entryFee,
        status: itemToUpdate.status,
        likes: itemToUpdate.likes,
        shares: itemToUpdate.shares,
        address: {
          address: itemToUpdate.address.address,
          city: itemToUpdate.address.city,
          state: itemToUpdate.address.state,
          pinCode: itemToUpdate.address.pincode,
          latitude: itemToUpdate.address.latitude,
          longitude: itemToUpdate.address.longitude,
        },
        prices: itemToUpdate.prices,
        deviceType:itemToUpdate.deviceType,
        terms: itemToUpdate.terms,
        slug:itemToUpdate.slug
      };
      setLoading(true);
      const response = await api.updateEvents(itemToUpdate.id, requestBody);
      setLoading(false);
      toast.success("Event Status Changed!");
      console.log(response);
    } catch (error) {
      console.error("Error adding admin:", error);
      toast.success("Event Status Cant be Changed!");
      setLoading(false);
    }
  };
  const toggleRowSelection = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
    }
  };
  const navigate = useNavigate();
  const exportSelectedRowsToCSV = () => {
    const selectedData = selectedRows.map((rowIndex) => eventList[rowIndex]);
    if (selectedData.length === 0) {
      alert("No rows selected for export.");
      return;
    }
    const headers = Object.keys(selectedData[0]);
    const csvContent = [headers.join(","), ...selectedData.map((rowData) => headers.map((header) => rowData[header]).join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const fileName = "selected_rows.csv";
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleEdit = (item) => {
    navigate(`/EventList/EditEventDetails/${item.id}`);
  };
  const handleDelete = async (item, rowIndex) => {
    try {
      setLoading(true);
      const response = await api.deleteEvent(item.id);
      setLoading(false);
      // Calculate the index in the original event array
      const originalIndex = indexOfFirstItem + rowIndex;
      // Remove the deleted item from the state
      setEventList((prevEvents) => {
        const updatedEvents = [...prevEvents];
        updatedEvents.splice(originalIndex, 1);
        return updatedEvents;
      });
      // Clear the selectedRows state
      setSelectedRows([]);
      toast.success("Event deleted successfully!");
      navigate('/EventList');
      console.log(response);
    } catch (error) {
      console.error("Error deleting the Event:", error);
    } finally {
      setLoading(false);
    }
  };
  const [openDropdowns, setOpenDropdowns] = useState(Array(currentItems.length).fill(false));
  const toggleDropdown = (rowIndex) => {
    const updatedDropdowns = [...openDropdowns];
    updatedDropdowns[rowIndex] = !updatedDropdowns[rowIndex];
    setOpenDropdowns(updatedDropdowns);
  };
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [selectedrowIndex,setRowIndex]=useState();
  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  const [copiedItems, setCopiedItems] = useState({});
  const copyToClipboard = (text, itemIndex) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedItems((prevCopiedItems) => ({
      ...prevCopiedItems,
      [itemIndex]: true,
    }));
    setTimeout(() => {
      setCopiedItems((prevCopiedItems) => ({
        ...prevCopiedItems,
        [itemIndex]: false,
      }));
    }, 2000);
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} />
    {loading ? (
      <LoaderModal isOpen={loading} toggle={() => setLoading(false)} />
    ) : (   
    <>
      <EventTopBar setSearchQuery={setSearchQuery} onClick={exportSelectedRowsToCSV} />
      <Button onClick={() => setState(!state)}>{state ? "Show All" : "Show Active"}</Button>
      <Table hover>
      <thead style={{position:'sticky',top:0,zIndex:1}}>
          <tr>
            {customTableHeadings.map((heading, index) => (
              <th key={index} style={{ whiteSpace: "nowrap" }}>
                {heading === "checkbox" ? (
                  <input
                    type="checkbox"
                    checked={selectedRows.length === currentItems.length}
                    onChange={() => setSelectedRows(selectedRows.length === currentItems.length ? [] : currentItems.map((_, index) => index))}
                  />
                ) : (
                  heading
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {customTableHeadings.map((heading, colIndex) => (
                <td key={colIndex}>
                  {heading === "Actions" ? (
                        <Dropdown style={{textAlign:'center'}} isOpen={openDropdowns[rowIndex]} toggle={() => toggleDropdown(rowIndex)}>
                          <DropdownToggle tag="span" data-toggle="dropdown" onClick={(e) => e.preventDefault()}>
                          <Button style={{ background: "none", border: "none" }}>
                            <FontAwesomeIcon color="blue" fontSize={18} icon={faEllipsisVertical} />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu right>
                          <DropdownItem onClick={() => handleEdit(item)} style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="blue" fontSize={18} icon={faPen} />
                              <span style={{ marginLeft: 10 }}>Edit</span>
                            </DropdownItem>
                            <hr style={{margin:0}} />
                            <DropdownItem
                              style={{ display: "flex", alignItems: "center" }}
                              onClick={() => {
                                setIsConfirmOpen(true);
                                setItemId(item);
                                setRowIndex(rowIndex);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginLeft: 10 }} color="red" fontSize={18} icon={faTrash} />
                              <span style={{ marginLeft: 10 }}> Delete</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) :heading === "ID" ? (
                        <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(item.id, rowIndex)} title={item.id}>
                          {item.id.slice(0, 8)}
                          {copiedItems[rowIndex] && <span style={{ color: "green", marginLeft: 10 }}>Copied!</span>}
                        </span>
                  ) : heading === "Event Name" ? (
                    <a href onClick={() => navigate(`/EventList/EditEventDetails/${item.id}`)} style={{ cursor: "pointer" }}>
                      {item.title}
                    </a>
                  ) : heading === "checkbox" ? (
                    <input type="checkbox" checked={selectedRows.includes(rowIndex)} onChange={() => toggleRowSelection(rowIndex)} />
                  ) : heading === "Event Type" ? (
                    item.eventType
                  ) : heading === "City" ? (
                    item.address.city
                  ) : heading === "Status" ? (
                    <Form>
                      <FormGroup switch>
                        <Input
                          type="switch"
                          role="switch"
                          checked={item.status === "Active"} // Check only if item.status is "Active"
                          value={item.id}
                          onChange={handleToggleChange}
                        />
                      </FormGroup>
                    </Form>
                  ) : heading === "Event Price" ? (
                    item.prices ? (
                      item.prices.map((price) => (
                        <div key={price.id}>
                          {price.ticketType}: ₹{price.eventPrice} (Max Persons: {price.maxPerson})
                        </div>
                      ))
                    ) : (
                      "No prices available" // Handle the case where prices is undefined
                    )
                  ) : heading === "Event Date" ? (
                    moment(item.scheduledDate).local().format("YYYY-MM-DD HH:mm A")
                  ) : (
                    item[heading]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <Label for="itemsPerPageSelect">Items per page:</Label>
            <Input
              type="select"
              name="itemsPerPageSelect"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
            >
              {[5, 10, 15, 20, 30,40,50].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={handlePreviousClick} />
                </PaginationItem>
                {pageNumbers.map((pageNum) => (
                  <PaginationItem key={pageNum} active={pageNum === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(pageNum)}>{pageNum}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={handleNextClick} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        toggle={() => setIsConfirmOpen(!isConfirmOpen)}
        onConfirm={() => handleDelete(itemId,selectedrowIndex)}
        message="Are you sure you want to remove the listing?"
      />
    </>
    )}
    </>
  );
};

export default EventList;
