import React from "react";
import { Button } from "reactstrap";
const MyAccount = () => {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-4 text-center">
          <span style={{ fontSize: 20 }}>
            <strong>My Account</strong>
          </span>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-4">
          <div>
            <span>Name*</span>
            <input className="form-control" placeholder="Enter your name" />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-4">
          <div>
            <span>Designation*</span>
            <input
              className="form-control"
              placeholder="Enter your designation"
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-4">
          <div>
            <span>E-mail address*</span>
            <input
              className="form-control"
              placeholder="Enter your email address"
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-4">
          <div>
            <span>Password*</span>
            <input
              className="form-control"
              placeholder="Enter your password"
            />
          </div>
        </div>
        <hr className="mt-4" />
        <div style={{flexDirection:'row',justifyContent:'center',display:'flex'}}>    <Button style={{marginRight:5,backgroundColor:'#0514A7'}}>Edit</Button>
      <Button style={{background:'#F43F5E',border:'none',}}>Back</Button></div>
    
      </div>
    </div>
  );
};

export default MyAccount;

